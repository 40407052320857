import React from "react";

import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext } from "react-admin";

import { VehicleActivateButton } from "./VehicleActivateButton";
import { VehicleDeactivateButton } from "./VehicleDeactivateButton";
import { VehicleForm } from "./VehicleForm";
import { EditToolbar } from "components";

const VehicleEditToolbar = () => {
  const record = useRecordContext();
  return (
    <EditToolbar>
      {record.is_active ? (
        <VehicleDeactivateButton />
      ) : (
        <VehicleActivateButton />
      )}
    </EditToolbar>
  );
};

export const VehicleEditButton = () => {
  return (
    <EditInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      title={`Edit vehicle`}
      mutationMode="pessimistic"
    >
      <VehicleForm toolbar={<VehicleEditToolbar />} />
    </EditInDialogButton>
  );
};
