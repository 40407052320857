import React from "react";

import { Edit, useRecordContext } from "react-admin";

import ShopForm from "./ShopForm";
import { EditToolbar } from "components";
import { useCustomMutationOptions } from "hooks";

const ShopTitle = () => {
  const record = useRecordContext();
  return <span>{record?.name}</span>;
};

export const ShopEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<ShopTitle />}
    >
      <ShopForm toolbar={<EditToolbar />} />
    </Edit>
  );
};
