import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  BooleanInput,
  Button,
  Create,
  DateInput,
  Form,
  SaveButton,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";

import { BenefitDefinitionRead } from "@vapaus/generated";

export const DuplicateModal = ({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  if (!record) return null;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Duplicate benefit definition"
      sx={{ form: { display: "contents" } }}
    >
      <Create
        resource={`benefit-definitions/${record.id}/duplicate`}
        title="&nbsp;— Duplicate"
        mutationOptions={{
          onSuccess: (newBenefitDefinition: BenefitDefinitionRead) => {
            onClose();
            notify("Benefit Definition duplicated successfully");
            redirect("edit", "benefit-definitions", newBenefitDefinition.id);
          },
        }}
        redirect={false}
      >
        <Form
          defaultValues={{
            copy_benefit_activations: true,
            start_date: DateTime.now().toISODate(),
          }}
        >
          <DialogTitle>Duplicate benefit definition</DialogTitle>
          <DialogContent>
            <DateInput
              source="start_date"
              label="Start date of the new benefit definition"
              helperText="The source benefit definition will be ended one day before that date."
              fullWidth
            />
            <BooleanInput
              source="copy_benefit_activations"
              label="Copy employee authorisations to the new benefit"
              helperText="Authorised employees will automatically be authorised to order a bike with the new copied benefit. They won’t need to request authorisation again."
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              startIcon={<CancelIcon />}
              label="Cancel"
            />
            <SaveButton
              label="Duplicate"
              alwaysEnable
              icon={<ContentCopyIcon />}
            />
          </DialogActions>
        </Form>
      </Create>
    </Dialog>
  );
};
