import BuildIcon from "@mui/icons-material/Build";
import { ResourceProps } from "react-admin";

import { MaintenanceEventCreate } from "./MaintenanceEventCreate";
import { MaintenanceEventEdit } from "./MaintenanceEventEdit";
import { MaintenanceEventsList } from "./MaintenanceEventsList";

const maintenanceEventsResource: ResourceProps = {
  name: "maintenance-events",
  icon: BuildIcon,
  list: MaintenanceEventsList,
  create: MaintenanceEventCreate,
  edit: MaintenanceEventEdit,
  options: {
    label: "Maintenance Events",
  },
};

export default maintenanceEventsResource;
