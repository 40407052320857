import type { ValidationErrorMessage, Validator } from "react-admin";

import { isEmpty, memoize } from "./utils";

/**
 * Minimum percentage validator
 *
 * Returns an error if the value is lower than the parameter.
 * Meant to work with the percent input as this input divide the value by 100 behind the scene.
 *
 *
 * @example
 *
 * const discountValidators = [minPercent(0)];
 * <PercentInput name="discount" validate={discountValidators} />
 */
export const minPercent = memoize(
  (min: number, message = "ra.validation.minValue"): Validator =>
    (value: number): ValidationErrorMessage | null =>
      !isEmpty(value) && value * 100 < min ? { message, args: { min } } : null,
);

/**
 * Maximum percentage validator
 *
 * Returns an error if the value is higher than the parameter.
 * Meant to work with the percent input as this input divide the value by 100 behind the scene.
 *
 *
 * @example
 *
 * const discountValidators = [maxPercent(100)];
 * <PercentInput name="discount" validate={discountValidators} />
 */
export const maxPercent = memoize(
  (max: number, message = "ra.validation.maxValue"): Validator =>
    (value: number): ValidationErrorMessage | null =>
      !isEmpty(value) && value * 100 > max ? { message, args: { max } } : null,
);
