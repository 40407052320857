/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Plan {
  CUSTOM = "custom",
  LEGACY = "legacy",
  STARTER = "starter",
  PRO = "pro",
  ENTERPRISE = "enterprise",
  SWEDEN_PILOT = "sweden_pilot",
}
