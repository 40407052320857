import { ReactNode } from "react";

// helper component to add actions buttons in a column (children),
// and also in the header (label) of a Datagrid
export const DatagridActionsColumn = ({
  label,
  children,
}: {
  label?: ReactNode;
  children: ReactNode;
}) => <>{children}</>;
