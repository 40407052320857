import AnnouncementIcon from "@mui/icons-material/Announcement";
import { ResourceProps } from "react-admin";

import { AnnouncementCreate } from "./AnnouncementCreate";
import { AnnouncementEdit } from "./AnnouncementEdit";
import { AnnouncementList } from "./AnnouncementList";

const announcementResource: ResourceProps = {
  options: { label: "Announcement" },
  name: "announcements",
  icon: AnnouncementIcon,
  create: AnnouncementCreate,
  edit: AnnouncementEdit,
  list: AnnouncementList,
};

export default announcementResource;
