import { toChoices } from "core/utils";

import {
  ErpSaleInvoiceLineState,
  ErpSaleInvoiceLineType,
} from "@vapaus/generated";

export const ERP_SALE_INVOICE_LINE_STATE_LABELS: {
  [key in ErpSaleInvoiceLineState]: string;
} = {
  [ErpSaleInvoiceLineState.IGNORED]: "Ignored",
  [ErpSaleInvoiceLineState.UNASSIGNED]: "Unassigned",
  [ErpSaleInvoiceLineState.PARTIALLY_ASSIGNED]: "Partially assigned",
  [ErpSaleInvoiceLineState.ASSIGNED]: "Assigned",
  [ErpSaleInvoiceLineState.INVALID]: "Invalid",
};

export const ERP_SALE_INVOICE_LINE_STATE_CHOICES = toChoices(
  ERP_SALE_INVOICE_LINE_STATE_LABELS,
);

export const ERP_SALE_INVOICE_LINE_TYPE_LABELS: Record<string, string> =
  Object.values(ErpSaleInvoiceLineType).reduce((types, name) => {
    return {
      ...types,
      [name]: name.slice(0, 1).toUpperCase() + name.slice(1).replace(/_/g, " "),
    };
  }, {});

export const ERP_SALE_INVOICE_LINE_TYPE_CHOICES = toChoices(
  ERP_SALE_INVOICE_LINE_TYPE_LABELS,
);
