import { VehicleType } from "@vapaus/generated";

import { toChoices } from "./utils";

export const VEHICLE_TYPE_CHOICES = toChoices(
  Object.values(VehicleType).reduce((vehicles, name) => {
    return {
      ...vehicles,
      [name]: name.slice(0, 1).toUpperCase() + name.slice(1),
    };
  }, {}),
);
