import { FC } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import { DateField, Link, TextField, useRecordContext } from "react-admin";

import { RowItem } from "./RowItem";
import { CurrencyGrossField } from "components";

export const Delivery: FC = () => {
  const record = useRecordContext();
  const deliveryTransport = record.delivery_transport;
  if (!deliveryTransport) return null;
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel-bike-content"
        id="panel-bike-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <LocalShippingIcon sx={{ mr: 2 }} /> <Typography>Delivery</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table aria-label="bike table">
          <TableBody>
            <RowItem label="Expected delivery date">
              <DateField source="delivery_transport.expected_delivery_date" />
            </RowItem>
            <RowItem label="Tracking URL">
              <Link
                to={deliveryTransport.tracking_url}
                target="_blank"
                rel="noreferrer"
              >
                <TextField source="delivery_transport.tracking_url" />
              </Link>
            </RowItem>
            <RowItem label="Delivery price">
              <CurrencyGrossField
                source="delivery_transport.price_gross"
                currencyRecord={record}
                currencySource="currency"
                vatRate={deliveryTransport.vat_rate}
              />
            </RowItem>
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
