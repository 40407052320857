import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  BooleanField,
  Datagrid,
  NumberField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";

import { FleetUsageSettingsCreateButton } from "./FleetUsageSettingsCreateButton";
import { FleetUsageSettingsEditButton } from "./FleetUsageSettingsEditButton";

export const NestedFleetUsageSettingsDataGrid = () => {
  const record = useRecordContext();
  if (!record?.id)
    return (
      <EmptyBoxInfo message="Please save the organisation first to manage fleet access settings." />
    );

  return (
    <ReferenceManyField
      label="Fleet usage settings"
      reference="fleet-usage-settings"
      target="organisation_id"
      filter={{ active_only: true }}
    >
      <Datagrid
        bulkActionButtons={false}
        empty={<FleetUsageSettingsCreateButton />}
        sx={{ maxWidth: 1024 }}
      >
        <TextField source="name" />
        <NumberField
          source="min_booking_time"
          options={{ style: "unit", unit: "minute" }}
        />
        <NumberField
          source="max_booking_time"
          options={{ style: "unit", unit: "minute" }}
        />
        <NumberField
          source="max_future_start"
          options={{ style: "unit", unit: "hour" }}
        />
        <BooleanField source="business_use" />
        <BooleanField source="private_use" />
        <DatagridActionsColumn label={<FleetUsageSettingsCreateButton />}>
          {<FleetUsageSettingsEditButton />}
        </DatagridActionsColumn>
      </Datagrid>
    </ReferenceManyField>
  );
};
