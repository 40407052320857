import { Table, TableBody } from "@mui/material";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { useRefreshableRow } from "components/RefreshableDatagridRow";
import {
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";
import { RowItem } from "resources/BikeBenefitContracts/components/RowItem";

import { EditToolbar, Separator } from "components";
import { ERP_SALE_INVOICE_LINE_TYPE_CHOICES } from "core";

export const EditErpSaleInvoiceLineButton = () => {
  const { refresh } = useRefreshableRow();
  return (
    <EditInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      title="Edit line"
      resource="erp-sale-invoice-lines"
      mutationMode="pessimistic"
      mutationOptions={{
        onSettled: refresh,
      }}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <Table>
          <TableBody>
            <RowItem label="Product code">
              <TextField source="product_code" />
            </RowItem>
            <RowItem label="Description">
              <TextField source="description" />
            </RowItem>
            <RowItem label="Quantity">
              <TextField source="quantity" />
            </RowItem>
          </TableBody>
        </Table>
        <Separator />
        <NumberInput source="required_assignment_count" fullWidth />
        <SelectInput
          source="line_type"
          choices={ERP_SALE_INVOICE_LINE_TYPE_CHOICES}
          fullWidth
        />
        <BooleanInput source="is_ignored" />
      </SimpleForm>
    </EditInDialogButton>
  );
};
