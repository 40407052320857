import React from "react";

import {
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  required,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { BikeBenefitOrderingMethod, ShopType } from "@vapaus/generated";

import { ORDERING_METHOD_CHOICES } from "core";

export const OrderFormOrderingMethod = () => {
  const record = useRecordContext();
  const { setValue, watch } = useFormContext();
  const benefitDefinitionId = watch("benefit_definition_id");
  const { data: benefitDefinition } = useGetOne(
    "benefit-definitions",
    {
      id: benefitDefinitionId,
    },
    { enabled: !!benefitDefinitionId },
  );
  const handleOrderingMethodChange = (
    orderingMethod: BikeBenefitOrderingMethod,
  ) => {
    setValue("bike_id", undefined);
    if (orderingMethod === BikeBenefitOrderingMethod.PRECYCLED) {
      setValue("shop_id", import.meta.env.VITE_VAPAUS_PRECYCLED_SHOP_ID);
      setValue("bike", undefined);
    } else {
      setValue("shop_id", null);
      setValue("bike", {
        purchase_currency: benefitDefinition?.currency,
        serial_number: "",
        condition: "new",
      });
    }
  };
  return (
    <>
      <SelectInput
        source="ordering_method"
        choices={ORDERING_METHOD_CHOICES}
        fullWidth
        onChange={(e) => handleOrderingMethodChange(e.target.value)}
        validate={record.id ? undefined : required()}
        disabled={!!record.id}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.ordering_method !== BikeBenefitOrderingMethod.PRECYCLED && (
            <ReferenceInput
              source="shop_id"
              reference="shops"
              filter={{
                shop_type: {
                  [BikeBenefitOrderingMethod.ONLINE_ORDER]:
                    ShopType.ONLINE_STORE,
                  [BikeBenefitOrderingMethod.PARTNER_STORE]:
                    ShopType.LOCAL_STORE,
                  [BikeBenefitOrderingMethod.PRECYCLED]: undefined,
                }[formData.ordering_method as BikeBenefitOrderingMethod],
              }}
              isRequired
            >
              <AutocompleteInput
                label="Shop"
                optionText={(option) => option.name || ""}
                optionValue="id"
                fullWidth
                validate={required()}
                disabled={!formData.ordering_method}
              />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
    </>
  );
};
