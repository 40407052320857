import React, { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";

const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  title?: string;
};

const ModalBox: FC<Props> = ({ open = false, setOpen, children, title }) => (
  <Modal
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="modal-modal-title"
  >
    <Box sx={modalBoxStyle}>
      <Grid container>
        {title && (
          <CardHeader
            title={title}
            sx={{
              padding: 0,
              paddingBottom: 2,
            }}
          />
        )}
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      {children}
    </Box>
  </Modal>
);

export default ModalBox;
