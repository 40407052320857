import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRedirect,
} from "react-admin";
import styled from "styled-components";

import { CurrencyField, Pagination } from "components";
import { useEnumLabel } from "hooks";

export const BikeMaintenanceHistory = () => {
  const getWorkTypeLabel = useEnumLabel("WorkTypes");
  const redirect = useRedirect();
  const record = useRecordContext();

  const onCreate = () =>
    redirect(
      "create",
      "maintenance-events",
      undefined,
      {},
      {
        record: {
          bike_id: record.id,
          currency: record.purchase_currency,
        },
      },
    );

  return (
    <Labeled fullWidth>
      <ReferenceManyField
        reference="maintenance-events"
        label="Maintenance history"
        target="bike_id"
        pagination={<Pagination />}
        perPage={10}
        sort={{ field: "work_date", order: "DESC" }}
      >
        <StyledCreateButtonWrapper>
          <Button onClick={onCreate} startIcon={<Add />}>
            Create
          </Button>
        </StyledCreateButtonWrapper>
        <Datagrid
          bulkActionButtons={false}
          rowClick={(id, resource, record) => {
            redirect(
              "edit",
              "maintenance-events",
              record.id,
              {},
              {
                record: {
                  bike_id: record.bike_id,
                },
              },
            );
            return false;
          }}
          expand={<MaintenanceEventDetailsPanel />}
        >
          <DateField source="work_date" sortable={false} label="Date" />
          <FunctionField
            label="Type"
            render={(maintenanceEvent: any) =>
              getWorkTypeLabel(maintenanceEvent.work_type)
            }
          />
          <CurrencyField
            source="parts_price"
            currencySource="currency"
            headerClassName="text-right"
            textAlign="right"
          />
          <CurrencyField
            source="work_price"
            currencySource="currency"
            headerClassName="text-right"
            textAlign="right"
          />
          <CurrencyField
            source="total_price"
            currencySource="currency"
            headerClassName="text-right"
            textAlign="right"
          />
        </Datagrid>
      </ReferenceManyField>
    </Labeled>
  );
};

const MaintenanceEventDetailsPanel = () => {
  return (
    <SimpleShowLayout>
      <ReferenceField source="shop_id" reference="shops">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="work_description" />
      <DateField source="created_at" />
      <BooleanField source="is_billable" />
    </SimpleShowLayout>
  );
};

const StyledCreateButtonWrapper = styled.div`
  align-self: end;
`;
