import Chip from "@mui/material/Chip";
import { ReferenceManyToManyField } from "@react-admin/ra-relationships";
import {
  AutocompleteInput,
  ChipField,
  Datagrid,
  EmailField,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";

import { MasterPermissions } from "@vapaus/generated";

import { Pagination } from "components";
import { MASTER_PERMISSIONS, MASTER_PERMISSION_LABELS } from "core";

const userFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    label="Master permissions"
    source="master_permission"
    choices={MASTER_PERMISSIONS}
  />,
  <ReferenceInput source="shop_id" reference="shops">
    <AutocompleteInput
      label="Shop"
      optionText="name"
      optionValue="id"
      fullWidth
      isRequired
    />
  </ReferenceInput>,
];

export const UserList = () => (
  <List
    filters={userFilters}
    perPage={20}
    pagination={<Pagination />}
    exporter={false}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <EmailField source="email" sortable={false} />
      <TextField source="full_name" />
      <FunctionField
        label="Master permissions"
        render={(record?: Record<string, any>) =>
          record?.master_permissions?.map((permission: MasterPermissions) => (
            <Chip
              key={permission}
              label={MASTER_PERMISSION_LABELS[permission]}
            />
          ))
        }
      />
      <ReferenceManyToManyField
        label="Shops managed"
        reference="shops"
        through="shop-managers"
        using="user_id,shop_id"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyToManyField>
    </Datagrid>
  </List>
);
