import AssessmentIcon from "@mui/icons-material/Assessment";
import { ResourceProps } from "react-admin";

import ReportsDownload from "./ReportsDownload";

const downloadReportResource: ResourceProps = {
  name: "download-reports",
  icon: AssessmentIcon,
  list: ReportsDownload,
  options: {
    label: "Download Reports",
  },
};

export default downloadReportResource;
