import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  useTheme,
} from "@mui/material";
import { CurrencyField } from "components/CurrencyField";
import { useEnumChoices, useEnumLabel } from "hooks/useEnum";
import { groupBy, sumBy } from "lodash-es";
import {
  ArrayField,
  Datagrid,
  DatagridClasses,
  DateField,
  FunctionField,
  Link,
  List,
  NumberField,
  RecordContextProvider,
  SelectInput,
  TextField,
  Toolbar,
  useGetOne,
  useListContext,
  useRecordContext,
} from "react-admin";
import { RowItem } from "resources/BikeBenefitContracts/components/RowItem";

import {
  ErpSaleInvoiceLineAssignmentRead,
  ErpSaleInvoiceLineRead,
} from "@vapaus/generated";

import { ERP_SALE_INVOICE_LINE_TYPE_LABELS } from "core";

const ErpSaleInvoiceModal = ({
  erpSaleInvoiceId,
  erpSaleInvoiceLineId,
  onClose,
}: {
  erpSaleInvoiceId: string | null;
  erpSaleInvoiceLineId: string | null;
  onClose: () => void;
}) => {
  const { data: erpSaleInvoice } = useGetOne(
    "erp-sale-invoices",
    { id: erpSaleInvoiceId },
    {
      enabled: !!erpSaleInvoiceId,
      keepPreviousData: false,
    },
  );
  const { palette } = useTheme();
  if (!erpSaleInvoice) return null;
  return (
    <Dialog
      fullWidth
      open={!!erpSaleInvoice}
      onClose={onClose}
      aria-label="ERP Sale Invoice"
      maxWidth="lg"
    >
      <DialogTitle>
        ERP Sale Invoice {erpSaleInvoice.invoice_number}
      </DialogTitle>
      <DialogContent>
        <RecordContextProvider value={erpSaleInvoice}>
          <Table>
            <TableBody>
              <RowItem label="Business ID">
                <TextField source="customer_business_id" />
              </RowItem>
              <RowItem label="ERP customer code">
                <TextField source="customer_code" />
              </RowItem>
              <RowItem label="Text before lines">
                <TextField
                  source="text_before_lines"
                  sx={{ whiteSpace: "pre-wrap" }}
                />
              </RowItem>
              {erpSaleInvoice.internal_additional_info ? (
                <RowItem label="Internal additional info">
                  <TextField
                    source="internal_additional_info"
                    sx={{ whiteSpace: "pre-wrap" }}
                  />
                </RowItem>
              ) : null}
              <RowItem label="Total">
                <CurrencyField source="total" currencySource="currency" />
              </RowItem>
            </TableBody>
          </Table>
          <ArrayField source="erp_sale_invoice_lines">
            <Datagrid
              bulkActionButtons={false}
              rowClick={false}
              rowStyle={(record) => ({
                backgroundColor:
                  record.id === erpSaleInvoiceLineId
                    ? palette.warning.light
                    : palette.background.paper,
              })}
            >
              <TextField source="product_code" sortable={false} />
              <FunctionField<ErpSaleInvoiceLineRead>
                label="Type"
                render={(line?: ErpSaleInvoiceLineRead) =>
                  line!.line_type
                    ? ERP_SALE_INVOICE_LINE_TYPE_LABELS[line!.line_type]
                    : ""
                }
              />
              <TextField source="description" sortable={false} />
              <NumberField source="price" label="Unit price" sortable={false} />
              <NumberField
                source="quantity"
                label="Quantity"
                sortable={false}
              />
              <NumberField
                source="subtotal"
                label="Subtotal"
                sortable={false}
              />
            </Datagrid>
          </ArrayField>
        </RecordContextProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} startIcon={<CancelIcon />}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DatagridSummary = () => {
  const { data } = useListContext();
  const assignmentsByCurrency = groupBy(data, "erp_sale_invoice.currency");
  const totalByCurrency = Object.keys(assignmentsByCurrency).reduce(
    (totals: string[], currency) => {
      const currencyFormatter = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currency,
      });
      totals.push(
        currencyFormatter.format(
          sumBy(assignmentsByCurrency[currency], "total"),
        ),
      );
      return totals;
    },
    [],
  );
  return (
    <Toolbar>
      <Grid container>
        <Grid item sm={6}>
          <Box display="flex" justifyContent="flex-start">
            Lines: {data?.length}
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" justifyContent="flex-end">
            Total: {totalByCurrency.length ? totalByCurrency.join(", ") : "-"}
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export const RelatedErpSaleInvoiceLineAssignments = ({
  target,
  withContractLink,
}: {
  target: "bike_benefit_contract_id" | "bike_id";
  withContractLink?: boolean;
}) => {
  const getErpSaleInvoiceLineTypeLabel = useEnumLabel("ErpSaleInvoiceLineType");
  const getErpSaleInvoicePaymentStatusLabel = useEnumLabel(
    "ErpSaleInvoicePaymentStatus",
  );
  const { id } = useRecordContext();
  const lineTypeChoices = useEnumChoices("ErpSaleInvoiceLineType");
  const [modalErpSaleInvoiceId, setModalErpSaleInvoiceId] = useState<
    string | null
  >(null);
  const [modalErpSaleInvoiceLineId, setModalErpSaleInvoiceLineId] = useState<
    string | null
  >(null);
  const handleModalClose = () => {
    setModalErpSaleInvoiceId(null);
  };
  return (
    <List
      resource="erp-sale-invoice-line-assignments"
      pagination={false}
      perPage={1000}
      filter={{ [target]: id }}
      title={<></>}
      filters={[
        <SelectInput
          source="line_type"
          choices={lineTypeChoices}
          emptyText="show all"
          alwaysOn
        />,
      ]}
      sort={{ field: "erp_sale_invoice.date", order: "DESC" }}
      disableSyncWithLocation
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={(id, resource, record) => {
          setModalErpSaleInvoiceId(record.erp_sale_invoice.id);
          setModalErpSaleInvoiceLineId(record.erp_sale_invoice_line.id);
          return false;
        }}
        sx={{
          ["." + DatagridClasses.tableWrapper]: {
            overflowX: "auto",
            overflowY: "clip",
          },
        }}
      >
        {withContractLink && (
          <FunctionField<ErpSaleInvoiceLineAssignmentRead>
            label="Contract"
            onClick={(e) => e.stopPropagation()}
            render={(assignment?: ErpSaleInvoiceLineAssignmentRead) =>
              assignment ? (
                <Link
                  to={`/bike-benefit-contracts/${assignment.bike_benefit_contract_id}/show`}
                  target="_blank"
                >
                  {assignment.bike_benefit_contract_vapaus_code}
                </Link>
              ) : null
            }
          />
        )}
        <DateField source="erp_sale_invoice.date" label="Invoice date" />
        <TextField
          source="erp_sale_invoice.invoice_number"
          label="Invoice number"
        />
        <FunctionField<ErpSaleInvoiceLineAssignmentRead>
          label="Line type"
          render={(assignment) =>
            getErpSaleInvoiceLineTypeLabel(
              assignment!.erp_sale_invoice_line.line_type,
            )
          }
        />
        <CurrencyField
          source="total"
          label="Amount"
          currencySource="erp_sale_invoice.currency"
        />
        <FunctionField<ErpSaleInvoiceLineAssignmentRead>
          label="Payment status"
          render={(assignment) =>
            getErpSaleInvoicePaymentStatusLabel(
              assignment!.erp_sale_invoice.payment_status,
            )
          }
        />
      </Datagrid>
      <DatagridSummary />
      <ErpSaleInvoiceModal
        erpSaleInvoiceId={modalErpSaleInvoiceId}
        erpSaleInvoiceLineId={modalErpSaleInvoiceLineId}
        onClose={handleModalClose}
      />
    </List>
  );
};
