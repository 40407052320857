import { ReactNode } from "react";

import { Grid, Tooltip as MUITooltip } from "@mui/material";

interface TooltipProps {
  text: string;
  children: ReactNode;
}

export function Tooltip({ text, children }: TooltipProps) {
  return (
    <Grid container>
      <Grid item>{children}</Grid>
      <Grid item>
        <MUITooltip title={text}>
          <span>&nbsp;&#9432;</span>
        </MUITooltip>
      </Grid>
    </Grid>
  );
}
