import TermsIcon from "@mui/icons-material/Description";
import { ResourceProps } from "react-admin";

import { TermsCreate } from "./TermsCreate";
import { TermsEdit } from "./TermsEdit";
import { TermsList } from "./TermsList";

const termsResource: ResourceProps = {
  name: "terms",
  icon: TermsIcon,
  create: TermsCreate,
  edit: TermsEdit,
  list: TermsList,
};

export default termsResource;
