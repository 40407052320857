import React from "react";

import { Grid, Table, TableBody, Typography } from "@mui/material";
import {
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { RowItem } from "resources/BikeBenefitOrders/components/RowItem";

import { BikeBenefitOrderingMethod } from "@vapaus/generated";

import { BenefitDefinitionDisplay } from "./BenefitDefinitionDisplay";
import { useCalculationRequestParams } from "./useCalculationRequestParams";
import { Separator } from "components";

export const OrderDetailsDisplay = () => {
  const record = useRecordContext();
  const { data: benefitDefinition } = useGetOne("benefit-definitions", {
    id: record.benefit_definition_id,
  });
  useCalculationRequestParams(benefitDefinition); // this is needed to store initial params state in OrderFormContext
  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography variant="h6">Order details</Typography>
        <Table>
          <TableBody>
            <RowItem label="Vapaus code">
              <TextField source="vapaus_code" />
            </RowItem>
            <RowItem label="Order date">
              <DateField source="created_at" />
            </RowItem>
            <RowItem label="User">
              <ReferenceField source="user_id" reference="users">
                <FunctionField
                  render={(user: any) =>
                    `${user.full_name ?? ""} (${user.email})`
                  }
                />
              </ReferenceField>
            </RowItem>
            {record.ordering_method === BikeBenefitOrderingMethod.PRECYCLED ? (
              <RowItem label="Shop">
                <ReferenceField source="shop_id" reference="shops">
                  <TextField source="name" />
                </ReferenceField>
              </RowItem>
            ) : null}
          </TableBody>
        </Table>
        <Separator />
        <Typography variant="h6">Benefit definition</Typography>
        <BenefitDefinitionDisplay benefitDefinition={benefitDefinition} />
      </Grid>
    </Grid>
  );
};
