import { createContext, useContext } from "react";

import { ErpSaleInvoiceLineRead } from "@vapaus/generated";

export const ErpSaleInvoiceLineContext = createContext<
  ErpSaleInvoiceLineRead | undefined
>(undefined);

export const useErpSaleInvoiceLineContext = () => {
  const line = useContext(ErpSaleInvoiceLineContext);
  if (!line) {
    throw new Error(
      "Use `ErpSaleInvoiceLineContext.Provider` before using this hook!",
    );
  }
  return line;
};
