import { Tooltip } from "@mui/material";
import { get, round } from "lodash-es";
import { useRecordContext } from "react-admin";

import { toNet } from "@vapaus/utils";

import { CurrencyField, CurrencyFieldProps } from "./CurrencyField";

interface CurrencyGrossFieldProps extends CurrencyFieldProps {
  vatRate: number;
}

export const CurrencyGrossField = (props: CurrencyGrossFieldProps) => {
  const record = useRecordContext(props);
  const vatRateDisplay = props.vatRate.toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: 1,
  });
  const value = get(record, props.source!);
  const currency = get(props.currencyRecord ?? record, props.currencySource);
  const netValue = toNet(value, props.vatRate).toLocaleString(undefined, {
    style: "currency",
    currency,
  });
  return (
    <Tooltip
      title={
        <>
          VAT {vatRateDisplay}
          <br />
          Net {netValue}
        </>
      }
    >
      <span>
        <CurrencyField {...props} />
        &nbsp;&#9432;
      </span>
    </Tooltip>
  );
};
