import { ReactNode } from "react";

import root from "react-shadow/styled-components";
import { ThemeProvider } from "styled-components";

import { CssBaseline, Fonts, createTheme } from "@vapaus/ui-v2";

const theme = createTheme();

interface UIV2ShadowRendererProps {
  children: ReactNode;
}

export function UIV2ShadowRenderer({ children }: UIV2ShadowRendererProps) {
  return (
    <>
      {/* Fonts need to be loaded outside of the shadow dom, as @font-face is not supported by it. */}
      <Fonts />
      <root.div>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div>{children}</div>
        </ThemeProvider>
      </root.div>
    </>
  );
}
