import { FC } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { useSummary } from "../hooks/useSummary";
import { CurrencyGrossField } from "components";

export const OrderTotal: FC = () => {
  const summary = useSummary();
  return (
    <Card variant="outlined">
      <CardContent className="order-total">
        <Typography color="text.secondary">Total</Typography>
        <CurrencyGrossField
          record={summary}
          source="order_total"
          vatRate={summary.flat_vat_rate}
          currencySource="currency"
          sx={{ fontSize: "h6.fontSize" }}
        />
      </CardContent>
    </Card>
  );
};
