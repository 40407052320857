import { FC, useMemo } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  DateField,
  Link,
  ReferenceField,
  ReferenceFieldClasses,
  TextField,
  useCreatePath,
  useGetOne,
  useRecordContext,
} from "react-admin";

import { BenefitDefinitionRead } from "@vapaus/generated";
import { toGross } from "@vapaus/utils";

import { CurrencyGrossField } from "components";

type BenefitDefinitionLinkProps = {
  children: JSX.Element;
};

const BenefitDefinitionLink = ({ children }: BenefitDefinitionLinkProps) => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  const resourceLinkPath = createPath({
    resource: "benefit-definitions",
    id: record.id,
    type: "edit",
  });

  return (
    <Link
      to={resourceLinkPath as string}
      className={ReferenceFieldClasses.link}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Link>
  );
};

const BenefitDefinitionTermsLink = ({
  children,
}: BenefitDefinitionLinkProps) => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  const resourceLinkPath =
    createPath({
      resource: "benefit-definitions",
      id: record.benefit_definition_id,
      type: "edit",
    }) + "/3";

  return (
    <Link
      to={resourceLinkPath as string}
      className={ReferenceFieldClasses.link}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Link>
  );
};

export const ContractSummary: FC = () => {
  const record = useRecordContext();
  const { data: benefitDefinition } = useGetOne<BenefitDefinitionRead>(
    "benefit-definitions",
    {
      id: record.benefit_definition_id,
    },
  );
  const infoRecord = useMemo(
    () => ({
      currency: record.currency,
      effective_monthly_taxable_value:
        record.fixed_monthly_taxable_value || record.monthly_taxable_value,
      monthly_office_fee: benefitDefinition
        ? toGross(
            benefitDefinition.current_net_monthly_office_fee,
            record.current_flat_vat_rate,
          )
        : null,
    }),
    [record, benefitDefinition],
  );
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" mb={1.5}>
          Contract Summary
        </Typography>
        <ReferenceField
          source="benefit_definition_id"
          reference="benefit-definitions"
          link={false}
        >
          <>
            <div className="order-summary-item">
              <Typography className="label">Organisation</Typography>
              <ReferenceField
                source="organisation_id"
                reference="organisations"
              >
                <TextField source="name" />
              </ReferenceField>
            </div>
            <div className="order-summary-item">
              <Typography className="label">Benefit</Typography>
              <BenefitDefinitionLink>
                <TextField source="name" />
              </BenefitDefinitionLink>
            </div>
          </>
        </ReferenceField>
        <div className="order-summary-item">
          <Typography className="label">Accepted benefit terms</Typography>
          <ReferenceField
            source="benefit_definition_terms_id"
            reference="benefit-definition-terms"
          >
            <BenefitDefinitionTermsLink>
              <DateField source="created_at" />
            </BenefitDefinitionTermsLink>
          </ReferenceField>
        </div>
        <div className="order-summary-item">
          <Typography className="label">Start date</Typography>
          <DateField source="start_date" />
        </div>
        <div className="order-summary-item">
          <Typography className="label">Planned end date</Typography>
          <DateField source="end_date" />
        </div>
        {record.exceptional_end_date && (
          <div className="order-summary-item">
            <Typography className="label">Exceptional end date</Typography>
            <DateField source="exceptional_end_date" />
          </div>
        )}
        <div className="order-summary-item">
          <Typography className="label">Monthly taxable value</Typography>
          <CurrencyGrossField
            record={infoRecord}
            source="effective_monthly_taxable_value"
            currencySource="currency"
            vatRate={record.current_flat_vat_rate}
          />
        </div>
        <div className="order-summary-item">
          <Typography className="label">Office fee</Typography>
          <CurrencyGrossField
            record={infoRecord}
            source="monthly_office_fee"
            currencySource="currency"
            vatRate={record.current_flat_vat_rate}
          />
        </div>
      </CardContent>
    </Card>
  );
};
