import React from "react";

import { Create, useRedirect } from "react-admin";

import UserForm from "./UserForm";
import {
  useCustomMutationOptions as useCustomMutationOptionsBase,
  useVapausMutation,
} from "hooks";

const useCustomMutationOptions = () => {
  const { mutate: sendInvitationMutate } = useVapausMutation(
    "sendInvitationEmail",
  );
  const redirect = useRedirect();

  const onSuccess = (data: any) => {
    sendInvitationMutate(data.id);
    redirect("list");
  };

  return {
    ...useCustomMutationOptionsBase(),
    onSuccess,
  };
};

export const UserCreate = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Create mutationOptions={mutationOptions} redirect="list">
      <UserForm />
    </Create>
  );
};
