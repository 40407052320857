import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import PaidIcon from "@mui/icons-material/Paid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  Form,
  SaveButton,
  TextInput,
  required,
  useNotify,
  useRecordContext,
  useTranslate,
} from "react-admin";

import { useCustomMutationOptions, useVapausMutation } from "hooks";

export const MarkAsPaidButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const { onError } = useCustomMutationOptions();
  const notify = useNotify();

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: record.currency,
  }).format;

  const { mutate, isLoading, queryClient } = useVapausMutation(
    "createSaleInvoiceTransaction",
  );

  const handleSubmit = ({ description }: any) => {
    mutate(
      {
        sale_invoice_id: record.id,
        date: DateTime.now().toISODate(),
        amount: record.balance,
        description,
        currency: record.currency,
      },
      {
        onSuccess: () => {
          notify("Invoice marked as paid");
          queryClient.invalidateQueries(["invoices/sale-invoices"]);
          setShowDialog(false);
        },
        onError,
      },
    );
  };

  const handleOpenClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button
        color="primary"
        startIcon={<PaidIcon />}
        onClick={handleOpenClick}
      >
        Mark as paid
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Mark as paid"
      >
        <DialogTitle>Mark as paid</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="body1" mb={2}>
              Amount to be marked as paid: {currencyFormatter(record.balance)}
            </Typography>
            <TextInput source="description" validate={[required()]} fullWidth />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseClick}
              disabled={isLoading}
              startIcon={<CancelIcon />}
            >
              {translate("ra.action.cancel")}
            </Button>
            <SaveButton
              label="Mark as paid"
              disabled={isLoading}
              alwaysEnable={!isLoading}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
