import { forwardRef, useImperativeHandle, useState } from "react";

import { CreateDialog } from "@react-admin/ra-form-layout";
// import { SaleInvoiceLineCreate } from "@vapaus/generated";
import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { Currency, SaleInvoiceLineType } from "@vapaus/generated";

import { GrossPriceInput, MoneyInput, PercentInput } from "components";
import { INVOICE_LINE_TYPE_CHOICES } from "core";

export type InvoiceLine = {
  id: number;
  // line: SaleInvoiceLineCreate;
  line: any;
};

type Props = {
  currency: Currency;
  defaultVatRate?: number;
  onSubmit: (values: any) => void;
};

export type InvoiceLineFormRef = {
  open: (line: InvoiceLine) => void;
  close: () => void;
};

// Invoice Line Form Modal
export const InvoiceLineModal = forwardRef<InvoiceLineFormRef, Props>(
  ({ onSubmit, currency, defaultVatRate }, ref) => {
    const [showModal, setShowModal] = useState(false);
    const [line, setLine] = useState<InvoiceLine | undefined>();

    const handleSubmit = (values: any) => {
      onSubmit({ id: line?.id, line: values });
      setShowModal(false);
    };

    useImperativeHandle(ref, () => ({
      open: (line: InvoiceLine) => {
        setLine(line);
        setShowModal(true);
      },
      close: () => {
        setShowModal(false);
        setLine(undefined);
      },
    }));

    return (
      <CreateDialog
        title="Add Invoice Line"
        isOpen={showModal}
        open={() => setShowModal(true)}
        close={() => setShowModal(false)}
      >
        <SimpleForm
          minWidth={400}
          onSubmit={handleSubmit}
          defaultValues={{
            vat_rate: defaultVatRate,
            quantity: 1,
            price: 0,
            price_includes_vat: true,
            description: "",
            ...line?.line,
          }}
        >
          <SelectInput
            source="type"
            choices={INVOICE_LINE_TYPE_CHOICES}
            fullWidth
            required
          />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <TextInput
                  source="description"
                  required={formData.type === SaleInvoiceLineType.OTHER}
                  parse={(value) => value || ""}
                  fullWidth
                />
                {formData.price_includes_vat ? (
                  <GrossPriceInput
                    source="price"
                    currency={currency}
                    vatRate={formData.vat_rate}
                    required
                    fullWidth
                    netDecimalPoints={4}
                    grossDecimalPoints={2}
                  />
                ) : (
                  <MoneyInput source="price" fullWidth required />
                )}
              </>
            )}
          </FormDataConsumer>
          <BooleanInput
            source="price_includes_vat"
            label="The entered price includes VAT"
          />
          <NumberInput source="quantity" fullWidth required />
          <PercentInput source="vat_rate" fullWidth required />
        </SimpleForm>
      </CreateDialog>
    );
  },
);
