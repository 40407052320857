import Alert from "@mui/material/Alert";
import { RowForm } from "@react-admin/ra-editable-datagrid";
import { isEmpty, max } from "lodash-es";
import { DateTime } from "luxon";
import {
  DateInput,
  Validator,
  maxValue,
  minValue,
  number,
  required,
  useGetList,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { Plan, PlanConfig } from "@vapaus/generated";

import { MoneyInput } from "components";

export const BenefitDefinitionMonthlyOfficeFeeForm = () => {
  const { watch } = useFormContext();
  const record = useRecordContext();
  const [benefitDefinitionId, plan] = watch(["id", "plan"]);
  const { data: planConfig } = useGetOne<PlanConfig>(
    "plans",
    { id: plan ?? Plan.STARTER },
    {
      enabled: !!plan,
    },
  );
  return (
    <>
      <RowForm
        defaultValues={{
          benefit_definition_id: benefitDefinitionId,
          net_monthly_office_fee: planConfig?.net_monthly_office_fee.default,
        }}
      >
        <DateInput source="valid_from" validate={required()} />
        <MonthlyOfficeFeeInput
          planConfig={planConfig}
          benefitDefinitionId={benefitDefinitionId}
        />
      </RowForm>
      {record?.id ? (
        <Alert severity="warning" sx={{ mt: 1 }}>
          This change will be applied to existing contracts in the validity
          period. Please be sure that you wish to proceed with this update.
        </Alert>
      ) : null}
    </>
  );
};

const MonthlyOfficeFeeInput = ({
  planConfig,
  benefitDefinitionId,
}: {
  planConfig?: PlanConfig;
  benefitDefinitionId: string;
}) => {
  const { watch } = useFormContext();
  const validFrom = watch("valid_from");
  const { data: monthlyOfficeFees } = useGetList(
    "benefit-definition-monthly-office-fees",
    {
      filter: { benefit_definition_id: benefitDefinitionId },
    },
  );
  const isNewestEntry =
    validFrom &&
    (isEmpty(monthlyOfficeFees) ||
      DateTime.fromISO(validFrom) >=
        max(
          monthlyOfficeFees!.map((fee) => DateTime.fromISO(fee.valid_from)),
        )!);
  const validateMonthlyOfficeFee = [
    required(),
    number(),
    planConfig &&
      isNewestEntry &&
      minValue(planConfig.net_monthly_office_fee.minimum),
    planConfig &&
      isNewestEntry &&
      maxValue(planConfig.net_monthly_office_fee.maximum),
  ].filter(Boolean) as Validator[];
  return (
    <MoneyInput
      source="net_monthly_office_fee"
      currency={planConfig?.currency}
      validate={validateMonthlyOfficeFee}
    />
  );
};
