import * as React from "react";
import { ReactElement } from "react";

import { Grid, Typography } from "@mui/material";
import { ReferenceManyToManyInput } from "@react-admin/ra-relationships";
import {
  ArrayInput,
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  FormTab,
  FunctionField,
  Link,
  ReferenceField,
  ReferenceManyField,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  SimpleFormIteratorClasses,
  SingleFieldList,
  TabbedForm,
  TextField,
  email,
  required,
  useRecordContext,
} from "react-admin";

import EmailsManager from "./components/EmailsManager";
import { UserBenefitActivationUserCreateButton } from "./components/UserBenefitActivationUserCreateButton";
import UserContracts from "./components/UserContracts";
import { UserMenu } from "./components/UserMenu";
import UserNotificationsPreferences from "./components/UserNotificationsPreferences";
import UserOrders from "./components/UserOrders";
import UserPhoneInput from "./components/UserPhoneInput";
import UserSSNInput from "./components/UserSSNInput";
import validateForm from "./validateForm";
import {
  CustomDataGrid,
  Pagination,
  SectionTitle,
  Separator,
  NullableTextInput as TextInput,
} from "components";
import {
  AVAILABLE_COUNTRIES,
  AVAILABLE_LANGUAGES,
  MASTER_PERMISSIONS,
} from "core";

type UserFormProps = {
  toolbar?: ReactElement;
  edit?: boolean;
};

const validateRequired = [required()];
const validateEmail = [required(), email()];

const UserForm = ({ toolbar, edit }: UserFormProps) => {
  const record = useRecordContext();

  return (
    <TabbedForm
      validate={validateForm}
      warnWhenUnsavedChanges
      sanitizeEmptyValues
      defaultValues={{
        language: "FI",
      }}
      toolbar={toolbar}
    >
      <FormTab label="Summary" sx={{ maxWidth: 1024 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="Identity" />
            <UserSSNInput />
            <TextInput source="first_name" fullWidth />
            <TextInput source="last_name" fullWidth />
            <Separator />
            <SectionTitle title="Contact" />
            <TextInput
              type="email"
              source="email"
              validate={validateEmail}
              fullWidth
              disabled={edit}
            />

            <UserPhoneInput />
            <Separator />
            <SectionTitle title="Address" />
            <TextInput source="address" fullWidth />
            <TextInput source="post_code" fullWidth />
            <TextInput source="city" fullWidth />
            <SelectInput
              source="country"
              choices={AVAILABLE_COUNTRIES}
              fullWidth
              disabled={!!record?.id}
              required
            />
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Language" />
            <SelectInput
              source="language"
              choices={AVAILABLE_LANGUAGES}
              fullWidth
              validate={validateRequired}
            />

            <SectionTitle title="Company info" />
            <TextInput source="company" fullWidth />
            <TextInput source="cost_center" fullWidth />
            <TextInput source="employee_number" fullWidth />

            <ReferenceManyField
              label="Mepco integration"
              reference="mepco/user-integration-settings"
              target="user_id"
              perPage={10}
              pagination={<Pagination />}
            >
              <CustomDataGrid
                label="Mepco integration"
                empty={<></>}
                bulkActionButtons={false}
              >
                <ReferenceField
                  source="organisation.id"
                  reference="organisations"
                  label="Organisation"
                  sortable={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField
                  source="pay_group_id"
                  sortable={false}
                  emptyText="-"
                />
                <TextField
                  source="pay_component"
                  sortable={false}
                  emptyText="-"
                />
              </CustomDataGrid>
            </ReferenceManyField>

            <SectionTitle title="Fleet access codes" />
            <ArrayInput source="fleet_access_codes" label="">
              <SimpleFormIterator
                disableReordering
                fullWidth
                sx={{
                  [`.${SimpleFormIteratorClasses.line}`]: {
                    borderBottom: "none",
                    marginTop: 0,
                  },
                }}
              >
                {/* @ts-ignore */}
                <TextInput label="Fleet access code" fullWidth helperText="" />
              </SimpleFormIterator>
            </ArrayInput>

            <SectionTitle title="Payment methods" />
            <ReferenceManyField
              label="Payment consents"
              reference="payments/consents"
              target="user_id"
              perPage={5}
              pagination={<Pagination />}
              sort={{ field: "created_at", order: "DESC" }}
            >
              <Datagrid
                bulkActionButtons={false}
                rowClick={false}
                empty={
                  <Typography variant="body2">
                    No payment methods added yet
                  </Typography>
                }
              >
                <TextField source="source_type" label="Type" />
                <TextField source="source_brand" label="Brand" />
                <TextField source="source_label" label="Label" />
                <FunctionField
                  label="Expiration"
                  render={(consent?: Record<string, any>) =>
                    consent?.source_card_expiration_month &&
                    consent?.source_card_expiration_year
                      ? `${consent?.source_card_expiration_month} / ${consent?.source_card_expiration_year}`
                      : "-"
                  }
                />
                <TextField source="status" />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
          <Grid item>
            <EmailsManager />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Benefits">
        <Grid container spacing={2}>
          <Grid item md={8}>
            <SectionTitle title="All benefits" />
            <ReferenceManyField
              label="All benefits"
              reference="user-benefit-activations"
              target="user_id"
              perPage={10}
              pagination={<Pagination />}
              sort={{ field: "created_at", order: "DESC" }}
            >
              <Datagrid
                bulkActionButtons={false}
                rowClick={false}
                empty={
                  <Typography variant="body2">No benefits added yet</Typography>
                }
              >
                <DateField source="created_at" label="Activation date" />
                <ReferenceField
                  source="benefit_definition_id"
                  reference="benefit-definitions"
                  sortable={false}
                  label="Organisation"
                  link={false}
                >
                  <ReferenceField
                    source="organisation_id"
                    reference="organisations"
                    sortable={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField
                  source="benefit_definition_id"
                  reference="benefit-definitions"
                  sortable={false}
                  label="Benefit"
                >
                  <TextField source="name" />
                </ReferenceField>
                <FunctionField
                  label="Granted by"
                  render={(activation: any) =>
                    activation?.access_request?.resolved_by_user_id ? (
                      <ReferenceManyField
                        source="access_request.resolved_by_user_id"
                        filter={{ include_deleted: true }}
                        target="ids"
                        reference="users"
                      >
                        <SingleFieldList linkType={false}>
                          <FunctionField
                            render={(user: any) =>
                              user.is_deleted ? (
                                "Deleted user"
                              ) : (
                                <Link to={`/users/${user.id}`}>
                                  {user.full_name || user.email}
                                </Link>
                              )
                            }
                          />
                        </SingleFieldList>
                      </ReferenceManyField>
                    ) : (
                      "Email domain"
                    )
                  }
                />
                <FunctionField
                  label="Status"
                  render={(activation: any) =>
                    activation.is_active ? "Active" : "Inactive"
                  }
                />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
          <Grid item md={4}>
            <UserBenefitActivationUserCreateButton />
          </Grid>
          <Grid item md={8}>
            <SectionTitle title="Access requests" />
            <ReferenceManyField
              label="Access requests"
              reference="benefit-definition-access-requests"
              target="user_id"
              perPage={10}
              pagination={<Pagination />}
              sort={{ field: "created_at", order: "DESC" }}
            >
              <Datagrid
                bulkActionButtons={false}
                rowClick={false}
                empty={
                  <Typography variant="body2">
                    No requests placed yet
                  </Typography>
                }
              >
                <DateField source="created_at" label="Request date" />
                <ReferenceField
                  source="organisation_id"
                  reference="organisations"
                  sortable={false}
                  emptyText="-"
                >
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                  source="benefit_definition_id"
                  reference="benefit-definitions"
                  sortable={false}
                  label="Benefit"
                  emptyText="-"
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="status" textTransform="capitalize" />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Permissions" sx={{ maxWidth: 500 }}>
        <SelectArrayInput
          label="Master permissions"
          source="master_permissions"
          helperText="Changes to master permissions are effective after signing out and signing back in"
          choices={MASTER_PERMISSIONS}
          disabled={!edit}
          fullWidth
        />
        <ReferenceManyToManyInput
          source="id"
          reference="shops"
          through="shop-managers"
          using="user_id,shop_id"
          perPage={200}
          perPageChoices={200}
        >
          <AutocompleteArrayInput
            label="Shops managed"
            optionText="name"
            fullWidth
          />
        </ReferenceManyToManyInput>
        <ReferenceManyToManyInput
          source="id"
          reference="benefit-definitions"
          through="benefit-managers"
          using="user_id,benefit_definition_id"
          perPage={200}
          perPageChoices={200}
        >
          <AutocompleteArrayInput
            label="Benefits managed"
            optionText={(record) =>
              `${record.organisation.name} (${record.name})`
            }
            fullWidth
          />
        </ReferenceManyToManyInput>
      </FormTab>
      <FormTab label="Orders">
        <UserOrders />
      </FormTab>
      <FormTab label="Contracts">
        <UserContracts />
      </FormTab>
      <FormTab label="Notifications">
        <UserNotificationsPreferences />
      </FormTab>
      {edit ? <UserMenu /> : null}
    </TabbedForm>
  );
};

export default UserForm;
