import { InputAdornment } from "@mui/material";
import { round } from "lodash-es";

import { NumberInput, NumberInputProps } from "./NumberInput";

type PercentInputProps = Omit<
  NumberInputProps,
  "format" | "parse" | "InputProps"
>;

const formatPercentToString = (value?: string | number): string => {
  value = value?.toString();
  if (!value) return "";
  const [, fractionPart = ""] = value.split(".");
  const fractionDigits = Math.max(fractionPart.length - 2, 0);
  const res = (parseFloat(value) * 100).toFixed(fractionDigits);
  return res;
};

const parseStringToPercent = (value: string): number | null => {
  if (!value) return null;
  const [, fractionPart = ""] = value.split(".");
  // max fractional digit -> 5 this means we have to use 7 because of / 100
  const fractionDigits = Math.min(fractionPart.length + 2, 7);
  return round(parseFloat(value) / 100, fractionDigits);
};

export const PercentInput = (props: PercentInputProps) => {
  return (
    <NumberInput
      {...props}
      format={formatPercentToString}
      parse={parseStringToPercent}
      inputProps={{ step: 0.00001 }}
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
    />
  );
};
