import { ChipField, ReferenceManyField, SingleFieldList } from "react-admin";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ConfirmationPopupContent = () => {
  return (
    <Root>
      <div>You're changing the access rules for the following benefit(s):</div>
      <ReferenceManyField
        label="Benefits using these rules"
        source="uuid"
        reference="benefit-definitions"
        target="benefit_access_rules_uuid"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <div>
        The changed rules will only affect new registrants. Access from
        previously-authorised employees can be revoked from the provider app.
      </div>
    </Root>
  );
};
