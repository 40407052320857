import { FC } from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  ChipField,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextInput,
  useRecordContext,
} from "react-admin";

import {
  BenefitCancellationCoverage,
  BikeBenefitOrderRead,
  SupportedOrganisationCountries,
  SwedenSuggestedBenefitValuesCalculation,
} from "@vapaus/generated";

import { useOrderDetails } from "../hooks/useOrderDetails";
import { useSummary } from "../hooks/useSummary";
import { RowItem } from "./RowItem";
import { CurrencyField, CurrencyGrossField } from "components";
import { useEnum, useVapausQuery } from "hooks";

export const OrderDetails: FC = () => {
  const record = useRecordContext();
  const { toMoney, ...summary } = useSummary();
  const orderDetailsData = useOrderDetails(record as BikeBenefitOrderRead);
  const cancellationCoverages = useEnum("BenefitCancellationCoverage");

  const isSwedishOrder =
    orderDetailsData?.benefit_country === SupportedOrganisationCountries.SE;

  const { data: swedenSuggestedBenefitValues } =
    useVapausQuery<SwedenSuggestedBenefitValuesCalculation>(
      "getSwedenSuggestedBenefitValuesForOrder",
      record.id,
      {
        enabled: isSwedishOrder,
      },
    );

  return (
    <Card variant="outlined">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className="table-header-title">
                  <ShoppingCartIcon /> <span>Order Details</span>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record?.corrects_bike_benefit_contract_id && (
              <RowItem label="Corrects Bike Benefit Contract">
                <ReferenceField
                  source="corrects_bike_benefit_contract_id"
                  reference="bike-benefit-contracts"
                  link={`/bike-benefit-contracts/${record?.corrects_bike_benefit_contract_id}/show`}
                >
                  <ChipField source="vapaus_code" />
                </ReferenceField>
              </RowItem>
            )}
            {record?.revises_bike_benefit_contract_id && (
              <RowItem label="Revises Bike Benefit Contract">
                <ReferenceField
                  source="revises_bike_benefit_contract_id"
                  reference="bike-benefit-contracts"
                  link={`/bike-benefit-contracts/${record?.revises_bike_benefit_contract_id}/show`}
                >
                  <ChipField source="vapaus_code" />
                </ReferenceField>
              </RowItem>
            )}
            {record.corrected_by_bike_benefit_order_ids.length ? (
              <RowItem label="Corrected by Bike Benefit Order">
                <ReferenceArrayField
                  source="corrected_by_bike_benefit_order_ids"
                  reference="bike-benefit-orders"
                >
                  <SingleFieldList
                    linkType="show"
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <ChipField source="vapaus_code" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </RowItem>
            ) : null}
            {record.revised_by_bike_benefit_order_ids.length ? (
              <RowItem label="Revised by Bike Benefit Order">
                <ReferenceArrayField
                  source="revised_by_bike_benefit_order_ids"
                  reference="bike-benefit-orders"
                >
                  <SingleFieldList
                    linkType="show"
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <ChipField source="vapaus_code" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </RowItem>
            ) : null}
            <RowItem label="Total Package Price">
              <CurrencyGrossField
                record={summary}
                source="order_total"
                vatRate={record.flat_vat_rate}
                currencyRecord={record}
                currencySource="currency"
              />
            </RowItem>

            <RowItem label="Leasing Period">
              <NumberField
                source="leasing_period_months"
                options={{ style: "unit", unit: "month", unitDisplay: "long" }}
              />
            </RowItem>

            {record.fixed_monthly_taxable_value ? (
              <>
                <RowItem label="Fixed Monthly Taxable Value">
                  <CurrencyGrossField
                    source="fixed_monthly_taxable_value"
                    currencySource="currency"
                    vatRate={record.flat_vat_rate}
                  />
                </RowItem>
                <RowItem label="Calculated Monthly Taxable Value">
                  <CurrencyGrossField
                    record={orderDetailsData}
                    source="monthly_taxable_value"
                    currencyRecord={record}
                    currencySource="currency"
                    vatRate={record.flat_vat_rate}
                  />
                </RowItem>
              </>
            ) : (
              <RowItem label="Monthly Taxable Value">
                <CurrencyGrossField
                  record={orderDetailsData}
                  source="monthly_taxable_value"
                  currencyRecord={record}
                  currencySource="currency"
                  vatRate={record.flat_vat_rate}
                />
              </RowItem>
            )}

            {isSwedishOrder ? (
              <>
                <RowItem label="Gross salary deduction">
                  <CurrencyField
                    record={swedenSuggestedBenefitValues}
                    source="suggested_salary_deduction"
                    currencySource="currency"
                    currencyRecord={record}
                  />
                </RowItem>
                <RowItem label="Benefit value">
                  <CurrencyField
                    record={swedenSuggestedBenefitValues}
                    source="monthly_benefit_value_after_tax_deduction"
                    currencySource="currency"
                    currencyRecord={record}
                  />
                </RowItem>
              </>
            ) : null}

            <RowItem label="Monthly leasing fee">
              <CurrencyGrossField
                record={orderDetailsData}
                source="monthly_leasing_fee"
                currencyRecord={record}
                currencySource="currency"
                vatRate={record.flat_vat_rate}
              />
            </RowItem>
            <RowItem label="Residual value">
              <CurrencyGrossField
                record={orderDetailsData}
                source="residual_value"
                currencyRecord={record}
                currencySource="currency"
                vatRate={record.flat_vat_rate}
              />
            </RowItem>

            <RowItem label="Down Payment">
              <FunctionField
                render={() =>
                  toMoney(orderDetailsData?.down_payment_amount as number)
                }
              />
            </RowItem>

            <RowItem label="Down payment insurance">
              {record?.is_down_payment_insured ? (
                <CurrencyGrossField
                  record={orderDetailsData}
                  source="yearly_down_payment_insurance_fee"
                  currencyRecord={record}
                  currencySource="currency"
                  vatRate={record.flat_vat_rate}
                />
              ) : (
                "No"
              )}
            </RowItem>
            <RowItem label="Extended Warranty">
              <FunctionField
                render={() =>
                  record?.has_extended_warranty
                    ? toMoney(orderDetailsData?.extended_warranty as number)
                    : "No"
                }
              />
            </RowItem>
            <RowItem label="Cancellation Coverage">
              <FunctionField
                render={() =>
                  cancellationCoverages.find(
                    (cc: any) => record?.cancellation_coverage === cc.value,
                  )?.label ||
                  record?.cancellation_coverage ||
                  "-"
                }
              />
            </RowItem>
            <RowItem label="Employee-paid Cancellation Coverage">
              {record?.cancellation_coverage ===
              BenefitCancellationCoverage.EMPLOYEE ? (
                <ReferenceField
                  source="benefit_definition_id"
                  reference="benefit-definitions"
                  link={false}
                >
                  <CurrencyField
                    source="individual_onetime_cancellation_coverage"
                    currencySource="currency"
                  />
                </ReferenceField>
              ) : (
                "NO"
              )}
            </RowItem>
            {!!record.notes && (
              <TableRow>
                <TableCell colSpan={2}>
                  <TextInput
                    multiline
                    source="notes"
                    helperText={false}
                    disabled
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            )}
            {!!record?.bike_benefit_contract_ids?.length && (
              <RowItem label="Contract">
                <ReferenceArrayField
                  source="bike_benefit_contract_ids"
                  reference="bike-benefit-contracts"
                >
                  <SingleFieldList
                    linkType="show"
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <ChipField source="state" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </RowItem>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
