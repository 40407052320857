import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import { RelatedErpSaleInvoiceLineAssignments } from "components";

export const ErpSaleInvoiceLineAssignments = () => {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel-erp-sale-invoice-line-assignments-content"
        id="panel-erp-sale-invoice-line-assignments-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <ChecklistRtlIcon sx={{ mr: 2 }} />{" "}
        <Typography>ERP Sale Invoice Lines</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RelatedErpSaleInvoiceLineAssignments target="bike_benefit_contract_id" />
      </AccordionDetails>
    </Accordion>
  );
};
