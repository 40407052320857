import React, { FC } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { DateTime } from "luxon";
import {
  DateInput,
  Form,
  SaveButton,
  TextInput,
  maxValue,
  required,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { BikeDataCheckResult } from "@vapaus/generated";
import { useDebounce } from "@vapaus/utils";

import { OrderMenuModalProps } from "./OrderMenu";
import {
  useCustomMutationOptions,
  useVapausMutation,
  useVapausQuery,
} from "hooks";

export const FinaliseOrderModal: FC<OrderMenuModalProps> = ({
  open,
  onClose,
}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const redirect = useRedirect();
  const { onError } = useCustomMutationOptions();
  const notify = useNotify();

  const { mutate, isLoading, queryClient } = useVapausMutation(
    "finaliseBikeBenefitOrder",
  );

  const handleSubmit = ({ id, date, bike_serial_number }: any) => {
    mutate(
      { id, date, bike_serial_number },
      {
        onSuccess: ({ data: order }: any) => {
          notify("Order finalised");
          queryClient.invalidateQueries(["bike-benefit-contracts"]);
          queryClient.invalidateQueries(["bike-benefit-orders"]);
          onClose();
          redirect(
            `/bike-benefit-contracts/${order.current_bike_benefit_contract_id}/show`,
          );
        },
        onError,
      },
    );
  };

  const today = DateTime.now().toISODate();

  return (
    <Dialog fullWidth open={open} onClose={onClose} aria-label="Finalise order">
      <DialogTitle>Finalise order</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{
          bike_serial_number: record.bike.serial_number,
          date: today,
        }}
      >
        <DialogContent>
          <DateInput
            source="date"
            validate={[required(), maxValue(today)]}
            fullWidth
          />
          <TextInput
            source="bike_serial_number"
            validate={[required()]}
            fullWidth
          />
          <BikeDataCheckNotifications />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isLoading}
            startIcon={<CancelIcon />}
          >
            {translate("ra.action.cancel")}
          </Button>
          <SaveButton
            label="Finalise order"
            disabled={isLoading}
            alwaysEnable={!isLoading}
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

const BikeDataCheckNotifications = () => {
  const record = useRecordContext();
  const { watch } = useFormContext();
  const bikeSerialNumber = watch("bike_serial_number");
  const bikeDataCheckSerialNumber = useDebounce(bikeSerialNumber);
  const { data: bikeDataCheckResult } = useVapausQuery<BikeDataCheckResult>(
    "checkBikeData",
    {
      id: record.bike_id,
      serial_number: bikeDataCheckSerialNumber,
    },
    {
      enabled:
        !!bikeDataCheckSerialNumber && bikeDataCheckSerialNumber.length > 0,
    },
  );

  return bikeDataCheckResult?.serial_number.warnings.map((warning) => (
    <Alert severity="warning" sx={{ mb: 2 }} key={warning.short}>
      {warning.short}
    </Alert>
  ));
};
