import { defaultTheme } from "react-admin";

const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#00838f",
    },
    secondary: {
      main: "#00838f",
    },
  },
};

export default theme;
