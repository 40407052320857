import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";

import { Pagination } from "components";

const UserContracts = () => {
  const record = useRecordContext();

  if (!record?.id) {
    return (
      <EmptyBoxInfo message="Please save the user first to manage user's contracts." />
    );
  }

  return (
    <ReferenceManyField
      label="Contracts"
      reference="bike-benefit-contracts"
      target="user_id"
      perPage={20}
      pagination={<Pagination />}
      sort={{ field: "end_date", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="user.full_name" sortable={false} />
        <TextField source="vapaus_code" sortable={false} />
        <TextField source="bike.serial_number" sortable={false} />
        <ReferenceField
          source="bike_benefit_order_id"
          reference="bike-benefit-orders"
          sortable={false}
          label="Shop"
        >
          <ReferenceField source="shop_id" reference="shops">
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="state" textTransform="uppercase" sortable={false} />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default UserContracts;
