import { ResourceProps } from "react-admin";

const benefitDefinitionMonthlyOfficeFeeResource: ResourceProps = {
  name: "benefit-definition-monthly-office-fees",
  options: {
    label: "Benefit definition monthly office fee",
  },
};

export default benefitDefinitionMonthlyOfficeFeeResource;
