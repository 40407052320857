import { ReactElement } from "react";

import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  SimpleForm,
  required,
  useRecordContext,
} from "react-admin";

export const FleetLocationForm = ({
  toolbar,
  organisation_id,
}: {
  toolbar?: ReactElement;
  organisation_id: string;
}) => {
  return (
    <SimpleForm toolbar={toolbar}>
      <ReferenceInput
        source="location_id"
        reference="locations"
        filter={{ organisation_ids: organisation_id }}
      >
        <AutocompleteInput
          label="Location"
          optionText={(location) =>
            `${location.name} (${location.city}, ${location.address})`
          }
          optionValue="id"
          validate={required()}
          fullWidth
        />
      </ReferenceInput>
      <BooleanInput source="is_default_pick_up" />
      <BooleanInput source="is_default_return" />
    </SimpleForm>
  );
};
