import React from "react";

import { Create } from "react-admin";

import { EditToolbar } from "../../components";
import BikeBrandForm from "./BikeBrandForm";
import { useCustomMutationOptions } from "hooks";

export const BikeBrandCreate = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Create mutationOptions={mutationOptions} redirect="list">
      <BikeBrandForm toolbar={<EditToolbar />} />
    </Create>
  );
};
