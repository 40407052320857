import BikeIcon from "@mui/icons-material/PedalBike";
import { ResourceProps } from "react-admin";

import { BikeEdit } from "./BikeEdit";
import { BikeList } from "./BikeList";

const bikeResource: ResourceProps = {
  name: "bikes",
  icon: BikeIcon,
  list: BikeList,
  edit: BikeEdit,
  options: {
    label: "Bikes",
  },
};

export default bikeResource;
