import * as React from "react";
import { FC, ReactElement } from "react";

import { Grid, Typography } from "@mui/material";
import { PictureInput } from "components/PictureInput";
import {
  BooleanField,
  Form,
  FormTab,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TabbedFormView,
  TextField,
  required,
  useRecordContext,
  useSaveContext,
} from "react-admin";

import { useFormRootPath } from "../../utils/useFormRootPath";
import { NestedBenefitAccessRulesDataGrid } from "./components/BenefitAccessRules";
import { NestedBillingDetailsDataGrid } from "./components/BillingDetails";
import BookingTermsAndConditionsList from "./components/BookingTermsAndConditions";
import FleetAccessRules from "./components/FleetAccessRules";
import FleetUsageSettings from "./components/FleetUsageSettings";
import { LocationList } from "./components/Location";
import {
  NullableAutocompleteInput as AutocompleteInput,
  CustomDataGrid,
  Pagination,
  SectionTitle,
  Separator,
  NullableTextInput as TextInput,
} from "components";
import { PLANS_CHOICES } from "core";
import { useEnumChoices } from "hooks";

type OrganisationFormProps = {
  toolbar?: ReactElement;
};

const validateRequired = [required()];
const emptyPledgedLenderId = "empty";

const OrganisationForm: FC<OrganisationFormProps> = (
  props: OrganisationFormProps,
) => {
  const formRootPathname = useFormRootPath();
  const { save } = useSaveContext();
  const handleSubmit = (values: any) => {
    save?.({
      ...values,
      pledged_lender:
        values.pledged_lender === emptyPledgedLenderId
          ? null
          : values.pledged_lender,
    });
  };
  return (
    <Form
      formRootPathname={formRootPathname}
      warnWhenUnsavedChanges
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors = {} as Record<string, string>;

        if (values.pictures && !values.logo_object_id) {
          errors.pictures =
            "Upload still in progress, wait for the upload to finish before submitting the form";
        }

        return errors;
      }}
      defaultValues={(organisation?: any) => {
        if (!organisation) return;

        const defaultOrg = { ...organisation };
        if (organisation.logo_url) {
          defaultOrg.pictures = [
            {
              src: organisation.logo_url,
            },
          ];
        }
        return defaultOrg;
      }}
    >
      <OrganisationFormTabs {...props} />
    </Form>
  );
};

const OrganisationFormTabs: FC<OrganisationFormProps> = ({
  toolbar,
}: OrganisationFormProps) => {
  const formRootPathname = useFormRootPath();
  const record = useRecordContext();
  const countryChoices = useEnumChoices("SupportedOrganisationCountries");
  const pledgedLenderChoices = [
    { id: emptyPledgedLenderId, name: "empty" },
    ...useEnumChoices("PledgedLender"),
  ];
  return (
    <TabbedFormView formRootPathname={formRootPathname} toolbar={toolbar}>
      <FormTab label="Main" sx={{ maxWidth: 1024 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="Summary" />
            <TextInput source="name" validate={validateRequired} fullWidth />
            <SelectInput
              source="country"
              choices={countryChoices}
              validate={validateRequired}
              fullWidth
            />
            <TextInput
              label="Business ID"
              source="business_id"
              validate={validateRequired}
              fullWidth
            />
            <TextInput source="description" multiline fullWidth />
            <ReferenceInput source="parent_id" reference="organisations">
              <AutocompleteInput
                label="Parent organisation"
                optionText="name"
                optionValue="id"
                fullWidth
              />
            </ReferenceInput>
            {record?.id && (
              <SelectInput
                label="Plan"
                source="plan"
                choices={PLANS_CHOICES}
                fullWidth
                disabled
              />
            )}
            <Separator />
            <SectionTitle title="Contact" />
            <TextInput source="phone_number" fullWidth />
            <TextInput type="email" source="email" fullWidth />
          </Grid>
          <Grid item md={6}>
            <Separator />
            <SectionTitle title="Logo" />
            <PictureInput
              pictureFormField="logo_object_id"
              pictureUrlField="logo_url"
              uploadMethodName="uploadOrganisationPicture"
            />
            {record?.id && (
              <>
                <ReferenceManyField
                  label="Benefit definitions"
                  reference="benefit-definitions"
                  target="organisation_id"
                  perPage={10}
                  pagination={<Pagination />}
                  sort={{ field: "name", order: "ASC" }}
                >
                  <CustomDataGrid
                    label="Benefit definitions"
                    empty={<></>}
                    bulkActionButtons={false}
                    rowClick="edit"
                  >
                    <TextField source="name" sortable={false} />
                    <BooleanField source="active" sortable={false} />
                  </CustomDataGrid>
                </ReferenceManyField>
                <ReferenceManyField
                  label="Fleets"
                  reference="fleets"
                  target="organisation_id"
                  perPage={10}
                  pagination={<Pagination />}
                  sort={{ field: "name", order: "ASC" }}
                >
                  <CustomDataGrid
                    label="Fleets"
                    empty={<></>}
                    bulkActionButtons={false}
                    rowClick="edit"
                  >
                    <TextField source="name" sortable={false} />
                  </CustomDataGrid>
                </ReferenceManyField>
                <ReferenceManyField
                  label="Child organisations"
                  reference="organisations"
                  target="parent_organisation_id"
                  perPage={10}
                  pagination={<Pagination />}
                  sort={{ field: "name", order: "ASC" }}
                >
                  <CustomDataGrid
                    label="Child organisations"
                    empty={<></>}
                    bulkActionButtons={false}
                    rowClick="edit"
                  >
                    <TextField source="name" sortable={false} />
                    <TextField source="business_id" sortable={false} />
                  </CustomDataGrid>
                </ReferenceManyField>
                <ReferenceManyField
                  label="Shops"
                  reference="shops"
                  target="organisation_id"
                  perPage={10}
                  pagination={<Pagination />}
                  sort={{ field: "name", order: "ASC" }}
                >
                  <CustomDataGrid
                    label="Shops"
                    empty={<></>}
                    bulkActionButtons={false}
                    rowClick="edit"
                  >
                    <TextField source="name" sortable={false} />
                    <BooleanField
                      source="is_active"
                      label="Active"
                      sortable={false}
                    />
                  </CustomDataGrid>
                </ReferenceManyField>
              </>
            )}
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Billing details">
        <Typography variant="h6">Organisation billing details</Typography>
        <NestedBillingDetailsDataGrid />
        <Separator />
        <Typography variant="h6">Pledging</Typography>
        <SelectInput
          label="Pledged lender"
          source="pledged_lender"
          choices={pledgedLenderChoices}
          defaultValue={emptyPledgedLenderId}
          fullWidth
          isRequired
        />
      </FormTab>
      <FormTab label="Benefit access rules">
        <NestedBenefitAccessRulesDataGrid />
      </FormTab>
      <FormTab label="Fleet access rules">
        <FleetAccessRules />
      </FormTab>
      <FormTab label="Fleet usage settings">
        <FleetUsageSettings />
      </FormTab>
      <FormTab label="Fleet booking terms and conditions">
        <BookingTermsAndConditionsList />
      </FormTab>
      <FormTab label="Locations">
        <LocationList />
      </FormTab>
    </TabbedFormView>
  );
};

export default OrganisationForm;
