import { Typography } from "@mui/material";
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
} from "react-admin";

import { Pagination } from "components";
import { TERMS_TYPES_LABELS, Terms } from "core";

export const TermsList = () => (
  <List
    perPage={20}
    pagination={<Pagination />}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="id" sortable={false} />
      <FunctionField<Terms>
        label="Type"
        render={(record?: Terms) => (
          <Typography component="span" variant="body2">
            {record && TERMS_TYPES_LABELS[record.terms_type]}
          </Typography>
        )}
      />
      <DateField source="created_at" showTime />
      <BooleanField source="is_current" sortable={false} />
    </Datagrid>
  </List>
);
