import {
  BooleanField,
  BooleanInput,
  Datagrid,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { exporter } from "utils/exporter";

import { AVAILABLE_COUNTRIES } from "../../core";
import { Pagination } from "components";

const filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <BooleanInput label="Active" source="is_active" />,
  <SelectInput source="country" choices={AVAILABLE_COUNTRIES} />,
];

export const ShopList = () => {
  return (
    <List
      filters={filters}
      perPage={20}
      pagination={<Pagination />}
      exporter={exporter}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="name" />
        <TextField label="Description" source="description" sortable={false} />
        <ReferenceField
          source="organisation_id"
          reference="organisations"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="is_active" sortable={false} />
        <BooleanField source="is_featured" sortable={false} />
      </Datagrid>
    </List>
  );
};
