import {
  Datagrid,
  DateField,
  Labeled,
  ReferenceManyField,
  TextField,
} from "react-admin";

import { Pagination } from "components";

const BikeContracts = () => {
  return (
    <Labeled fullWidth>
      <ReferenceManyField
        label="Contracts"
        reference="bike-benefit-contracts"
        target="bike_id"
        perPage={20}
        pagination={<Pagination />}
        sort={{ field: "created_at", order: "DESC" }}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="user.full_name" sortable={false} />
          <TextField source="vapaus_code" sortable={false} />
          <TextField
            source="state"
            textTransform="uppercase"
            sortable={false}
          />
          <DateField source="created_at" sortable={false} />
        </Datagrid>
      </ReferenceManyField>
    </Labeled>
  );
};

export default BikeContracts;
