import { FC, useMemo } from "react";

import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BikeBenefitOrderState } from "core/benefit";
import { useRecordContext } from "react-admin";

export const OrderStatus: FC = () => {
  const { state, cancellation_reason, is_billable } = useRecordContext();

  const color = useMemo(() => {
    if (state === BikeBenefitOrderState.CANCELLED) return "error";
    if (state === BikeBenefitOrderState.DRAFT) return "warning";
    if (state === BikeBenefitOrderState.FINALISED) return "success";
    return undefined;
  }, [state]);

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography color="text.secondary">Status</Typography>
        </Grid>
        <Grid item xs={6}>
          <Chip
            label={state}
            color={color}
            sx={{ textTransform: "uppercase", width: "100%" }}
          />
        </Grid>
        {cancellation_reason && (
          <>
            <Grid item xs={6}>
              <Typography color="text.secondary">
                Cancellation reason
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                {cancellation_reason}
              </Typography>
            </Grid>
          </>
        )}
        {!is_billable && (
          <>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Chip
                label="NON-BILLABLE"
                color="info"
                sx={{ width: "100%", mt: 1 }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};
