import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext } from "react-admin";

import { LocationForm } from "./LocationForm";

export const LocationCreateButton = () => {
  const organisation = useRecordContext();
  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      record={{ organisation_id: organisation.id }}
      title="Add a location"
    >
      <LocationForm />
    </CreateInDialogButton>
  );
};
