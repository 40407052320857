import { FC, ReactNode } from "react";

import { Build } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRedirect,
} from "react-admin";

import { BikeBenefitContractCancellationReason } from "@vapaus/generated";

import { CurrencyField, Pagination } from "components";
import { useEnum } from "hooks";

export const MaintenanceHistory: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const workTypes = useEnum("WorkTypes");
  if (!record.monthly_maintenance_budget) return null;
  return (
    <>
      <Accordion>
        <AccordionSummary
          aria-controls="panel-accessories-content"
          id="panel-accessories-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Build sx={{ mr: 2 }} /> <Typography>Maintenance history</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="order-summary-item">
            <Typography className="label">Total spent</Typography>
            <CurrencyField
              source="spent_maintenance_budget"
              currencySource="currency"
            />
          </div>
          <div className="order-summary-item">
            <Typography className="label wide">Remaining budget</Typography>
            <CurrencyField
              source="remaining_maintenance_budget"
              currencySource="currency"
            />
            /
            <CurrencyField
              source="total_maintenance_budget"
              currencySource="currency"
            />
          </div>
        </AccordionDetails>
        <AccordionDetails>
          {record.cancellation_reason ==
          BikeBenefitContractCancellationReason.MERGER_ACQUISITION ? (
            <Typography>
              The maintenance history has been moved to another contract of this
              order due to a merger or acquisition. Please see other contracts
              of this order linked above.
            </Typography>
          ) : (
            <ReferenceManyField
              reference="maintenance-events"
              source="vapaus_code"
              target="vapaus_code"
              pagination={<Pagination />}
              perPage={10}
              sort={{ field: "work_date", order: "DESC" }}
            >
              <Datagrid
                bulkActionButtons={false}
                rowClick={(id, resource, record) => {
                  redirect(
                    "edit",
                    "maintenance-events",
                    record.id,
                    {},
                    {
                      record: {
                        bike_benefit_contract_id:
                          record.bike_benefit_contract_id,
                      },
                    },
                  );
                  return false;
                }}
                expand={<MaintenanceEventDetailsPanel />}
              >
                <DateField source="work_date" sortable={false} label="Date" />
                <FunctionField
                  label="Type"
                  render={(maintenanceEvent: any) =>
                    workTypes.find(
                      (wt: any) => wt.value === maintenanceEvent.work_type,
                    )?.label || maintenanceEvent.work_type
                  }
                />
                <CurrencyField
                  source="parts_price"
                  currencySource="currency"
                  headerClassName="text-right"
                  textAlign="right"
                />
                <CurrencyField
                  source="work_price"
                  currencySource="currency"
                  headerClassName="text-right"
                  textAlign="right"
                />
                <CurrencyField
                  source="total_price"
                  currencySource="currency"
                  headerClassName="text-right"
                  textAlign="right"
                />
              </Datagrid>
            </ReferenceManyField>
          )}
        </AccordionDetails>
      </Accordion>
      {children}
    </>
  );
};

const MaintenanceEventDetailsPanel: FC = () => {
  return (
    <SimpleShowLayout>
      <ReferenceField source="shop_id" reference="shops">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="work_description" />
      <DateField source="created_at" />
      <NumberField
        source="parts_vat_rate"
        label="Parts VAT rate"
        options={{ style: "percent", maximumFractionDigits: 2 }}
      />
      <NumberField
        source="work_vat_rate"
        label="Work VAT rate"
        options={{ style: "percent", maximumFractionDigits: 2 }}
      />
      <BooleanField source="is_billable" />
    </SimpleShowLayout>
  );
};
