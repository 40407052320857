import { useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MergeIcon from "@mui/icons-material/Merge";
import { DuplicateModal } from "resources/BenefitDefinition/components/DuplicateModal";

import { ProcessMergerModal } from "./ProcessMergerModal";
import { DropdownIconMenu } from "components";

enum MenuType {
  PROCESS_MERGER,
  DUPLICATE,
}

export const BenefitDefinitionMenu = () => {
  const [menu, setMenu] = useState<MenuType>();
  const menus = [
    {
      name: "Process merger",
      icon: <MergeIcon />,
      onClick: () => setMenu(MenuType.PROCESS_MERGER),
      menuType: MenuType.PROCESS_MERGER,
      Component: ProcessMergerModal,
    },
    {
      name: "Duplicate",
      icon: <ContentCopyIcon />,
      onClick: () => setMenu(MenuType.DUPLICATE),
      menuType: MenuType.DUPLICATE,
      Component: DuplicateModal,
    },
  ];
  return (
    <>
      <DropdownIconMenu menus={menus} tooltip="Contract Actions" />
      {menus.map(
        ({ Component, name, menuType }) =>
          Component && (
            <Component
              key={name}
              open={menu === menuType}
              onClose={() => setMenu(undefined)}
            />
          ),
      )}
    </>
  );
};
