import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FINANCING_METHODS_CHOICES_BY_COUNTRY,
  TUKIRAHOITUS_FINANCING_METHODS,
} from "core/benefit";
import {
  FunctionField,
  ReferenceField,
  SelectInput,
  required,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";

import {
  OrganisationRead,
  SupportedOrganisationCountries,
} from "@vapaus/generated";

import { RowItem } from "./RowItem";
import { CurrencyGrossField, GrossPriceInput } from "components";
import { useEnumLabel } from "hooks";

export const FinancingDetails = () => {
  const record = useRecordContext();
  const currency = record.currency;
  const financingMethod = useWatch({ name: "financing_method" });
  const getPledgedLenderLabel = useEnumLabel("PledgedLender");

  // Somehow the ReferenceField doesn't work here, so we use useGetOne instead
  const { data: benefitDefinition } = useGetOne("benefit-definitions", {
    id: record.benefit_definition_id,
  });

  const financingChoices =
    FINANCING_METHODS_CHOICES_BY_COUNTRY[
      benefitDefinition?.organisation.country as SupportedOrganisationCountries
    ] || [];

  return (
    <Card variant="outlined">
      <Table aria-label="contract details">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <div className="table-header-title">
                <AccountBalanceIcon sx={{ mr: 2 }} />{" "}
                <Typography component="span">Financing details</Typography>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RowItem label="Financing method">
            <SelectInput
              label="Financing method"
              source="financing_method"
              emptyText="-"
              helperText={false}
              choices={financingChoices}
              validate={record.financing_method ? required() : undefined}
              fullWidth
            />
          </RowItem>
          <RowItem label="Pledged lender">
            <ReferenceField
              record={benefitDefinition}
              source="organisation_id"
              reference="organisations"
              link={false}
            >
              <FunctionField<OrganisationRead>
                render={(organisation) =>
                  getPledgedLenderLabel(organisation?.pledged_lender) ?? (
                    <em>empty</em>
                  )
                }
              />
            </ReferenceField>
          </RowItem>
          {TUKIRAHOITUS_FINANCING_METHODS.includes(financingMethod) && (
            <RowItem label="Tukirahoitus monthly leasing price">
              <GrossPriceInput
                source="tukirahoitus_monthly_leasing_price"
                label="Tukirahoitus monthly leasing price"
                currency={currency}
                vatRate={record.current_flat_vat_rate}
                netDecimalPoints={2}
                grossDecimalPoints={2}
                fullWidth
              />
            </RowItem>
          )}
          <RowItem label="Residual ratio">
            <Tooltip
              title={record?.residual_ratio.toLocaleString(undefined, {
                style: "percent",
                maximumFractionDigits: 7,
              })}
              placement="bottom-end"
            >
              <Typography>
                {record?.residual_ratio.toLocaleString(undefined, {
                  style: "percent",
                  maximumFractionDigits: 1,
                })}
              </Typography>
            </Tooltip>
          </RowItem>
          <RowItem label="Redemption value at the end of the contract">
            <CurrencyGrossField
              source="discounted_residual_value"
              currencySource="currency"
              vatRate={record.current_flat_vat_rate}
            />
          </RowItem>
        </TableBody>
      </Table>
    </Card>
  );
};
