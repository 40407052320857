import { get } from "lodash-es";
import type { NumberFieldProps } from "react-admin";
import { NumberField, useRecordContext } from "react-admin";

export interface CurrencyFieldProps extends NumberFieldProps {
  currencyRecord?: Record<string, any>;
  currencySource: string;
}

export function CurrencyField({
  currencyRecord,
  currencySource,
  ...props
}: CurrencyFieldProps) {
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  return (
    <NumberField
      {...props}
      options={{
        ...props.options,
        style: "currency",
        currency: get(currencyRecord ?? record, currencySource),
      }}
    />
  );
}
