import React from "react";

import { Create } from "react-admin";

import BenefitDefinitionForm from "./BenefitDefinitionForm";
import { useCustomMutationOptions } from "hooks";

export const BenefitDefinitionCreate = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Create mutationOptions={mutationOptions} redirect="edit">
      <BenefitDefinitionForm />
    </Create>
  );
};
