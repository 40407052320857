import { ResourceProps } from "react-admin";

const shopDiscountsResource: ResourceProps = {
  name: "shop-discounts",
  options: {
    label: "Shop discounts",
  },
};

export default shopDiscountsResource;
