import * as React from "react";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Grid, Toolbar as MUIToolbar } from "@mui/material";
import {
  WizardForm,
  WizardFormStep,
  useWizardFormContext,
} from "@react-admin/ra-form-layout";
import { debounce } from "lodash-es";
import {
  SaveButton,
  useFormGroup,
  useFormGroups,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { useFormContext, useFormState } from "react-hook-form";

import { BikeBenefitOrderingMethod } from "@vapaus/generated";

import { HubspotTicketContextProvider } from "./HubspotTicketContext";
import { OrderDetailsDisplay } from "./OrderDetailsDisplay";
import { OrderFormAccessories } from "./OrderFormAccessories";
import { OrderFormBike } from "./OrderFormBike";
import {
  OrderFormContextProvider,
  useOrderFormContext,
} from "./OrderFormContext";
import { OrderFormExtras } from "./OrderFormExtras";
import { OrderFormOrderingMethod } from "./OrderFormOrderingMethod";
import { OrderFormSummary } from "./OrderFormSummary";
import { OrderFormUser } from "./OrderFormUser";

export const OrderForm = () => {
  const record = useRecordContext();
  const isPrecycled =
    record?.ordering_method === BikeBenefitOrderingMethod.PRECYCLED;
  return (
    <OrderFormContextProvider>
      <HubspotTicketContextProvider>
        <WizardForm
          record={{
            ...record,
            bike_id: isPrecycled ? record.bike.id : undefined,
            bike: isPrecycled ? undefined : record.bike,
            bike_condition: record.bike?.condition,
          }}
          defaultValues={{
            monthly_maintenance_budget: 0,
          }}
          toolbar={<OrderFormToolbar />}
          warnWhenUnsavedChanges
        >
          {record.id ? (
            <WizardFormStep label="Benefit">
              <OrderDetailsDisplay />
            </WizardFormStep>
          ) : null}
          {!record.id ? (
            <WizardFormStep label="User">
              <OrderFormUser />
            </WizardFormStep>
          ) : null}
          <WizardFormStep label="Ordering method">
            <OrderFormOrderingMethod />
          </WizardFormStep>
          <WizardFormStep label="Bike details">
            <OrderFormBike />
          </WizardFormStep>
          <WizardFormStep label="Accessories">
            <OrderFormAccessories />
          </WizardFormStep>
          <WizardFormStep label="Extras">
            <OrderFormExtras />
          </WizardFormStep>
          <WizardFormStep label="Summary">
            <OrderFormSummary />
          </WizardFormStep>
        </WizardForm>
      </HubspotTicketContextProvider>
    </OrderFormContextProvider>
  );
};

const OrderFormToolbar = () => {
  const t = useTranslate();
  const {
    hasPreviousStep,
    hasNextStep,
    goToPreviousStep,
    goToNextStep,
    currentStep,
  } = useWizardFormContext();
  const { trigger } = useFormContext();
  const { isValidating } = useFormState();
  const { isValid } = useFormGroup(`step-${currentStep}`);
  const formGroups = useFormGroups();
  const { calculation } = useOrderFormContext();

  formGroups.subscribe(
    `step-${currentStep}`,
    debounce(() => {
      trigger(formGroups.getGroupFields(`step-${currentStep}`));
    }, 100),
  );

  const handleNextClick = (event: React.MouseEvent<HTMLElement>) => {
    const groupFields = formGroups.getGroupFields(`step-${currentStep}`);
    event.preventDefault();
    trigger(groupFields).then((isValid) => isValid && goToNextStep());
  };

  const handlePreviousClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    goToPreviousStep();
  };

  return (
    <MUIToolbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {hasPreviousStep ? (
            <Button
              variant="text"
              onClick={handlePreviousClick}
              aria-label={t("ra-form-layout.action.previous")}
              startIcon={<ChevronLeft />}
            >
              PREVIOUS
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          {hasNextStep ? (
            <Button
              onClick={handleNextClick}
              variant="text"
              disabled={isValidating || !isValid}
              endIcon={<ChevronRight />}
            >
              Next
            </Button>
          ) : (
            <SaveButton
              disabled={isValidating || !isValid || !calculation}
              alwaysEnable={!isValidating && isValid && !!calculation}
            />
          )}
        </Grid>
      </Grid>
    </MUIToolbar>
  );
};
