import React, { useEffect, useMemo, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { round, sum } from "lodash-es";
import { DateTime } from "luxon";
import {
  CheckboxGroupInput,
  Create,
  DateInput,
  Form,
  FormDataConsumer,
  ImageField,
  ImageInput,
  RadioButtonGroupInput,
  SaveButton,
  SelectInput,
  TextInput,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { sequentialPromiseAll } from "utils/sequentialPromiseAll";

import {
  Accessory,
  BenefitCancellationCoverageStatus,
  BikeBenefitContractAdminRead,
  BikeBenefitContractReturnOrRedemptionReason,
  ReturnProcessInvoicingAction,
} from "@vapaus/generated";
import { toGross, useDebounce } from "@vapaus/utils";

import { ContractMenuModalProps } from "./ContractMenu";
import { AVAILABLE_COUNTRIES } from "core";
import {
  useCustomMutationOptions,
  useEnumChoices,
  useEnumLabel,
  useVapausMutation,
  useVapausQuery,
} from "hooks";

export const CreateReturnProcessModal = ({
  onClose,
  open,
}: ContractMenuModalProps) => {
  const contract = useRecordContext<BikeBenefitContractAdminRead>();
  const refresh = useRefresh();
  const notify = useNotify();
  const getAccessoryTypeLabel = useEnumLabel("AccessoryType");
  const accessoryChoices = contract.accessories.map((accessory: Accessory) => ({
    id: accessory.id,
    name: [
      getAccessoryTypeLabel(accessory.accessory_type),
      `(${accessory.manufacturer || "-"} ${accessory.model || "-"})`,
    ].join(" "),
  }));
  const now = DateTime.now();
  const defaultLeaseEndDate = DateTime.min(
    now.day < 20 ? now.endOf("month") : now.plus({ month: 1 }).endOf("month"),
    DateTime.fromISO(contract.end_date),
  ).toISODate();
  const [picturesLoading, setPicturesLoading] = useState(false);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Start Return Process"
    >
      <DialogTitle>Start Return Process</DialogTitle>
      <Create
        resource="return-processes"
        record={{
          bike_benefit_contract_id: contract.id,
          redeemed_accessory_ids: [],
          return_type: null,
          return_method: null,
          pickup_details: null,
          lease_end_date: defaultLeaseEndDate,
          pictures: [],
          picture_ids: [],
        }}
        mutationOptions={{
          onSuccess: () => {
            refresh();
            notify("Return Process started successfully");
            onClose();
          },
        }}
        transform={(formData: any) => {
          const isEndOfLeaseReturn = formData.return_type === "end-of-lease";
          return {
            bike_benefit_contract_id: contract.id,
            is_end_of_lease_return: isEndOfLeaseReturn,
            lease_end_date: isEndOfLeaseReturn
              ? contract.end_date
              : formData.lease_end_date,
            redeemed_accessory_ids: formData.redeemed_accessory_ids,
            pickup_details:
              formData.return_method === "pick-up"
                ? formData.pickup_details
                : null,
            return_reason: formData.return_reason,
            picture_ids: formData.picture_ids,
          };
        }}
        title={<></>}
        sx={{ mt: "-2em" }}
      >
        <Form>
          <DialogContent>
            <Typography>
              Contract cancellation coverage status is{" "}
              <b>{contract.cancellation_coverage_status}</b>.
            </Typography>
            <RadioButtonGroupInput
              source="return_type"
              choices={[
                contract.cancellation_coverage_status ===
                BenefitCancellationCoverageStatus.ACTIVE
                  ? { id: "mid-term", name: "Mid-term" }
                  : null,
                { id: "end-of-lease", name: "End of lease" },
              ].filter(Boolean)}
              validate={required()}
              helperText="Choosing End of lease means that the contract won't be cancelled and will end on its planned end date. Choosing Mid-term will let you pick a lease end date the contract will be cancelled on."
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.return_type === "mid-term" ? (
                  <DateInput
                    source="lease_end_date"
                    fullWidth
                    helperText={
                      formData.lease_end_date < DateTime.now().startOf("day")
                        ? "This date will be applied as the contract’s exceptional end date. Since the date is in the past, the contract will be cancelled immediately after you complete the return process."
                        : "This date will be applied as the contract’s exceptional end date. The contract will remain active until that date."
                    }
                  />
                ) : null
              }
            </FormDataConsumer>
            <RadioButtonGroupInput
              source="return_method"
              choices={[
                { id: "self-return", name: "Self-return" },
                { id: "pick-up", name: "Pick up" },
              ]}
              validate={required()}
              fullWidth
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.return_method === "pick-up" ? (
                  <>
                    <TextInput
                      source="pickup_details.address"
                      validate={required()}
                      defaultValue={contract.user.address}
                      label="Address"
                      fullWidth
                    />
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <TextInput
                          source="pickup_details.post_code"
                          validate={required()}
                          defaultValue={contract.user.post_code}
                          label="Post code"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={8}>
                        <TextInput
                          source="pickup_details.city"
                          validate={required()}
                          defaultValue={contract.user.city}
                          label="City"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <SelectInput
                      source="pickup_details.country"
                      choices={AVAILABLE_COUNTRIES}
                      validate={required()}
                      defaultValue={contract.user.country}
                      fullWidth
                      label="Country"
                    />
                    <TextInput
                      source="pickup_details.phone_number"
                      validate={required()}
                      defaultValue={contract.user.phone_number}
                      label="Phone number"
                      fullWidth
                    />
                    <DateInput
                      source="pickup_details.requested_pickup_date"
                      validate={required()}
                      defaultValue={DateTime.now()
                        .plus({ days: 7 })
                        .toISODate()}
                      fullWidth
                    />
                  </>
                ) : null
              }
            </FormDataConsumer>
            {accessoryChoices.length ? (
              <CheckboxGroupInput
                source="redeemed_accessory_ids"
                choices={accessoryChoices}
                label="Choose redeemed accessories"
                row={false}
              />
            ) : null}
            <ReturnReasonSelectInput />
            <PicturesInput setPicturesLoading={setPicturesLoading} />
            <ReturnProcessInvoicingPlan contract={contract} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} startIcon={<CancelIcon />}>
              Cancel
            </Button>
            <SaveButton disabled={picturesLoading} />
          </DialogActions>
        </Form>
      </Create>
    </Dialog>
  );
};

const ReturnReasonSelectInput = () => {
  const returnReasonChoices = useEnumChoices(
    "BikeBenefitContractReturnOrRedemptionReason",
  );
  const returnReasonChoicesFiltered = returnReasonChoices.filter(
    (choice) =>
      choice.id !== BikeBenefitContractReturnOrRedemptionReason.END_OF_LEASE,
  );
  const { watch, setValue } = useFormContext();
  const returnType = watch("return_type");

  useEffect(() => {
    if (returnType === "end-of-lease") {
      setValue(
        "return_reason",
        BikeBenefitContractReturnOrRedemptionReason.END_OF_LEASE,
      );
      return;
    }
    setValue("return_reason", undefined);
  }, [returnType, setValue]);

  return (
    <SelectInput
      source="return_reason"
      label="Choose return reason"
      choices={
        returnType === "end-of-lease"
          ? returnReasonChoices
          : returnReasonChoicesFiltered
      }
      validate={[required()]}
      fullWidth
      disabled={returnType === "end-of-lease"}
    />
  );
};

const PicturesInput = ({
  setPicturesLoading,
}: {
  setPicturesLoading: (v: boolean) => void;
}) => {
  const { watch, setValue } = useFormContext();
  const [pictureIdMap, setPictureIdMap] = useState(new Map<string, string>());
  const formPictures = watch("pictures");
  const { mutate } = useVapausMutation("uploadReturnProcessPicture");
  const { onError } = useCustomMutationOptions();

  useEffect(() => {
    setPicturesLoading(true);
    sequentialPromiseAll(formPictures, (picture: any) => {
      return new Promise<string>((resolve, reject) => {
        if (pictureIdMap.has(picture.src)) {
          resolve(pictureIdMap.get(picture.src)!);
        } else {
          mutate(picture.rawFile, {
            onSuccess: (storageObject: any) => {
              setPictureIdMap(pictureIdMap.set(picture.src, storageObject.id));
              resolve(storageObject.id);
            },
            onError: (e) => {
              onError(e);
              reject(e);
            },
          });
        }
      });
    })
      .then((pictureIds: string[]) => {
        setValue("picture_ids", pictureIds);
      })
      .catch(() => {
        setValue(
          "pictures",
          formPictures.filter((p: any) => pictureIdMap.has(p.src)),
        );
      })
      .finally(() => setPicturesLoading(false));
  }, [formPictures, pictureIdMap, setPictureIdMap, setValue]);
  return (
    <ImageInput
      source="pictures"
      label="Pictures"
      multiple
      helperText="The maximum accepted file size is 10MB"
    >
      <ImageField source="src" />
    </ImageInput>
  );
};

const ReturnProcessInvoicingPlan = ({
  contract,
}: {
  contract: BikeBenefitContractAdminRead;
}) => {
  const {
    watch,
    formState: { isValid },
  } = useFormContext();
  const [
    bike_benefit_contract_id,
    return_type,
    lease_end_date,
    redeemed_accessory_ids,
    return_method,
    pickup_details,
    return_reason,
  ] = watch([
    "bike_benefit_contract_id",
    "return_type",
    "lease_end_date",
    "redeemed_accessory_ids",
    "return_method",
    "pickup_details",
    "return_reason",
  ]);
  const planInvoicingParams = useDebounce(
    useMemo(
      () => ({
        bike_benefit_contract_id,
        is_end_of_lease_return: return_type === "end-of-lease",
        lease_end_date:
          return_type === "end-of-lease" ? contract.end_date : lease_end_date,
        redeemed_accessory_ids,
        pickup_details:
          return_method === "pick-up"
            ? {
                requested_pickup_date: pickup_details.requested_pickup_date,
                country: pickup_details.country,
                address: pickup_details.address,
                post_code: pickup_details.post_code,
                city: pickup_details.city,
                phone_number: pickup_details.phone_number,
              }
            : null,
        return_reason,
      }),
      [
        bike_benefit_contract_id,
        return_type,
        redeemed_accessory_ids,
        return_method,
        pickup_details?.requested_pickup_date,
        pickup_details?.country,
        pickup_details?.address,
        pickup_details?.post_code,
        pickup_details?.city,
        pickup_details?.phone_number,
        return_reason,
      ],
    ),
  );
  const { data: invoicingPlan } = useVapausQuery<
    Array<ReturnProcessInvoicingAction>
  >("planReturnProcessInvoicing", planInvoicingParams, {
    enabled: isValid,
    keepPreviousData: true,
  });
  const getSaleInvoiceLineTypeLabel = useEnumLabel("SaleInvoiceLineType");
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: contract.currency,
  }).format;
  if (!invoicingPlan || !isValid) return null;
  return (
    <>
      <Divider sx={{ mb: 2 }} />
      {invoicingPlan.length ? (
        <>
          <Typography>
            Starting this Return Process will trigger charging the following
            items:
          </Typography>
          <Stack divider={<Divider />} spacing={1} sx={{ mt: 2, mb: 2 }}>
            {invoicingPlan.map((action, index) => (
              <div key={index}>
                <Typography variant="body2">
                  Type: {getSaleInvoiceLineTypeLabel(action.line_in.type)}
                </Typography>
                {action.line_in.description ? (
                  <Typography variant="body2">
                    Description: {action.line_in.description}
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  Amount:{" "}
                  {currencyFormatter(
                    toGross(
                      action.line_in.price * action.line_in.quantity,
                      action.line_in.vat_rate,
                    ),
                  )}
                </Typography>
                <Typography variant="body2">Notes: {action.notes}</Typography>
                <Typography variant="body2">Paid by: {action.payer}</Typography>
              </div>
            ))}
          </Stack>
          <Typography>
            Total invoiced amount:{" "}
            {currencyFormatter(
              sum(
                invoicingPlan.map((action) =>
                  toGross(
                    action.line_in.price * action.line_in.quantity,
                    action.line_in.vat_rate,
                  ),
                ),
              ),
            )}
          </Typography>
        </>
      ) : (
        <Typography>
          No invoice will be issued for this Return Process.
        </Typography>
      )}
    </>
  );
};
