import OrganisationIcon from "@mui/icons-material/Business";
import { ResourceProps } from "react-admin";

import { OrganisationCreate } from "./OrganisationCreate";
import { OrganisationEdit } from "./OrganisationEdit";
import { OrganisationList } from "./OrganisationList";

const organisationResource: ResourceProps = {
  name: "organisations",
  icon: OrganisationIcon,
  create: OrganisationCreate,
  edit: OrganisationEdit,
  list: OrganisationList,
};

export default organisationResource;
