/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ShopType {
  ONLINE_STORE = "online_store",
  LOCAL_STORE = "local_store",
  MAINTENANCE = "maintenance",
}
