import { FC } from "react";

import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext } from "react-admin";

import { EmailForm } from "./EmailForm";

export const EmailCreateButton: FC = () => {
  const user = useRecordContext();
  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="xs"
      record={{ user_id: user.id, email: "" }}
      title={`Add a new email for ${user.full_name}`}
    >
      <EmailForm />
    </CreateInDialogButton>
  );
};
