/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BikeBenefitOrderingMethod {
  PRECYCLED = "precycled",
  ONLINE_ORDER = "online_order",
  PARTNER_STORE = "partner_store",
}
