import React from "react";

import { Create, useCreatePath } from "react-admin";
import { useLocation } from "react-router-dom";

import { MaintenanceEventForm } from "./MaintenanceEventForm";

export function MaintenanceEventCreate() {
  const location = useLocation();
  const createPath = useCreatePath();
  const contractId = location.state?.record?.bike_benefit_contract_id;
  const bikeId = location.state?.record?.bike_id;
  const currency = location.state?.record?.currency;

  const transform = (values: any) => ({
    ...values,
    bike_benefit_contract_id: contractId ?? values.bike_benefit_contract_id,
    bike_id: bikeId ?? values.bike_id,
    currency: currency ?? values.currency,
  });

  return (
    <Create
      redirect={
        contractId
          ? createPath({
              resource: "bike-benefit-contracts",
              id: contractId,
              type: "show",
            })
          : bikeId
          ? createPath({
              resource: "bikes",
              id: bikeId,
              type: "edit",
            })
          : "edit"
      }
      transform={transform}
    >
      <MaintenanceEventForm />
    </Create>
  );
}
