import BenefitDefinitionIcon from "@mui/icons-material/ListAlt";
import { ResourceProps } from "react-admin";

import { BenefitDefinitionCreate } from "./BenefitDefinitionCreate";
import { BenefitDefinitionEdit } from "./BenefitDefinitionEdit";
import { BenefitDefinitionList } from "./BenefitDefinitionList";

const benefitDefinitionResource: ResourceProps = {
  options: { label: "Benefit definitions" },
  name: "benefit-definitions",
  icon: BenefitDefinitionIcon,
  create: BenefitDefinitionCreate,
  edit: BenefitDefinitionEdit,
  list: BenefitDefinitionList,
};

export default benefitDefinitionResource;
