import { useMemo } from "react";

import { InputAdornment } from "@mui/material";

import { NumberInput, NumberInputProps } from "./NumberInput";

export type MoneyInputProps = { currency?: string } & Omit<
  NumberInputProps,
  "InputProps"
>;

export const MoneyInput = ({ currency = "EUR", ...rest }: MoneyInputProps) => {
  // Get the currency symbol and it's position depending on the local being set
  const [position, currencySymbol] = useMemo((): ["start" | "end", string] => {
    const formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency,
    });

    const parts = formatter.formatToParts(0);
    const currencyPartIndex = parts.findIndex(
      ({ type }) => type === "currency",
    );

    const position = currencyPartIndex === 0 ? "start" : "end";
    const currencySymbol = parts[currencyPartIndex]?.value;
    return [position, currencySymbol];
  }, [currency]);

  const inputProps = useMemo(
    () => ({
      [`${position}Adornment`]: (
        <InputAdornment position={position}>{currencySymbol}</InputAdornment>
      ),
    }),
    [currencySymbol, position],
  );

  return <NumberInput {...rest} InputProps={inputProps} />;
};
