import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext } from "react-admin";

import { BenefitTermsForm } from "./BenefitTermsForm";
import { transformTerms } from "./transformTerms";

export const BenefitTermsCreateButton = () => {
  const benefitDefinition = useRecordContext();

  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="md"
      record={{ benefit_definition_id: benefitDefinition.id }}
      title={`Add a benefit terms & conditions for ${benefitDefinition.name}`}
      transform={transformTerms}
    >
      <BenefitTermsForm />
    </CreateInDialogButton>
  );
};
