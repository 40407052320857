import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext, useRefresh } from "react-admin";

import { FleetLocationForm } from "./FleetLocationForm";
import { EditToolbar } from "components";

export const FleetLocationEditButton = () => {
  const fleet = useRecordContext();
  const refresh = useRefresh();
  return (
    <EditInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      resource="fleet-locations"
      mutationMode="pessimistic"
      title={`Add a location to ${fleet.name} fleet`}
      mutationOptions={{
        onSettled: refresh,
      }}
    >
      <FleetLocationForm
        organisation_id={fleet.organisation_id}
        toolbar={<EditToolbar />}
      />
    </EditInDialogButton>
  );
};
