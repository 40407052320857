/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AccessoryStatus {
  BENEFIT_BIKE = "benefit_bike",
  IN_STORAGE = "in_storage",
  SOLD = "sold",
  STOLEN = "stolen",
}
