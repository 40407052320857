import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Form,
  SaveButton,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  useUpdate,
} from "react-admin";

import { BookingTermsAndConditionsForm } from "./BookingTermsAndConditionsForm";
import { useCustomMutationOptions } from "hooks";

export const EditBookingTermsAndConditionsButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const { onError } = useCustomMutationOptions();

  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();

  const handleSubmit = (values: any) => {
    update(
      "booking-terms-and-conditions",
      { id: record.uuid, data: values },
      {
        onSuccess: () => {
          notify("Updated successfully");
          setShowDialog(false);
          refresh();
        },
        onError,
      },
    );
  };

  const handleOpenClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button onClick={handleOpenClick} fullWidth>
        Edit
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update booking terms and conditions"
      >
        <DialogTitle>Update booking terms and conditions</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <BookingTermsAndConditionsForm />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseClick}
              disabled={isLoading}
              startIcon={<CancelIcon />}
            >
              {translate("ra.action.cancel")}
            </Button>
            <SaveButton
              label="Save booking terms and conditions"
              disabled={isLoading}
              alwaysEnable={!isLoading}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
