import { EditVersionedInDialogButton } from "components/EditVersionedInDialogButton";

import { FleetUsageSettingsForm } from "./FleetUsageSettingsForm";
import { EditToolbar } from "components";

export const FleetUsageSettingsEditButton = () => {
  return (
    <EditVersionedInDialogButton
      fullWidth
      maxWidth="md"
      title="Edit fleet usage settings"
      mutationMode="pessimistic"
    >
      <FleetUsageSettingsForm toolbar={<EditToolbar />} />
    </EditVersionedInDialogButton>
  );
};
