import { Chip } from "@mui/material";
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Datagrid,
  List,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";
import invariant from "tiny-invariant";

import { Pagination } from "components";

const filters = [<BooleanInput label="Active" source="is_active" />];

const StringChip = () => {
  const record = useRecordContext();
  invariant(typeof record === "string");
  return <Chip label={record} size="small" />;
};

export const AnnouncementList = () => (
  <List filters={filters} perPage={20} pagination={<Pagination />}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="internal_name" />
      <TextField source="variant" />
      <TextField source="enabled_from" />
      <TextField source="enabled_to" />
      <ArrayField source="scopes">
        <SingleFieldList linkType={false}>
          <StringChip />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="countries">
        <SingleFieldList linkType={false}>
          <StringChip />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="is_enabled" />
    </Datagrid>
  </List>
);
