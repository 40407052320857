import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import { HubSpotBikeOrderTicketData } from "@vapaus/generated";

type HubSpotData = HubSpotBikeOrderTicketData & {
  userId: string;
  bikeBrandId?: string | null;
};

export const HubspotTicketContext = createContext<{
  hubspotTicketData: HubSpotData;
  setHubspotTicketData: Dispatch<SetStateAction<HubSpotData>>;
}>({
  hubspotTicketData: {} as HubSpotData,
  setHubspotTicketData: () => {},
});

export const HubspotTicketContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [hubspotTicketData, setHubspotTicketData] = useState<HubSpotData>(
    {} as HubSpotData,
  );

  return (
    <HubspotTicketContext.Provider
      value={{ hubspotTicketData, setHubspotTicketData }}
    >
      {children}
    </HubspotTicketContext.Provider>
  );
};

export const useHubspotTicketContext = () => useContext(HubspotTicketContext);
