import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRefreshableRow } from "components/RefreshableDatagridRow";
import { SaveButton, SimpleForm, Toolbar, useRecordContext } from "react-admin";

import {
  ErpSaleInvoiceLineRead,
  ErpSaleInvoiceLineState,
} from "@vapaus/generated";

export const ErpSaleInvoiceToggleIgnoreButton = () => {
  const record = useRecordContext();
  const anyIgnoredLines = record.erp_sale_invoice_lines.some(
    (line: ErpSaleInvoiceLineRead) =>
      line.state === ErpSaleInvoiceLineState.IGNORED,
  );
  const { refresh } = useRefreshableRow();
  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      record={{}}
      resource={`erp-sale-invoices/${record.id}/toggle-ignore`}
      title={
        anyIgnoredLines ? "Mark all lines as not ignored" : "Ignore all lines"
      }
      label={
        anyIgnoredLines ? "Mark all lines as not ignored" : "Ignore all lines"
      }
      icon={anyIgnoredLines ? <ToggleOffIcon /> : <ToggleOnIcon />}
      mutationOptions={{
        onSettled: refresh,
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton
              alwaysEnable
              label={
                anyIgnoredLines ? "Mark as not ignored" : "Mark as ignored"
              }
            />
          </Toolbar>
        }
      >
        {anyIgnoredLines
          ? "Are you sure you want to mark all lines of this invoice as not ignored?"
          : "Are you sure you want to ignore all lines of this invoice?"}
      </SimpleForm>
    </CreateInDialogButton>
  );
};
