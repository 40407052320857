import { SaleInvoiceLineType } from "@vapaus/generated";

import { toChoices } from "./utils";

export enum SaleInvoiceStatus {
  OPEN = "open",
  PAID = "paid",
  DUE = "due",
  OVERDUE = "overdue",
}

export const INVOICE_STATUS_CHOICES = toChoices({
  [SaleInvoiceStatus.OPEN]: "OPEN",
  [SaleInvoiceStatus.PAID]: "PAID",
  [SaleInvoiceStatus.DUE]: "DUE",
  [SaleInvoiceStatus.OVERDUE]: "OVERDUE",
});

export const INVOICE_LINE_TYPE_LABELS: {
  [key in SaleInvoiceLineType]: string;
} = {
  [SaleInvoiceLineType.CANCELLATION_COVERAGE]: "CANCELLATION COVERAGE",
  [SaleInvoiceLineType.DELIVERY]: "DELIVERY",
  [SaleInvoiceLineType.DOWN_PAYMENT]: "DOWN PAYMENT",
  [SaleInvoiceLineType.DOWN_PAYMENT_INSURANCE]: "DOWN PAYMENT INSURANCE",
  [SaleInvoiceLineType.EXTENDED_WARRANTY]: "EXTENDED WARRANTY",
  [SaleInvoiceLineType.INSURANCE_DEDUCTIBLE]: "INSURANCE DEDUCTIBLE",
  [SaleInvoiceLineType.LATE_FEES]: "LATE FEES",
  [SaleInvoiceLineType.MAINTENANCE]: "MAINTENANCE",
  [SaleInvoiceLineType.OVERSPENT_MAINTENANCE_BUDGET]:
    "OVERSPENT MAINTENANCE BUDGET",
  [SaleInvoiceLineType.REDEMPTION_FEE]: "REDEMPTION FEE",
  [SaleInvoiceLineType.REDEMPTION_FEE_ACCESSORIES_ONLY]:
    "REDEMPTION FEE (ACCESSORIES ONLY)",
  [SaleInvoiceLineType.REFUND]: "REFUND",
  [SaleInvoiceLineType.RETURN_FEE]: "RETURN FEE",
  [SaleInvoiceLineType.OTHER]: "OTHER",
  [SaleInvoiceLineType.EXCESS_MONTHLY_PAYMENT]: "EXCESS MONTHLY PAYMENT",
};

export const INVOICE_LINE_TYPE_CHOICES = toChoices(INVOICE_LINE_TYPE_LABELS);
