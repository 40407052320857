import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext, useRefresh } from "react-admin";

import { FleetLocationForm } from "./FleetLocationForm";

export const FleetLocationCreateButton = () => {
  const fleet = useRecordContext();
  const refresh = useRefresh();
  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      resource="fleet-locations"
      record={{ fleet_id: fleet.id }}
      title={`Add a location to ${fleet.name} fleet`}
      mutationOptions={{
        onSettled: refresh,
      }}
    >
      <FleetLocationForm organisation_id={fleet.organisation_id} />
    </CreateInDialogButton>
  );
};
