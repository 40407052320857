import { CircularProgress } from "@mui/material";
import { Confirm, useNotify, useRecordContext } from "react-admin";

import { useVapausMutation } from "hooks";

type SendEmailNotificationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SendEmailNotificationModal = ({
  isOpen,
  onClose,
}: SendEmailNotificationModalProps) => {
  const notify = useNotify();
  const record = useRecordContext();

  const { mutate: sendInvitationMutate, isLoading } = useVapausMutation(
    "sendInvitationEmail",
  );

  const handleSendEmail = () => {
    sendInvitationMutate(record.id, {
      onSuccess: () => {
        notify("Email notification sent", { type: "success" });
        onClose();
      },
      onError: () => {
        notify("Email notification not sent. Please try again.", {
          type: "error",
        });
      },
    });
  };

  return (
    <Confirm
      isOpen={isOpen}
      title="Send Email Notification"
      content={
        <>
          <p>
            Are you sure you want to send the email notification to the user?
          </p>
          {isLoading && <CircularProgress size={24} />}
        </>
      }
      onConfirm={handleSendEmail}
      onClose={onClose}
    />
  );
};
