import { capitalize, lowerCase } from "lodash-es";
import { HttpError, Options as RAOptions } from "react-admin";

interface Options extends RAOptions {
  skipParseOnSuccess?: boolean;
}

const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: "application/json",
    })) as Headers;
  if (
    !requestHeaders.has("Content-Type") &&
    !(options && (!options.method || options.method === "GET")) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set("Authorization", options.user.token);
  }

  return requestHeaders;
};

// Copied from react admin with little changes on the error handling
export const fetchJson = (url: string, options: Options = {}) => {
  const requestHeaders = createHeadersFromOptions(options);
  return fetch(url, { ...options, headers: requestHeaders })
    .then(async (response) => {
      const isSuccess = response.status >= 200 && response.status < 300;
      return {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body:
          options.skipParseOnSuccess && isSuccess ? "" : await response.text(),
        response,
        isSuccess,
      };
    })
    .then(({ status, statusText, headers, body, response, isSuccess }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (!isSuccess) {
        if (Array.isArray(json.detail)) {
          const { loc, msg } = json.detail[0];
          const field = capitalize(lowerCase(loc.at(-1)));
          return Promise.reject(
            new HttpError(`${field}: ${msg}`, status, json),
          );
        }

        return Promise.reject(
          new HttpError((json && json.detail) || statusText, status, json),
        );
      }
      return Promise.resolve({ status, headers, body, json, response });
    });
};
