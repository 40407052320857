import { humanize, underscore } from "inflection";
import { memoize as lodashMemoize } from "lodash-es";

type Memoize = <T extends (...args: any[]) => any>(
  func: T,
  resolver?: (...args: any[]) => any,
) => T;

export const memoize: Memoize = (fn: any) =>
  lodashMemoize(fn, (...args) => JSON.stringify(args));

export const sourceToLabel = (source: string) =>
  humanize(underscore(source), true);

export const isEmpty = (value: any) =>
  typeof value === "undefined" ||
  value === null ||
  value === "" ||
  (Array.isArray(value) && value.length === 0);
