import React, { useState } from "react";

import {
  Button,
  Confirm,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { useCustomMutation } from "hooks";

export const VehicleActivateButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [isOpen, setIsOpen] = useState(false);
  const notify = useNotify();
  const { mutate } = useCustomMutation("activateVehicle", record.id, {
    onSuccess: () => {
      notify("Vehicle has been activated");
      refresh();
    },
  });
  return (
    <>
      <Button
        sx={{ marginLeft: 4 }}
        label="Activate"
        size="medium"
        onClick={() => setIsOpen(true)}
      />
      <Confirm
        isOpen={isOpen}
        title="Activate vehicle"
        content="Are you sure you want to activate this vehicle?"
        onConfirm={() => mutate()}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
