import { FC, useState } from "react";

import { ChevronLeft, Replay } from "@mui/icons-material";
import { Toolbar as MuiToolbar, Theme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Button,
  Confirm,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";

import { MarkAsPaidButton } from "./MarkAsPaidButton";
import { SaleInvoiceStatus } from "core";

export const InvoiceEditToolbar: FC = () => {
  const record = useRecordContext();
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const provider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleRevert = () => {
    provider
      .create(`invoices/sale-invoices/${record.id}/revert`, {} as any)
      .then((res) => {
        notify(`Invoice ${record.invoice_number} reverted`, {
          type: "success",
        });
        redirect("../");
      })
      .catch((error) => {
        notify(error?.message, { type: "error" });
      });
    setOpen(false);
  };

  return (
    <StyledToolbar
      className={clsx({
        [ToolbarClasses.mobileToolbar]: isXs,
        [ToolbarClasses.desktopToolbar]: !isXs,
      })}
      role="toolbar"
    >
      <div className={ToolbarClasses.defaultToolbar}>
        <Button
          startIcon={<ChevronLeft />}
          label="Back"
          onClick={() => redirect("../")}
        />
        <div>
          {record.status !== SaleInvoiceStatus.PAID && <MarkAsPaidButton />}
          <Button
            startIcon={<Replay />}
            label="Revert"
            onClick={() => setOpen(true)}
          />
        </div>
        <Confirm
          isOpen={open}
          title={`Revert Invoice: ${record?.invoice_number}`}
          content="Are you sure you want to revert this invoice?"
          onConfirm={handleRevert}
          onClose={() => setOpen(false)}
        />
      </div>
    </StyledToolbar>
  );
};

const PREFIX = "RaToolbar";

export const ToolbarClasses = {
  desktopToolbar: `${PREFIX}-desktopToolbar`,
  mobileToolbar: `${PREFIX}-mobileToolbar`,
  defaultToolbar: `${PREFIX}-defaultToolbar`,
};

const StyledToolbar = styled(MuiToolbar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[900],

  [`&.${ToolbarClasses.desktopToolbar}`]: {},

  [`&.${ToolbarClasses.mobileToolbar}`]: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px",
    width: "100%",
    boxSizing: "border-box",
    flexShrink: 0,
    zIndex: 2,
  },

  [`& .${ToolbarClasses.defaultToolbar}`]: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
}));
