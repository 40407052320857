import { FC } from "react";

import ElectricBikeIcon from "@mui/icons-material/ElectricBike";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import { Link, TextField, useRecordContext } from "react-admin";

import { RowItem } from "./RowItem";
import { CurrencyGrossField } from "components";

export const BikeInfo: FC = () => {
  const record = useRecordContext();
  const initialVatRate = record.initial_vat_rate ?? record.flat_vat_rate;
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel-bike-content"
        id="panel-bike-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <ElectricBikeIcon sx={{ mr: 2 }} />{" "}
        <Typography>Bike Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table aria-label="bike table">
          <TableBody>
            <RowItem label="Model">
              {record.bike.url ? (
                <Link to={record.bike.url} target="_blank">
                  <TextField source="bike.model" />
                </Link>
              ) : (
                <TextField source="bike.model" />
              )}
            </RowItem>
            <RowItem label="Manufacturer" source="bike.bike_brand.name" />
            <RowItem
              label="Drive Type"
              source="bike.drive_type"
              textTransform="capitalize"
            />
            <RowItem
              label="Frame Type"
              source="bike.frame_type"
              textTransform="capitalize"
            />
            <RowItem label="Size" source="bike.size" />
            <RowItem label="Color" source="bike.colour" />
            <RowItem label="Frame Number">
              <Link to={`/bikes/${record.bike.id}`}>
                <TextField source="bike.serial_number" />
              </Link>
            </RowItem>
            <RowItem label="Price">
              <CurrencyGrossField
                source="bike_price"
                currencySource="currency"
                vatRate={initialVatRate}
              />
            </RowItem>
            <RowItem label="Purchase Price">
              <CurrencyGrossField
                source="bike.purchase_price"
                currencySource="purchase_currency"
                currencyRecord={record.bike}
                vatRate={record.bike.purchase_vat_rate}
              />
            </RowItem>
            <RowItem
              label="Condition"
              source="bike.condition"
              textTransform="uppercase"
            />
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
