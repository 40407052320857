import { Edit, useRecordContext } from "react-admin";

import AnnouncementForm from "./AnnouncementForm";
import { EditToolbar } from "components";
import { useCustomMutationOptions } from "hooks";

const AnnouncementTitle = () => {
  const record = useRecordContext();
  return <span>{record?.internal_name}</span>;
};

export const AnnouncementEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<AnnouncementTitle />}
    >
      <AnnouncementForm toolbar={<EditToolbar />} />
    </Edit>
  );
};
