import * as React from "react";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Button,
  Grid,
  Toolbar as MUIToolbar,
  ToolbarProps,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import {
  WizardFormContextValue,
  useWizardFormContext,
} from "@react-admin/ra-form-layout";
// import { BikeBenefitOrderRead } from "@vapaus/generated";
import { Link, SaveButton, useFormGroup, useTranslate } from "react-admin";
import { useFormContext, useFormState } from "react-hook-form";

interface Props extends WizardToolbarProps {
  // data: BikeBenefitOrderRead;
  data: any;
}

export const CreateInvoiceToolbar: React.FC<Props> = (props) => {
  const data = props?.data;
  const translate = useTranslate();
  const { trigger } = useFormContext();

  const {
    hasPreviousStep,
    hasNextStep,
    goToPreviousStep,
    goToNextStep,
    currentStep,
  } = useWizardFormContext(props);

  React.useEffect(() => {
    trigger();
  }, [currentStep, trigger]);

  const handleNextClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    goToNextStep();
  };

  const handlePreviousClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    goToPreviousStep();
  };

  const { isValidating } = useFormState();
  const wizardFormContext = useWizardFormContext();
  const { isValid } = useFormGroup(`step-${wizardFormContext.currentStep}`);

  const errorContent = React.useMemo(() => {
    if (!data?.id) {
      return (
        <>
          A valid Vapaus Code is required to create an invoice. Please enter one
          and click the next button.
        </>
      );
    }

    if (!data?.user?.address || !data?.user?.post_code || !data?.user?.city) {
      return (
        <>
          The user attributed to this order does not have an address. Please{" "}
          <Link to={`/users/${data?.user?.id}`}>add full address</Link>{" "}
          information to the user before creating an invoice.
        </>
      );
    }

    return null;
  }, [data]);

  return (
    <MUIToolbar {...sanitizeRestProps(props)}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {!errorContent ? (
          <>
            <Grid item>
              {hasPreviousStep ? (
                <Button
                  variant="text"
                  onClick={handlePreviousClick}
                  aria-label={translate("ra-form-layout.action.previous")}
                  startIcon={<ChevronLeft />}
                >
                  PREVIOUS
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              {hasNextStep ? (
                <Button
                  onClick={handleNextClick}
                  variant="text"
                  disabled={isValidating || !isValid}
                  endIcon={<ChevronRight />}
                >
                  Next
                </Button>
              ) : (
                <SaveButton alwaysEnable />
              )}
            </Grid>
          </>
        ) : (
          <Stack sx={{ width: "100%", marginBottom: "1em" }} spacing={2}>
            <Alert severity="error">{errorContent}</Alert>
          </Stack>
        )}
      </Grid>
    </MUIToolbar>
  );
};

const sanitizeRestProps = ({
  currentStep,
  goToNextStep,
  goToPreviousStep,
  goToStep,
  steps,
  ...rest
}: WizardToolbarProps): Omit<ToolbarProps, "classes"> => rest;

export interface WizardToolbarProps
  extends Omit<ToolbarProps, "classes">,
    Partial<WizardFormContextValue> {}
