import { useCallback } from "react";

import { useNotify } from "react-admin";

const getErrorMessage = (error: any) => {
  if (Array.isArray(error?.body?.detail)) {
    // Currently we only care about the last error has react admin makes it hard to display many errors
    const { loc, msg } = error.body.detail[0];
    return `${loc.slice(1).join(".")}: ${msg}`;
  }

  return error?.body?.detail || error.message;
};

export const useCustomMutationOptions = () => {
  const notify = useNotify();
  const onError = useCallback(
    (error: any) => {
      const message = getErrorMessage(error);
      notify(message, { type: "warning" });
    },
    [notify],
  );

  return { onError };
};
