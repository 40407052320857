import { Button, useRecordContext, useRefresh } from "react-admin";

import { useCustomMutation, useCustomMutationOptions } from "hooks";

export const EmailMakePrimaryButton = () => {
  const email = useRecordContext();
  const { onError } = useCustomMutationOptions();
  const refresh = useRefresh();
  const { mutate, isLoading } = useCustomMutation(
    "makeUserEmailPrimary",
    email.id,
    {
      onSuccess: refresh,
      onError: onError,
    },
  );
  if (email.primary) return null;
  return (
    <Button
      label={isLoading ? "Updating..." : "Make primary"}
      onClick={() => mutate()}
      disabled={isLoading}
    />
  );
};
