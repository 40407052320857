import { FC } from "react";

import Alert from "@mui/material/Alert";
import { DateTime } from "luxon";
import {
  Create,
  DateInput,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { BikeBenefitContractCancellationData } from "@vapaus/generated";

import { ContractMenuModalProps } from "./ContractMenu";
import { ModalBox, NullableTextInput as TextInput } from "components";
import { BIKE_BENEFIT_CANCELLATION_REASONS_CHOICES } from "core";

export const CancelContractModal: FC<ContractMenuModalProps> = ({
  onClose,
  open,
}) => {
  const { id } = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <ModalBox open={open} setOpen={onClose} title="Cancel Contract">
      <Create
        resource={`bike-benefit-contracts/${id}/cancel`}
        mutationOptions={{
          onSuccess: () => {
            refresh();
            notify("Contract cancelled successfully");
            onClose();
          },
        }}
      >
        <SimpleForm
          defaultValues={{
            exceptional_end_date: DateTime.now().toISODate(),
          }}
        >
          <DateInput
            source="exceptional_end_date"
            label="Cancellation Date"
            validate={[required()]}
            fullWidth
          />
          <SelectInput
            source="cancellation_reason"
            label="Cancellation Reason"
            choices={BIKE_BENEFIT_CANCELLATION_REASONS_CHOICES}
            validate={[required()]}
            fullWidth
          />
          <FormDataConsumer<BikeBenefitContractCancellationData>>
            {({ formData }) =>
              (formData.cancellation_reason ===
                BikeBenefitContractCancellationData.cancellation_reason
                  .OTHER && (
                <TextInput
                  source="cancellation_reason_description"
                  label="Description"
                  fullWidth
                  multiline
                  validate={[required()]}
                />
              )) ||
              (formData.cancellation_reason ===
                BikeBenefitContractCancellationData.cancellation_reason
                  .REDEEMED && (
                <Alert severity="warning">
                  Are you sure you want to cancel this contract? Please consider
                  starting a Redemption Process instead
                </Alert>
              )) ||
              (formData.cancellation_reason ===
                BikeBenefitContractCancellationData.cancellation_reason
                  .RETURNED && (
                <Alert severity="warning">
                  Are you sure you want to cancel this contract? Please consider
                  starting a Return Process instead
                </Alert>
              ))
            }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </ModalBox>
  );
};
