import React from "react";

import { Edit, useRecordContext } from "react-admin";

import BikeBrandForm from "./BikeBrandForm";
import { EditToolbar } from "components";
import { useCustomMutationOptions } from "hooks";

const BikeBrandTitle = () => {
  const record = useRecordContext();
  return <span>Bike brand: {record?.name}</span>;
};

export const BikeBrandEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<BikeBrandTitle />}
    >
      <BikeBrandForm toolbar={<EditToolbar />} />
    </Edit>
  );
};
