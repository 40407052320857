export const AVAILABLE_COUNTRIES = [
  { id: "FI", name: "Finland" },
  { id: "SE", name: "Sweden" },
];

export const AVAILABLE_LANGUAGES = [
  { id: "FI", name: "Finnish" },
  { id: "EN", name: "English" },
  { id: "SV", name: "Swedish" },
];
