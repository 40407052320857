import { email } from "react-admin";

const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;
  if (!values.email) {
    errors.email = "The email is required";
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  return errors;
};

export default validateForm;
