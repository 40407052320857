/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ErpSaleInvoiceLineState {
  IGNORED = "ignored",
  UNASSIGNED = "unassigned",
  PARTIALLY_ASSIGNED = "partially_assigned",
  ASSIGNED = "assigned",
  INVALID = "invalid",
}
