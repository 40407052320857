import { EditInDialogButton } from "@react-admin/ra-form-layout";

import { BenefitTermsForm } from "./BenefitTermsForm";
import { transformTerms } from "./transformTerms";
import { EditToolbar } from "components";

export const BenefitTermsEditButton = () => {
  return (
    <EditInDialogButton
      fullWidth
      maxWidth="md"
      title="Edit benefit terms & conditions"
      mutationMode="pessimistic"
      transform={transformTerms}
    >
      <BenefitTermsForm toolbar={<EditToolbar />} />
    </EditInDialogButton>
  );
};
