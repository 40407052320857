import { FC, useState } from "react";

import { Grid } from "@mui/material";
import { WizardForm, WizardFormStep } from "@react-admin/ra-form-layout";
import { useProductVatRate } from "hooks/useProductVatRates";
import { omit } from "lodash-es";
import { DateTime } from "luxon";
import {
  AutocompleteInput,
  Create,
  DateInput,
  ReferenceInput,
  required,
  useCreatePath,
  useGetOne,
} from "react-admin";
import { useLocation } from "react-router-dom";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  ProductTypes,
  SupportedCountriesEnum,
} from "@vapaus/generated";

import { BikeInfo } from "./BikeInfo";
import { CreateInvoiceToolbar } from "./CreateInvoiceToolbar";
import ManageInvoiceLines, { InvoiceLine } from "./ManageInvoiceLines";
import { useCustomMutationOptions } from "hooks";

const validateRequired = [required()];

const WizardInvoiceForm: FC = () => {
  const location = useLocation();
  const createPath = useCreatePath();
  const requestedOrderId = location.state?.record?.bike_benefit_order_id;
  const requestedContractId = location.state?.record?.bike_benefit_contract_id;
  const mutationOptions = useCustomMutationOptions();
  const [orderId, setOrderId] = useState<string | undefined>(requestedOrderId);

  const { data: order } = useGetOne(
    "../admin-api/bike-benefit-orders",
    { id: orderId },
    {
      enabled: !!orderId,
    },
  );

  const { data: benefitDefinition } =
    useGetOne<BenefitDefinitionReadWithOrganisationSummary>(
      "benefit-definitions",
      { id: order?.benefit_definition_id },
      { enabled: !!order },
    );

  const defaultVatRate = useProductVatRate(
    benefitDefinition?.organisation
      .country as unknown as SupportedCountriesEnum,
    ProductTypes.DEFAULT_PRODUCT,
  );

  const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);

  const transform = (values: any) => {
    return omit(
      {
        ...values,
        currency: order?.currency,
        bike_benefit_order_id: requestedOrderId ?? order?.id,
        bike_benefit_contract_id:
          requestedContractId ?? order?.current_bike_benefit_contract_id,
        sale_invoice_lines: invoiceLines.map(({ line }) => line),
      },
      ["vapaus_code"],
    );
  };

  const defaultValues = {
    date: DateTime.now().toISODate(),
    due_date: DateTime.now().plus({ days: 14 }).toISODate(),
    customer_user_id: order?.user?.id,
    bike_benefit_order_id: order?.id,
  };

  return (
    <Create
      mutationOptions={mutationOptions}
      redirect={
        requestedContractId
          ? createPath({
              resource: "bike-benefit-contracts",
              id: requestedContractId,
              type: "show",
            })
          : "list"
      }
      transform={transform}
    >
      <WizardForm
        defaultValues={defaultValues}
        toolbar={<CreateInvoiceToolbar data={order} />}
      >
        {!requestedOrderId && (
          <WizardFormStep label="Vapaus Code">
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: 450 }}
            >
              <ReferenceInput
                source="bike_benefit_order_id"
                reference="bike-benefit-orders"
              >
                <AutocompleteInput
                  label="Order"
                  optionText={(choice) =>
                    `${choice.vapaus_code} ${
                      choice.user?.full_name
                        ? "- " + choice.user?.full_name
                        : ""
                    }`
                  }
                  optionValue="id"
                  style={{ width: 400 }}
                  isRequired
                  validate={required()}
                  onChange={(orderId) => setOrderId(orderId)}
                />
              </ReferenceInput>
            </Grid>
          </WizardFormStep>
        )}
        <WizardFormStep label="Invoice Details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              {order?.id && <BikeInfo order={order} />}
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid item md={12}>
                <DateInput
                  source="date"
                  validate={validateRequired}
                  fullWidth
                />
                <DateInput
                  source="due_date"
                  validate={validateRequired}
                  fullWidth
                />
                <ReferenceInput source="customer_user_id" reference="users">
                  <AutocompleteInput
                    label="User"
                    optionText={(choice) =>
                      `${choice.full_name || ""} (${choice.email})`
                    }
                    optionValue="id"
                    fullWidth
                    isRequired
                    defaultValue={order?.user?.id}
                    disabled
                  />
                </ReferenceInput>
              </Grid>
              <Grid item md={12}>
                <ManageInvoiceLines
                  updateLines={(values: InvoiceLine[]) =>
                    setInvoiceLines(values)
                  }
                  data={invoiceLines}
                  currency={order?.currency}
                  defaultVatRate={defaultVatRate}
                />
              </Grid>
            </Grid>
          </Grid>
        </WizardFormStep>
      </WizardForm>
    </Create>
  );
};

export default WizardInvoiceForm;
