import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  Datagrid,
  ReferenceManyField,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";

import { BillingDetailsCreateButton } from "./BillingDetailsCreateButton";
import { BillingDetailsEditButton } from "./BillingDetailsEditButton";
import { BILLING_SCHEMES_CHOICES, INVOICING_METHODS_CHOICES } from "core";

export const NestedBillingDetailsDataGrid = () => {
  const record = useRecordContext();
  if (!record?.id)
    return (
      <EmptyBoxInfo message="Please save the organisation first to manage billing details." />
    );
  return (
    <ReferenceManyField
      label="Billing details"
      reference="organisation-billing-details"
      target="organisation_id"
    >
      <Datagrid
        bulkActionButtons={false}
        empty={<BillingDetailsCreateButton />}
        sx={{ maxWidth: 1024 }}
      >
        <TextField source="netvisor_customer_code" sortable={false} />
        <TextField source="contact_name" sortable={false} />
        <TextField source="contact_phone" sortable={false} />
        <TextField source="contact_email" sortable={false} />
        <SelectField
          source="invoicing_method"
          choices={INVOICING_METHODS_CHOICES}
          sortable={false}
        />
        <SelectField
          source="billing_scheme"
          choices={BILLING_SCHEMES_CHOICES}
          sortable={false}
        />
        <DatagridActionsColumn label={<BillingDetailsCreateButton />}>
          {<BillingDetailsEditButton />}
        </DatagridActionsColumn>
      </Datagrid>
    </ReferenceManyField>
  );
};
