import React, { useEffect } from "react";

import { Typography } from "@mui/material";
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  required,
  useGetOne,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import {
  AdditionalServiceType,
  Currency,
  ProductTypes,
  SupportedCountriesEnum,
} from "@vapaus/generated";

import { useHubspotTicketContext } from "./HubspotTicketContext";
import { CustomFormIterator, MoneyInput, Separator } from "components";
import { ADDITIONAL_SERVICE_TYPE_CHOICES } from "core";
import { useProductVatRate } from "hooks";

export const OrderFormExtras = () => {
  const { watch, setValue } = useFormContext();
  const { hubspotTicketData } = useHubspotTicketContext();
  const [benefitDefinitionId, deliveryTransport] = watch([
    "benefit_definition_id",
    "delivery_transport",
  ]);
  const { data: benefitDefinition } = useGetOne(
    "benefit-definitions",
    {
      id: benefitDefinitionId,
    },
    { enabled: !!benefitDefinitionId },
  );
  const additionalServicesVatRate = useProductVatRate(
    benefitDefinition?.organisation
      .country as unknown as SupportedCountriesEnum,
    ProductTypes.ADDITIONAL_SERVICES,
  );
  const deliveryVatRate = useProductVatRate(
    benefitDefinition?.organisation
      .country as unknown as SupportedCountriesEnum,
    ProductTypes.DELIVERY,
  );

  useEffect(() => {
    hubspotTicketData.additional_services_price &&
      setValue("additional_services", [
        {
          service_type: "",
          price_gross: hubspotTicketData.additional_services_price,
        },
      ]);
    if (
      hubspotTicketData.delivery_transport_price ||
      hubspotTicketData.expected_delivery_date ||
      hubspotTicketData.delivery_tracking_url
    ) {
      setValue("delivery_transport", true);
      setValue(
        "delivery_transport.tracking_url",
        hubspotTicketData.delivery_tracking_url,
      );
      setValue(
        "delivery_transport.price_gross",
        hubspotTicketData.delivery_transport_price,
      );
      setValue(
        "delivery_transport.expected_delivery_date",
        hubspotTicketData.expected_delivery_date,
      );
    }
  }, [
    hubspotTicketData.additional_services_price,
    hubspotTicketData.delivery_tracking_url,
    hubspotTicketData.delivery_transport_price,
    hubspotTicketData.expected_delivery_date,
    setValue,
  ]);

  return (
    <>
      <TextInput
        source="order_number"
        defaultValue={hubspotTicketData.order_number}
        fullWidth
      />
      <TextInput
        source="notes"
        multiline
        defaultValue={
          hubspotTicketData.hubspot_ticket_id &&
          `Hubspot ticket id: ${hubspotTicketData.hubspot_ticket_id}`
        }
        fullWidth
      />
      <Separator />

      <Typography variant="h6">Additional services</Typography>
      <ArrayInput source="additional_services" fullWidth>
        <CustomFormIterator
          fullWidth
          inline
          disableReordering
          getItemLabel={(index) => `Additional service #${index + 1}`}
          actionsAlwaysVisible
        >
          <SelectInput
            source="service_type"
            choices={ADDITIONAL_SERVICE_TYPE_CHOICES}
            validate={required()}
          />
          <AdditionalServiceDescriptionInput />
          <NumberInput
            source="vat_rate"
            defaultValue={additionalServicesVatRate}
            sx={{ display: "none" }}
          />
          <AdditionalServiceGrossPriceInput
            currency={benefitDefinition?.currency}
          />
        </CustomFormIterator>
      </ArrayInput>
      <Separator />

      <Typography variant="h6">Delivery</Typography>
      <BooleanInput
        source="delivery_transport"
        defaultValue={null}
        parse={(value) => (value ? {} : null)}
        options={{ checked: !!deliveryTransport }}
      />
      {deliveryTransport ? (
        <>
          <TextInput
            source="delivery_transport.tracking_url"
            label="Tracking URL"
            defaultValue=""
            fullWidth
          />
          <NumberInput
            source="delivery_transport.vat_rate"
            defaultValue={deliveryVatRate}
            sx={{ display: "none" }}
          />
          <MoneyInput
            source="delivery_transport.price_gross"
            currency={benefitDefinition?.currency}
            label="Price"
            helperText={`VAT ${deliveryTransport.vat_rate?.toLocaleString(
              undefined,
              { style: "percent", maximumFractionDigits: 2 },
            )}`}
            validate={required()}
            fullWidth
          />
          <DateInput
            source="delivery_transport.expected_delivery_date"
            label="Expected delivery date"
            fullWidth
          />
        </>
      ) : null}
      <Separator />
    </>
  );
};

const AdditionalServiceDescriptionInput = ({
  source,
  index,
}: {
  source?: string;
  index?: number;
}) => {
  return (
    <FormDataConsumer index={index} source={source}>
      {({ scopedFormData }) =>
        scopedFormData?.service_type === AdditionalServiceType.OTHER && (
          <TextInput source={`${source}.description`} validate={required()} />
        )
      }
    </FormDataConsumer>
  );
};

const AdditionalServiceGrossPriceInput = ({
  source,
  index,
  currency,
}: {
  source?: string;
  index?: number;
  currency?: Currency;
}) => {
  return (
    <FormDataConsumer index={index} source={source}>
      {({ scopedFormData }) => (
        <MoneyInput
          source={`${source}.price_gross`}
          currency={currency}
          validate={required()}
          helperText={`VAT ${scopedFormData?.vat_rate?.toLocaleString(
            undefined,
            { style: "percent", maximumFractionDigits: 2 },
          )}`}
        />
      )}
    </FormDataConsumer>
  );
};
