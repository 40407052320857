import { useEffect, useRef } from "react";

import { NumberInputProps as RaNumberInputProps } from "react-admin";

import { NullableTextInput as TextInput } from "./NullableTextInput";

export type NumberInputProps = Omit<RaNumberInputProps, "type">;

export const NumberInput = (props: NumberInputProps) => {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // If we scroll on the input when it's focused, we remove the focus to prevent accidental change of data
    const ref = inputEl.current;
    const blur = () => inputEl.current?.blur();
    ref?.addEventListener("wheel", blur);
    return () => ref?.removeEventListener("wheel", blur);
  }, [inputEl]);

  return (
    <TextInput
      {...props}
      type="number"
      InputProps={{
        ...(props.InputProps || {}),
        inputRef: inputEl,
      }}
    />
  );
};
