import React from "react";

import { useTheme } from "@mui/material";
import { BooleanField, BooleanFieldProps, useRecordContext } from "react-admin";

const StyledBooleanField = (props: BooleanFieldProps) => {
  const record = useRecordContext(props);
  const value = props.source ? record[props.source] : props.defaultValue;
  const { palette } = useTheme();
  return (
    <BooleanField
      {...props}
      mx={{
        backgroundColor: value ? palette.success.main : palette.error.main,
        borderRadius: "50%",
        padding: 3,
        color: "white",
      }}
    />
  );
};

export default StyledBooleanField;
