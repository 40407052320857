import React from "react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Resource as RbacResource } from "@react-admin/ra-rbac";
import { Admin, Options, Resource } from "react-admin";

import { Login } from "./components";
import theme from "./core/theme";
import createAuthProvider from "./providers/createAuthProvider";
import {
  createCustomMethodsProvider,
  createDataProvider,
  fetchJson,
} from "./providers/dataProvider";
import * as resources from "./resources";

const authenticatedFetchJson = (url: string, options: Options = {}) => {
  options.user = {
    authenticated: true,
    // use the token from local storage
    token: localStorage.getItem("token") || undefined,
  };
  return fetchJson(url, options);
};

const authProvider = createAuthProvider(
  `${import.meta.env.VITE_API_BASE_URL}/api`,
);
const dataProvider = createDataProvider(
  `${import.meta.env.VITE_API_BASE_URL}/admin-api`,
  authenticatedFetchJson,
);
const customMethodsProvider = createCustomMethodsProvider(
  `${import.meta.env.VITE_API_BASE_URL}/admin-api`,
  authenticatedFetchJson,
);

const App = () => (
  <Admin
    theme={theme}
    loginPage={Login}
    authProvider={authProvider}
    dataProvider={{ ...dataProvider, ...customMethodsProvider }}
  >
    <Resource {...resources.user} />
    <Resource {...resources.organisation} />
    <Resource {...resources.benefitDefinitionMonthlyOfficeFee} />
    <Resource {...resources.benefitDefinition} />
    <Resource {...resources.shop} />
    <Resource {...resources.shopDiscounts} />
    <Resource {...resources.fleet} />
    <Resource {...resources.terms} />
    <Resource {...resources.invoice} />
    <Resource {...resources.bike} />
    <Resource {...resources.bikeBrand} />
    <Resource {...resources.bikeBenefitOrders} />
    <Resource {...resources.bikeBenefitContracts} />
    <Resource {...resources.maintenanceEvents} />
    <Resource {...resources.redemptionProcess} />
    <Resource {...resources.returnProcess} />
    <Resource {...resources.reportDownload} />
    <Resource {...resources.billing} />
    <RbacResource {...resources.erpSaleInvoice} />
    <Resource {...resources.erpSaleInvoiceLineAssignment} />
    <Resource {...resources.announcement} />
  </Admin>
);

export default App;
