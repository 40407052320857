import React from "react";

import { Create } from "react-admin";

import OrganisationForm from "./OrganisationForm";
import { useCustomMutationOptions } from "hooks";

export const OrganisationCreate = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Create mutationOptions={mutationOptions} redirect="edit">
      <OrganisationForm />
    </Create>
  );
};
