import React, { ReactElement, useEffect, useMemo } from "react";

import { Table, TableBody, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useCalculatePeriod } from "hooks/useCalculatePeriod";
import {
  BooleanInput,
  NumberField,
  NumberInput,
  SelectInput,
  required,
  useGetOne,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { RowItem } from "resources/BikeBenefitOrders/components/RowItem";
import { getMaintenance } from "utils/getMaintenance";

import { BenefitDefinitionReadWithOrganisationSummary } from "@vapaus/generated";
import { ErrorCode } from "@vapaus/utils";

import { useHubspotTicketContext } from "./HubspotTicketContext";
import { useOrderFormContext } from "./OrderFormContext";
import { useCalculationRequestParams } from "./useCalculationRequestParams";
import { CurrencyField, MoneyInput, Separator } from "components";

export const OrderFormSummary = () => {
  const { watch, setValue } = useFormContext();
  const { hubspotTicketData } = useHubspotTicketContext();
  const locale = undefined; // use browser's locale
  const benefitDefinitionId = watch("benefit_definition_id");
  const { data: benefitDefinition } = useGetOne(
    "benefit-definitions",
    {
      id: benefitDefinitionId,
    },
    { enabled: !!benefitDefinitionId },
  );

  const fixedDownPaymentAmount = watch("fixed_down_payment_amount");
  const { calculation } = useOrderFormContext();

  useEffect(() => {
    if (hubspotTicketData.monthly_maintenance_budget) {
      setValue(
        "monthly_maintenance_budget",
        hubspotTicketData.monthly_maintenance_budget,
      );
    }
  }, []);

  if (!benefitDefinition) return null;

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: benefitDefinition?.currency,
  });
  const fixedMonthlyTaxableValues =
    benefitDefinition?.fixed_monthly_taxable_values
      ? benefitDefinition.fixed_monthly_taxable_values.map((value: number) => ({
          id: value.toString(),
          name: currencyFormatter.format(value),
        }))
      : [];
  const hasFixedTaxableValue = !!fixedMonthlyTaxableValues.length;
  const MAINTENANCES = getMaintenance(benefitDefinition?.currency);

  return (
    <>
      <Typography variant="h6">Maintenance budget</Typography>
      <SelectInput
        source="monthly_maintenance_budget"
        choices={MAINTENANCES}
        validate={required()}
        disabled={
          !!calculation?.down_payment_amount &&
          calculation.down_payment_amount > 0
        }
        fullWidth
      />
      <Separator />

      <Typography variant="h6">Leasing parameters</Typography>
      <NumberInput
        source="leasing_period_months"
        label="Leasing period (in months)"
        disabled={hasFixedTaxableValue}
        defaultValue={calculation?.leasing_period_months}
        fullWidth
      />
      <MoneyInput
        source="fixed_down_payment_amount"
        currency={benefitDefinition?.currency}
        label="Down payment amount"
        fullWidth
      />
      {!!fixedDownPaymentAmount && fixedDownPaymentAmount > 0 && (
        <BooleanInput
          source="is_down_payment_insured"
          label="Down payment insurance"
        />
      )}
      {hasFixedTaxableValue && (
        <SelectInput
          source="fixed_monthly_taxable_value"
          label="Monthly payment"
          choices={fixedMonthlyTaxableValues}
          defaultValue={fixedMonthlyTaxableValues[0].id}
          fullWidth
          validate={required()}
        />
      )}
      <OrderCalculation benefitDefinition={benefitDefinition} />
    </>
  );
};

const OrderCalculation = ({
  benefitDefinition,
}: {
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
}) => {
  const record = useRecordContext();
  const hasFixedTaxableValue =
    !!benefitDefinition.fixed_monthly_taxable_values?.length;
  const { setFocus, clearErrors, setValue } = useFormContext();
  const notify = useNotify();
  const requestParams = useCalculationRequestParams(benefitDefinition);
  const { setCalculation, initialOrderDetails } = useOrderFormContext();
  const calculate = useCalculatePeriod(requestParams, {
    enabled: !!requestParams && requestParams.total_package_price > 0,
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setCalculation(data);
      clearErrors("leasing_period_months");
      if (!requestParams.leasing_period_months) {
        setValue("leasing_period_months", data.leasing_period_months);
      }
      if (!requestParams.fixed_down_payment_amount) {
        setValue("fixed_down_payment_amount", data.down_payment_amount);
      }
    },
    onError(error) {
      setCalculation(null);
      if (
        error.body?.code === ErrorCode.CALCULATION_MAINTENANCE_BUDGET_TOO_HIGH
      ) {
        setValue("monthly_maintenance_budget", 0);
        setFocus("monthly_maintenance_budget");
        notify(
          "The selected maintenance budget is too high. It has been removed.",
        );
      }
    },
  });
  const summaryRecord = useMemo(
    () =>
      calculate.data
        ? {
            ...calculate.data,
            monthly_maintenance_budget:
              requestParams.monthly_maintenance_budget,
            old_total_taxable_package_price: initialOrderDetails
              ? initialOrderDetails.taxable_package_price +
                initialOrderDetails.leasing_period_months *
                  record.monthly_maintenance_budget
              : null,
            total_taxable_package_price:
              calculate.data.taxable_package_price +
              calculate.data.leasing_period_months *
                requestParams.monthly_maintenance_budget,
            currency: benefitDefinition?.currency,
            old_monthly_taxable_value:
              record.fixed_monthly_taxable_value ??
              initialOrderDetails?.monthly_taxable_value,
            monthly_taxable_value: hasFixedTaxableValue
              ? requestParams.fixed_monthly_taxable_value
              : calculate.data.monthly_taxable_value,
          }
        : null,
    [calculate.data, benefitDefinition?.currency],
  );
  if (calculate.error) {
    return <Alert severity="error">{calculate.error.message}</Alert>;
  }
  if (!summaryRecord) return null;
  return (
    <Table>
      <TableBody>
        <RowItem label="Total taxable package price">
          <SummaryField
            showOldValue={
              summaryRecord.old_total_taxable_package_price &&
              summaryRecord.old_total_taxable_package_price !==
                summaryRecord.total_taxable_package_price
            }
          >
            <CurrencyField
              record={summaryRecord}
              source="old_total_taxable_package_price"
              currencySource="currency"
            />
            <CurrencyField
              record={summaryRecord}
              source="total_taxable_package_price"
              currencySource="currency"
            />
          </SummaryField>
        </RowItem>
        <RowItem label="Monthly taxable value">
          <SummaryField
            showOldValue={
              summaryRecord.old_monthly_taxable_value &&
              summaryRecord.old_monthly_taxable_value !==
                summaryRecord.monthly_taxable_value
            }
          >
            <CurrencyField
              record={summaryRecord}
              source="old_monthly_taxable_value"
              currencySource="currency"
            />
            <CurrencyField
              record={summaryRecord}
              source="monthly_taxable_value"
              currencySource="currency"
            />
          </SummaryField>
        </RowItem>
        {record.id &&
        record.leasing_period_months !== summaryRecord.leasing_period_months ? (
          <RowItem label="Leasing duration (in months)">
            <SummaryField showOldValue>
              <NumberField record={record} source="leasing_period_months" />
              <NumberField
                record={summaryRecord}
                source="leasing_period_months"
              />
            </SummaryField>
          </RowItem>
        ) : null}
        {record.id &&
        record.monthly_maintenance_budget !==
          summaryRecord.monthly_maintenance_budget ? (
          <RowItem label="Maintenance budget">
            <SummaryField showOldValue>
              <CurrencyField
                record={record}
                source="monthly_maintenance_budget"
                currencySource="currency"
              />
              <CurrencyField
                record={summaryRecord}
                source="monthly_maintenance_budget"
                currencySource="currency"
              />
            </SummaryField>
          </RowItem>
        ) : null}
        {record.id &&
        record.fixed_down_payment_amount !==
          summaryRecord.down_payment_amount ? (
          <RowItem label="Down payment">
            <SummaryField showOldValue>
              <CurrencyField
                record={record}
                source="fixed_down_payment_amount"
                currencySource="currency"
              />
              <CurrencyField
                record={summaryRecord}
                source="down_payment_amount"
                currencySource="currency"
              />
            </SummaryField>
          </RowItem>
        ) : null}
      </TableBody>
    </Table>
  );
};

const SummaryField = ({
  children: [oldValue, newValue],
  showOldValue,
}: {
  children: [ReactElement, ReactElement];
  showOldValue: boolean;
}) => {
  return (
    <>
      {showOldValue && <>{oldValue} &rarr; </>}
      {newValue}
    </>
  );
};
