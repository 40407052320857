import CartIcon from "@mui/icons-material/ShoppingCart";
import { ResourceProps } from "react-admin";

import { OrderCreate } from "./OrderCreate";
import { OrderEdit } from "./OrderEdit";
import { OrderList } from "./OrderList";
import { OrderShow } from "./OrderShow";

const bikeBenefitOrdersResource: ResourceProps = {
  name: "bike-benefit-orders",
  icon: CartIcon,
  create: OrderCreate,
  show: OrderShow,
  edit: OrderEdit,
  list: OrderList,
  options: {
    label: "Bike Orders",
  },
};

export default bikeBenefitOrdersResource;
