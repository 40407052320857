import { TextInput, TextInputProps } from "react-admin";

/**
 * Simple wrapper for the TextInput component that allows null values.
 *
 * When a null value is provided by the data provider, the TextInput component will use an empty string instead.
 * Respectively, when the value is an empty string while saving, it will be turned into null instead.
 */
export const NullableTextInput = ({
  format = (value) => value,
  parse = (input) => input,
  ...props
}: TextInputProps) => (
  <TextInput
    {...props}
    format={(value) => format(value ?? "")}
    parse={(input) => parse(input === "" ? null : input)}
  />
);
