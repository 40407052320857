import { TextInput, TextInputProps, useInput } from "react-admin";

export const TextListInput = (props: TextInputProps) => {
  const {
    field: { onChange, value },
  } = useInput({ ...props });

  // Trim values and remove empty values when input is blurred (onBlur)
  // This is to prevent the user from entering a value like "foo, bar, , baz"
  const handleValueChange = () => {
    const trimmedValues = value
      .map?.((v: string) => v.trim())
      .filter((v: string) => v);
    onChange(trimmedValues);
  };

  return (
    <TextInput
      {...props}
      minRows={3}
      fullWidth
      multiline
      format={(value) => (value ? value.join(", ") : "")}
      parse={(value) => (value ? value.split(/, */) : [])}
      onBlur={handleValueChange}
    />
  );
};
