import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";

import { Pagination } from "components";

const UserOrders = () => {
  const record = useRecordContext();

  if (!record?.id) {
    return (
      <EmptyBoxInfo message="Please save the user first to manage user's orders." />
    );
  }

  return (
    <ReferenceManyField
      label="Orders"
      reference="bike-benefit-orders"
      target="user_id"
      perPage={20}
      pagination={<Pagination />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="user.full_name" sortable={false} />
        <TextField source="vapaus_code" sortable={false} />
        <TextField source="bike.serial_number" sortable={false} />
        <ReferenceField source="shop_id" reference="shops" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="state" textTransform="uppercase" sortable={false} />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default UserOrders;
