import { toChoices } from "./utils";

export enum BikeStatus {
  DRAFT = "draft",
  IN_STORAGE = "in_storage",
  RESERVED = "reserved",
  BENEFIT_BIKE = "benefit_bike",
  STOLEN = "stolen",
  SOLD = "sold",
  IN_FLEET = "in_fleet",
  RETIRED = "retired",
  UNKNOWN = "unknown",
}

export const BIKE_STATUS_LABELS = {
  [BikeStatus.DRAFT]: "Draft",
  [BikeStatus.IN_STORAGE]: "In storage",
  [BikeStatus.RESERVED]: "Reserved",
  [BikeStatus.BENEFIT_BIKE]: "Benefit bike",
  [BikeStatus.STOLEN]: "Stolen",
  [BikeStatus.SOLD]: "Sold",
  [BikeStatus.IN_FLEET]: "In fleet",
  [BikeStatus.RETIRED]: "Retired",
  [BikeStatus.UNKNOWN]: "Unknown",
};

export const BIKE_STATUS_CHOICES = toChoices(BIKE_STATUS_LABELS);

export enum BikeCondition {
  NEW = "new",
  LIKE_NEW = "like_new",
  EXCELLENT = "excellent",
  VERY_GOOD = "very_good",
  SATISFACTORY = "satisfactory",
  POOR = "poor",
}

export const BIKE_CONDITION_LABELS = {
  [BikeCondition.NEW]: "New",
  [BikeCondition.LIKE_NEW]: "Like new",
  [BikeCondition.EXCELLENT]: "Excellent",
  [BikeCondition.VERY_GOOD]: "Very good",
  [BikeCondition.SATISFACTORY]: "Satisfactory",
  [BikeCondition.POOR]: "Poor",
};
export const BIKE_CONDITION_CHOICES = toChoices(BIKE_CONDITION_LABELS);

export enum BikeDriveType {
  NORMAL = "normal",
  ELECTRIC = "electric",
}
export const BIKE_DRIVE_LABELS = {
  [BikeDriveType.ELECTRIC]: "Electric",
  [BikeDriveType.NORMAL]: "Normal",
};
export const BIKE_DRIVE_CHOICES = toChoices(BIKE_DRIVE_LABELS);

export enum BikeFrameType {
  HYBRID = "hybrid",
  GRAVEL = "gravel",
  FULLY = "fully",
  MTB = "mtb",
  FAT = "fat",
  CITY = "city",
  CARGO = "cargo",
  ROAD = "road",
  FOLDING = "folding",
  FIX = "fix",
  KICK = "kick",
  TANDEM = "tandem",
}
export const BIKE_FRAME_TYPE_LABELS = {
  [BikeFrameType.HYBRID]: "Hybrid",
  [BikeFrameType.GRAVEL]: "Gravel",
  [BikeFrameType.FULLY]: "Fully",
  [BikeFrameType.MTB]: "MTB",
  [BikeFrameType.FAT]: "Fat",
  [BikeFrameType.CITY]: "City",
  [BikeFrameType.CARGO]: "Cargo",
  [BikeFrameType.ROAD]: "Road",
  [BikeFrameType.FOLDING]: "Folding",
  [BikeFrameType.FIX]: "Fix",
  [BikeFrameType.KICK]: "Kick",
  [BikeFrameType.TANDEM]: "Tandem",
};
export const BIKE_FRAME_TYPE_CHOICES = toChoices(BIKE_FRAME_TYPE_LABELS);
