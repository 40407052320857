import { FC, useEffect, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { AVAILABLE_COUNTRIES } from "core/country";
import { Form, SaveButton, SelectInput, Title, required } from "react-admin";
import { useFormContext } from "react-hook-form";

import { DownloadFullFleetReport } from "./components/DownloadFullFleetReport";
import { DownloadFullOrdersReport } from "./components/DownloadFullOrdersReport";
import DownloadInvoiceReport from "./components/DownloadInvoiceReport";
import { DownloadMrrReport } from "./components/DownloadMrrReport";
import DownloadRepaymentPlanReport from "./components/DownloadRepaymentPlanReport";
import { DownloadUserReport } from "./components/DownloadUserReport";
import ParamsBetweenDates from "./components/ParamsBetweenDates";
import {
  REPORT_TYPES_CHOICES,
  REPORT_TYPES_LABELS,
  ReportType,
  useDownloadReport,
} from "./hooks";

const ReportsDownload: FC = () => {
  const [reportType, setReportType] = useState<ReportType>(
    ReportType.Deliveries,
  );
  const { download, status } = useDownloadReport(reportType);

  const handleSubmit = (values: any) => {
    values.country = values.country === "ALL" ? null : values.country;
    if (values.hasOwnProperty("language")) {
      values.language = values.language === "ALL" ? null : values.language;
    }
    const { report_type, ...sentData } = values;
    download(sentData, "text/csv");
  };

  return (
    <Card sx={{ mt: 2 }}>
      <Title title="Download Reports" />
      <CardContent>
        <Typography variant="h4" textTransform="capitalize">
          Download {REPORT_TYPES_LABELS[reportType]} Reports
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please select a report type below to download.
        </Typography>
        <Form onSubmit={handleSubmit}>
          <ReportTypeSelectInput
            reportType={reportType}
            setReportType={setReportType}
          />
          {reportType !== ReportType.TukirahoitusSales && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CountrySelectInput reportType={reportType} />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            {[
              ReportType.Deliveries,
              ReportType.PurchaseInvoice,
              ReportType.SaleInvoiceTransactions,
              ReportType.FleetBookings,
              ReportType.TukirahoitusSales,
            ].includes(reportType) && (
              <ParamsBetweenDates
                required={reportType === ReportType.Deliveries}
              />
            )}
            {reportType === ReportType.FullOrders && (
              <DownloadFullOrdersReport />
            )}
            {reportType === ReportType.InvoiceReport && (
              <DownloadInvoiceReport />
            )}
            {reportType === ReportType.RepaymentPlan && (
              <DownloadRepaymentPlanReport />
            )}
            {reportType === ReportType.Users && <DownloadUserReport />}
            {reportType === ReportType.FullFleet && <DownloadFullFleetReport />}
            {reportType === ReportType.Mrr && <DownloadMrrReport />}
          </Grid>

          {status === "loading" ? (
            <Grid container justifyContent="space-around">
              <Grid item alignContent="center">
                <Typography variant="body2" gutterBottom>
                  Your report is being generated, some reports can take up to a
                  few minutes.
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <SaveButton
                  alwaysEnable
                  icon={<DownloadIcon />}
                  variant="contained"
                  label="Download"
                />
              </Grid>
            </Grid>
          )}
        </Form>
      </CardContent>
    </Card>
  );
};

const ReportTypeSelectInput = ({
  reportType,
  setReportType,
}: {
  reportType: ReportType;
  setReportType: (value: ReportType) => void;
}) => {
  const { setValue, reset } = useFormContext();

  const onReportTypeChange = (value: ReportType) => {
    reset();
    setReportType(value);
    setValue("report_type", value);
  };

  return (
    <SelectInput
      validate={required()}
      fullWidth
      label="Report Type"
      source="report_type"
      choices={REPORT_TYPES_CHOICES}
      defaultValue={reportType}
      onChange={(e) => onReportTypeChange(e.target.value as ReportType)}
    />
  );
};

const CountrySelectInput = ({ reportType }: { reportType: ReportType }) => {
  const { setValue } = useFormContext();
  const hasAllCountriesOption = reportType !== ReportType.Mrr;

  const countriesList = (
    hasAllCountriesOption ? [{ id: "ALL", name: "All countries" }] : []
  ).concat(AVAILABLE_COUNTRIES);

  useEffect(() => {
    setValue("country", hasAllCountriesOption ? "ALL" : "FI");
  }, [reportType, hasAllCountriesOption]);

  return (
    <SelectInput
      label="Country"
      source="country"
      choices={countriesList}
      validate={required()}
    />
  );
};

export default ReportsDownload;
