import { Edit, useRecordContext } from "react-admin";

import UserForm from "./UserForm";
import { useCustomMutationOptions } from "hooks";

const UserTitle = () => {
  const record = useRecordContext();
  return <span>User {record?.full_name || record?.email}</span>;
};

export const UserEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<UserTitle />}
    >
      <UserForm edit />
    </Edit>
  );
};
