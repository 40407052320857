import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
} from "react-admin";

import { Pagination } from "components";

const bikeBrandFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <BooleanInput source="active" defaultValue={true} />,
];

export const BikeBrandList = () => (
  <List
    filters={bikeBrandFilters}
    perPage={20}
    pagination={<Pagination />}
    sort={{ field: "name", order: "ASC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="name" />
      <BooleanField source="active" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);
