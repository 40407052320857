import React, { FC } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  DateInput,
  Form,
  SaveButton,
  required,
  useNotify,
  useRecordContext,
  useTranslate,
} from "react-admin";

import { ContractMenuModalProps } from "./ContractMenu";
import { useDownloadDeedOfSale } from "hooks";

export const DeedOfSaleModal: FC<ContractMenuModalProps> = ({
  onClose,
  open,
}) => {
  const notify = useNotify();
  const { id } = useRecordContext();
  const translate = useTranslate();
  const { download, status } = useDownloadDeedOfSale(id as string);
  const handleSubmit = (formData: any) => {
    download({ validity_date: formData.validity_date }, "application/pdf").then(
      () => {
        onClose();
        notify("Deed of Sale generated successfully");
      },
    );
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Generate Deed of Sale PDF</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{
          validity_date: DateTime.now().toISODate(),
        }}
      >
        <DialogContent>
          <DateInput
            source="validity_date"
            label="Validity Date"
            validate={[required()]}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            startIcon={<CancelIcon />}
            disabled={status === "loading"}
          >
            {translate("ra.action.cancel")}
          </Button>
          <SaveButton label="Generate" alwaysEnable={status !== "loading"} />
        </DialogActions>
      </Form>
    </Dialog>
  );
};
