import UserIcon from "@mui/icons-material/People";
import { ResourceProps } from "react-admin";

import { UserCreate } from "./UserCreate";
import { UserEdit } from "./UserEdit";
import { UserList } from "./UserList";

const userResource: ResourceProps = {
  name: "users",
  icon: UserIcon,
  create: UserCreate,
  edit: UserEdit,
  list: UserList,
};

export default userResource;
