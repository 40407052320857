import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Create,
  Form,
  SaveButton,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { ContractMenuModalProps } from "resources/BikeBenefitContracts/components/ContractMenu";

export const CancelReturnProcessModal = ({
  onClose,
  open,
}: ContractMenuModalProps) => {
  const contract = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: returnProcess } = useGetOne("return-processes", {
    id: contract.current_return_process_id,
  });
  if (!returnProcess) return null;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Cancel Return Process"
    >
      <DialogTitle>Cancel Return Process</DialogTitle>
      <Create
        resource={`return-processes/${returnProcess.id}/cancel`}
        mutationOptions={{
          onSuccess: () => {
            refresh();
            notify("Return Process cancelled successfully");
            onClose();
          },
        }}
        title={<></>}
        sx={{ mt: "-2em" }}
      >
        <Form>
          <DialogContent>
            Are you sure you want to cancel this Return Process?
            {returnProcess.sale_invoice_id ? (
              <Alert severity="warning" sx={{ mt: 1 }}>
                The previously issued return invoice will be reverted.
              </Alert>
            ) : null}
            {returnProcess.hubspot_ticket_id ? (
              <Alert severity="warning" sx={{ mt: 1 }}>
                The associated HubSpot ticket won't be cancelled automatically.
              </Alert>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} startIcon={<CancelIcon />}>
              Go back
            </Button>
            <SaveButton alwaysEnable label="Cancel Return Process" />
          </DialogActions>
        </Form>
      </Create>
    </Dialog>
  );
};
