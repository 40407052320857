import type { ValidationErrorMessage, Validator } from "react-admin";

import { memoize, sourceToLabel } from "./utils";

/**
 * Relative min number validator
 *
 * Return an error if the number is lower than the number from the relative field.
 *
 * @example
 *
 * const maxNumberValidators = [relativeMinNumber("min_number")];
 * <NumberInput source="min_number" />
 * <NumberInput source="max_number" validate={maxNumberValidators} />
 */
export const relativeMinNumber = memoize(
  (
    source: string,
    label?: string,
    message = "The value must be greater or equal to the one set for %label%",
  ): Validator =>
    (value: any, allValues: any): ValidationErrorMessage | null => {
      const number = parseFloat(value);
      const minNumber = parseFloat(allValues[source]);
      const defaultLabel = label || sourceToLabel(source);
      return number < minNumber
        ? message.replace("%label%", defaultLabel)
        : null;
    },
);

/**
 * Relative max number validator
 *
 * Return an error if the number is greater than the number from the relative field.
 *
 * @example
 *
 * const minNumberValidators = [relativeMaxNumber("max_number")];
 * <NumberInput source="min_number" validate={minNumberValidators} />
 * <NumberInput source="max_number" />
 */
export const relativeMaxNumber = memoize(
  (
    source: string,
    label?: string,
    message = "The value must be lower or equal to the one set for %label%",
  ): Validator =>
    (value: any, allValues: any): ValidationErrorMessage | null => {
      const number = parseFloat(value);
      const maxNumber = parseFloat(allValues[source]);
      const defaultLabel = label || sourceToLabel(source);
      return number > maxNumber
        ? message.replace("%label%", defaultLabel)
        : null;
    },
);
