import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  BooleanInput,
  Create,
  DateInput,
  Form,
  FormDataConsumer,
  SaveButton,
  SelectInput,
  required,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FieldValues } from "react-hook-form";

import {
  BikeBenefitContractAdminRead,
  BikeBenefitContractCancellationData,
  BikeBenefitContractCancellationReason,
} from "@vapaus/generated";

import { NullableTextInput as TextInput } from "components";
import { BIKE_BENEFIT_CANCELLATION_REASONS_CHOICES } from "core";

interface UpdateContractCancellationModalProps {
  open: boolean;
  onClose: () => void;
}

export const UpdateContractCancellationModal = ({
  onClose,
  open,
}: UpdateContractCancellationModalProps) => {
  const record = useRecordContext<BikeBenefitContractAdminRead>();
  const refresh = useRefresh();

  if (
    !record?.exceptional_end_date ||
    (record?.cancellation_reason &&
      ![
        BikeBenefitContractCancellationReason.REDEEMED,
        BikeBenefitContractCancellationReason.RETURNED,
        BikeBenefitContractCancellationReason.INSURANCE_THEFT,
        BikeBenefitContractCancellationReason.INSURANCE_ACCIDENT,
        BikeBenefitContractCancellationReason.OTHER,
      ].includes(record.cancellation_reason))
  )
    return null;

  const shouldClearCancellationReasonDescription = (formData: FieldValues) =>
    formData.clear_cancellation ||
    formData.cancellation_reason !==
      BikeBenefitContractCancellationReason.OTHER;

  const prepareRequestData = (formData: FieldValues) => {
    return {
      exceptional_end_date: formData.clear_cancellation
        ? null
        : formData.exceptional_end_date,
      cancellation_reason: formData.clear_cancellation
        ? null
        : formData.cancellation_reason,
      cancellation_reason_description: shouldClearCancellationReasonDescription(
        formData,
      )
        ? null
        : formData.cancellation_reason_description,
    };
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Modify cancellation</DialogTitle>
      <Create
        record={{
          clear_cancellation: false,
          exceptional_end_date: record.exceptional_end_date,
          cancellation_reason: record.cancellation_reason,
          cancellation_reason_description:
            record.cancellation_reason_description,
        }}
        transform={prepareRequestData}
        resource={`bike-benefit-contracts/${record.id}/update-cancellation`}
        redirect="show"
        mutationOptions={{
          onSuccess: () => {
            refresh();
            onClose();
          },
        }}
        sx={{ mt: "-1em" }}
      >
        <Form>
          <DialogContent>
            <Typography sx={{ mb: 3, fontWeight: 600 }} color="error">
              This change will impact invoicing. Please inform invoicing teams.
            </Typography>
            <BooleanInput
              label="Remove exceptional end date"
              source="clear_cancellation"
            />
            <FormDataConsumer<
              BikeBenefitContractCancellationData & {
                clear_cancellation: boolean;
              }
            >>
              {({ formData }) =>
                !formData.clear_cancellation && (
                  <>
                    <DateInput
                      source="exceptional_end_date"
                      label="Cancellation Date"
                      fullWidth
                    />
                    <SelectInput
                      source="cancellation_reason"
                      label="Cancellation Reason"
                      choices={BIKE_BENEFIT_CANCELLATION_REASONS_CHOICES}
                      fullWidth
                    />
                    {formData.cancellation_reason ===
                      BikeBenefitContractCancellationData.cancellation_reason
                        .OTHER && (
                      <TextInput
                        source="cancellation_reason_description"
                        label="Description"
                        fullWidth
                        multiline
                        validate={[required()]}
                      />
                    )}
                  </>
                )
              }
            </FormDataConsumer>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} startIcon={<CancelIcon />}>
              Cancel
            </Button>
            <SaveButton alwaysEnable />
          </DialogActions>
        </Form>
      </Create>
    </Dialog>
  );
};
