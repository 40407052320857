import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  required,
} from "react-admin";
import { relativeMaxDate, relativeMinDate } from "validators";

const DownloadRepaymentPlanReport = () => {
  return (
    <>
      <Grid item xs={12}>
        <ReferenceInput source="organisation_id" reference="organisations">
          <AutocompleteInput
            label="Organisation"
            optionText="name"
            optionValue="id"
            fullWidth
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <DateInput
          defaultValue={DateTime.now()
            .minus({ months: 1 })
            .startOf("month")
            .toISODate()}
          source="start_date_from"
          label="Start Date From"
          fullWidth
          validate={[relativeMaxDate("start_date_to"), required()]}
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          defaultValue={DateTime.now()
            .minus({ months: 1 })
            .endOf("month")
            .toISODate()}
          source="start_date_to"
          label="Start Date To"
          fullWidth
          validate={[relativeMinDate("start_date_from"), required()]}
        />
      </Grid>
    </>
  );
};

export default DownloadRepaymentPlanReport;
