import { FC, useRef } from "react";

import { Add, Delete, Edit } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
// import { SaleInvoiceLineCreate } from "@vapaus/generated";
import {
  Button,
  Datagrid,
  NumberField,
  TextField,
  useRecordContext,
} from "react-admin";

import { Currency } from "@vapaus/generated";

import { InvoiceLineFormRef, InvoiceLineModal } from "./InvoiceLineModal";

export type InvoiceLine = {
  id: number;
  // line: SaleInvoiceLineCreate;
  line: any;
};

const ActionButton = ({ onClick, icon, label, className }: any) => {
  const record = useRecordContext();
  return (
    <Button
      onClick={() => onClick?.(record)}
      label={label}
      size="small"
      startIcon={icon}
      className={className}
    />
  );
};

const Actions = ({ label, children }: any) => <>{children}</>;

type LineProps = {
  data: InvoiceLine[];
  currency: Currency;
  defaultVatRate?: number;
  updateLines: (data: InvoiceLine[]) => void;
  readOnly?: boolean;
};

// Invoice Lines Table Component
const ManageInvoiceLines: FC<LineProps> = ({
  updateLines,
  data = [],
  readOnly,
  currency,
  defaultVatRate,
}) => {
  const invoiceLineModalRef = useRef<InvoiceLineFormRef>(null);

  const handleOnAddInvoiceLine = (line: InvoiceLine) => {
    const currentLine = data.find((l) => l.id === line.id);
    if (currentLine) {
      const newLines = data.map((l) => (l.id === line.id ? line : l));
      updateLines(newLines);
    } else {
      updateLines([...data, line]);
    }
  };

  const handleEdit = (line: InvoiceLine) => {
    invoiceLineModalRef.current?.open(line);
  };

  const handleDelete = ({ id }: InvoiceLine) => {
    updateLines(data.filter((line) => line.id !== id));
  };

  const addNewButton = !readOnly && (
    <Button
      startIcon={<Add />}
      label="Add Invoice Line"
      onClick={() =>
        invoiceLineModalRef.current?.open({
          id: data.length + 1,
        } as InvoiceLine)
      }
    />
  );

  return (
    <>
      <TextField label="Invoice Lines" />
      <Datagrid
        title="Invoice Lines"
        data={data}
        sort={{ field: "type", order: "ASC" }}
        empty={
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{ minHeight: "300px" }}
          >
            <Typography gutterBottom>
              No invoice lines, please add some
            </Typography>
            {addNewButton}
          </Grid>
        }
      >
        <TextField source="line.type" label="Type" textTransform="uppercase" />
        <TextField source="line.description" label="Description" />
        <TextField source="line.quantity" label="Qty" />
        <NumberField
          source="line.price"
          label="Price"
          options={{ style: "currency", currency, maximumFractionDigits: 4 }}
        />
        <NumberField
          source="line.vat_rate"
          label="Vat"
          options={{ style: "percent", maximumFractionDigits: 1 }}
        />
        {!readOnly && (
          <Actions label="Actions">
            <ActionButton icon={<Edit />} onClick={handleEdit} />
            <ActionButton icon={<Delete />} onClick={handleDelete} />
          </Actions>
        )}
      </Datagrid>
      {data.length > 0 && addNewButton}
      <InvoiceLineModal
        ref={invoiceLineModalRef}
        onSubmit={handleOnAddInvoiceLine}
        currency={currency}
        defaultVatRate={defaultVatRate}
      />
    </>
  );
};

export default ManageInvoiceLines;
