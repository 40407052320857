import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  ChipField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";

import { FleetAccessRulesCreateButton } from "./FleetAccessRulesCreateButton";
import { FleetAccessRulesEditButton } from "./FleetAccessRulesEditButton";

export const NestedFleetAccessRulesDataGrid = () => {
  const record = useRecordContext();
  if (!record?.id)
    return (
      <EmptyBoxInfo message="Please save the organisation first to manage fleet access rules." />
    );

  return (
    <ReferenceManyField
      label="Fleet access rules"
      reference="fleet-access-rules"
      target="organisation_id"
      filter={{ is_active: true }}
    >
      <Datagrid
        bulkActionButtons={false}
        empty={<FleetAccessRulesCreateButton />}
        sx={{ maxWidth: 1024 }}
      >
        <TextField source="name" />
        <FunctionField
          source="allowed_email_addresses"
          label="Allowed emails"
          render={(record: any) =>
            `${record.allowed_email_addresses?.length} emails`
          }
        />
        <FunctionField
          source="allowed_codes"
          label="Allowed codes"
          render={(record: any) => `${record.allowed_codes?.length} codes`}
        />
        <FunctionField
          source="blacklist_emails"
          label="Blacklisted emails"
          render={(record: any) => `${record.blacklist_emails?.length} emails`}
        />
        <ReferenceManyField
          label="Fleets using these rules"
          source="uuid"
          reference="fleets"
          target="fleet_access_rules_uuid"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceManyField>
        <DatagridActionsColumn label={<FleetAccessRulesCreateButton />}>
          {<FleetAccessRulesEditButton />}
        </DatagridActionsColumn>
      </Datagrid>
    </ReferenceManyField>
  );
};
