import { FC } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import { UpdatePurchaseInvoiceButton } from "components/UpdatePurchaseInvoiceButton";
import {
  Datagrid,
  DateField,
  NumberField,
  TextField,
  useGetManyReference,
  useRecordContext,
} from "react-admin";

export const PurchaseInvoices: FC<{
  idField: "bike_benefit_contract_id" | "bike_benefit_order_id";
}> = ({ idField }) => {
  const record = useRecordContext();
  const { data } = useGetManyReference("invoices/purchase-invoices", {
    target: idField,
    id: record.id,
    filter:
      // for a contract fetch also purchase invoices for its order
      // for an order fetch also purchase invoices for its contract
      idField === "bike_benefit_contract_id"
        ? { bike_benefit_order_id: record.bike_benefit_order_id }
        : {
            bike_benefit_contract_id:
              record.current_bike_benefit_contract_id || undefined,
          },
  });

  if (data?.length === 0) return null;
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel-purchase-invoices-content"
        id="panel-purchase-invoices-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <ReceiptIcon sx={{ mr: 2 }} />{" "}
        <Typography>Purchase Invoices</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Datagrid data={data || []} sort={{ field: "date", order: "ASC" }}>
          <TextField source="invoice_number" textTransform="uppercase" />
          <DateField source="date" />
          <TextField source="description" />
          <NumberField
            source="total"
            options={{ style: "currency", currency: record.currency }}
          />
          <DatagridActionsColumn label="">
            <UpdatePurchaseInvoiceButton />
          </DatagridActionsColumn>
        </Datagrid>
      </AccordionDetails>
    </Accordion>
  );
};
