import { AuthProvider } from "react-admin";

import { MasterPermissions } from "@vapaus/generated";

async function callApi(request: Request): Promise<any> {
  const response = await fetch(request);
  if (response.status < 200 || response.status >= 300) {
    const result = await response.json();
    throw new Error(result.detail || response.statusText);
  }
  const result = await response.json();
  return result;
}

const createAuthProvider = (apiUrl: string) =>
  ({
    login: async ({ username, password }) => {
      const signInRequest = new Request(`${apiUrl}/auth/sign-in`, {
        method: "POST",
        body: new URLSearchParams({ username, password }),
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      });

      try {
        const auth = await callApi(signInRequest);
        const token = `${auth.token_type} ${auth.access_token}`;
        localStorage.setItem("token", token);
        const getMeRequest = new Request(`${apiUrl}/users/me`, {
          method: "GET",
          headers: new Headers({ Authorization: token }),
        });
        const user = await callApi(getMeRequest);
        if (user.master_permission?.permissions.indexOf("MASTER_ROOT") === -1) {
          throw new Error("Forbidden");
        }
        localStorage.setItem("user", JSON.stringify(user));
      } catch (e: any) {
        throw new Error(e.toString());
      }
    },
    logout: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return Promise.resolve();
    },
    checkAuth: () => {
      return localStorage.getItem("token")
        ? Promise.resolve()
        : Promise.reject();
    },
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        return Promise.reject();
      }
      // other error code (404, 500, etc): no need to log out
      return Promise.resolve();
    },
    getIdentity() {
      try {
        const {
          id,
          full_name: fullName,
          email,
        } = JSON.parse(localStorage.getItem("user") || "");
        return Promise.resolve({ id, fullName, email });
      } catch (e) {
        return Promise.reject();
      }
    },
    getPermissions: () => {
      try {
        const { master_permissions: masterPermissions } = JSON.parse(
          localStorage.getItem("user") || "",
        );
        return Promise.resolve(
          [
            masterPermissions.includes(MasterPermissions.ERP_SALE_INVOICE) && {
              action: "*",
              resource: "erp-sale-invoices",
            },
          ].filter(Boolean),
        );
      } catch (e) {
        return Promise.reject();
      }
    },
  }) as AuthProvider;

export default createAuthProvider;
