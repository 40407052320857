import { Currency } from "@vapaus/generated";

export const getMaintenance = (currency: string) => {
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  }).format;

  const CURRENCY_MAINTENANCES = {
    [Currency.EUR.toString()]: [0, 12, 16, 20],
    [Currency.SEK.toString()]: [0, 50, 100, 200],
  };

  return CURRENCY_MAINTENANCES[currency].map((value) => ({
    name: currencyFormatter(value),
    id: value,
  }));
};
