export const toChoices = (obj: { [key: string]: string }) => {
  return Object.entries(obj).map(([id, name]) => ({
    id,
    name,
  }));
};

export const valuesToChoices = (obj: { [key: string]: string }) => {
  return Object.values(obj).map((value) => ({
    id: value,
    name: value,
  }));
};
