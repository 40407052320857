import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { Pagination } from "components";
import { BIKE_CONDITION_CHOICES, BIKE_STATUS_CHOICES } from "core";

const bikeFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="shop_id" reference="shops" alwaysOn>
    <AutocompleteInput label="Shop" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <SelectInput source="condition" choices={BIKE_CONDITION_CHOICES} />,
  <SelectInput source="status" choices={BIKE_STATUS_CHOICES} />,
];

export const BikeList = () => (
  <List
    filters={bikeFilters}
    perPage={20}
    pagination={<Pagination />}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="serial_number" />
      <TextField source="bike_brand.name" label="Brand" />
      <TextField source="model" />
      <TextField source="shop.name" />
      <DateField source="created_at" />
      <SelectField
        source="condition"
        choices={BIKE_CONDITION_CHOICES}
        sortable={false}
      />
      <SelectField source="status" choices={BIKE_STATUS_CHOICES} />
    </Datagrid>
  </List>
);
