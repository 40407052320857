import { Grid } from "@mui/material";
import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  BooleanField,
  Datagrid,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin";

import { CreateBookingTermsAndConditionsButton } from "./CreateBookingTermsAndConditionsButton";
import { EditBookingTermsAndConditionsButton } from "./EditBookingTermsAndConditionsButton";

const BookingTermsAndConditionsList = () => {
  const record = useRecordContext();

  const { data } = useGetList(
    `organisations/${record?.id}/booking-terms-and-conditions`,
    undefined,
    {
      enabled: !!record?.id,
    },
  );

  if (!record?.id)
    return (
      <EmptyBoxInfo message="Please save the organisation first to manage booking terms and conditions." />
    );

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Datagrid data={data} sort={{ field: "name", order: "ASC" }}>
          <TextField source="name" />
          <BooleanField source="is_active" label="Active" />
          <DatagridActionsColumn label="">
            <EditBookingTermsAndConditionsButton />
          </DatagridActionsColumn>
        </Datagrid>
      </Grid>
      <Grid item md={12}>
        <CreateBookingTermsAndConditionsButton />
      </Grid>
    </Grid>
  );
};

export default BookingTermsAndConditionsList;
