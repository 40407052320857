import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext } from "react-admin";

import { BillingDetailsForm } from "./BillingDetailsForm";

export const BillingDetailsCreateButton = () => {
  const organisation = useRecordContext();

  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="md"
      record={{ organisation_id: organisation.id }}
      title={`Add a billing details for ${organisation.name}`}
    >
      <BillingDetailsForm />
    </CreateInDialogButton>
  );
};
