import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRefreshableRow } from "components/RefreshableDatagridRow";
import { useRecordContext } from "react-admin";

import { useErpSaleInvoiceContext } from "./ErpSaleInvoiceContext";
import { ErpSaleInvoiceLineAssignmentForm } from "./ErpSaleInvoiceLineAssignmentForm";

export const AddErpSaleInvoiceLineAssignmentButton = () => {
  const line = useRecordContext();
  const { refresh } = useRefreshableRow();
  const invoice = useErpSaleInvoiceContext();
  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      record={{
        erp_sale_invoice_line_id: line.id,
        billing_date: invoice.default_billing_date,
      }}
      title="Create new assignment"
      mutationOptions={{
        onSettled: refresh,
      }}
    >
      <ErpSaleInvoiceLineAssignmentForm />
    </CreateInDialogButton>
  );
};
