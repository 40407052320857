import type { ValidationErrorMessage, Validator } from "react-admin";

import { memoize, sourceToLabel } from "./utils";

/**
 * Relative min date validator
 *
 * Return an error if the date is inferior or equal to the date from the relative field.
 *
 * @example
 *
 * const endDateValidators = [relativeMinDate("start_date")];
 * <DateInput source="start_date" />
 * <DateInput source="end_date" validate={endDateValidators} />
 */
export const relativeMinDate = memoize(
  (
    source: string,
    label?: string,
    message = "The date must be after the date set for %label%",
  ): Validator =>
    (value: any, allValues: any): ValidationErrorMessage | null => {
      if (!value) return null;
      const date = new Date(value);
      const minDate = new Date(allValues[source]);
      const defaultLabel = label || sourceToLabel(source);
      return date <= minDate ? message.replace("%label%", defaultLabel) : null;
    },
);

/**
 * Relative max date validator
 *
 * Return an error if the date is superior or equal to the date from the relative field.
 *
 * @example
 *
 * const startDateValidators = [relativeMaxDate("end_date")];
 * <DateInput source="start_date" validate={startDateValidators} />
 * <DateInput source="end_date" />
 */
export const relativeMaxDate = memoize(
  (
    source: string,
    label?: string,
    message = "The date must be before the date set for %label%",
  ): Validator =>
    (value: any, allValues: any): ValidationErrorMessage | null => {
      if (!value) return null;
      const date = new Date(value);
      const maxDate = new Date(allValues[source]);
      const defaultLabel = label || sourceToLabel(source);
      return date >= maxDate ? message.replace("%label%", defaultLabel) : null;
    },
);
