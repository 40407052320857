import { useState } from "react";

import ReplyIcon from "@mui/icons-material/Reply";
import { Toolbar, useRecordContext } from "react-admin";
import styled from "styled-components";

import { SendEmailNotificationModal } from "./SendEmailNotificationModal";
import { DropdownIconMenu } from "components";
import { useVapausQuery } from "hooks";

type EmailSearchDataType = {
  data: { items: { email_verified: boolean }[] };
};

export const UserMenu = () => {
  const record = useRecordContext();
  const { data: userEmailData } = useVapausQuery<EmailSearchDataType>(
    "searchUserEmails",
    { user_id: record.id },
  );

  const showResendVerificationMenu = userEmailData?.data
    ? !userEmailData.data.items?.find((email) => email.email_verified)
    : false;

  const [isOpenResendEmailNotification, setIsOpenResendEmailNotification] =
    useState(false);

  const menuItems = [
    {
      name: "Re-send invitation",
      icon: <ReplyIcon />,
      onClick: () => setIsOpenResendEmailNotification(true),
    },
  ];

  if (!showResendVerificationMenu) return null;

  return (
    <StyledToolbar variant="dense">
      <DropdownIconMenu menus={menuItems} tooltip="User Actions" />
      <SendEmailNotificationModal
        isOpen={isOpenResendEmailNotification}
        onClose={() => setIsOpenResendEmailNotification(false)}
      />
    </StyledToolbar>
  );
};

const StyledToolbar = styled(Toolbar)`
  position: absolute;
  right: 0;
`;
