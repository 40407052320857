import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext } from "react-admin";

import { FleetAccessRulesForm } from "./FleetAccessRulesForm";

export const FleetAccessRulesCreateButton = () => {
  const organisation = useRecordContext();

  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="md"
      record={{ organisation_id: organisation.id }}
      title={`Add a fleet access rule for ${organisation.name}`}
    >
      <FleetAccessRulesForm />
    </CreateInDialogButton>
  );
};
