import { FC, useState } from "react";

import BoltIcon from "@mui/icons-material/Bolt";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { DateTime, Interval } from "luxon";
import {
  Confirm,
  DateInput,
  Form,
  FormDataConsumer,
  SaveButton,
  SelectInput,
  Title,
  required,
} from "react-admin";
import invariant from "tiny-invariant";

import InvoicesSynchronisationStatusDialog from "./components/InvoicesSynchronisationStatusDialog";
import { BILLING_SCHEMES_CHOICES, BillingSchemes } from "core";
import { useCustomMutationOptions, useVapausMutation } from "hooks";

const MonthlyInvoicesGenerate: FC = () => {
  const now = DateTime.local();
  const invoicingPeriodsChoices = Interval.fromDateTimes(
    now.minus({ months: 6 }),
    now.plus({ month: 1 }),
  )
    .splitBy({ month: 1 })
    .map((d) => {
      const start = d.start;
      invariant(start);
      return {
        id: `${start.year}-${start.month}`,
        name: start.toFormat("LLLL yyyy"),
      };
    })
    .reverse();
  const { mutate, isLoading } = useVapausMutation("generateMonthlyInvoices");
  const { onError } = useCustomMutationOptions();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showSynchronisationDialog, setShowSynchronisationDialog] =
    useState(false);
  const [synchronisationId, setSynchronisationId] = useState("");
  const synchronise = (formData: any) => {
    const [invoicingPeriodYear, invoicingPeriodMonth] =
      formData.invoicing_period.split("-");
    mutate(
      {
        billing_scheme: formData.billing_scheme,
        invoicing_date: formData.invoicing_date,
        invoicing_period_year: invoicingPeriodYear,
        invoicing_period_month: invoicingPeriodMonth,
      },
      {
        onSuccess: (data: any) => {
          setSynchronisationId(data.synchronisation_id);
          setShowSynchronisationDialog(true);
        },
        onError: onError,
      },
    );
  };
  const handleSubmit = (formData: any) => {
    const [invoicingPeriodYear, invoicingPeriodMonth] =
      formData.invoicing_period.split("-");
    const invoicingDate = DateTime.fromISO(formData.invoicing_date);
    if (
      invoicingDate.year === parseInt(invoicingPeriodYear) &&
      invoicingDate.month === parseInt(invoicingPeriodMonth)
    ) {
      synchronise(formData);
    } else {
      setShowConfirmationDialog(true);
    }
  };
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Title title="Billing" />
        <CardContent>
          <Typography variant="h4" textTransform="capitalize">
            Monthly invoices synchronisation
          </Typography>
          <Typography>
            Submitting the form below will generate invoices data and import
            them to Netvisor.
          </Typography>
          <Form onSubmit={handleSubmit}>
            <SelectInput
              validate={required()}
              fullWidth
              label="Billing Scheme"
              source="billing_scheme"
              choices={BILLING_SCHEMES_CHOICES}
              defaultValue={BillingSchemes.Standard}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectInput
                  validate={required()}
                  label="Invoicing Period"
                  source="invoicing_period"
                  choices={invoicingPeriodsChoices}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  validate={required()}
                  defaultValue={DateTime.local().toISODate()}
                  source="invoicing_date"
                  label="Invoicing Date"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <SaveButton
                  disabled={isLoading}
                  icon={<BoltIcon />}
                  variant="contained"
                  label="Synchronise invoices"
                />
              </Grid>
            </Grid>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <Confirm
                    title="Confirm invoicing period"
                    content="The invoicing period and invoice date do not match. Are you sure that you want to proceed with the selected dates?"
                    isOpen={showConfirmationDialog}
                    onClose={() => setShowConfirmationDialog(false)}
                    onConfirm={() => {
                      synchronise(formData);
                      setShowConfirmationDialog(false);
                    }}
                  />
                );
              }}
            </FormDataConsumer>
          </Form>
        </CardContent>
      </Card>
      <InvoicesSynchronisationStatusDialog
        isOpen={showSynchronisationDialog}
        setShowDialog={setShowSynchronisationDialog}
        synchronisationId={synchronisationId}
      />
    </>
  );
};

export default MonthlyInvoicesGenerate;
