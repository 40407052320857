import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { CurrencyField, Pagination } from "../../components";
import { useEnumChoices, useEnumLabel } from "../../hooks";

export function MaintenanceEventsList() {
  const getWorkTypeLabel = useEnumLabel("WorkTypes");
  const workTypeChoices = useEnumChoices("WorkTypes");

  return (
    <List
      filters={getFilters(workTypeChoices)}
      perPage={20}
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="vapaus_code" sortable={false} />
        <ReferenceField
          source="bike_benefit_contract_id"
          reference="bike-benefit-contracts"
          label="User fullname"
          sortable={false}
        >
          <TextField source="user.full_name" />
        </ReferenceField>
        <ReferenceField
          source="bike_id"
          reference="bikes"
          sortBy="bike.serial_number"
        >
          <TextField source="serial_number" label="Bike frame number" />
        </ReferenceField>
        <ReferenceField source="shop_id" reference="shops" sortBy="shop.name">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Type"
          source="work_type"
          render={(maintenanceEvent: any) =>
            getWorkTypeLabel(maintenanceEvent.work_type)
          }
        />
        <DateField source="work_date" label="Event date" />
        <CurrencyField
          source="total_price"
          label="Total price (incl. VAT)"
          currencySource="currency"
        />
      </Datagrid>
    </List>
  );
}

const getFilters = (workTypes: { id: string; name: string }[]) => {
  return [
    <TextInput name="q" label="Search" source="q" alwaysOn />,
    <SelectInput
      name="work_type"
      label="Type"
      source="work_type"
      alwaysOn
      choices={workTypes}
    />,
    <ReferenceInput name="shop_id" source="shop_id" reference="shops">
      <AutocompleteInput
        name="shop_id"
        label="Shop"
        optionText="name"
        optionValue="id"
        fullWidth
        isRequired
      />
    </ReferenceInput>,
  ];
};
