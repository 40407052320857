import {
  AdditionalServiceType,
  BikeBenefitOrderingMethod,
  SupportedOrganisationCountries,
} from "@vapaus/generated";

import { toChoices } from "./utils";

// TODO: Restore this import and remove the BikeBenefit type from this file when
// we have fixed the issue of import type parsing errors in the workshop app.

// import { BikeBenefitOrderState } from "@vapaus/generated";

export enum FinancingMethods {
  Tukirahoitus = "tukirahoitus",
  TukirahoitusPuitesopimus = "tukirahoitus-puitesopimus",
  Oomi = "oomi",
  SiemensFinance = "siemens-finance",
  VapausBikesFinlandOy = "vapaus-bikes-finland-oy",
  VapausEiVoiMyyda = "vapaus-ei-voi-myyda",
  VapausFinanceOy = "vapaus-finance-oy",
}

export const TUKIRAHOITUS_FINANCING_METHODS = [
  FinancingMethods.Tukirahoitus,
  FinancingMethods.TukirahoitusPuitesopimus,
];

export const FINANCING_METHODS_LABELS = {
  [FinancingMethods.Tukirahoitus]: "Tukirahoitus",
  [FinancingMethods.TukirahoitusPuitesopimus]:
    "Tukirahoitus, puitesopimus (do not use)",
  [FinancingMethods.Oomi]: "Oomi",
  [FinancingMethods.SiemensFinance]: "Siemens Finance",
  [FinancingMethods.VapausBikesFinlandOy]: "Vapaus Bikes Finland Oy",
  [FinancingMethods.VapausEiVoiMyyda]: "Vapaus - ei voi myydä",
  [FinancingMethods.VapausFinanceOy]: "Vapaus Finance Oy",
};

export const FINANCING_METHODS_CHOICES = toChoices(FINANCING_METHODS_LABELS);

export const FINANCING_METHODS_CHOICES_BY_COUNTRY: Partial<{
  [key in SupportedOrganisationCountries]: { name: string; id: string }[];
}> = {
  [SupportedOrganisationCountries.FI]: FINANCING_METHODS_CHOICES,
  [SupportedOrganisationCountries.SE]: FINANCING_METHODS_CHOICES.filter(
    (choice) => choice.id === FinancingMethods.VapausBikesFinlandOy,
  ),
};

export enum BikeBenefitOrderState {
  DRAFT = "draft",
  READY_FOR_DELIVERY = "ready_for_delivery",
  WAITING_FOR_PAYMENT = "waiting_for_payment",
  FINALISED = "finalised",
  CANCELLED = "cancelled",
}

export const BIKE_BENEFIT_ORDER_STATES: {
  [key in BikeBenefitOrderState]: string;
} = {
  [BikeBenefitOrderState.DRAFT]: "Draft",
  [BikeBenefitOrderState.READY_FOR_DELIVERY]: "Ready for delivery",
  [BikeBenefitOrderState.WAITING_FOR_PAYMENT]: "Waiting for payment",
  [BikeBenefitOrderState.FINALISED]: "Finalised",
  [BikeBenefitOrderState.CANCELLED]: "Cancelled",
};

export const BIKE_BENEFIT_ORDER_STATES_CHOICES = toChoices(
  BIKE_BENEFIT_ORDER_STATES,
);

export enum BikeBenefitContractState {
  SCHEDULED = "scheduled",
  ACTIVE = "active",
  ACTIVE_PENDING_REVISION = "active_pending_revision",
  ACTIVE_PENDING_CORRECTION = "active_pending_correction",
  ACTIVE_PENDING_REDEMPTION = "active_pending_redemption",
  ACTIVE_PENDING_RETURN = "active_pending_return",
  ENDED = "ended",
  ENDED_PENDING_PAYMENT = "ended_pending_payment",
  CANCELLED = "cancelled",
  CANCELLED_PENDING_PAYMENT = "cancelled_pending_payment",
  INVALIDATED = "invalidated",
}

export const BIKE_BENEFIT_CONTRACT_STATES = {
  [BikeBenefitContractState.SCHEDULED]: "Scheduled",
  [BikeBenefitContractState.ACTIVE]: "Active",
  [BikeBenefitContractState.ACTIVE_PENDING_REVISION]: "Active pending revision",
  [BikeBenefitContractState.ACTIVE_PENDING_CORRECTION]:
    "Active pending correction",
  [BikeBenefitContractState.ACTIVE_PENDING_REDEMPTION]:
    "Active pending redemption",
  [BikeBenefitContractState.ACTIVE_PENDING_RETURN]: "Active pending return",
  [BikeBenefitContractState.ENDED]: "Ended",
  [BikeBenefitContractState.ENDED_PENDING_PAYMENT]: "Ended pending payment",
  [BikeBenefitContractState.CANCELLED]: "Cancelled",
  [BikeBenefitContractState.CANCELLED_PENDING_PAYMENT]:
    "Cancelled pending payment",
  [BikeBenefitContractState.INVALIDATED]: "Invalidated",
};

export const BIKE_BENEFIT_CONTRACT_STATES_CHOICES = toChoices(
  BIKE_BENEFIT_CONTRACT_STATES,
);

export enum BikeBenefitContractCancellationReason {
  REDEEMED = "redeemed",
  RETURNED = "returned",
  // REVISED not available for setting in front end
  INSURANCE_THEFT = "insurance_theft",
  INSURANCE_ACCIDENT = "insurance_accident",
  // MERGER_OR_ACQUISITION not available for setting in front end
  OTHER = "other",
}

export const BIKE_BENEFIT_CANCELLATION_REASONS_STATES = {
  [BikeBenefitContractCancellationReason.REDEEMED]: "Redeemed",
  [BikeBenefitContractCancellationReason.RETURNED]: "Returned",
  [BikeBenefitContractCancellationReason.INSURANCE_THEFT]: "Insurance (theft)",
  [BikeBenefitContractCancellationReason.INSURANCE_ACCIDENT]:
    "Insurance (accident)",
  [BikeBenefitContractCancellationReason.OTHER]: "Other",
};

export const BIKE_BENEFIT_CANCELLATION_REASONS_CHOICES = toChoices(
  BIKE_BENEFIT_CANCELLATION_REASONS_STATES,
);

export enum BenefitDefinitionAccessRulesRequestRequirements {
  ALWAYS = "always",
  NEVER = "never",
  IF_NEEDED = "if needed",
}

export const ACCESS_RULES_REQUEST_REQUIREMENTS = {
  [BenefitDefinitionAccessRulesRequestRequirements.ALWAYS]: "Always",
  [BenefitDefinitionAccessRulesRequestRequirements.NEVER]: "Never",
  [BenefitDefinitionAccessRulesRequestRequirements.IF_NEEDED]: "If needed",
};
export const ACCESS_RULES_REQUEST_REQUIREMENTS_CHOICES = toChoices(
  ACCESS_RULES_REQUEST_REQUIREMENTS,
);

export const SERVICE_FEE_PRODUCT_CODES = {
  [FinancingMethods.Tukirahoitus]: "TSTO-TR",
  [FinancingMethods.TukirahoitusPuitesopimus]: "TSTO-TR",
  [FinancingMethods.Oomi]: "OT",
  [FinancingMethods.SiemensFinance]: "SF-TSTO",
  [FinancingMethods.VapausBikesFinlandOy]: "TSTO",
};

export const BIKE_CONTRACT_PRODUCT_CODES = {
  [FinancingMethods.Tukirahoitus]: "TSP-TR",
  [FinancingMethods.TukirahoitusPuitesopimus]: "TSP-TR",
  [FinancingMethods.Oomi]: "OF",
  [FinancingMethods.SiemensFinance]: "SF",
  [FinancingMethods.VapausBikesFinlandOy]: "TSP",
};

export const ORDERING_METHODS = {
  [BikeBenefitOrderingMethod.ONLINE_ORDER]: "Online order",
  [BikeBenefitOrderingMethod.PRECYCLED]: "Precycled",
  [BikeBenefitOrderingMethod.PARTNER_STORE]: "Partner store",
};

export const ORDERING_METHOD_CHOICES = toChoices(ORDERING_METHODS);

export const ADDITIONAL_SERVICE_TYPE_LABELS = {
  [AdditionalServiceType.ASSEMBLY]: "Assembly",
  [AdditionalServiceType.BIKE_FITTING]: "Bike fitting",
  [AdditionalServiceType.OTHER]: "Other",
};

export const ADDITIONAL_SERVICE_TYPE_CHOICES = toChoices(
  ADDITIONAL_SERVICE_TYPE_LABELS,
);
