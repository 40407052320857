import { FC } from "react";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Datagrid,
  FunctionField,
  Link,
  NumberField,
  TextField,
  useRecordContext,
} from "react-admin";

import { Accessory } from "@vapaus/generated";

export const Accessories = () => {
  const record = useRecordContext();
  if (record.accessories?.length === 0) return null;
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel-accessories-content"
        id="panel-accessories-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <AutoFixHighIcon sx={{ mr: 2 }} /> <Typography>Accessories</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Datagrid
          data={record.accessories}
          sort={{ field: "purchase_date", order: "ASC" }}
        >
          <TextField source="accessory_type" />
          <TextField source="manufacturer" />
          <FunctionField<Accessory>
            label="Model"
            render={(accessory) =>
              accessory!.url ? (
                <Link to={accessory!.url} target="_blank">
                  <TextField source="model" />
                </Link>
              ) : (
                <TextField source="model" />
              )
            }
          />
          <NumberField
            label="Price"
            source="purchase_price"
            options={{ style: "currency", currency: record.currency }}
          />
          <NumberField
            source="purchase_vat_rate"
            label="VAT rate"
            options={{ style: "percent", maximumFractionDigits: 2 }}
          />
        </Datagrid>
      </AccordionDetails>
    </Accordion>
  );
};
