import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { useRefreshableRow } from "components/RefreshableDatagridRow";

import { ErpSaleInvoiceLineAssignmentForm } from "./ErpSaleInvoiceLineAssignmentForm";
import { EditToolbar } from "components";

export const EditErpSaleInvoiceLineAssignmentButton = () => {
  const { refresh } = useRefreshableRow();
  return (
    <EditInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      title="Edit assignment"
      mutationMode="pessimistic"
      mutationOptions={{
        onSettled: refresh,
      }}
    >
      <ErpSaleInvoiceLineAssignmentForm toolbar={<EditToolbar />} />
    </EditInDialogButton>
  );
};
