import React from "react";

import {
  Button,
  Edit,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";

import TermsForm from "./TermsForm";
import { EditToolbar } from "components";
import { useCustomMutation, useCustomMutationOptions } from "hooks";

const TermsEditToolbar = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const { mutate, isLoading } = useCustomMutation(
    "activateTermsAndConditions",
    record.id,
    {
      onSuccess: () => {
        notify("ra.notification.updated", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
        redirect("/terms");
      },
    },
  );

  return (
    <EditToolbar>
      <Button
        sx={{ marginLeft: 4 }}
        label="Set active"
        size="medium"
        onClick={() => mutate()}
        disabled={isLoading}
      />
    </EditToolbar>
  );
};

export const TermsEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
    >
      <TermsForm toolbar={<TermsEditToolbar />} isEdit />
    </Edit>
  );
};
