import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { Pagination } from "components";

const filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="organisation_id" reference="organisations" alwaysOn>
    <AutocompleteInput
      label="Organisation"
      optionText="name"
      optionValue="id"
      fullWidth
    />
  </ReferenceInput>,
  <SelectInput
    label="Status"
    source="is_active"
    choices={[
      { id: true, name: "Active" },
      { id: false, name: "Inactive" },
    ]}
  />,
];

export const FleetList = () => (
  <List filters={filters} perPage={20} pagination={<Pagination />}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="name" />
      <BooleanField source="is_active" label="Active" />
      <TextField label="Description" source="description" sortable={false} />
      <ReferenceField
        source="organisation_id"
        reference="organisations"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
