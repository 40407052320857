import { useState } from "react";

import { Button, Confirm, useNotify, useRecordContext } from "react-admin";

import { useCustomMutation } from "hooks";

export const BenefitTermsActivateButton = ({ source }: any) => {
  const [open, setOpen] = useState(false);
  const terms = useRecordContext();
  const notify = useNotify();

  const { mutate, isLoading, queryClient } = useCustomMutation(
    "activateBenefitDefinitionTerms",
    terms?.id,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["benefit-definition-terms"]);
        notify("ra.notification.updated", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
      },
    },
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <>
      <Button label="Set active" onClick={handleClick} disabled={isLoading} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Set benefit terms & condition active"
        content="Are you sure you want to set this terms & condition as active?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
