import { useRecordContext } from "react-admin";

import { NullableTextInput as TextInput } from "components";

const UserSSNInput = () => {
  const record = useRecordContext();

  const message = record?.ssn
    ? "User verified social security number"
    : "No social security number provided yet";

  return (
    <TextInput
      label="Social Security Number"
      source="ssn"
      fullWidth
      disabled
      helperText={message}
    />
  );
};

export default UserSSNInput;
