/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AdditionalServiceType {
  ASSEMBLY = "assembly",
  BIKE_FITTING = "bike_fitting",
  OTHER = "other",
}
