import { Typography } from "@mui/material";
import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  BooleanField,
  Datagrid,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";

import { LocationCreateButton } from "./LocationCreateButton";
import { LocationEditButton } from "./LocationEditButton";

export const LocationList = () => {
  const record = useRecordContext();
  if (!record?.id)
    return (
      <EmptyBoxInfo message="Please save the organisation first to manage locations." />
    );
  return (
    <ReferenceManyField
      label="Locations"
      reference="locations"
      target="organisation_ids"
    >
      <Datagrid
        bulkActionButtons={false}
        empty={
          <>
            <Typography variant="body2">
              No locations have been added for this organisation yet
            </Typography>
            <LocationCreateButton />
          </>
        }
        sx={{ maxWidth: 1024 }}
      >
        <TextField source="name" />
        <TextField source="address" />
        <TextField source="postal_code" />
        <TextField source="city" />
        <BooleanField source="is_active" />
        <DatagridActionsColumn label={<LocationCreateButton />}>
          <LocationEditButton />
        </DatagridActionsColumn>
      </Datagrid>
    </ReferenceManyField>
  );
};
