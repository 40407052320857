import { createContext, useContext } from "react";

import { ErpSaleInvoiceRead } from "@vapaus/generated";

export const ErpSaleInvoiceContext = createContext<
  ErpSaleInvoiceRead | undefined
>(undefined);

export const useErpSaleInvoiceContext = () => {
  const invoice = useContext(ErpSaleInvoiceContext);
  if (!invoice) {
    throw new Error(
      "Use `ErpSaleInvoiceContext.Provider` before using this hook!",
    );
  }
  return invoice;
};
