import Alert from "@mui/material/Alert";
import {
  EditRowButton,
  EditableDatagrid,
} from "@react-admin/ra-editable-datagrid";
import { DateField, DeleteWithConfirmButton, NumberField } from "react-admin";

import { ShopDiscountsForm } from "./ShopDiscountsForm";

export const ShopDiscountsDatagrid = () => {
  return (
    <EditableDatagrid
      mutationMode="pessimistic"
      createForm={<ShopDiscountsForm />}
      editForm={<ShopDiscountsForm />}
      actions={<ShopDiscountsDatagridActions />}
    >
      <DateField source="valid_from" />
      <NumberField
        source="discount_bike"
        options={{ style: "percent", maximumFractionDigits: 7 }}
      />
      <NumberField
        source="discount_accessory"
        options={{ style: "percent", maximumFractionDigits: 7 }}
      />
    </EditableDatagrid>
  );
};

const ShopDiscountsDatagridActions = () => {
  return (
    <>
      <EditRowButton />
      <DeleteWithConfirmButton
        redirect={false}
        confirmContent={
          <>
            Are you sure you want to delete this item?
            <Alert severity="warning" sx={{ mt: 2 }}>
              This change will affect bike purchase prices of existing orders.
              Please be sure that you wish to proceed with this update.
            </Alert>
          </>
        }
      />
    </>
  );
};
