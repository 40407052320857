import { Create } from "react-admin";

import FleetForm from "./FleetForm";
import { useCustomMutationOptions } from "hooks";

export const FleetCreate = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Create mutationOptions={mutationOptions} redirect="list">
      <FleetForm />
    </Create>
  );
};
