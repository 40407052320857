import {
  BooleanField,
  Datagrid,
  DateField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from "react-admin";

import { Pagination } from "components";

const BikeFleets = () => {
  return (
    <Labeled fullWidth>
      <ReferenceManyField
        label="Fleets"
        reference="fleets"
        target="bike_id"
        perPage={20}
        pagination={<Pagination />}
        sort={{ field: "created_at", order: "DESC" }}
      >
        <Datagrid bulkActionButtons={false} rowClick="edit">
          <ReferenceField
            source="organisation_id"
            reference="organisations"
            sortable={false}
          >
            <TextField source="name" sortable={false} />
          </ReferenceField>
          <TextField source="name" sortable={false} />
          <BooleanField source="is_active" sortable={false} />
          <DateField source="created_at" sortable={false} />
        </Datagrid>
      </ReferenceManyField>
    </Labeled>
  );
};

export default BikeFleets;
