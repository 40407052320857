import { FC } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  DateInput,
  Form,
  NumberInput,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";

import { useCustomMutationOptions } from "hooks";

interface Props {
  view: "order" | "contract";
  open: boolean;
  onClose: () => void;
}

export const CreatePurchaseInvoiceModal: FC<Props> = ({
  view,
  open,
  onClose,
}) => {
  const translate = useTranslate();
  const record = useRecordContext();

  const notify = useNotify();
  const refresh = useRefresh();

  const [create, { isLoading }] = useCreate();

  const { onError } = useCustomMutationOptions();

  const handleSubmit = (purchaseInvoice: any) => {
    create(
      "invoices/purchase-invoices",
      {
        data: purchaseInvoice,
      },
      {
        onSuccess: () => {
          refresh();
          notify("Purchase invoice created");
          onClose();
        },
        onError,
      },
    );
  };

  const defaultValues =
    view === "order"
      ? {
          bike_benefit_order_id: record?.id,
          bike_benefit_contract_id: record?.current_bike_benefit_contract_id,
        }
      : {
          bike_benefit_contract_id: record?.id,
          bike_benefit_order_id: record?.bike_benefit_order_id,
        };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Add Purchase Invoice"
    >
      <DialogTitle>Create Purchase Invoice</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        record={{
          ...defaultValues,
          currency: record?.currency,
          date: DateTime.now().toISODate(),
        }}
      >
        <DialogContent>
          <DateInput source="date" fullWidth required />
          <TextInput source="invoice_number" fullWidth required />
          <NumberInput source="total" fullWidth required />
          <TextInput source="description" fullWidth multiline />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isLoading}
            startIcon={<CancelIcon />}
          >
            {translate("ra.action.cancel")}
          </Button>

          <SaveButton
            label="Create Purchase Invoice"
            disabled={isLoading}
            alwaysEnable={!isLoading}
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
};
