import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { ResourceProps } from "react-admin";

const erpSaleInvoiceLineAssignmentResource: ResourceProps = {
  name: "erp-sale-invoice-line-assignments",
  icon: ChecklistRtlIcon,
  options: {
    label: "ERP Sale Invoice Line Assignments",
  },
};

export default erpSaleInvoiceLineAssignmentResource;
