import React from "react";

import { TextInput, TextInputProps, regex, useInput } from "react-admin";

export const UrlInput = (props: TextInputProps) => {
  const {
    field: { onChange, value },
  } = useInput({ ...props });
  const ensureProtocol = () => {
    if (value.length && !value.includes("://")) {
      onChange(`https://${value}`);
    }
  };
  return (
    <TextInput
      {...props}
      onBlur={ensureProtocol}
      validate={[
        ...(Array.isArray(props.validate)
          ? props.validate
          : props.validate
          ? [props.validate]
          : []),
        regex(
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
          "The entered value is not a valid URL",
        ),
      ]}
    />
  );
};
