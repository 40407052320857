import { useEffect, useRef } from "react";

import { ReferenceInput, SelectInput } from "react-admin";
import { useFormContext, useFormState } from "react-hook-form";

export const BenefitAccessRulesInput = () => {
  const { watch, setValue } = useFormContext();
  const { dirtyFields } = useFormState();
  const [organisationId, isBenefitDefinitionActive] = watch([
    "organisation_id",
    "active",
  ]);
  const prevOrganisationIdRef = useRef<string>(organisationId);

  useEffect(() => {
    if (organisationId !== prevOrganisationIdRef.current) {
      prevOrganisationIdRef.current = organisationId;
      setValue("access_rules_uuid", "");
    }
  }, [organisationId, prevOrganisationIdRef, setValue]);

  return (
    <ReferenceInput
      source="access_rules_uuid"
      reference="benefit-definition-access-rules"
      filter={{ organisation_id: organisationId, is_active: true }}
      enableGetChoices={() => !!organisationId}
    >
      <SelectInput
        label="Access rules"
        optionText="name"
        optionValue="uuid"
        fullWidth
        helperText={
          isBenefitDefinitionActive && dirtyFields.access_rules_uuid
            ? "The changed rules will only affect new registrants. Access from previously-authorised employees can be revoked from the provider app."
            : ""
        }
      />
    </ReferenceInput>
  );
};
