import * as React from "react";
import { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetOne,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import { Currency } from "@vapaus/api-codegen";

import {
  CurrencyField,
  MoneyInput,
  SectionTitle,
  Separator,
} from "../../components";
import { useEnum } from "../../hooks";

type MaintenanceEventFormProps = {
  toolbar?: ReactElement;
  editMode?: boolean;
};

export function MaintenanceEventForm({
  toolbar,
  editMode = false,
}: MaintenanceEventFormProps) {
  const workTypes = useEnum("WorkTypes");

  return (
    <SimpleForm
      warnWhenUnsavedChanges
      toolbar={toolbar}
      sx={{ maxWidth: 1024 }}
    >
      <Grid container spacing={2}>
        <Grid item md={6}>
          <SectionTitle title="Basic" />
          <ReferenceInput
            source="bike_benefit_contract_id"
            reference="bike-benefit-contracts"
            filter={{ with_maintenance_budget_only: true }}
          >
            <AutocompleteInput
              label="Contract"
              optionText={(choice) =>
                `${choice.vapaus_code} (${choice.user.full_name}) ${
                  choice.start_date
                } - ${choice.exceptional_end_date ?? choice.end_date}`
              }
              optionValue="id"
              fullWidth
              disabled={editMode}
            />
          </ReferenceInput>
          <ReferenceInput source="shop_id" reference="shops">
            <AutocompleteInput
              label="Shop"
              optionText="name"
              optionValue="id"
              fullWidth
              validate={required()}
              disabled={editMode}
            />
          </ReferenceInput>
          <BikeInput editMode={editMode} />
          <DateInput
            source="created_at"
            label="Created at"
            disabled
            fullWidth
          />
          <Separator />
          <SectionTitle title="Work details" />
          <SelectInput
            label="Work type"
            source="work_type"
            fullWidth
            validate={required()}
            choices={workTypes.map((workType) => ({
              id: workType.value,
              name: workType.label,
            }))}
          />
          <TextInput
            multiline
            minRows={3}
            source="work_description"
            label="Description"
            fullWidth
            validate={required()}
          />
          <DateInput
            source="work_date"
            label="Work date"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item md={6}>
          <SectionTitle title="Billing" />
          <MoneyWithCurrencyInput source="work_price" label="Labour price" />
          <MoneyWithCurrencyInput source="parts_price" label="Parts price" />
          <TotalInput />
          <CurrencyInput editMode={editMode} />
          <Separator />
          <MaintenanceBudgetSection />
        </Grid>
      </Grid>
    </SimpleForm>
  );
}

const BikeInput = ({ editMode }: { editMode: boolean }) => {
  const contractId = useWatch({ name: "bike_benefit_contract_id" });
  const { setValue } = useFormContext();

  const { data: contract } = useGetOne(
    "bike-benefit-contracts",
    {
      id: contractId,
    },
    {
      enabled: !!contractId,
    },
  );

  useEffect(() => {
    if (contract) {
      setValue("bike_id", contract.bike.id);
    }
  }, [contract, editMode, setValue]);

  return (
    <ReferenceInput source="bike_id" reference="bikes" validate={required()}>
      <AutocompleteInput
        label="Select bike"
        optionText={(bike) =>
          `${bike.bike_brand.name} ${bike.model} (${bike.serial_number})`
        }
        optionValue="id"
        fullWidth
        validate={required()}
        disabled={editMode}
      />
    </ReferenceInput>
  );
};

const MoneyWithCurrencyInput = ({
  source,
  label,
}: {
  source: string;
  label: string;
}) => {
  const currency = useWatch({ name: "currency" });

  return (
    <MoneyInput
      currency={currency ?? Currency.Eur}
      source={source}
      label={label}
      name={source}
      fullWidth
      validate={required()}
    />
  );
};

const TotalInput = () => {
  const [work_price, parts_price, currency] = useWatch({
    name: ["work_price", "parts_price", "currency"],
  });
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("total_price", Number(work_price || 0) + Number(parts_price || 0));
  }, [work_price, parts_price, setValue]);

  return (
    <MoneyInput
      currency={currency}
      disabled
      source="total_price"
      label="Total price"
      fullWidth
    />
  );
};

const CurrencyInput = ({ editMode }: { editMode: boolean }) => {
  const contractId = useWatch({ name: "bike_benefit_contract_id" });
  const bikeId = useWatch({ name: "bike_id" });
  const { setValue } = useFormContext();

  const { data: contract } = useGetOne(
    "bike-benefit-contracts",
    {
      id: contractId,
    },
    {
      enabled: !!contractId,
    },
  );

  const { data: bike } = useGetOne(
    "bikes",
    {
      id: bikeId,
    },
    {
      enabled: !!bikeId,
    },
  );

  useEffect(() => {
    if (contract && !editMode) {
      setValue("currency", contract.currency);
      return;
    }

    if (bike && !editMode) {
      setValue("currency", bike.purchase_currency);
    }
  }, [bike, contract, editMode, setValue]);

  return (
    <SelectInput
      choices={Object.values(Currency).map((currency) => ({
        id: currency,
        name: currency,
      }))}
      source="currency"
      label="Currency"
      name="currency"
      defaultValue={contract?.currency}
      disabled={!!contractId}
      fullWidth
      validate={required()}
    />
  );
};

const MaintenanceBudgetSection = () => {
  const contractId = useWatch({ name: "bike_benefit_contract_id" });

  const { data: contract } = useGetOne(
    "bike-benefit-contracts",
    {
      id: contractId,
    },
    {
      enabled: !!contractId,
    },
  );

  return (
    <>
      <SectionTitle title="Maintenance budget" />
      <StyledContainer>
        <StyledRow>
          <StyledLabel>Total maintenance budget</StyledLabel>
          <CurrencyField
            source="total_maintenance_budget"
            currencySource="currency"
            record={contract}
          />{" "}
        </StyledRow>
        <StyledRow>
          <StyledLabel>Spent maintenance budget</StyledLabel>
          <CurrencyField
            source="spent_maintenance_budget"
            currencySource="currency"
            record={contract}
          />
        </StyledRow>
        <StyledRow>
          <StyledLabel>Remaining maintenance budget</StyledLabel>
          <CurrencyField
            source="remaining_maintenance_budget"
            currencySource="currency"
            record={contract}
          />
        </StyledRow>
      </StyledContainer>
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLabel = styled(Typography)`
  font-size: 14px !important;
  font-weight: 500 !important;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 26px;
`;
