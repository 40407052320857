import { FC } from "react";

import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useRecordContext, useRefresh } from "react-admin";

import { OrderMenuModalProps } from "./OrderMenu";
import { useVapausMutation } from "hooks";

export const CancelOrderModal: FC<OrderMenuModalProps> = ({
  open,
  onClose,
}) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const { mutate, isLoading } = useVapausMutation("cancelBikeBenefitOrder");

  return (
    <Dialog fullWidth open={open} onClose={onClose} aria-label="Cancel order">
      <DialogTitle>Cancel order</DialogTitle>
      <DialogContent>Are you sure you want to cancel this order?</DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Go back
        </Button>
        <Button
          disabled={isLoading}
          startIcon={<CheckIcon />}
          onClick={() =>
            mutate(record.id, {
              onSuccess: () => refresh(),
            })
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
