import WarningIcon from "@mui/icons-material/Warning";
import { Table, TableBody, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import { RefreshableDatagridRow } from "components/RefreshableDatagridRow";
import { countBy, sortBy } from "lodash-es";
import { DateTime } from "luxon";
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  ChipField,
  Datagrid,
  DatagridBody,
  DatagridRow,
  DatagridRowProps,
  DateField,
  FunctionField,
  Link,
  List,
  NumberField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { RowItem } from "resources/BikeBenefitContracts/components/RowItem";
import { EditErpSaleInvoiceLineButton } from "resources/ErpSaleInvoice/components/EditErpSaleInvoiceLineButton";
import { ErpSaleInvoiceContext } from "resources/ErpSaleInvoice/components/ErpSaleInvoiceContext";
import { ErpSaleInvoiceLineContext } from "resources/ErpSaleInvoice/components/ErpSaleInvoiceLineContext";
import { ErpSaleInvoiceToggleIgnoreButton } from "resources/ErpSaleInvoice/components/ErpSaleInvoiceToggleIgnoreButton";

import {
  ErpSaleInvoiceLineAssignmentRead,
  ErpSaleInvoiceLineRead,
  ErpSaleInvoiceLineState,
  ErpSaleInvoiceRead,
} from "@vapaus/generated";

import { AddErpSaleInvoiceLineAssignmentButton } from "./components/AddErpSaleInvoiceLineAssignmentButton";
import { DeleteErpSaleInvoiceLineAssignmentButton } from "./components/DeleteErpSaleInvoiceLineAssignmentButton";
import { EditErpSaleInvoiceLineAssignmentButton } from "./components/EditErpSaleInvoiceLineAssignmentButton";
import { CurrencyField, Pagination } from "components";
import {
  ERP_SALE_INVOICE_LINE_STATE_CHOICES,
  ERP_SALE_INVOICE_LINE_TYPE_LABELS,
} from "core";

const ERP_SALE_INVOICE_LINE_STATE_COLORS: Record<
  ErpSaleInvoiceLineState,
  "default" | "secondary" | "success" | "warning" | "error"
> = {
  [ErpSaleInvoiceLineState.IGNORED]: "warning",
  [ErpSaleInvoiceLineState.UNASSIGNED]: "default",
  [ErpSaleInvoiceLineState.PARTIALLY_ASSIGNED]: "secondary",
  [ErpSaleInvoiceLineState.ASSIGNED]: "success",
  [ErpSaleInvoiceLineState.INVALID]: "error",
};

const filters = () => {
  const currentYear = DateTime.now().year;

  const yearChoices = Array.from({ length: currentYear - 2020 + 1 }, (_, i) => {
    const year = currentYear - i + 1;
    return { id: year, name: year.toString() };
  });

  const monthChoices = Array.from({ length: 12 }, (_, i) => ({
    id: i + 1,
    name: DateTime.fromObject({ month: i + 1 }).monthLong,
  }));
  return [
    <TextInput label="Search" source="q" alwaysOn />,
    <SelectInput
      label="Line status"
      source="line_states"
      choices={ERP_SALE_INVOICE_LINE_STATE_CHOICES}
      alwaysOn
    />,
    <ReferenceInput source="organisation_id" reference="organisations" alwaysOn>
      <AutocompleteInput
        label="Organisation"
        optionText="name"
        optionValue="id"
      />
    </ReferenceInput>,
    <SelectInput
      label="Year"
      source="voucher_or_invoice_date_year"
      choices={yearChoices}
      alwaysOn
    />,
    <SelectInput
      label="Month"
      source="voucher_or_invoice_date_month"
      choices={monthChoices}
      alwaysOn
    />,
  ];
};

const ErpSaleInvoiceRow = (props: DatagridRowProps) => {
  return (
    <ErpSaleInvoiceContext.Provider value={props.record as ErpSaleInvoiceRead}>
      <RefreshableDatagridRow {...props} />
    </ErpSaleInvoiceContext.Provider>
  );
};

export const ErpSaleInvoiceList = () => (
  <List
    filters={filters()}
    perPage={20}
    pagination={<Pagination />}
    sort={{ field: "voucher_or_invoice_date", order: "DESC" }}
    queryOptions={{
      refetchOnWindowFocus: false,
    }}
  >
    <Datagrid
      bulkActionButtons={false}
      expand={<ErpSaleInvoiceDisplay />}
      body={<DatagridBody row={<ErpSaleInvoiceRow />} />}
    >
      <TextField source="invoice_number" />
      <TextField source="customer_full_name" label="Customer" />
      <DateField source="voucher_or_invoice_date" label="Date" />
      <CurrencyField source="total" currencySource="currency" />
      <FunctionField<ErpSaleInvoiceRead>
        render={(invoice?: ErpSaleInvoiceRead) => {
          const statusCounts = countBy(
            invoice?.erp_sale_invoice_lines.map(
              (line: ErpSaleInvoiceLineRead) => line.state,
            ),
          );
          return sortBy(
            Object.entries(statusCounts),
            ([state, count]) => state,
          ).map(([state, count]) => (
            <Chip
              key={state}
              label={state}
              size="medium"
              color={
                ERP_SALE_INVOICE_LINE_STATE_COLORS[
                  state as ErpSaleInvoiceLineState
                ]
              }
              icon={
                <Chip
                  label={count}
                  size="small"
                  sx={{ fontSize: "12px !important" }}
                />
              }
              sx={{ mr: 1 }}
            />
          ));
        }}
        label="Lines status"
      />
    </Datagrid>
  </List>
);

const ErpSaleInvoiceLineRow = (props: DatagridRowProps) => {
  return (
    <ErpSaleInvoiceLineContext.Provider
      value={props.record as ErpSaleInvoiceLineRead}
    >
      <DatagridRow {...props} />
    </ErpSaleInvoiceLineContext.Provider>
  );
};

const ErpSaleInvoiceDisplay = () => {
  return (
    <Card variant="outlined" sx={{ ml: 5 }}>
      <Table>
        <TableBody>
          <RowItem label="Invoice date">
            <TextField source="date" />
          </RowItem>
          <RowItem label="Voucher date">
            <TextField source="voucher_date" />
          </RowItem>
          <RowItem label="Voucher number">
            <TextField source="voucher_number" />
          </RowItem>
          <RowItem label="Business ID">
            <TextField source="customer_business_id" />
          </RowItem>
          <RowItem label="ERP customer code">
            <TextField source="customer_code" />
          </RowItem>
          <RowItem label="Text before lines">
            <TextField
              source="text_before_lines"
              sx={{ whiteSpace: "pre-wrap" }}
            />
          </RowItem>
          <RowItem label="Internal additional info">
            <TextField
              source="internal_additional_info"
              sx={{ whiteSpace: "pre-wrap" }}
            />
          </RowItem>
        </TableBody>
      </Table>
      <ArrayField source="erp_sale_invoice_lines">
        <Datagrid
          bulkActionButtons={false}
          expand={<ErpSaleInvoiceLineDisplay />}
          body={<DatagridBody row={<ErpSaleInvoiceLineRow />} />}
        >
          <TextField source="product_code" sortable={false} />
          <FunctionField<ErpSaleInvoiceLineRead>
            label="Type"
            render={(line?: ErpSaleInvoiceLineRead) =>
              line!.line_type
                ? ERP_SALE_INVOICE_LINE_TYPE_LABELS[line!.line_type]
                : ""
            }
          />
          <TextField source="description" sortable={false} />
          <NumberField source="price" label="Unit price" sortable={false} />
          <FunctionField<ErpSaleInvoiceLineRead>
            label="Quantity"
            render={(line?: ErpSaleInvoiceLineRead) =>
              `${line!.assigned_quantity}/${line!.quantity}`
            }
          />
          <NumberField source="subtotal" label="Subtotal" sortable={false} />
          <FunctionField<ErpSaleInvoiceLineRead>
            label="Assignments"
            render={(line?: ErpSaleInvoiceLineRead) =>
              line!.required_assignment_count
                ? `${line!.assignment_count}/${line!.required_assignment_count}`
                : "-"
            }
          />
          <FunctionField<ErpSaleInvoiceLineRead>
            label="Status"
            render={(line?: ErpSaleInvoiceLineRead) => (
              <>
                <ChipField
                  source="state"
                  color={
                    ERP_SALE_INVOICE_LINE_STATE_COLORS[
                      line!.state as ErpSaleInvoiceLineState
                    ]
                  }
                />
                {line?.warnings.length ? (
                  <Tooltip title={line?.warnings.join(" ")}>
                    <WarningIcon
                      color="warning"
                      sx={{ verticalAlign: "middle" }}
                    />
                  </Tooltip>
                ) : null}
              </>
            )}
          />
          <DatagridActionsColumn label={<ErpSaleInvoiceToggleIgnoreButton />}>
            <EditErpSaleInvoiceLineButton />
          </DatagridActionsColumn>
        </Datagrid>
      </ArrayField>
    </Card>
  );
};

const ErpSaleInvoiceLineDisplay = () => {
  return (
    <Card variant="outlined" sx={{ ml: 5 }}>
      <ReferenceManyField
        reference="erp-sale-invoice-line-assignments"
        target="erp_sale_invoice_line_id"
        perPage={1000}
      >
        <Datagrid
          bulkActionButtons={false}
          empty={<AddErpSaleInvoiceLineAssignmentButton />}
        >
          <FunctionField<ErpSaleInvoiceLineAssignmentRead>
            label="Contract"
            render={(assignment?: ErpSaleInvoiceLineAssignmentRead) =>
              assignment ? (
                <Link
                  to={`/bike-benefit-contracts/${assignment.bike_benefit_contract_id}/show`}
                  target="_blank"
                >
                  {assignment.bike_benefit_contract_vapaus_code}
                </Link>
              ) : null
            }
          />
          <NumberField source="quantity" />
          <DateField source="billing_date" />
          <BooleanField source="is_manual" />
          <DatagridActionsColumn
            label={<AddErpSaleInvoiceLineAssignmentButton />}
          >
            <EditErpSaleInvoiceLineAssignmentButton />
            <DeleteErpSaleInvoiceLineAssignmentButton />
          </DatagridActionsColumn>
        </Datagrid>
      </ReferenceManyField>
    </Card>
  );
};
