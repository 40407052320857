import { AutocompleteInput, AutocompleteInputProps } from "react-admin";

/**
 * Simple wrapper for the AutocompleteInput component that allows null values.
 *
 * When a null value is provided by the data provider, the AutocompleteInput component will use an empty string instead.
 * Respectively, when the value is an empty string while saving, it will be turned into null instead.
 */
export const NullableAutocompleteInput = ({
  format = (value) => value,
  parse = (input) => input,
  ...props
}: AutocompleteInputProps) => (
  <AutocompleteInput
    {...props}
    format={(value) => format(value ?? "")}
    parse={(input) => parse(input === "" ? null : input)}
  />
);
