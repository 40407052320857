import Alert from "@mui/material/Alert";
import {
  EditRowButton,
  EditableDatagrid,
} from "@react-admin/ra-editable-datagrid";
import {
  DateField,
  DeleteWithConfirmButton,
  useRecordContext,
} from "react-admin";
import { BenefitDefinitionMonthlyOfficeFeeForm } from "resources/BenefitDefinition/components/BenefitDefinitionMonthlyOfficeFees/BenefitDefinitionMonthlyOfficeFeeForm";

import { CurrencyField } from "components";

export const BenefitDefinitionMonthlyOfficeFeeDatagrid = () => {
  const record = useRecordContext();
  return (
    <EditableDatagrid
      mutationMode="pessimistic"
      createForm={<BenefitDefinitionMonthlyOfficeFeeForm />}
      editForm={<BenefitDefinitionMonthlyOfficeFeeForm />}
      actions={<MonthlyOfficeFeeDatagridActions />}
    >
      <DateField source="valid_from" />
      <CurrencyField
        source="net_monthly_office_fee"
        currencySource="currency"
        currencyRecord={record}
        options={{
          maximumFractionDigits: 4,
        }}
      />
    </EditableDatagrid>
  );
};

const MonthlyOfficeFeeDatagridActions = () => {
  return (
    <>
      <EditRowButton />
      <DeleteWithConfirmButton
        redirect={false}
        confirmContent={
          <>
            Are you sure you want to delete this item?
            <Alert severity="warning" sx={{ mt: 2 }}>
              This office fee will be deleted from the benefit’s office fee
              history. Please be sure that you wish to proceed with this update.
            </Alert>
          </>
        }
      />
    </>
  );
};
