/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ErpSaleInvoiceLineType {
  DOWN_PAYMENT = "down_payment",
  DOWN_PAYMENT_INSURANCE = "down_payment_insurance",
  CANCELLATION_COVERAGE = "cancellation_coverage",
  EXTENDED_WARRANTY = "extended_warranty",
  INSURANCE_DEDUCTIBLE = "insurance_deductible",
  REDEMPTION_FEE = "redemption_fee",
  REDEMPTION_FEE_ACCESSORIES_ONLY = "redemption_fee_accessories_only",
  RETURN_FEE = "return_fee",
  MAINTENANCE = "maintenance",
  OTHER = "other",
  REFUND = "refund",
  OVERSPENT_MAINTENANCE_BUDGET = "overspent_maintenance_budget",
  EXCESS_MONTHLY_PAYMENT = "excess_monthly_payment",
  DELIVERY = "delivery",
  LATE_FEES = "late_fees",
  MONTHLY_PAYMENT = "monthly_payment",
  OFFICE_FEE = "office_fee",
  FINANCING = "financing",
  TRANSFER = "transfer",
}
