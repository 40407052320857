import React from "react";

import { Table, TableBody } from "@mui/material";
import {
  DateField,
  Link,
  RecordContextProvider,
  TextField,
  useCreatePath,
} from "react-admin";
import { RowItem } from "resources/BikeBenefitOrders/components/RowItem";

import { BenefitDefinitionRead } from "@vapaus/generated";

export const BenefitDefinitionDisplay = ({
  benefitDefinition,
}: {
  benefitDefinition?: BenefitDefinitionRead;
}) => {
  if (!benefitDefinition) return null;
  const createPath = useCreatePath();
  const resourceLinkPath = createPath({
    resource: "benefit-definitions",
    id: benefitDefinition.id,
    type: "edit",
  });
  return (
    <Table>
      <TableBody>
        <RecordContextProvider value={benefitDefinition}>
          <RowItem label="Organisation">
            <TextField source="organisation.name" />
          </RowItem>
          <RowItem label="Benefit">
            <Link to={resourceLinkPath as string}>
              {benefitDefinition.name}
            </Link>
          </RowItem>
          {benefitDefinition.to_whom ? (
            <RowItem label="To whom">
              <TextField source="to_whom" sx={{ whiteSpace: "pre-wrap" }} />
            </RowItem>
          ) : null}
          <RowItem label="Validity period">
            <DateField source="start_date" /> - <DateField source="end_date" />
          </RowItem>
        </RecordContextProvider>
      </TableBody>
    </Table>
  );
};
