import { useRecordContext } from "react-admin";

import { NullableTextInput as TextInput } from "components";

const UserPhoneInput = () => {
  const record = useRecordContext();

  const message = record?.phone_number
    ? `Phone number is - ${
        record?.phone_number_verified ? "Verified" : "Not verified"
      }`
    : "No phone number provided yet";

  return <TextInput source="phone_number" fullWidth helperText={message} />;
};

export default UserPhoneInput;
