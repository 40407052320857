import queryString from "query-string";
import { DataProvider } from "ra-core";

import { fetchJson } from "./fetchJson";

const MULTIPART_RESOURCES = ["benefit-definition-terms"];

const handleBody = (
  resource: string,
  data: Record<string, any>,
): FormData | string => {
  if (MULTIPART_RESOURCES.includes(resource)) {
    const body = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value?.rawFile) {
        value = value.rawFile;
      }
      return body.append(key, value);
    });
    return body;
  }

  return JSON.stringify(data);
};

const createDataProvider = (
  apiUrl: string,
  httpClient = fetchJson,
): DataProvider => ({
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const skip = (page - 1) * perPage;
    const query = {
      sort_field: field,
      sort_direction: order.toLowerCase(),
      skip,
      limit: perPage,
      ...params.filter,
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    const { json } = await httpClient(url);
    return {
      data: json.items,
      total: json.total,
    };
  },

  getOne: async (resource, params) => {
    const { json } = await httpClient(
      `${apiUrl}/${resource}/${params.id}` +
        (params.meta?.queryParams
          ? `?${queryString.stringify(params.meta.queryParams)}`
          : ""),
    );
    return {
      data: json,
    };
  },

  getMany: async (resource, params) => {
    const query = { limit: params.ids.length };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
    const { json } = await httpClient(url, {
      headers: new Headers({
        "X-Filter-Ids": JSON.stringify(params.ids),
      }),
    });
    return {
      data: json.items,
      total: json.total,
    };
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const skip = (page - 1) * perPage;

    const query = {
      sort_field: field,
      sort_direction: order.toLowerCase(),
      skip,
      limit: perPage,
      [params.target]: params.id,
      ...params.filter,
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      return {
        data: json.items,
        total: json.total,
      };
    });
  },

  update: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: handleBody(resource, params.data),
    });
    return {
      data: json,
    };
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: async (resource, params) => {
    const responses = await Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }),
      ),
    );
    return { data: responses.map(({ json }) => json.id) };
  },

  create: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: handleBody(resource, params.data),
    });
    return {
      data: json,
    };
  },

  delete: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "text/plain",
      }),
    });
    return {
      data: json,
    };
  },
  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: async (resource, params) => {
    const responses = await Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "text/plain",
          }),
        }),
      ),
    );
    return {
      data: responses.map(({ json }) => json.id),
    };
  },
});

export default createDataProvider;
