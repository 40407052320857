import { Edit, useRecordContext } from "react-admin";

import { EditBikeForm } from "./components/EditBikeForm";
import { useCustomMutationOptions } from "hooks";

const BikeTitle = () => {
  const record = useRecordContext();
  return <span>Bike: {record?.serial_number}</span>;
};

export const BikeEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<BikeTitle />}
    >
      <EditBikeForm />
    </Edit>
  );
};
