import { FC, useMemo } from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {
  ChipField,
  DateField,
  FunctionField,
  NumberField,
  RaRecord,
  RecordContextProvider,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextInput,
  useGetOne,
  useRecordContext,
} from "react-admin";

import {
  BenefitCancellationCoverage,
  SupportedCountriesEnum,
  SwedenSuggestedBenefitValuesCalculation,
} from "@vapaus/generated";
import { toGross, toNet } from "@vapaus/utils";

import { RowItem } from "./RowItem";
import { CurrencyField, CurrencyGrossField } from "components";
import { useEnum, useVapausQuery } from "hooks";

export const ContractDetails: FC = () => {
  const record = useRecordContext();
  const isSwedishContract =
    record?.benefit_country === SupportedCountriesEnum.SE;

  const { data: order } = useGetOne("bike-benefit-orders", {
    id: record.bike_benefit_order_id,
  });
  const cancellationCoverages = useEnum("BenefitCancellationCoverage");

  const otherContractsInfo: RaRecord<string> = {
    id: "otherContractsInfo",
    other_contract_ids: order?.bike_benefit_contract_ids
      ? order.bike_benefit_contract_ids.filter(
          (contractId: string) => contractId != record.id,
        )
      : [],
  };

  const { data: swedenSuggestedBenefitValues } =
    useVapausQuery<SwedenSuggestedBenefitValuesCalculation>(
      "getSwedenSuggestedBenefitValuesForContract",
      record.id,
      { enabled: isSwedishContract },
    );

  const infoRecord = useMemo(
    () => ({
      currency: record.currency,
      total_package_price:
        record.initial_total_package_price -
        toGross(
          toNet(
            record.taxable_package_price_reduction,
            record.current_flat_vat_rate,
          ),
          record.initial_vat_rate,
        ),
    }),
    [record],
  );

  return (
    <Card variant="outlined">
      <Table aria-label="contract details">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <div className="table-header-title">
                <ShoppingCartIcon sx={{ mr: 2 }} />{" "}
                <Typography component="span">Contract Details</Typography>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.corrected_by_bike_benefit_order_ids.length ? (
            <RowItem label="Corrected by Bike Benefit Order">
              <ReferenceArrayField
                source="corrected_by_bike_benefit_order_ids"
                reference="bike-benefit-orders"
              >
                <SingleFieldList
                  linkType="show"
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ChipField source="vapaus_code" />
                </SingleFieldList>
              </ReferenceArrayField>
            </RowItem>
          ) : null}
          {record.revised_by_bike_benefit_order_ids.length ? (
            <RowItem label="Revised by Bike Benefit Order">
              <ReferenceArrayField
                source="revised_by_bike_benefit_order_ids"
                reference="bike-benefit-orders"
              >
                <SingleFieldList
                  linkType="show"
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ChipField source="vapaus_code" />
                </SingleFieldList>
              </ReferenceArrayField>
            </RowItem>
          ) : null}
          <RecordContextProvider value={order}>
            {order?.corrects_bike_benefit_contract_id && (
              <RowItem label="Corrects Bike Benefit Contract">
                <ReferenceField
                  source="corrects_bike_benefit_contract_id"
                  reference="bike-benefit-contracts"
                  link={`/bike-benefit-contracts/${order?.corrects_bike_benefit_contract_id}/show`}
                >
                  <ChipField source="vapaus_code" />
                </ReferenceField>
              </RowItem>
            )}
            {order?.revises_bike_benefit_contract_id && (
              <RowItem label="Revises Bike Benefit Contract">
                <ReferenceField
                  source="revises_bike_benefit_contract_id"
                  reference="bike-benefit-contracts"
                  link={`/bike-benefit-contracts/${order.revises_bike_benefit_contract_id}/show`}
                >
                  <ChipField source="vapaus_code" />
                </ReferenceField>
              </RowItem>
            )}
            {!!otherContractsInfo.other_contract_ids.length && (
              <RowItem label="Other contracts of this order">
                <ReferenceArrayField
                  record={otherContractsInfo}
                  source="other_contract_ids"
                  reference="bike-benefit-contracts"
                >
                  <SingleFieldList
                    linkType="show"
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <ChipField source="state" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </RowItem>
            )}
          </RecordContextProvider>
          <RowItem label="Contract Start Date">
            <DateField source="start_date" />
          </RowItem>
          <RowItem label="Contract Planned End Date">
            <DateField source="end_date" />
          </RowItem>
          <RowItem
            label={
              <Tooltip title="Bike package + tax free options. Please note that bike package may include down payment and tax-free options may include maintenance budget.">
                <span>Total Package Price&nbsp;&#9432;</span>
              </Tooltip>
            }
          >
            <CurrencyGrossField
              record={infoRecord}
              source="total_package_price"
              vatRate={record.initial_vat_rate}
              currencySource="currency"
            />
          </RowItem>
          <RowItem label="Leasing Period">
            <NumberField
              source="leasing_period_months"
              options={{ style: "unit", unit: "month" }}
            />
          </RowItem>
          {record.fixed_monthly_taxable_value ? (
            <>
              <RowItem label="Fixed Monthly Taxable Value">
                <CurrencyGrossField
                  source="fixed_monthly_taxable_value"
                  currencySource="currency"
                  vatRate={record.current_flat_vat_rate}
                />
              </RowItem>
              <RowItem label="Calculated Monthly Taxable Value">
                <CurrencyGrossField
                  source="monthly_taxable_value"
                  currencySource="currency"
                  vatRate={record.current_flat_vat_rate}
                />
              </RowItem>
            </>
          ) : (
            <RowItem label="Monthly Taxable Value">
              <CurrencyGrossField
                source="monthly_taxable_value"
                currencySource="currency"
                vatRate={record.current_flat_vat_rate}
              />
            </RowItem>
          )}
          {isSwedishContract ? (
            <>
              <RowItem label="Gross salary deduction">
                <CurrencyField
                  record={swedenSuggestedBenefitValues}
                  source="suggested_salary_deduction"
                  currencySource="currency"
                  currencyRecord={record}
                />
              </RowItem>
              <RowItem label="Benefit value">
                <CurrencyField
                  record={swedenSuggestedBenefitValues}
                  source="monthly_benefit_value_after_tax_deduction"
                  currencySource="currency"
                  currencyRecord={record}
                />
              </RowItem>
            </>
          ) : null}
          <RowItem label="Monthly Leasing Fee">
            <CurrencyGrossField
              source="monthly_leasing_fee"
              currencySource="currency"
              vatRate={record.current_flat_vat_rate}
            />
          </RowItem>
          <RowItem label="Monthly Maintenance Budget">
            <CurrencyField
              source="monthly_maintenance_budget"
              currencySource="currency"
            />
          </RowItem>
          <RowItem label="Down Payment Amount">
            <CurrencyField
              source="down_payment_amount"
              currencySource="currency"
            />
          </RowItem>
          <RowItem label="Down Payment Insurance">
            {record.yearly_down_payment_insurance_fee !== null ? (
              <CurrencyField
                source="yearly_down_payment_insurance_fee"
                currencySource="currency"
              />
            ) : (
              "NO"
            )}
          </RowItem>
          <RowItem label="Extended Warranty">
            {record.extended_warranty ? (
              <CurrencyField
                source="extended_warranty"
                currencySource="currency"
              />
            ) : (
              "NO"
            )}
          </RowItem>
          <RowItem label="Cancellation Coverage">
            <FunctionField
              render={() =>
                cancellationCoverages.find(
                  (cc: any) => record?.cancellation_coverage === cc.value,
                )?.label ||
                record?.cancellation_coverage ||
                "-"
              }
            />
            {record?.cancellation_coverage &&
              record?.cancellation_coverage !==
                BenefitCancellationCoverage.NO && (
                <ChipField source="cancellation_coverage_status" />
              )}
          </RowItem>
          <RowItem label="Employee-paid Cancellation Coverage">
            {record?.cancellation_coverage ===
            BenefitCancellationCoverage.EMPLOYEE ? (
              <ReferenceField
                source="benefit_definition_id"
                reference="benefit-definitions"
                link={false}
              >
                <CurrencyField
                  source="individual_onetime_cancellation_coverage"
                  currencySource="currency"
                />
              </ReferenceField>
            ) : (
              "NO"
            )}
          </RowItem>
          <TableRow>
            <TableCell colSpan={2}>
              <TextInput multiline source="notes" fullWidth />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};
