import { ReactElement } from "react";

import {
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

import { VehicleType } from "@vapaus/generated";

import { VEHICLE_TYPE_CHOICES } from "core";
import { useEnumChoices } from "hooks";

export const VehicleForm = ({
  toolbar,
  showIsActive,
}: {
  toolbar?: ReactElement;
  showIsActive?: boolean;
}) => {
  const lockTypes = useEnumChoices("LockType");
  return (
    <SimpleForm toolbar={toolbar}>
      <TextInput source="name" required fullWidth />
      <SelectInput
        source="vehicle_type"
        choices={VEHICLE_TYPE_CHOICES}
        required
        fullWidth
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.vehicle_type === VehicleType.BIKE ? (
            <>
              <ReferenceInput
                source="mapped_id"
                reference="bikes"
                filter={{ status: "in_storage" }}
              >
                <AutocompleteInput
                  label="Bike"
                  optionText={(bike) =>
                    `${bike.bike_brand.name} ${bike.model} (${bike.serial_number})`
                  }
                  optionValue="id"
                  validate={required()}
                  fullWidth
                />
              </ReferenceInput>
              <SelectInput source="lock_type" choices={lockTypes} fullWidth />
              <TextInput label="Lock ID" source="lock_id" fullWidth />
            </>
          ) : formData.vehicle_type === VehicleType.CAR ? (
            <ReferenceInput source="mapped_id" reference="cars">
              <AutocompleteInput
                label="Car"
                optionText={(car) =>
                  `${car.manufacturer} ${car.model} (${car.licence_plate})`
                }
                optionValue="id"
                validate={required()}
                fullWidth
              />
            </ReferenceInput>
          ) : formData.vehicle_type === VehicleType.SCOOTER ? (
            <ReferenceInput source="mapped_id" reference="scooters">
              <AutocompleteInput
                label="Scooter"
                optionText={(scooter) =>
                  `${scooter.manufacturer} ${scooter.model} (${
                    scooter.serial_number || "no serial number"
                  })`
                }
                optionValue="id"
                validate={required()}
                fullWidth
              />
            </ReferenceInput>
          ) : null
        }
      </FormDataConsumer>
      <TextInput source="how_to_use" multiline fullWidth />
      {showIsActive && <BooleanInput source="is_active" fullWidth />}
    </SimpleForm>
  );
};
