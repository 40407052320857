import { FC } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import StepConnector from "@mui/material/StepConnector";
import Typography from "@mui/material/Typography";
import StyledBooleanField from "components/StyledBooleanField";
import { ReferenceField, TextField, useRecordContext } from "react-admin";

import { useVapausQuery } from "hooks";

export const OrderUser: FC = () => {
  const record = useRecordContext();

  const { data: emails, isLoading } = useVapausQuery(
    "searchUserEmails",
    {
      user_id: record.user_id,
      q: record?.user?.email,
    },
    {
      enabled: !!record?.user_id,
    },
  );

  const userPrimaryEmail = ((emails as any)?.data?.items || []).find(
    (email: any) => email.email === record?.user?.email,
  );

  if (isLoading) return null;

  return (
    <Card variant="outlined">
      <CardContent>
        <ReferenceField source="user.id" reference="users">
          <TextField
            source="full_name"
            variant="h5"
            component="h5"
            sx={{ mb: 1 }}
            textTransform="capitalize"
          />
        </ReferenceField>
        <Typography variant="body2" mb={0.5}>
          <strong>Email: </strong> <TextField source="user.email" />{" "}
          <StyledBooleanField
            record={userPrimaryEmail}
            source="email_verified"
          />
        </Typography>
        {record?.user?.phone_number && (
          <Typography variant="body2" mb={0.5}>
            <strong>Phone: </strong> <TextField source="user.phone_number" />{" "}
            <StyledBooleanField source="user.phone_number_verified" />
          </Typography>
        )}
        <Typography variant="body2" mb={0.5}>
          <strong>Language: </strong> <TextField source="user.language" />
        </Typography>
        <Typography variant="body2" mb={0.5}>
          <strong>Cost center: </strong> <TextField source="user.cost_center" />
        </Typography>
        <Typography variant="body2" mb={0.5}>
          <strong>Employee ID: </strong>{" "}
          <TextField source="user.employee_number" />
        </Typography>
        <Typography variant="body2" mb={0.5}>
          <strong>Company: </strong> <TextField source="user.company" />
        </Typography>

        <Typography component="div" sx={{ mt: 1.5 }}>
          Address
        </Typography>
        <StepConnector sx={{ mb: 1, mt: 1 }} />
        <Typography variant="body2" mb={0.5}>
          <strong>Address: </strong> <TextField source="user.address" />
        </Typography>
        <Typography variant="body2" mb={0.5}>
          <strong>City: </strong> <TextField source="user.city" />
        </Typography>
        <Typography variant="body2" mb={0.5}>
          <strong>Postcode: </strong> <TextField source="user.post_code" />
        </Typography>
        <Typography variant="body2" mb={0.5}>
          <strong>Country: </strong> <TextField source="user.country" />
        </Typography>
      </CardContent>
    </Card>
  );
};
