import { EditInDialogButton } from "@react-admin/ra-form-layout";

import { BillingDetailsForm } from "./BillingDetailsForm";
import { EditToolbar } from "components";

export const BillingDetailsEditButton = () => {
  return (
    <EditInDialogButton
      fullWidth
      maxWidth="md"
      title={`Edit billing details`}
      mutationMode="pessimistic"
    >
      <BillingDetailsForm toolbar={<EditToolbar />} />
    </EditInDialogButton>
  );
};
