import ReplyIcon from "@mui/icons-material/Reply";
import { ResourceProps } from "react-admin";
import { ReturnProcessShow } from "resources/ReturnProcess/ReturnProcessShow";

import { ReturnProcessList } from "./ReturnProcessList";

const returnProcessResource: ResourceProps = {
  name: "return-processes",
  icon: ReplyIcon,
  list: ReturnProcessList,
  show: ReturnProcessShow,
  options: {
    label: "Return Processes",
  },
};

export default returnProcessResource;
