import { FC, useState } from "react";

import { Grid } from "@mui/material";
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SimpleForm,
  useRecordContext,
} from "react-admin";

import { InvoiceEditToolbar } from "./EditInvoiceToolBar";
import { InvoiceSummary } from "./InvoiceSummary";
import ManageInvoiceLines, { InvoiceLine } from "./ManageInvoiceLines";

export const EditInvoiceForm: FC<any> = (props) => {
  const record = useRecordContext();

  const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>(
    record.sale_invoice_lines.map((line: any, index: number) => ({
      id: index,
      line,
    })),
  );

  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={<InvoiceEditToolbar />}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <InvoiceSummary invoice={record as any} />
        </Grid>

        <Grid item xs={12} sm={7}>
          <Grid item md={12}>
            <DateInput source="date" fullWidth disabled />
            <DateInput source="due_date" fullWidth disabled />

            <ReferenceInput source="customer_user_id" reference="users">
              <AutocompleteInput
                label="User"
                optionText="email"
                optionValue="id"
                readOnly
                fullWidth
                isRequired
                disabled
              />
            </ReferenceInput>
          </Grid>
          <Grid item md={12}>
            <ManageInvoiceLines
              currency={record.currency}
              updateLines={(values: InvoiceLine[]) => setInvoiceLines(values)}
              data={invoiceLines}
              readOnly
            />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
