import { FC } from "react";

import { Box, Grid } from "@mui/material";
import { Edit, SimpleForm, TextField, useRecordContext } from "react-admin";
import { ErpSaleInvoiceLineAssignments } from "resources/BikeBenefitContracts/components/ErpSaleInvoiceLineAssignments";
import { PurchaseInvoices } from "resources/BikeBenefitOrders/components/PurchaseInvoices";

import { Accessories } from "../BikeBenefitOrders/components/Accessories";
import { AdditionalServices } from "../BikeBenefitOrders/components/AdditionalServices";
import { BikeInfo } from "../BikeBenefitOrders/components/BikeInfo";
import { OrderUser } from "../BikeBenefitOrders/components/OrderUser";
import { ContractContents } from "./components/ContractContents";
import { ContractDetails } from "./components/ContractDetails";
import ContractMenu from "./components/ContractMenu";
import { ContractStatus } from "./components/ContractStatus";
import { ContractSummary } from "./components/ContractSummary";
import { ContractTotal } from "./components/ContractTotal";
import { FinancingDetails } from "./components/FinancingDetails";
import { MaintenanceHistory } from "./components/MaintenanceHistory";
import "./styles.scss";
import { EditToolbar, RelatedSaleInvoices, Separator } from "components";

const ContractTitle = () => {
  const record = useRecordContext();
  return <span>Vapaus Code: {record?.vapaus_code}</span>;
};

export const ContractShow: FC = () => {
  return (
    <Edit
      title={<ContractTitle />}
      actions={false}
      mutationMode="pessimistic"
      redirect={false}
    >
      <SimpleForm toolbar={<EditToolbar />} warnWhenUnsavedChanges>
        <Grid container columnSpacing={2} className="benefit-bike-contract">
          <Grid item xs={12} mb={4}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <TextField source="vapaus_code" variant="h4" />
              <ContractMenu />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <ContractDetails />
            <Separator />
            <FinancingDetails />
            <Separator />
            <MaintenanceHistory>
              <Separator />
            </MaintenanceHistory>
            <ContractContents />
            <Separator />
            <BikeInfo />
            <Accessories />
            <AdditionalServices />
            <Separator />
            <PurchaseInvoices idField="bike_benefit_contract_id" />
            <ErpSaleInvoiceLineAssignments />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <ContractStatus />
            <Separator />
            <OrderUser />
            <Separator />
            <ContractSummary />
            <Separator />
            <ContractTotal />
            <Separator />
            <RelatedSaleInvoices target="bike_benefit_contract_id" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
