import { ReactElement } from "react";

import { TextListInput } from "components/TextListInput";
import { SimpleForm, TextInput } from "react-admin";

type FleetAccessRulesFormProps = {
  toolbar?: ReactElement;
};

export const FleetAccessRulesForm = ({
  toolbar,
}: FleetAccessRulesFormProps) => {
  return (
    <SimpleForm toolbar={toolbar}>
      <TextInput source="name" label="Name" fullWidth required />
      <TextListInput
        source="allowed_email_addresses"
        label="Allowed email addresses"
        helperText="Separate with comma"
      />
      <TextListInput
        source="allowed_codes"
        label="Allowed codes"
        helperText="Separate with comma"
      />
      <TextListInput
        source="blacklist_emails"
        label="Blacklisted emails"
        helperText="Separate with comma"
      />
    </SimpleForm>
  );
};
