import React from "react";

import { Create } from "react-admin";

import ShopForm from "./ShopForm";
import { useCustomMutationOptions } from "hooks";

export const ShopCreate = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Create mutationOptions={mutationOptions} redirect="list">
      <ShopForm />
    </Create>
  );
};
