export async function sequentialPromiseAll<ElementType, PromisedReturnType>(
  items: ElementType[],
  functor: (item: ElementType) => Promise<PromisedReturnType>,
): Promise<PromisedReturnType[]> {
  // source: https://medium.com/@dragossebestin/functional-way-of-executing-an-array-of-promises-sequentially-1916fe3b31b2
  return items.reduce(
    (promiseChain, item) =>
      promiseChain.then((resultsSoFar) =>
        functor(item).then((currentResult) => [...resultsSoFar, currentResult]),
      ),
    Promise.resolve<PromisedReturnType[]>([]),
  );
}
