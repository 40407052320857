import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReferenceField, useRecordContext } from "react-admin";

export const VehicleReferenceField = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <ReferenceField source="mapped_id" reference={record?.vehicle_type + "s"}>
      <VisibilityIcon />
    </ReferenceField>
  );
};
