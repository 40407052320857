import { FC } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HandymanIcon from "@mui/icons-material/Handyman";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Datagrid,
  NumberField,
  TextField,
  useRecordContext,
} from "react-admin";

export const AdditionalServices: FC = () => {
  const { additional_services, currency } = useRecordContext();
  if (additional_services?.length === 0) return null;
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel-additional-services-content"
        id="panel-additional-services-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <HandymanIcon sx={{ mr: 2 }} />{" "}
        <Typography>Additional Services</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Datagrid
          data={additional_services}
          sort={{ field: "purchase_date", order: "ASC" }}
        >
          <TextField source="service_type" textTransform="uppercase" />
          <TextField source="description" />
          <NumberField
            label="Price"
            source="price_gross"
            options={{ style: "currency", currency }}
          />
          <NumberField
            source="vat_rate"
            label="VAT rate"
            options={{ style: "percent", maximumFractionDigits: 1 }}
          />
        </Datagrid>
      </AccordionDetails>
    </Accordion>
  );
};
