import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useCalculatePeriod } from "hooks/useCalculatePeriod";
import { isEmpty, pick } from "lodash-es";
import { DateTime } from "luxon";
import {
  AutocompleteInput,
  DateInput,
  Form,
  HttpError,
  NumberField,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  required,
  useDataProvider,
  useGetOne,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { getMaintenance } from "utils/getMaintenance";

import { ErrorCode, toNet } from "@vapaus/utils";

import { ContractMenuModalProps } from "./ContractMenu";
import { RowItem } from "./RowItem";
import { useCustomMutationOptions, useVapausMutation } from "hooks";

const contractToRevisionOrder = (
  contract: any,
  user_id: string,
  benefit_definition_id: string,
  benefit_definition_terms_id: string,
  revision_date: string,
  monthly_maintenance_budget: string,
  leasing_period_months: string,
  down_payment_amount: string,
  fixed_monthly_taxable_value: string | null,
) => {
  let { id: _, ...delivery_transport } = contract.delivery_transport ?? {};
  delivery_transport = isEmpty(delivery_transport)
    ? null
    : pick(delivery_transport, [
        "expected_delivery_date",
        "price_gross",
        "tracking_url",
        "vat_rate",
      ]);

  return {
    // Specific order revision fields
    benefit_definition_id,
    benefit_definition_terms_id,
    user_id,
    revises_bike_benefit_contract_id: contract.id,
    revision_apply_date: revision_date,
    monthly_maintenance_budget: monthly_maintenance_budget,
    leasing_period_months: leasing_period_months,
    // Usual order fields
    order_number: contract.order_number,
    fixed_monthly_taxable_value: fixed_monthly_taxable_value,
    fixed_down_payment_amount: down_payment_amount,
    has_extended_warranty: contract.has_extended_warranty,
    is_down_payment_insured: contract.is_down_payment_insured,
    bike_id: contract.bike.id,
    net_bike_price: toNet(contract.bike_price, contract.initial_vat_rate),
    accessories: contract.accessories,
    additional_services:
      contract.additional_services?.map(({ id, ...as }: any) => as) ?? [],
    delivery_transport,
  };
};

export const TransferToEmployeeModal: FC<ContractMenuModalProps> = ({
  onClose,
  open,
}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [isLoadingTerms, setIsLoadingTerms] = useState(false);
  const redirect = useRedirect();
  const { onError } = useCustomMutationOptions();
  const notify = useNotify();

  const { mutate, isLoading, queryClient } = useVapausMutation(
    "contractCreateOrderRevision",
  );
  const [isCalculationSuccessful, setIsCalculationSuccessful] = useState(false);

  const { data: benefitDefinition } = useGetOne("benefit-definitions", {
    id: record.benefit_definition_id,
  });

  const handleSubmit = async (contract: any) => {
    if (isLoading || isLoadingTerms) return;
    if (contract.user_id === contract.updated_user_id) {
      notify("Please select a different user than the current one", {
        type: "warning",
      });
      return;
    }

    try {
      setIsLoadingTerms(true);
      // We get the the benefit definition terms for the case where there is a new one since
      // the one used in the current contract
      const { data: terms } = await dataProvider.getList(
        "benefit-definition-terms",
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "DESC" },
          filter: {
            benefit_definition_id: contract.updated_benefit_definition_id,
          },
        },
      );
      const term = terms.find((t) => t.is_current);
      if (!term) {
        notify("No current benefit definition terms found", {
          type: "warning",
        });
        setIsLoadingTerms(false);
        return;
      }

      const order = contractToRevisionOrder(
        contract,
        contract.updated_user_id,
        contract.updated_benefit_definition_id,
        term.id,
        contract.revision_date,
        contract.updated_monthly_maintenance_budget,
        contract.updated_leasing_period_months,
        contract.updated_fixed_down_payment_amount,
        contract.updated_fixed_monthly_taxable_value,
      );

      mutate(order, {
        onSuccess: ({ data: order }: any) => {
          notify("Transfer order created successfully");
          queryClient.invalidateQueries(["bike-benefit-contracts"]);
          queryClient.invalidateQueries(["bike-benefit-orders"]);
          onClose();
          redirect(`/bike-benefit-orders/${order.id}/show`);
        },
        onError,
        onSettled: () => setIsLoadingTerms(false),
      });
    } catch (error) {
      onError(error);
    }
    setIsLoadingTerms(false);
  };

  const maintenances = getMaintenance(record.currency);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Transfer contract to another employee"
    >
      <DialogTitle>Transfer contract to another employee</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{
          updated_user_id: record.user_id,
          updated_benefit_definition_id: record.benefit_definition_id,
          revision_date: DateTime.now().toISODate(),
          updated_monthly_maintenance_budget: record.monthly_maintenance_budget,
          updated_fixed_down_payment_amount: 0,
          updated_fixed_monthly_taxable_value:
            record.fixed_monthly_taxable_value,
        }}
      >
        <DialogContent>
          <ReferenceInput source="updated_user_id" reference="users">
            <AutocompleteInput
              label="Users"
              optionText={(option: any) =>
                `${option.full_name || ""} (${option.email})`
              }
              optionValue="id"
              validate={required()}
            />
          </ReferenceInput>
          {benefitDefinition && !benefitDefinition.active && (
            <ReferenceInput
              source="updated_benefit_definition_id"
              reference="benefit-definitions"
              filter={{
                only_active: true,
                organisation_id: benefitDefinition.organisation_id,
              }}
            >
              <AutocompleteInput
                label="Benefit definition"
                optionText={(option: any) =>
                  `${option.organisation?.name || ""} (${option.name})`
                }
                optionValue="id"
                validate={required()}
              />
            </ReferenceInput>
          )}
          <SelectInput
            source="updated_monthly_maintenance_budget"
            label="New monthly maintenance budget"
            choices={maintenances}
            validate={required()}
            fullWidth
          />
          <DateInput source="revision_date" label="Transfer date" fullWidth />
          <LeasingPeriodInput
            contract={record}
            setIsCalculationSuccessful={setIsCalculationSuccessful}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isLoading}
            startIcon={<CancelIcon />}
          >
            {translate("ra.action.cancel")}
          </Button>
          <SaveButton
            label="Transfer contract to employee"
            disabled={isLoading || isLoadingTerms || !isCalculationSuccessful}
            alwaysEnable={!isLoading && isCalculationSuccessful}
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

type PrevFormRef = {
  prevBenefitDefinitionId?: string;
  prevMonthlyMaintenanceBudget?: string;
  prevLeasingPeriodMonths?: string;
  prevRevisionApplyDate?: string;
};

const LeasingPeriodInput = ({
  contract,
  setIsCalculationSuccessful,
}: {
  contract: any;
  setIsCalculationSuccessful: Dispatch<SetStateAction<boolean>>;
}) => {
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const values = watch();
  const prevFormRef = useRef<PrevFormRef>({});
  const notify = useNotify();
  const { data: benefitDefinition, isLoading: isDefinitionLoading } = useGetOne(
    "benefit-definitions",
    {
      id: values.updated_benefit_definition_id,
    },
  );
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: contract.currency,
  }).format;
  const hasFixedMonthlyTaxableValue =
    !!benefitDefinition?.fixed_monthly_taxable_values?.length;
  const fixedMonthlyTaxableValues = hasFixedMonthlyTaxableValue
    ? benefitDefinition.fixed_monthly_taxable_values.map((value: number) => ({
        id: value,
        name: currencyFormatter(value),
      }))
    : [];
  useEffect(() => {
    if (
      (hasFixedMonthlyTaxableValue &&
        !benefitDefinition.fixed_monthly_taxable_values.includes(
          values.updated_fixed_monthly_taxable_value,
        )) ||
      (!hasFixedMonthlyTaxableValue &&
        values.updated_fixed_monthly_taxable_value)
    ) {
      setValue(
        "updated_fixed_monthly_taxable_value",
        benefitDefinition?.fixed_monthly_taxable_values[0] || null,
      );
    }
  }, [
    benefitDefinition,
    hasFixedMonthlyTaxableValue,
    values.updated_fixed_monthly_taxable_value,
  ]);
  const params = {
    plan: benefitDefinition?.plan,
    leasing_period_months: values.updated_leasing_period_months,
    benefit_definition_id: benefitDefinition?.id,
    monthly_maintenance_budget: values.updated_monthly_maintenance_budget,
    total_package_price:
      contract.total_package_price - contract.total_maintenance_budget,
    contract_revision: {
      revision_apply_date: values.revision_date,
      revised_contract_id: contract.id,
    },
    fixed_monthly_taxable_value: fixedMonthlyTaxableValues
      ? values.updated_fixed_monthly_taxable_value
      : null,
  };

  const {
    current: {
      prevBenefitDefinitionId,
      prevMonthlyMaintenanceBudget,
      prevLeasingPeriodMonths,
      prevRevisionApplyDate,
    },
  } = prevFormRef;

  // in some case we remove the leasing period months from the params to call the shortest period endpoint instead of the fixed period endpoint
  if (
    prevBenefitDefinitionId !== params.benefit_definition_id ||
    prevMonthlyMaintenanceBudget !== params.monthly_maintenance_budget ||
    prevRevisionApplyDate !== params.contract_revision.revision_apply_date ||
    hasFixedMonthlyTaxableValue
  ) {
    delete params.leasing_period_months;
  }

  const [calculateError, setCalculateError] = useState<HttpError>();
  const calculate = useCalculatePeriod(params, {
    enabled:
      !isDefinitionLoading &&
      !!benefitDefinition &&
      ((prevLeasingPeriodMonths &&
        prevLeasingPeriodMonths !== params.leasing_period_months) ||
        prevBenefitDefinitionId !== params.benefit_definition_id ||
        prevMonthlyMaintenanceBudget !== params.monthly_maintenance_budget ||
        prevRevisionApplyDate !== params.contract_revision.revision_apply_date),
    retry: false,
    keepPreviousData: true,
    onSuccess(data) {
      setCalculateError(undefined);
      setValue("updated_leasing_period_months", data.leasing_period_months);
      setValue("updated_fixed_down_payment_amount", data.down_payment_amount);
    },
    onError(error) {
      setCalculateError(error);
      if (
        error.body?.code === ErrorCode.CALCULATION_MAINTENANCE_BUDGET_TOO_HIGH
      ) {
        setValue("updated_monthly_maintenance_budget", 0);
        notify(
          "The selected maintenance budget is too high. It has been removed.",
        );
      }
    },
    onSettled(data) {
      prevFormRef.current.prevBenefitDefinitionId =
        params.benefit_definition_id;
      prevFormRef.current.prevMonthlyMaintenanceBudget =
        params.monthly_maintenance_budget;
      prevFormRef.current.prevLeasingPeriodMonths = data.leasing_period_months;
      prevFormRef.current.prevRevisionApplyDate =
        params.contract_revision.revision_apply_date;
    },
  });

  useEffect(() => {
    setIsCalculationSuccessful(!calculate.isLoading && calculate.isSuccess);
  }, [calculate.isLoading, calculate.isSuccess]);

  const infoRecord = useMemo(
    () => ({
      monthlyTaxableValue:
        values.updated_fixed_monthly_taxable_value ||
        calculate.data?.monthly_taxable_value,
      downPaymentAmount: calculate.data?.down_payment_amount,
      isSuccess: calculate.isSuccess,
    }),
    [
      values.updated_fixed_monthly_taxable_value,
      calculate.data?.monthly_taxable_value,
      calculate.data?.down_payment_amount,
      calculate.isSuccess,
    ],
  );

  return (
    <>
      {fixedMonthlyTaxableValues.length > 1 && (
        <SelectInput
          source="updated_fixed_monthly_taxable_value"
          label="New monthly payment"
          choices={fixedMonthlyTaxableValues}
          validate={required()}
          fullWidth
        />
      )}
      <NumberInput
        source="updated_leasing_period_months"
        label="Leasing period"
        fullWidth
        disabled={hasFixedMonthlyTaxableValue}
      />
      {calculateError?.message && (
        <Alert severity="error">{calculateError.message}</Alert>
      )}
      <Table>
        <TableBody>
          <RowItem label="New monthly taxable value" fullWidth>
            <NumberField
              record={infoRecord}
              source="monthlyTaxableValue"
              options={{ style: "currency", currency: contract.currency }}
            />
          </RowItem>
          {infoRecord.isSuccess && infoRecord.downPaymentAmount > 0 && (
            <TableRow>
              <TableCell colSpan={2}>
                <Typography>
                  A{" "}
                  <NumberField
                    record={infoRecord}
                    source="downPaymentAmount"
                    options={{ style: "currency", currency: contract.currency }}
                  />{" "}
                  down payment invoice will be automatically created for this
                  transfer order
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};
