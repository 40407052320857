import { Create, useDataProvider } from "react-admin";

import {
  AccessoryCreateUpdate,
  AccessoryStatus,
  AdminBikeBenefitOrderCreateUpdate,
  BenefitDefinitionReadWithOrganisationSummary,
} from "@vapaus/generated";

import { OrderForm } from "./components/OrderForm/OrderForm";
import { useCustomMutationOptions } from "hooks";

export const OrderCreate = () => {
  const mutationOptions = useCustomMutationOptions();
  const dataProvider = useDataProvider();
  const orderCreateTransform = (data: AdminBikeBenefitOrderCreateUpdate) => {
    return dataProvider
      .getOne<BenefitDefinitionReadWithOrganisationSummary>(
        "benefit-definitions",
        { id: data.benefit_definition_id },
      )
      .then((benefitDefinition) => {
        return {
          ...data,
          bike: data.bike
            ? {
                ...data.bike,
                shop_id: data.shop_id,
              }
            : undefined,
          accessories: data.accessories.map(
            (accessory: AccessoryCreateUpdate) => ({
              ...accessory,
              shop_id: data.shop_id,
              purchase_currency:
                accessory.purchase_currency ?? benefitDefinition.data.currency,
              status: accessory.status ?? AccessoryStatus.BENEFIT_BIKE,
            }),
          ),
        };
      });
  };
  return (
    <Create
      mutationOptions={mutationOptions}
      redirect="show"
      record={{
        monthly_maintenance_budget: 0,
        down_payment_amount: 0,
        is_billable: true,
      }}
      transform={orderCreateTransform}
    >
      <OrderForm />
    </Create>
  );
};
