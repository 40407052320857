import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { ResourceProps } from "react-admin";

import { ErpSaleInvoiceList } from "./ErpSaleInvoiceList";

const erpSaleInvoiceResource: ResourceProps = {
  name: "erp-sale-invoices",
  icon: ChecklistRtlIcon,
  list: ErpSaleInvoiceList,
  options: {
    label: "ERP Sale Invoices",
  },
};

export default erpSaleInvoiceResource;
