import { FC } from "react";

import { Grid } from "@mui/material";
import {
  AutocompleteInput,
  DateInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";

import BikeContracts from "./BikeContracts";
import BikeFleets from "./BikeFleets";
import { BikeMaintenanceHistory } from "./BikeMaintenanceHistory";
import BikeOrders from "./BikeOrders";
import {
  EditToolbar,
  MoneyInput,
  RelatedErpSaleInvoiceLineAssignments,
  SectionTitle,
} from "components";
import {
  BIKE_CONDITION_CHOICES,
  BIKE_DRIVE_CHOICES,
  BIKE_FRAME_TYPE_CHOICES,
  BIKE_STATUS_CHOICES,
} from "core";

const validateRequired = [required()];

export const EditBikeForm: FC<any> = (props) => {
  const record = useRecordContext();

  return (
    <TabbedForm warnWhenUnsavedChanges toolbar={<EditToolbar />}>
      <FormTab label="Bike">
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <SectionTitle title="Summary" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="serial_number"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceInput source="shop_id" reference="shops" alwaysOn>
                  <AutocompleteInput
                    label="Shop"
                    optionText="name"
                    optionValue="id"
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ReferenceInput
                  source="bike_brand_id"
                  reference="bike-brands"
                  alwaysOn
                >
                  <AutocompleteInput
                    label="Brand"
                    optionText="name"
                    optionValue="id"
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="model"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="colour"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="size"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  source="drive_type"
                  choices={BIKE_DRIVE_CHOICES}
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  source="frame_type"
                  choices={BIKE_FRAME_TYPE_CHOICES}
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  source="condition"
                  choices={BIKE_CONDITION_CHOICES}
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  source="status"
                  choices={BIKE_STATUS_CHOICES}
                  validate={validateRequired}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DateInput
                  label="Added"
                  source="created_at"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateInput
                  label="Last modified"
                  source="updated_at"
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
            <MoneyInput
              source="purchase_price"
              validate={validateRequired}
              currency={record?.purchase_currency}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <BikeContracts />
            <BikeOrders />
            <BikeFleets />
            <BikeMaintenanceHistory />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="ERP Sale Invoices">
        <Grid container>
          <Grid item sm={12}>
            <RelatedErpSaleInvoiceLineAssignments
              target="bike_id"
              withContractLink
            />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  );
};
