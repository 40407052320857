import { ReactElement } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { MarkdownInput } from "@react-admin/ra-markdown";
import { Tooltip } from "components/Tooltip";
import { UIV2ShadowRenderer } from "components/UIV2ShadowRenderer";
import { valuesToChoices } from "core/utils";
import {
  BooleanInput,
  DateInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  required,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { Announcement } from "@vapaus/common-v2";
import {
  AnnouncementVariant,
  AvailableScopes,
  SupportedCountriesEnum,
  SupportedLanguagesEnum,
} from "@vapaus/generated";

import { SectionTitle } from "components";

type AnnouncementFormProps = {
  toolbar?: ReactElement;
};

const SUPPORTED_COUNTRIES = valuesToChoices(SupportedCountriesEnum);
const SCOPES = valuesToChoices(AvailableScopes).filter(
  ({ id }) => id !== "workshop",
);
const ANNOUNCEMENT_VARIANT = valuesToChoices(AnnouncementVariant);
const supportedLanguages = Object.values(SupportedLanguagesEnum).sort();

const validateRequired = [required()];

const AnnouncementForm = ({ toolbar }: AnnouncementFormProps) => {
  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={toolbar}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextInput
            source="internal_name"
            validate={validateRequired}
            label="Internal name"
            fullWidth
          />
          <SelectInput
            source="variant"
            label="Announcement type"
            choices={ANNOUNCEMENT_VARIANT}
            fullWidth
            validate={validateRequired}
          />
          <SelectArrayInput
            source="scopes"
            label="Apps"
            choices={SCOPES}
            fullWidth
            validate={validateRequired}
          />
          <SelectArrayInput
            source="countries"
            choices={SUPPORTED_COUNTRIES}
            fullWidth
            validate={validateRequired}
          />
          <BooleanInput source="is_closeable" />
          <Tooltip text="Control if the banner is shown or not. If the enabled from/until dates are used, this still needs to be true to show the banner.">
            <BooleanInput source="is_enabled" />
          </Tooltip>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <DateInput source="enabled_from" fullWidth />
            </Grid>
            <Grid item md={6}>
              <DateInput source="enabled_until" fullWidth />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6}>
          <TranslatableInputs locales={supportedLanguages} defaultLocale="EN">
            <MarkdownInput source="content" />
          </TranslatableInputs>
        </Grid>
      </Grid>
      <Box sx={{ pt: 2, width: "100%" }}>
        <SectionTitle title="Preview" />
        <PreviewAnnouncement />
      </Box>
    </SimpleForm>
  );
};

function PreviewAnnouncement() {
  const { watch } = useFormContext();
  const [variant, content, is_closeable] = watch([
    "variant",
    "content",
    "is_closeable",
  ]);

  return (
    <UIV2ShadowRenderer>
      {content?.EN ? (
        <Announcement
          variant={variant}
          content={content.EN}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={is_closeable ? () => {} : undefined}
        />
      ) : (
        <Typography>Enter some english content to see the preview.</Typography>
      )}
    </UIV2ShadowRenderer>
  );
}

export default AnnouncementForm;
