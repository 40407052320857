import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
  useRedirect,
} from "react-admin";

import { Pagination } from "components";
import { useEnumChoices } from "hooks";

export const RedemptionProcessList = () => {
  const redirect = useRedirect();
  const statusChoices = useEnumChoices("RedemptionProcessStatus");
  const filters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <SelectInput
      source="status"
      label="Status"
      choices={statusChoices}
      alwaysOn
    />,
  ];
  return (
    <List filters={filters} perPage={20} pagination={<Pagination />}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={(id, resource, record) => {
          redirect(
            "show",
            "bike-benefit-contracts",
            record.bike_benefit_contract_id,
          );
          return false;
        }}
      >
        <ReferenceField
          source="bike_benefit_contract_id"
          reference="bike-benefit-contracts"
          sortable={false}
        >
          <TextField source="vapaus_code" />
        </ReferenceField>
        <ReferenceField
          source="bike_benefit_contract_id"
          reference="bike-benefit-contracts"
          sortable={false}
          label="Employee full name"
        >
          <TextField source="user.full_name" />
        </ReferenceField>
        <DateField source="planned_redemption_date" />
        <TextField source="status" />
        <TextField source="redemption_reason" />
      </Datagrid>
    </List>
  );
};
