import { useEffect, useRef } from "react";

import { ReferenceInput, SelectInput } from "react-admin";
import { useFormContext } from "react-hook-form";

export const FleetAccessRulesInput = () => {
  const { watch, setValue } = useFormContext();
  const organisationId = watch("organisation_id");
  const prevOrganisationIdRef = useRef<string>(organisationId);

  useEffect(() => {
    if (organisationId !== prevOrganisationIdRef.current) {
      prevOrganisationIdRef.current = organisationId;
      setValue("access_rules_uuid", "");
    }
  }, [organisationId, prevOrganisationIdRef, setValue]);

  return (
    <ReferenceInput
      source="access_rules_uuid"
      reference="fleet-access-rules"
      filter={{ organisation_id: organisationId, is_active: true }}
      enableGetChoices={() => !!organisationId}
    >
      <SelectInput
        label="Access rules"
        optionText="name"
        optionValue="uuid"
        fullWidth
        isRequired
      />
    </ReferenceInput>
  );
};
