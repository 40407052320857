import { toChoices } from "core/utils";

import { MasterPermissions } from "@vapaus/generated";

export const MASTER_PERMISSION_LABELS: {
  [key in MasterPermissions]: string;
} = {
  [MasterPermissions.MASTER_ROOT]: "Master root",
  [MasterPermissions.ERP_SALE_INVOICE]: "ERP sale invoice",
};

export const MASTER_PERMISSIONS = toChoices(MASTER_PERMISSION_LABELS);
