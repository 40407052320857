import ReceiptIcon from "@mui/icons-material/Receipt";
import { ResourceProps } from "react-admin";

import { InvoiceEdit } from "./InvoiceEdit";
import { InvoiceList } from "./InvoiceList";
import InvoiceCreate from "./components/CreateInvoiceForm";

const invoiceResource: ResourceProps = {
  name: "invoices/sale-invoices",
  icon: ReceiptIcon,
  create: InvoiceCreate,
  edit: InvoiceEdit,
  list: InvoiceList,
  options: {
    label: "Invoices",
  },
};

export default invoiceResource;
