/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BikeStatus {
  DRAFT = "draft",
  IN_STORAGE = "in_storage",
  RESERVED = "reserved",
  BENEFIT_BIKE = "benefit_bike",
  STOLEN = "stolen",
  SOLD = "sold",
  IN_FLEET = "in_fleet",
  RETIRED = "retired",
  UNKNOWN = "unknown",
}
