import { Grid } from "@mui/material";
import { SelectArrayInput } from "react-admin";

import { FINANCING_METHODS_CHOICES, FinancingMethods } from "core";

export const DownloadFullFleetReport = () => {
  return (
    <Grid item md={12}>
      <SelectArrayInput
        source="default_financing_methods"
        label="Please select which benefit financing method(s) should be exported"
        choices={FINANCING_METHODS_CHOICES}
        defaultValue={Object.values(FinancingMethods)}
        fullWidth
      />
    </Grid>
  );
};
