import React from "react";

import ContentCreate from "@mui/icons-material/Create";
import {
  FormDialogButton,
  FormDialogButtonProps,
} from "@react-admin/ra-form-layout";

import {
  EditVersionedDialog,
  EditVersionedDialogProps,
} from "./EditVersionedDialog";

/**
 * Copied from react admin EditInDialogButton
 *
 * A component which creates a `<EditVersionedDialog>`, along with a `<Button>` to open it.
 * This component is also responsible for managing the open/close state of the Dialog
 * (using an internal state, not the router).
 *
 * @example
 * const editButton = (
 *  <EditVersionedInDialogButton fullWidth maxWidth="md">
 *      <SimpleForm>
 *          <TextInput source="first_name" validate={required()} fullWidth />
 *      </SimpleForm>
 *  </EditVersionedInDialogButton>
 * );
 */
export const EditVersionedInDialogButton = (
  props: EditVersionedInDialogButtonProps,
) => {
  const {
    inline,
    icon = defaultIcon,
    label = "ra.action.edit",
    ButtonProps,
    ...editVersionedDialogProps
  } = props;

  const editVersionedDialog = (
    <EditVersionedDialog {...editVersionedDialogProps} />
  );

  return (
    <FormDialogButton
      icon={icon}
      label={label}
      dialog={editVersionedDialog}
      inline={inline}
      ButtonProps={ButtonProps}
    />
  );
};

const defaultIcon = <ContentCreate />;

export type EditVersionedInDialogButtonProps = Omit<
  FormDialogButtonProps,
  "dialog"
> &
  EditVersionedDialogProps;
