import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { ResourceProps } from "react-admin";

import { BikeBrandCreate } from "./BikeBrandCreate";
import { BikeBrandEdit } from "./BikeBrandEdit";
import { BikeBrandList } from "./BikeBrandList";

const bikeBrandResource: ResourceProps = {
  name: "bike-brands",
  icon: PrecisionManufacturingIcon,
  list: BikeBrandList,
  create: BikeBrandCreate,
  edit: BikeBrandEdit,
  options: {
    label: "Bike Brands",
  },
};

export default bikeBrandResource;
