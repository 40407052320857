import { useEffect, useRef } from "react";

import { ReferenceInput, SelectInput } from "react-admin";
import { useFormContext } from "react-hook-form";

export const FleetBookingTermsInput = () => {
  const { watch, setValue } = useFormContext();
  const organisationId = watch("organisation_id");
  const prevOrganisationIdRef = useRef<string>(organisationId);

  useEffect(() => {
    if (organisationId !== prevOrganisationIdRef.current) {
      prevOrganisationIdRef.current = organisationId;
      setValue("booking_terms_and_conditions_uuid", "");
    }
  }, [organisationId, prevOrganisationIdRef, setValue]);

  return (
    <ReferenceInput
      source="booking_terms_and_conditions_uuid"
      reference={`organisations/${organisationId}/booking-terms-and-conditions`}
      filter={{ organisation_id: organisationId, only_active: true }}
      enableGetChoices={() => !!organisationId}
    >
      <SelectInput
        label="Booking Terms and Conditions"
        optionText="name"
        optionValue="uuid"
        fullWidth
        isRequired
      />
    </ReferenceInput>
  );
};
