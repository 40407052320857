import { round } from "lodash-es";
import { useInput } from "react-admin";

import { MoneyInput, MoneyInputProps } from "./MoneyInput";

type GrossPriceInputProps = Omit<MoneyInputProps, "format" | "parse"> & {
  vatRate?: number;
  netDecimalPoints: number;
  grossDecimalPoints: number;
};

export const GrossPriceInput = (props: GrossPriceInputProps) => {
  const {
    vatRate = 0,
    netDecimalPoints,
    grossDecimalPoints,
    currency = "EUR",
    ...rest
  } = props;
  const {
    field: { onChange, value },
  } = useInput({ ...props });
  const netFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: netDecimalPoints,
  });
  const vatFormatter = new Intl.NumberFormat(undefined, {
    style: "percent",
    maximumFractionDigits: 2,
  });
  const handleValueChange = () => {
    const netValue = round(value, netDecimalPoints);
    onChange(netValue);
  };

  return (
    <MoneyInput
      {...rest}
      currency={currency}
      format={(v) => (v ? round(v * (1 + vatRate), grossDecimalPoints) : v)}
      parse={(v) => (v ? v / (1 + vatRate) : v)}
      helperText={`VAT rate ${vatFormatter.format(
        vatRate,
      )}, net amount: ${netFormatter.format(value)}`}
      onBlur={handleValueChange}
    />
  );
};
