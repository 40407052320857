import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import { DateTime } from "luxon";
import { SelectInput, required } from "react-admin";

export const DownloadMrrReport = () => {
  const currentYear = DateTime.now().year;
  const yearChoices = Array.from({ length: currentYear - 2020 + 2 }, (_, i) => {
    const year = currentYear + 1 - i;
    return { id: year, name: year.toString() };
  });
  return (
    <Grid item md={12} mb={4}>
      <SelectInput
        source="year"
        choices={yearChoices}
        defaultValue={currentYear}
        validate={required()}
      />
      <Alert severity="info">
        Creating this report might take a few minutes. Sit back and relax while
        we crunch the numbers for you!
      </Alert>
    </Grid>
  );
};
