/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RedemptionProcessStatus {
  WAITING_FOR_PAYMENT = "waiting_for_payment",
  PAYMENT_OVERDUE = "payment_overdue",
  PENDING_CONTRACT_TERMINATION = "pending_contract_termination",
  CONTRACT_TERMINATED = "contract_terminated",
  CANCELLED = "cancelled",
  FINALISED = "finalised",
}
