import FleetIcon from "@mui/icons-material/BikeScooter";
import { ResourceProps } from "react-admin";

import { FleetCreate } from "./FleetCreate";
import { FleetEdit } from "./FleetEdit";
import { FleetList } from "./FleetList";

const benefitDefinitionResource: ResourceProps = {
  options: { label: "Shared fleets" },
  name: "fleets",
  icon: FleetIcon,
  create: FleetCreate,
  edit: FleetEdit,
  list: FleetList,
};

export default benefitDefinitionResource;
