import { toChoices } from "./utils";

export enum InvoicingMethods {
  Email = "email",
  EInvoicing = "e-invoicing",
}

export const INVOICING_METHODS_LABELS = {
  [InvoicingMethods.Email]: "Email",
  [InvoicingMethods.EInvoicing]: "E-Invoicing",
};

export const INVOICING_METHODS_CHOICES = toChoices(INVOICING_METHODS_LABELS);

export enum BillingSchemes {
  Standard = "standard",
  SingleContract = "single_contract",
}

export const BILLING_SCHEMES_LABELS = {
  [BillingSchemes.Standard]: "Standard",
  [BillingSchemes.SingleContract]: "Single contract",
};

export const BILLING_SCHEMES_CHOICES = toChoices(BILLING_SCHEMES_LABELS);
