import { MouseEvent } from "react";

import {
  Confirm,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useSaveContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { useConfirmationPopup } from "./EditVersionedInDialogButton/ConfirmationPopupContext";

// Used to hide the delete button for entity that doesn't support delete
export const EditToolbar = ({
  children,
  confirmationRequired,
  ...props
}: ToolbarProps & { confirmationRequired?: boolean }) => {
  const { getValues } = useFormContext();
  const { save } = useSaveContext();

  const { isOpen, setIsOpen, title, content } = useConfirmationPopup();

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(true);
  };

  const onClose = () => setIsOpen(false);
  const onConfirm = () => save?.(getValues());

  return (
    <Toolbar {...props}>
      {confirmationRequired ? (
        <>
          <SaveButton onClick={onClick} />
          <Confirm
            isOpen={isOpen}
            title={title}
            content={content}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </>
      ) : (
        <SaveButton />
      )}
      {children}
    </Toolbar>
  );
};
