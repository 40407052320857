import React, { useEffect, useState } from "react";

import { Autocomplete, Table, TableBody } from "@mui/material";
import Alert from "@mui/material/Alert";
import { CurrencyGrossField } from "components/CurrencyGrossField";
import { useProductVatRate } from "hooks/useProductVatRates";
import { isUndefined, round } from "lodash-es";
import {
  AutocompleteInput,
  NumberInput,
  RecordContextProvider,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  minValue,
  required,
  useDataProvider,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { RowItem } from "resources/BikeBenefitOrders/components/RowItem";

import {
  AutocompleteResults,
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitOrderingMethod,
  BikeCondition,
  BikeDataCheckResult,
  BikeStatus,
  ProductTypes,
  SupportedCountriesEnum,
} from "@vapaus/generated";
import { toNet, useDebounce } from "@vapaus/utils";

import { useHubspotTicketContext } from "./HubspotTicketContext";
import { GrossPriceInput, MoneyInput, UrlInput } from "components";
import { BIKE_CONDITION_CHOICES } from "core";
import {
  useCustomMutationOptions,
  useEnumChoices,
  useVapausMutation,
  useVapausQuery,
} from "hooks";

export const OrderFormBike = () => {
  const { watch } = useFormContext();
  const [benefitDefinitionId, orderingMethod] = watch([
    "benefit_definition_id",
    "ordering_method",
  ]);
  const { data: benefitDefinition } = useGetOne(
    "benefit-definitions",
    {
      id: benefitDefinitionId,
    },
    { enabled: !!benefitDefinitionId },
  );
  return orderingMethod === BikeBenefitOrderingMethod.PRECYCLED ? (
    <>
      <OrderFormBikeSelection benefitDefinition={benefitDefinition} />
      <OrderFormBikeDisplay />
    </>
  ) : (
    <OrderFormBikeData benefitDefinition={benefitDefinition} />
  );
};

const OrderFormBikeSelection = ({
  benefitDefinition,
}: {
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
}) => {
  const { setValue } = useFormContext();
  const { watch } = useFormContext();
  const [selectedBikeId, setSelectedBikeId] = useState<string | null>();
  const bikeId = watch("bike_id");
  const dataProvider = useDataProvider();
  const { onError } = useCustomMutationOptions();
  const { mutate: updateBike } = useVapausMutation("updateBike");
  const bikeVatRate = useProductVatRate(
    benefitDefinition?.organisation
      .country as unknown as SupportedCountriesEnum,
    ProductTypes.BIKE,
  );

  useEffect(() => {
    if (selectedBikeId) {
      dataProvider.getOne("bikes", { id: selectedBikeId }).then((bike) => {
        if (
          !isUndefined(bikeVatRate) &&
          bike.data.purchase_currency === benefitDefinition?.currency
        ) {
          setValue("net_bike_price", bike.data.net_purchase_price, {
            shouldValidate: true,
            shouldTouch: true,
          });
        }

        setValue("bike_condition", bike.data.condition, {
          shouldValidate: true,
          shouldTouch: true,
        });
      });
    }
  }, [selectedBikeId]);

  const handleBikeConditionChange = async (new_condition: BikeCondition) => {
    if (bikeId) {
      const bike = await dataProvider.getOne("bikes", { id: bikeId });
      updateBike(
        {
          bikeId: bikeId,
          bikeDetails: {
            serial_number: bike.data.serial_number,
            condition: new_condition,
            bike_brand_id: bike.data.bike_brand_id,
            model: bike.data.model,
            drive_type: bike.data.drive_type,
            frame_type: bike.data.frame_type,
            size: bike.data.size,
            colour: bike.data.colour,
            shop_id: bike.data.shop_id,
          },
        },
        {
          onSuccess: () => {
            setValue("bike_condition", new_condition);
          },
          onError,
        },
      );
    }
  };

  if (isUndefined(bikeVatRate)) return null;

  return (
    <>
      <ReferenceInput
        source="bike_id"
        reference="bikes"
        filter={{ status: BikeStatus.IN_STORAGE }}
        isRequired
      >
        <AutocompleteInput
          label="Select bike"
          optionText={(bike) =>
            `${bike.bike_brand.name} ${bike.model} (${bike.serial_number})`
          }
          optionValue="id"
          fullWidth
          validate={required()}
          onChange={(value) => {
            setSelectedBikeId(value);
          }}
        />
      </ReferenceInput>
      <GrossPriceInput
        label="Price"
        source="net_bike_price"
        currency={benefitDefinition?.currency}
        vatRate={bikeVatRate}
        validate={[required(), minValue(0.01)]}
        netDecimalPoints={4}
        grossDecimalPoints={2}
        fullWidth
      />
      <SelectInput
        source="bike_condition"
        choices={BIKE_CONDITION_CHOICES}
        label="Condition"
        validate={required()}
        onChange={async (event) => {
          await handleBikeConditionChange(event.target.value);
        }}
        fullWidth
        disabled={!bikeId}
      />
    </>
  );
};

const OrderFormBikeDisplay = () => {
  const { watch } = useFormContext();
  const bikeId = watch("bike_id");
  const { data: bike } = useGetOne(
    "bikes",
    { id: bikeId },
    {
      enabled: !!bikeId,
    },
  );
  if (!bike) return null;
  return (
    <Table>
      <TableBody>
        <RecordContextProvider value={bike}>
          <RowItem label="Shop of origin">
            <ReferenceField source="shop_id" reference="shops">
              <TextField source="name" />
            </ReferenceField>
          </RowItem>
          <RowItem label="Bike manufacturer">
            <ReferenceField source="bike_brand_id" reference="bike-brands">
              <TextField source="name" />
            </ReferenceField>
          </RowItem>
          <RowItem label="Bike model">
            <TextField source="model" />
          </RowItem>
          <RowItem label="Drive type">
            <TextField source="drive_type" />
          </RowItem>
          <RowItem label="Frame type">
            <TextField source="frame_type" />
          </RowItem>
          <RowItem label="Size">
            <TextField source="size" />
          </RowItem>
          <RowItem label="Colour">
            <TextField source="colour" />
          </RowItem>
          <RowItem label="Retail price">
            <CurrencyGrossField
              source="purchase_price"
              currencySource="purchase_currency"
              vatRate={bike.purchase_vat_rate}
            />
          </RowItem>
        </RecordContextProvider>
      </TableBody>
    </Table>
  );
};

const OrderFormBikeData = ({
  benefitDefinition,
}: {
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
}) => {
  const record = useRecordContext();
  const { hubspotTicketData } = useHubspotTicketContext();
  const { watch, setValue } = useFormContext();
  const bikeVatRate = useProductVatRate(
    benefitDefinition?.organisation
      .country as unknown as SupportedCountriesEnum,
    ProductTypes.BIKE,
  );
  const [
    bikePurchasePrice,
    bikePurchaseVatRate,
    bikeCondition,
    orderingMethod,
    bikeId,
    bikeSerialNumber,
  ] = watch([
    "bike.purchase_price",
    "bike.purchase_vat_rate",
    "bike.condition",
    "ordering_method",
    "bike.id",
    "bike.serial_number",
  ]);
  useEffect(() => {
    if (!record.id) {
      setValue(
        "net_bike_price",
        toNet(bikePurchasePrice, bikePurchaseVatRate!),
      );
      setValue("bike_condition", bikeCondition);
    }
  }, [bikePurchasePrice, bikePurchaseVatRate, bikeCondition]);

  const bikeDriveTypes = useEnumChoices("BikeDriveType");
  const bikeFrameTypes = useEnumChoices("BikeFrameType");

  const bikeDataCheckSerialNumber = useDebounce(bikeSerialNumber);
  const { data: bikeDataCheckResult } = useVapausQuery<BikeDataCheckResult>(
    "checkBikeData",
    {
      id: bikeId,
      serial_number: bikeDataCheckSerialNumber,
    },
    {
      enabled:
        !!bikeDataCheckSerialNumber && bikeDataCheckSerialNumber.length > 0,
    },
  );

  return (
    <>
      <ReferenceInput source="bike.bike_brand_id" reference="bike-brands">
        <AutocompleteInput
          label="Manufacturer"
          optionText={(brand) => brand.name}
          defaultValue={hubspotTicketData.bikeBrandId}
          validate={required()}
        />
      </ReferenceInput>
      <BikeModelInput />
      <TextInput
        source="bike.serial_number"
        defaultValue={hubspotTicketData.bike_serial_number}
        fullWidth
      />
      {bikeDataCheckResult?.serial_number.warnings.map((warning) => (
        <Alert severity="warning" sx={{ mb: 2 }} key={warning.short}>
          {warning.short}
        </Alert>
      ))}
      <SelectInput
        source="bike.drive_type"
        choices={bikeDriveTypes}
        label="Drive type"
        validate={required()}
        fullWidth
      />
      <SelectInput
        source="bike.frame_type"
        choices={bikeFrameTypes}
        label="Frame type"
        validate={required()}
        fullWidth
      />
      <TextInput
        source="bike.size"
        validate={required()}
        defaultValue={hubspotTicketData.bike_size}
        fullWidth
      />
      <TextInput
        source="bike.colour"
        validate={required()}
        defaultValue={hubspotTicketData.bike_colour}
        fullWidth
      />
      <NumberInput
        source="bike.purchase_vat_rate"
        defaultValue={bikeVatRate}
        sx={{ display: "none" }}
      />
      <MoneyInput
        label="Purchase price"
        source="bike.purchase_price"
        currency={benefitDefinition?.currency}
        validate={[required(), minValue(0.01)]}
        defaultValue={hubspotTicketData.bike_price}
        helperText={`VAT ${bikePurchaseVatRate?.toLocaleString(undefined, {
          style: "percent",
          maximumFractionDigits: 2,
        })}`}
        fullWidth
      />
      {record.id ? (
        <GrossPriceInput
          label="Order bike price"
          source="net_bike_price"
          currency={benefitDefinition?.currency}
          vatRate={record.flat_vat_rate}
          validate={[required(), minValue(0.01)]}
          netDecimalPoints={4}
          grossDecimalPoints={2}
          fullWidth
        />
      ) : null}
      {orderingMethod === BikeBenefitOrderingMethod.ONLINE_ORDER && (
        <UrlInput
          source="bike.url"
          label="URL"
          validate={required()}
          fullWidth
        />
      )}
    </>
  );
};

const BikeModelInput = () => {
  const { watch, setValue } = useFormContext();
  const { hubspotTicketData } = useHubspotTicketContext();
  const value = watch("bike.model");
  const autocompleteQuery = useDebounce(value, 500);
  const { data: suggestions } = useVapausQuery<AutocompleteResults>(
    "getBikeFieldSuggestions",
    {
      field_name: "model",
      q: autocompleteQuery,
    },
    {
      enabled: !!autocompleteQuery && !!autocompleteQuery.length,
    },
  );
  const options = suggestions?.results ?? [];
  return (
    <Autocomplete
      freeSolo
      options={options}
      filterOptions={(x) => x}
      onChange={(e, v) => setValue("bike.model", v)}
      onInputChange={(e, v) => setValue("bike.model", v)}
      inputValue={value || ""}
      renderInput={(params) => {
        return (
          <TextInput
            source="bike.model"
            label="Bike model"
            {...params}
            validate={required()}
            fullWidth
            defaultValue={hubspotTicketData.bike_model}
          />
        );
      }}
    />
  );
};
