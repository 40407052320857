import { FC, useMemo } from "react";

import { TextField, TextFieldProps, useRecordContext } from "react-admin";

import { SaleInvoiceStatus } from "core";

export const InvoiceStatusField: FC<TextFieldProps> = (props) => {
  const { status } = useRecordContext();

  const color = useMemo(() => {
    if (status === SaleInvoiceStatus.PAID) return "success.main";
    if ([SaleInvoiceStatus.DUE, SaleInvoiceStatus.OVERDUE].includes(status))
      return "error.main";
    return "info.main";
  }, [status]);

  return <TextField {...props} color={color} />;
};
