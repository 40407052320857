import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useResourceContext } from "ra-core";
import { DatagridRow, DatagridRowProps, useDataProvider } from "react-admin";

import { ErpSaleInvoiceRead } from "@vapaus/generated";

type RefreshableRowContextProps = {
  refresh: () => void;
};

const RefreshableRowContext = createContext<RefreshableRowContextProps>({
  refresh: () => {},
});

export const useRefreshableRow = () => {
  return useContext(RefreshableRowContext);
};

export const RefreshableDatagridRow = ({
  record: listRecord,
  ...rest
}: DatagridRowProps) => {
  const [localRecord, setLocalRecord] = useState<ErpSaleInvoiceRead>(
    listRecord as ErpSaleInvoiceRead,
  );
  const dataProvider = useDataProvider();
  const resource = useResourceContext(rest);
  const refreshRecord = useCallback(() => {
    dataProvider.getOne(resource, { id: listRecord!.id }).then((record) => {
      setLocalRecord(record.data);
    });
  }, [dataProvider, resource, listRecord, setLocalRecord]);
  const contextProps = useMemo(() => {
    return {
      refresh: refreshRecord,
    };
  }, [refreshRecord]);
  useEffect(() => {
    setLocalRecord(listRecord as ErpSaleInvoiceRead);
  }, [listRecord]);
  return (
    <RefreshableRowContext.Provider value={contextProps}>
      <DatagridRow record={localRecord} {...rest} />
    </RefreshableRowContext.Provider>
  );
};
