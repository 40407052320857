import React from "react";

import { AnimatedIcon } from "../AnimatedIcon";
import "./Loading.scss";

type LoadingProps = {
  className?: string;
};

export const Loading = ({ className }: LoadingProps) => {
  let _className = "Loading";

  if (className) {
    _className += " " + className;
  }

  return (
    <div className={_className}>
      <AnimatedIcon type="spinner1" />
    </div>
  );
};
