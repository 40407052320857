import { ReactElement } from "react";

import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { MoneyInput, Separator } from "../../../../components";

type FleetUsageSettingsFormProps = {
  toolbar?: ReactElement;
};

export const FleetUsageSettingsForm = ({
  toolbar,
}: FleetUsageSettingsFormProps) => {
  return (
    <SimpleForm toolbar={toolbar}>
      <TextInput source="name" label="Name" fullWidth required />
      <NumberInput
        source="min_booking_time"
        helperText="Minimum length of the booking, in minutes"
        fullWidth
        required
      />
      <NumberInput
        source="max_booking_time"
        helperText="Maximum length of the booking, in minutes"
        fullWidth
        required
      />
      <NumberInput
        source="max_future_start"
        helperText="How long in advance the booking can be made, in hours"
        fullWidth
        required
      />
      <Separator />
      <Separator />
      <BooleanInput source="business_use" defaultValue={true} fullWidth />
      <FormDataConsumer<{ business_use: boolean }>>
        {({ formData, ...rest }) =>
          formData.business_use && (
            <>
              <MoneyInput
                currency="EUR"
                source="booking_fee_business"
                defaultValue={0}
                isRequired
                fullWidth
              />
              <MoneyInput
                helperText="Hourly price for a booking"
                currency="EUR"
                source="usage_fee_business"
                defaultValue={0}
                isRequired
                fullWidth
              />
            </>
          )
        }
      </FormDataConsumer>
      <BooleanInput source="private_use" defaultValue={true} fullWidth />
      <FormDataConsumer<{ private_use: boolean }>>
        {({ formData, ...rest }) =>
          formData.private_use && (
            <>
              <MoneyInput
                currency="EUR"
                source="booking_fee_private"
                defaultValue={0}
                isRequired
                fullWidth
              />
              <MoneyInput
                helperText="Hourly price for a booking"
                currency="EUR"
                source="usage_fee_private"
                defaultValue={0}
                isRequired
                fullWidth
              />
            </>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};
