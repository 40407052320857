import { useEffect, useMemo } from "react";

import { deepClone } from "@mui/x-data-grid/utils/utils";
import { CalculatePeriodParams } from "hooks/useCalculatePeriod";
import { useProductVatRate } from "hooks/useProductVatRates";
import { round, sum } from "lodash-es";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";

import {
  AccessoryCreateUpdate,
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitOrderAdditionalServiceWrite,
  ProductTypes,
  SupportedCountriesEnum,
} from "@vapaus/generated";
import { toGross, toNet, useDebounce } from "@vapaus/utils";

import { useOrderFormContext } from "./OrderFormContext";

export const useCalculationRequestParams = (
  benefitDefinition?: BenefitDefinitionReadWithOrganisationSummary,
) => {
  const hasFixedTaxableValue =
    !!benefitDefinition?.fixed_monthly_taxable_values?.length;
  const record = useRecordContext();
  const { watch, setValue } = useFormContext();
  const [
    netBikePrice,
    accessories,
    additionalServices,
    deliveryTransport,
    leasingPeriodMonths,
    monthlyMaintenanceBudget,
    fixedMonthlyTaxableValue,
    fixedDownPaymentAmount,
  ] = watch([
    "net_bike_price",
    "accessories",
    "additional_services",
    "delivery_transport",
    "leasing_period_months",
    "monthly_maintenance_budget",
    "fixed_monthly_taxable_value",
    "fixed_down_payment_amount",
  ]);
  useEffect(() => {
    if (
      monthlyMaintenanceBudget &&
      monthlyMaintenanceBudget > 0 &&
      fixedDownPaymentAmount &&
      fixedDownPaymentAmount > 0
    ) {
      setValue("monthly_maintenance_budget", 0);
    }
  }, [monthlyMaintenanceBudget, fixedDownPaymentAmount]);
  const currentFlatVatRate = useProductVatRate(
    benefitDefinition?.organisation
      .country as unknown as SupportedCountriesEnum,
    ProductTypes.BIKE,
  );
  const flatVatRate = record.flat_vat_rate ?? currentFlatVatRate;
  const { paramsRef, calculation } = useOrderFormContext();

  const toOrderVatRate = (price: number, vatRate: number) =>
    toGross(toNet(price, vatRate), flatVatRate);
  const totalPackagePrice = sum([
    toGross(netBikePrice, flatVatRate),
    ...accessories.map((a: AccessoryCreateUpdate) =>
      toOrderVatRate(a.purchase_price, a.purchase_vat_rate),
    ),
    ...additionalServices.map((as: BikeBenefitOrderAdditionalServiceWrite) =>
      toOrderVatRate(as.price_gross, as.vat_rate),
    ),
    deliveryTransport
      ? toOrderVatRate(
          deliveryTransport.price_gross,
          deliveryTransport.vat_rate,
        )
      : 0,
  ]);

  const params = useMemo<CalculatePeriodParams | null>(() => {
    if (!benefitDefinition || !flatVatRate) return null;
    return {
      plan: benefitDefinition.plan,
      leasing_period_months: hasFixedTaxableValue ? null : leasingPeriodMonths,
      benefit_definition_id: benefitDefinition.id,
      monthly_maintenance_budget: monthlyMaintenanceBudget,
      total_package_price: totalPackagePrice,
      fixed_monthly_taxable_value: hasFixedTaxableValue
        ? fixedMonthlyTaxableValue
        : null,
      fixed_down_payment_amount: parseFloat(fixedDownPaymentAmount),
      contract_correction: record.corrects_bike_benefit_contract_id
        ? {
            corrected_contract_id: record.corrects_bike_benefit_contract_id,
          }
        : undefined,
      contract_revision: record.revises_bike_benefit_contract_id
        ? {
            revised_contract_id: record.revises_bike_benefit_contract_id,
            revision_apply_date: record.revision_apply_date,
          }
        : undefined,
    };
  }, [
    benefitDefinition,
    hasFixedTaxableValue,
    leasingPeriodMonths,
    monthlyMaintenanceBudget,
    totalPackagePrice,
    fixedMonthlyTaxableValue,
    fixedDownPaymentAmount,
    record.corrects_bike_benefit_contract_id,
    record.revises_bike_benefit_contract_id,
    record.revision_apply_date,
  ]);
  const requestParams = useDebounce(
    useMemo(() => {
      if (!params) return null;
      const requestParams = { ...params };
      if (hasFixedTaxableValue) {
        delete requestParams.leasing_period_months;
      }
      if (!paramsRef.current) {
        return requestParams;
      }
      if (
        paramsRef.current.benefit_definition_id !==
          params.benefit_definition_id ||
        paramsRef.current.total_package_price !== params.total_package_price ||
        paramsRef.current.monthly_maintenance_budget !==
          params.monthly_maintenance_budget
      ) {
        delete requestParams.fixed_down_payment_amount;
        delete requestParams.leasing_period_months;
      }
      if (!calculation) {
        return requestParams;
      }
      if (
        calculation.down_payment_amount !== params.fixed_down_payment_amount
      ) {
        delete requestParams.leasing_period_months;
      }
      if (
        hasFixedTaxableValue &&
        paramsRef.current.fixed_monthly_taxable_value !==
          params.fixed_monthly_taxable_value
      ) {
        delete requestParams.fixed_down_payment_amount;
      }
      if (
        params.leasing_period_months &&
        calculation.leasing_period_months &&
        parseFloat(params.leasing_period_months.toString()) <
          calculation.leasing_period_months
      ) {
        delete requestParams.fixed_down_payment_amount;
      }
      return requestParams;
    }, [params]),
  );
  useEffect(() => {
    if (params) {
      paramsRef.current = deepClone(params);
    }
  }, [params]);
  return requestParams;
};
