import { FC, useMemo } from "react";

import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { BikeBenefitContractState } from "core/benefit";
import { Link, useGetOne, useRecordContext } from "react-admin";

import { useEnumLabel } from "hooks";

export const ContractStatus: FC = () => {
  const {
    state,
    cancellation_reason,
    cancellation_reason_description,
    current_redemption_process_id,
    current_return_process_id,
  } = useRecordContext();
  const { data: currentReturnProcess } = useGetOne(
    "return-processes",
    { id: current_return_process_id },
    { enabled: !!current_return_process_id },
  );
  const { data: currentRedemptionProcess } = useGetOne(
    "redemption-processes",
    { id: current_redemption_process_id },
    { enabled: !!current_redemption_process_id },
  );
  const getCancellationReasonLabel = useEnumLabel(
    "BikeBenefitContractCancellationReason",
  );
  const getReturnProcessStatusLabel = useEnumLabel("ReturnProcessStatus");
  const getRedemptionProcessStatusLabel = useEnumLabel(
    "RedemptionProcessStatus",
  );

  const color = useMemo(() => {
    if (state === BikeBenefitContractState.CANCELLED) return "error";
    if (state === BikeBenefitContractState.ENDED) return "warning";
    if (state === BikeBenefitContractState.ACTIVE) return "success";
    return undefined;
  }, [state]);

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography color="text.secondary">Status</Typography>
        </Grid>
        <Grid item xs={6}>
          <Chip
            label={state}
            color={color}
            sx={{ textTransform: "uppercase", width: "100%" }}
          />
        </Grid>
        {cancellation_reason && (
          <>
            <Grid item xs={6}>
              <Typography color="text.secondary">
                Cancellation reason
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ textAlign: "center" }}>
                {getCancellationReasonLabel(cancellation_reason)}
                {cancellation_reason_description && (
                  <StyledTooltip title={cancellation_reason_description}>
                    <span>&nbsp;&#9432;</span>
                  </StyledTooltip>
                )}
              </Typography>
            </Grid>
          </>
        )}
        {currentReturnProcess && (
          <>
            <Grid item xs={6}>
              <Typography color="text.secondary">Return Process</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ textAlign: "center" }}>
                <Link to={`/return-processes/${currentReturnProcess.id}/show`}>
                  {getReturnProcessStatusLabel(currentReturnProcess.status)}
                </Link>
              </Typography>
            </Grid>
          </>
        )}
        {currentRedemptionProcess && (
          <>
            <Grid item xs={6}>
              <Typography color="text.secondary">Redemption Process</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ textAlign: "center" }}>
                {getRedemptionProcessStatusLabel(
                  currentRedemptionProcess.status,
                )}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;
