import { ReactElement } from "react";

import { SimpleForm, TextInput } from "react-admin";

export const EmailForm = ({ toolbar }: { toolbar?: ReactElement }) => {
  return (
    <SimpleForm toolbar={toolbar}>
      <TextInput label="Email" source="email" type="email" fullWidth />
    </SimpleForm>
  );
};
