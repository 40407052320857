import { styled } from "@mui/material/styles";
import { SimpleFormIterator, SimpleFormIteratorClasses } from "react-admin";

export const CustomFormIterator = styled(SimpleFormIterator)<{
  actionsAlwaysVisible?: boolean;
}>(({ actionsAlwaysVisible }) => ({
  [`.${SimpleFormIteratorClasses.action}`]: actionsAlwaysVisible && {
    visibility: "visible",
  },
}));
