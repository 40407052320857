import { FC } from "react";

import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { InvoiceStatusField } from "components/InvoiceStatusField";
import {
  ArrayField,
  Datagrid,
  DatagridClasses,
  DateField,
  FunctionField,
  Pagination,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";

import type { SaleInvoiceLine } from "@vapaus/generated";

import { CurrencyField } from "./CurrencyField";
import { INVOICE_LINE_TYPE_LABELS } from "core";

export const RelatedSaleInvoices: FC<{
  target: "bike_benefit_contract_id" | "bike_benefit_order_id";
}> = ({ target }) => {
  const record = useRecordContext();
  return (
    <Card variant="outlined">
      <CardContent sx={{ pb: "0 !important" }}>
        <Typography variant="h6" component="div" sx={{ mb: 1.5 }}>
          Sale Invoices
        </Typography>
        <ReferenceManyField
          reference="invoices/sale-invoices"
          target={target}
          filter={
            // when displaying sale invoices of a contract, we want to list sale invoices of its order too
            target === "bike_benefit_contract_id"
              ? { bike_benefit_order_id: record.bike_benefit_order_id }
              : undefined
          }
          pagination={<Pagination />}
          perPage={10}
          sort={{ field: "invoice_number_serial", order: "DESC" }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            sx={{
              ["." + DatagridClasses.tableWrapper]: {
                overflowX: "auto",
                overflowY: "clip",
              },
            }}
          >
            <TextField
              source="invoice_number"
              sortable={false}
              label="Number"
            />
            <CurrencyField
              source="total"
              currencySource="currency"
              label="Amount"
            />
            <InvoiceStatusField
              source="status"
              textTransform="uppercase"
              sortable={false}
            />
            <ArrayField
              source="sale_invoice_lines"
              label="Type"
              sortable={false}
            >
              <SingleFieldList linkType={false}>
                <FunctionField<SaleInvoiceLine>
                  render={(line) => {
                    if (!line) return "";
                    return line.description.length ? (
                      <Tooltip title={line.description}>
                        <span>
                          {INVOICE_LINE_TYPE_LABELS[line.type]}&nbsp;&#9432;
                        </span>
                      </Tooltip>
                    ) : (
                      INVOICE_LINE_TYPE_LABELS[line.type]
                    );
                  }}
                />
              </SingleFieldList>
            </ArrayField>
            <DateField source="due_date" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </CardContent>
    </Card>
  );
};
