import GavelIcon from "@mui/icons-material/Gavel";
import { ResourceProps } from "react-admin";

import { ContractList } from "./ContractList";
import { ContractShow } from "./ContractShow";

const bikeBenefitContractResource: ResourceProps = {
  name: "bike-benefit-contracts",
  icon: GavelIcon,
  show: ContractShow,
  list: ContractList,
  options: {
    label: "Bike Contracts",
  },
};

export default bikeBenefitContractResource;
