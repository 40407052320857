import {
  CalculatePeriodParams,
  useCalculatePeriod,
} from "hooks/useCalculatePeriod";
import { round } from "lodash-es";
import { useGetOne } from "react-admin";

import {
  BenefitCalculation,
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
  Plan,
} from "@vapaus/generated";

import { useSummary } from "./useSummary";

export type OrderDetailsType = BikeBenefitContractRead & BenefitCalculation;

export const useOrderDetails = (
  order: BikeBenefitOrderRead,
): OrderDetailsType => {
  const { total_package_price, maintenance_budget } = useSummary();

  const { data: benefitDefinition, isLoading: isDefinitionLoading } = useGetOne(
    "benefit-definitions",
    {
      id: order.benefit_definition_id,
    },
    { keepPreviousData: true, enabled: !!order.benefit_definition_id },
  );

  const params: CalculatePeriodParams = {
    plan: benefitDefinition?.plan as Plan,
    leasing_period_months: order.leasing_period_months,
    benefit_definition_id: benefitDefinition?.id as string,
    monthly_maintenance_budget: order.monthly_maintenance_budget,
    total_package_price: round(total_package_price - maintenance_budget, 2),
    fixed_monthly_taxable_value: order.fixed_monthly_taxable_value,
    fixed_down_payment_amount: order.fixed_down_payment_amount,
  };
  if (order.fixed_monthly_taxable_value) {
    delete params.leasing_period_months;
  }

  if (order.corrects_bike_benefit_contract_id) {
    params.contract_correction = {
      corrected_contract_id: order.corrects_bike_benefit_contract_id,
    };
  }

  if (order.revises_bike_benefit_contract_id) {
    params.contract_revision = {
      revision_apply_date: order.revision_apply_date as string,
      revised_contract_id: order.revises_bike_benefit_contract_id,
    };
  }

  const { data: calculated } = useCalculatePeriod(params, {
    enabled:
      !isDefinitionLoading &&
      !!benefitDefinition &&
      order.current_bike_benefit_contract_id === null,
  });

  const { data: bikeContract } = useGetOne(
    "bike-benefit-contracts",
    {
      id: order?.current_bike_benefit_contract_id as string,
    },
    { enabled: !!order.current_bike_benefit_contract_id },
  );

  if (bikeContract) return bikeContract as OrderDetailsType;
  return calculated as OrderDetailsType;
};
