import { toChoices } from "core/utils";
import { useDataProvider } from "react-admin";

import { useDownload } from "hooks";

export enum ReportType {
  Deliveries = "deliveries",
  InvoiceReport = "invoice-report",
  FullFleet = "full-fleet",
  FleetBookings = "fleet-bookings",
  FullOrders = "full-orders",
  PurchaseInvoice = "purchase-invoices",
  RepaymentPlan = "repayment-plan",
  SaleInvoiceTransactions = "sale-invoice-transactions",
  Shops = "shops",
  BenefitDefinitions = "benefit-definitions",
  TukirahoitusSales = "tukirahoitus-sales",
  Mrr = "mrr",
  Users = "users",
}

export const REPORT_TYPES_LABELS = {
  [ReportType.Deliveries]: "Deliveries",
  [ReportType.InvoiceReport]: "Invoice",
  [ReportType.FullFleet]: "Full fleet",
  [ReportType.FleetBookings]: "Fleet bookings",
  [ReportType.FullOrders]: "Full orders",
  [ReportType.PurchaseInvoice]: "Purchase invoice",
  [ReportType.RepaymentPlan]: "Repayment plan per bike",
  [ReportType.SaleInvoiceTransactions]: "Sale invoice transactions",
  [ReportType.Shops]: "Shops",
  [ReportType.BenefitDefinitions]: "Benefit definitions",
  [ReportType.TukirahoitusSales]: "Tukirahoitus sales",
  [ReportType.Mrr]: "MRR",
  [ReportType.Users]: "Users",
};

export const REPORT_TYPES_CHOICES = toChoices(REPORT_TYPES_LABELS);

export const useDownloadReport = (reportType: ReportType) => {
  const provider = useDataProvider();
  return useDownload((params: any) =>
    provider.downloadReport(reportType, params),
  );
};
