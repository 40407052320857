import { useEffect } from "react";

import { CustomMethodsNames } from "providers/dataProvider/createCustomMethodsProvider";
import { ImageField, ImageInput } from "react-admin";
import { useFormContext } from "react-hook-form";

import { StorageObjectRead } from "@vapaus/api-codegen";

import { useCustomMutationOptions, useVapausMutation } from "hooks";

interface PictureInputProps {
  onPictureUploadStart?: () => void;
  onPictureUploadDone?: () => void;
  pictureFormField: string;
  pictureUrlField: string;
  uploadMethodName: CustomMethodsNames;
}

export const PictureInput = ({
  onPictureUploadStart,
  onPictureUploadDone,
  pictureFormField,
  pictureUrlField,
  uploadMethodName,
}: PictureInputProps) => {
  const { setValue, watch } = useFormContext();
  const pictureUrl = watch(pictureUrlField);
  const pictures = watch("pictures");
  const { mutate } = useVapausMutation<StorageObjectRead, unknown, File>(
    uploadMethodName,
  );
  const { onError } = useCustomMutationOptions();

  useEffect(() => {
    if (pictureUrl && pictures === undefined) {
      setValue("pictures", [
        {
          src: pictureUrl,
        },
      ]);
    }
  }, [pictures, pictureUrl, setValue]);

  function handleFileDrop(files: File[]) {
    const picture = files[0];
    setValue(pictureFormField, null);
    onPictureUploadStart?.();
    mutate(picture, {
      onSuccess(storageObject) {
        setValue(pictureFormField, storageObject.id);
      },
      onError(e) {
        onError(e);
      },
      onSettled() {
        onPictureUploadDone?.();
      },
    });
  }

  return (
    <ImageInput
      source="pictures"
      label="Picture"
      helperText="The maximum accepted file size is 10MB"
      options={{ onDropAccepted: handleFileDrop }}
      onRemove={() => setValue(pictureFormField, null)}
      accept="image/*"
    >
      <ImageField source="src" />
    </ImageInput>
  );
};
