import { Grid } from "@mui/material";
import { SelectArrayInput } from "react-admin";

import ParamsBetweenDates from "./ParamsBetweenDates";
import { BIKE_BENEFIT_ORDER_STATES_CHOICES, BikeBenefitOrderState } from "core";

export const DownloadFullOrdersReport = () => {
  return (
    <>
      <Grid item md={12}>
        <SelectArrayInput
          source="states"
          label="Please select states of orders which should be exported"
          choices={BIKE_BENEFIT_ORDER_STATES_CHOICES}
          defaultValue={[
            BikeBenefitOrderState.DRAFT,
            BikeBenefitOrderState.READY_FOR_DELIVERY,
            BikeBenefitOrderState.WAITING_FOR_PAYMENT,
            BikeBenefitOrderState.FINALISED,
            BikeBenefitOrderState.CANCELLED,
          ]}
          required
          fullWidth
        />
      </Grid>
      <ParamsBetweenDates />
    </>
  );
};
