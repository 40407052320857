import { FC } from "react";

import { Box, Grid } from "@mui/material";
import {
  Form,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";

import { Accessories } from "./components/Accessories";
import { AdditionalServices } from "./components/AdditionalServices";
import { BikeInfo } from "./components/BikeInfo";
import { Delivery } from "./components/Delivery";
import { OrderDetails } from "./components/OrderDetails";
import OrderMenu from "./components/OrderMenu";
import { OrderStatus } from "./components/OrderStatus";
import { OrderSummary } from "./components/OrderSummary";
import { OrderTotal } from "./components/OrderTotal";
import { OrderUser } from "./components/OrderUser";
import { PurchaseInvoices } from "./components/PurchaseInvoices";
import "./styles.scss";
import { RelatedSaleInvoices, Separator } from "components";

const UserTitle = () => {
  const record = useRecordContext();
  return <span>Vapaus Code: {record?.vapaus_code}</span>;
};

export const OrderShow: FC = () => {
  return (
    <Show title={<UserTitle />} actions={false}>
      <Form>
        <SimpleShowLayout>
          <Grid container className="benefit-bike-order" columnSpacing={2}>
            <Grid item xs={12} mb={4}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <TextField source="vapaus_code" variant="h4" />
                <OrderMenu />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <OrderDetails />
              <Separator />
              <BikeInfo />
              <Accessories />
              <Delivery />
              <AdditionalServices />
              <Separator />
              <PurchaseInvoices idField="bike_benefit_order_id" />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <OrderStatus />
              <Separator />
              <OrderUser />
              <Separator />
              <OrderSummary />
              <Separator />
              <OrderTotal />
              <Separator />
              <RelatedSaleInvoices target="bike_benefit_order_id" />
            </Grid>
          </Grid>
        </SimpleShowLayout>
      </Form>
    </Show>
  );
};
