/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BikeBenefitContractCancellationReason {
  REDEEMED = "redeemed",
  RETURNED = "returned",
  REVISED = "revised",
  INSURANCE_THEFT = "insurance_theft",
  INSURANCE_ACCIDENT = "insurance_accident",
  MERGER_ACQUISITION = "merger_acquisition",
  OTHER = "other",
}
