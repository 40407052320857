import React from "react";

import "./AnimatedIcon.scss";

export type AnimatedIconType =
  | "success"
  | "error"
  | "warning"
  | "spinner1"
  | "spinner2"
  | "spinner3"
  | "spinner4"
  | "spinner5";

type AnimatedIconProps = {
  type: AnimatedIconType;
};

export const AnimatedIcon = ({ type }: AnimatedIconProps) => {
  let icon = null;
  switch (type) {
    case "success":
      icon = (
        <div className="svg-box">
          <svg className="circular green-stroke">
            <circle
              className="path"
              cx="75"
              cy="75"
              r="50"
              fill="none"
              strokeWidth="5"
              strokeMiterlimit="10"
            />
          </svg>
          <svg className="checkmark green-stroke">
            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
              <path
                className="checkmark__check"
                fill="none"
                d="M616.306,283.025L634.087,300.805L673.361,261.53"
              />
            </g>
          </svg>
        </div>
      );
      break;

    case "error":
      icon = (
        <div className="svg-box">
          <svg className="circular red-stroke">
            <circle
              className="path"
              cx="75"
              cy="75"
              r="50"
              fill="none"
              strokeWidth="5"
              strokeMiterlimit="10"
            />
          </svg>
          <svg className="cross red-stroke">
            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
              <path
                className="first-line"
                d="M634.087,300.805L673.361,261.53"
                fill="none"
              />
            </g>
            <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
              <path
                className="second-line"
                d="M634.087,300.805L673.361,261.53"
              />
            </g>
          </svg>
        </div>
      );
      break;

    case "warning":
      icon = (
        <div className="svg-box">
          <svg className="circular yellow-stroke">
            <circle
              className="path"
              cx="75"
              cy="75"
              r="50"
              fill="none"
              strokeWidth="5"
              strokeMiterlimit="10"
            />
          </svg>
          <svg className="alert-sign yellow-stroke">
            <g transform="matrix(1,0,0,1,-615.516,-257.346)">
              <g transform="matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)">
                <path
                  className="line"
                  d="M634.087,300.805L673.361,261.53"
                  fill="none"
                />
              </g>
              <g transform="matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)">
                <circle className="dot" cx="621.52" cy="316.126" r="1.318" />
              </g>
            </g>
          </svg>
        </div>
      );
      break;

    case "spinner1":
      icon = (
        <div className="svg-box">
          <svg
            version="1.1"
            id="loader-1"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
          >
            <path
              opacity="0.3"
              fill="#000"
              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
            />
            <path
              opacity="0.9"
              fill="#000"
              d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      );
      break;

    case "spinner2":
      icon = (
        <div className="svg-box">
          <svg
            version="1.1"
            id="loader-1"
            x="0px"
            y="0px"
            width="120px"
            height="120px"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
          >
            <path
              opacity="0.2"
              fill="#fff"
              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
            />
            <path
              opacity="0.8"
              fill="#fff"
              d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      );
      break;

    case "spinner3":
      icon = (
        <div className="svg-box">
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#fff"
          >
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)" stroke-width="2">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
      );
      break;

    case "spinner4":
      icon = (
        <div className="svg-box">
          <svg
            version="1.1"
            id="loader-4"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
          >
            <path
              opacity="0.3"
              fill="#000"
              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
            />
            <path
              opacity="0.9"
              fill="#000"
              d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      );
      break;
    case "spinner5":
      icon = (
        <div className="svg-box">
          <svg
            version="1.1"
            id="loader-5"
            x="0px"
            y="0px"
            width="52px"
            height="52px"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
          >
            <path
              opacity="0.3"
              fill="#000"
              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
            />
            <path
              opacity="0.9"
              fill="#000"
              d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      );
      break;
    default:
  }

  return icon;
};
