import { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { BooleanInput, SelectInput, required } from "react-admin";
import { useFormContext } from "react-hook-form";

import { AVAILABLE_LANGUAGES } from "core";

export const DownloadUserReport = () => {
  const languageChoices = [{ id: "ALL", name: "All languages" }].concat(
    AVAILABLE_LANGUAGES,
  );
  const { watch, setValue } = useFormContext();
  const withAllRoles = watch("with_all_roles") ?? true;
  useEffect(() => {
    if (withAllRoles) {
      setValue("with_shop_managers", true);
      setValue("with_benefit_managers", true);
      setValue("with_masters", true);
      setValue("with_regular_users", true);
    }
  }, [withAllRoles]);
  return (
    <Grid item md={12} mb={4}>
      <SelectInput
        label="Language"
        source="language"
        choices={languageChoices}
        validate={required()}
        defaultValue="ALL"
      />
      <Typography variant="h6">Role</Typography>
      <BooleanInput
        source="with_all_roles"
        defaultValue={true}
        label="All users regardless of their role"
        helperText={false}
      />
      <BooleanInput
        source="with_shop_managers"
        defaultValue={true}
        label="Shop manager"
        helperText="Users with shop management permission"
        disabled={withAllRoles}
      />
      <BooleanInput
        source="with_benefit_managers"
        defaultValue={true}
        label="Benefit manager"
        helperText="Users with benefit management permission"
        disabled={withAllRoles}
      />
      <BooleanInput
        source="with_masters"
        defaultValue={true}
        label="Workshop admin"
        helperText="Users with a master permission"
        disabled={withAllRoles}
      />
      <BooleanInput
        source="with_regular_users"
        defaultValue={true}
        label="Consumer"
        helperText="Users who accepted User Terms"
        disabled={withAllRoles}
      />
    </Grid>
  );
};
