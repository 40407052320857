import type { CustomMethodsNames } from "providers/dataProvider/createCustomMethodsProvider";
import { HttpError, useDataProvider } from "react-admin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UseMutationOptions, UseQueryOptions } from "react-query/types";

export const useCustomMutation = (
  methodName: CustomMethodsNames,
  params: any,
  options?: UseMutationOptions,
) => {
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(
    [methodName, params],
    () => dataProvider[methodName](params),
    options,
  );
  return { mutate, isLoading, queryClient };
};

export const useVapausMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
>(
  methodName: CustomMethodsNames,
) => {
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const mutation = useMutation<TData, TError, TVariables>(
    (variables?: TVariables) => dataProvider[methodName](variables),
  );
  return { ...mutation, queryClient };
};

export const useVapausQuery = <TData>(
  methodName: CustomMethodsNames,
  params: any,
  options?: Omit<UseQueryOptions<TData, HttpError>, "queryKey" | "queryFn">,
) => {
  const dataProvider = useDataProvider();
  return useQuery<TData, HttpError>(
    [methodName, params],
    () => dataProvider[methodName](params),
    options,
  );
};
