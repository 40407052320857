import { ReactElement } from "react";

import { Grid, Typography } from "@mui/material";
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  FormDataConsumer,
  FormTab,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  TabbedForm,
  TextField,
  required,
  useRecordContext,
} from "react-admin";

import { DatagridActionsColumn } from "../../components/DatagridActionsColumn";
import { FleetAccessRulesInput } from "./FleetAccessRulesInput";
import { FleetBookingTermsInput } from "./FleetBookingTermsInput";
import { FleetUsagesSettingsInput } from "./FleetUsageSettingsInput";
import { FleetLocationCreateButton } from "./components/FleetLocationCreateButton";
import { FleetLocationEditButton } from "./components/FleetLocationEditButton";
import { VehicleCreateButton } from "./components/VehicleCreateButton";
import { VehicleEditButton } from "./components/VehicleEditButton";
import { VehicleReferenceField } from "./components/VehicleReferenceField";
import {
  Pagination,
  SectionTitle,
  NullableTextInput as TextInput,
} from "components";

type FleetFormProps = {
  toolbar?: ReactElement;
  disableOrganisation?: boolean;
};

const validateRequired = [required()];

const FleetForm = ({ toolbar, disableOrganisation }: FleetFormProps) => {
  const record = useRecordContext();
  return (
    <TabbedForm warnWhenUnsavedChanges toolbar={toolbar}>
      <FormTab label="Fleet">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="Summary" />
            <TextInput source="name" validate={validateRequired} fullWidth />
            <ReferenceInput source="organisation_id" reference="organisations">
              <AutocompleteInput
                label="Organisation"
                optionText="name"
                optionValue="id"
                fullWidth
                isRequired
                disabled={disableOrganisation}
                helperText={
                  !disableOrganisation &&
                  "Once the benefit definition is created, you won't be able to change the organisation it belongs to!"
                }
              />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  source="organisation_billing_details_id"
                  reference="organisation-billing-details"
                  filter={{ organisation_id: formData.organisation_id }}
                >
                  <AutocompleteInput
                    optionText={(choice) =>
                      `${choice.netvisor_customer_code} - ${choice.invoicing_method}`
                    }
                    disabled={!formData.organisation_id}
                    isRequired
                  />
                </ReferenceInput>
              )}
            </FormDataConsumer>
            <TextInput source="description" fullWidth multiline />
            {record && (
              <>
                This fleet is <b>{record.is_active ? "active" : "inactive"}</b>
              </>
            )}
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Information" />
            <TextInput source="home_page_text" fullWidth multiline />
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Access rules" />
            <FleetAccessRulesInput />
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Usage settings" />
            <FleetUsagesSettingsInput />
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Booking Terms and Conditions" />
            <FleetBookingTermsInput />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Vehicles">
        {record?.id ? (
          <ReferenceManyField
            label="Vehicles"
            reference="vehicles"
            target="fleet_id"
            perPage={10}
            pagination={<Pagination />}
            sort={{ field: "created_at", order: "DESC" }}
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={false}
              empty={
                <>
                  <Typography variant="body2">
                    No vehicles have been added to this fleet yet
                  </Typography>
                  <VehicleCreateButton />
                </>
              }
            >
              <TextField source="vehicle_type" textTransform="capitalize" />
              <TextField source="name" />
              <BooleanField source="is_active" />
              <VehicleReferenceField />
              <DatagridActionsColumn label={<VehicleCreateButton />}>
                <VehicleEditButton />
              </DatagridActionsColumn>
            </Datagrid>
          </ReferenceManyField>
        ) : (
          "Please save the fleet first to manage vehicles."
        )}
      </FormTab>
      <FormTab label="Locations">
        {record?.id ? (
          <ReferenceManyField
            label="Fleet locations"
            reference={`fleets/${record.id}/locations`}
            target="fleet_id"
            perPage={10}
            pagination={<Pagination />}
            sort={{ field: "created_at", order: "DESC" }}
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={false}
              empty={
                <>
                  <Typography variant="body2">
                    No locations have been added to this fleet yet
                  </Typography>
                  <FleetLocationCreateButton />
                </>
              }
            >
              <ReferenceField
                source="location_id"
                reference="locations"
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <BooleanField source="is_default_pick_up" />
              <BooleanField source="is_default_return" />
              <DatagridActionsColumn label={<FleetLocationCreateButton />}>
                <FleetLocationEditButton />
              </DatagridActionsColumn>
            </Datagrid>
          </ReferenceManyField>
        ) : (
          "Please save the fleet first to manage locations."
        )}
      </FormTab>
    </TabbedForm>
  );
};

export default FleetForm;
