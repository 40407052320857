import { toChoices } from "./utils";

export enum TermsType {
  UserTerms = "user_terms",
  ShopTerms = "shop_terms",
  ProviderTerms = "provider_terms",
}

export type Terms = {
  terms_type: TermsType;
  content: string;
  id?: string;
  is_current?: boolean;
  created_at?: Date;
};

export const TERMS_TYPES_LABELS = {
  [TermsType.UserTerms]: "User terms",
  [TermsType.ShopTerms]: "Shop terms",
  [TermsType.ProviderTerms]: "Provider terms",
};

export const TERMS_TYPES_CHOICES = toChoices(TERMS_TYPES_LABELS);
