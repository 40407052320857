import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  CreateInDialogButton,
  useFormDialogContext,
} from "@react-admin/ra-form-layout";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  required,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";

export const UserBenefitActivationBenefitCreateButton = () => {
  const benefitDefinition = useRecordContext();
  const refresh = useRefresh();
  return (
    <CreateInDialogButton
      fullWidth
      label="Add employee"
      title="Grant access to a new employee"
      resource="user-benefit-activations"
      record={{ benefit_definition_id: benefitDefinition.id }}
      mutationOptions={{ onSettled: refresh }}
    >
      <SimpleForm toolbar={<UserBenefitActivationCreateToolbar />}>
        <ReferenceInput source="user_id" reference="users">
          <AutocompleteInput
            label="User"
            optionText={(option) =>
              `${option.full_name || ""} (${option.email})`
            }
            optionValue="id"
            isRequired
            fullWidth
            validate={required()}
          />
        </ReferenceInput>
        <BooleanInput
          source="confirm"
          label="I have a written authorisation from the above organisation to grant access to the selected user"
          validate={required()}
        />
      </SimpleForm>
    </CreateInDialogButton>
  );
};

const UserBenefitActivationCreateToolbar = ({
  children,
  ...props
}: ToolbarProps) => {
  const { close } = useFormDialogContext();
  const { watch } = useFormContext();
  const [userId, confirm] = watch(["user_id", "confirm"]);
  return (
    <Toolbar {...props}>
      <SaveButton label="Grant access" disabled={!userId || !confirm} />
      <Button
        label="Cancel"
        startIcon={<CancelIcon />}
        onClick={close}
        sx={{ ml: 2 }}
      />
    </Toolbar>
  );
};
