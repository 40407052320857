import { Create } from "react-admin";

import { EditToolbar } from "../../components";
import AnnouncementForm from "./AnnouncementForm";
import { useCustomMutationOptions } from "hooks";

export const AnnouncementCreate = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Create mutationOptions={mutationOptions} redirect="list">
      <AnnouncementForm toolbar={<EditToolbar />} />
    </Create>
  );
};
