import { Switch } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import { useEnumLabel } from "hooks/useEnum";
import { useRecordContext } from "react-admin";
import styled from "styled-components";

import { AdminNotificationPreference } from "@vapaus/generated";

import { useVapausQuery } from "hooks";

const UserNotificationsPreferences = () => {
  const record = useRecordContext();
  const getEnumLabel = useEnumLabel("NotificationCategory");

  const { data: preferences, isLoading } = useVapausQuery<
    Array<AdminNotificationPreference>
  >("getUserNotificationsPreferences", [record?.id], { enabled: !!record?.id });

  if (!record?.id) {
    return (
      <EmptyBoxInfo message="Please save the user first to manage user's notifications preferences." />
    );
  }

  if (!preferences || isLoading) {
    return <EmptyBoxInfo message="Loading notifications preferences." />;
  }
  return (
    <div>
      <PreferencesTitle>Notifications preferences</PreferencesTitle>
      {Array.from(preferences).map((preference) => (
        <CardContent key={preference.notification_category}>
          <Switch checked={preference.opted_in} disabled />
          <OptionDescription>
            {getEnumLabel(preference.notification_category)}
          </OptionDescription>
        </CardContent>
      ))}
    </div>
  );
};

const PreferencesTitle = styled.span`
  margin-top: 6px;
  margin-bottom: 10px;
  display: block;
  width: max-content;
  font-weight: bold;
  color: rgb(118, 119, 122);
`;

const OptionDescription = styled.span`
  display: inline-block;
  width: max-content;
  color: rgb(118, 119, 122);
`;

export default UserNotificationsPreferences;
