import { BooleanInput, TextInput } from "react-admin";

export const BookingTermsAndConditionsForm = () => {
  return (
    <>
      <TextInput source="name" label="Name" fullWidth required />
      <TextInput source="terms" label="Terms" multiline fullWidth required />
      <BooleanInput source="is_active" fullWidth />
    </>
  );
};
