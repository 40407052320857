import { Plan, SaleInvoiceLineType } from "@vapaus/generated";

import { toChoices } from "./utils";

export const PLANS_LABELS = Object.values(Plan).reduce((plans, name) => {
  return {
    ...plans,
    [name]: name
      .split("_")
      .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(" "),
  };
}, {}) as { [key in Plan]: string };

export const PLANS_CHOICES = toChoices(PLANS_LABELS);
