import React from "react";

import { Edit, TopToolbar, useRecordContext, useRefresh } from "react-admin";
import { BenefitDefinitionMenu } from "resources/BenefitDefinition/components/BenefitDefinitionMenu";

import BenefitDefinitionForm from "./BenefitDefinitionForm";
import { useCustomMutationOptions } from "hooks";

const BenefitDefinitionTitle = () => {
  const record = useRecordContext();
  return <span>{record?.name}</span>;
};

const BenefitDefinitionEditToolbar = () => {
  return (
    <TopToolbar>
      <BenefitDefinitionMenu />
    </TopToolbar>
  );
};

export const BenefitDefinitionEdit = () => {
  const mutationOptions = useCustomMutationOptions();
  const refresh = useRefresh();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={{
        ...mutationOptions,
        onSuccess: refresh,
      }}
      redirect="edit"
      actions={<BenefitDefinitionEditToolbar />}
      title={<BenefitDefinitionTitle />}
    >
      <BenefitDefinitionForm disableOrganisation />
    </Edit>
  );
};
