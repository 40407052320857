import { FC } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TextField, TextFieldProps } from "react-admin";

export const RowItem: FC<TextFieldProps> = (props) => (
  <TableRow>
    <TableCell component="th" scope="row" variant="head">
      {props.label}
    </TableCell>
    <TableCell align="right">
      {props?.children || <TextField {...props} />}
    </TableCell>
  </TableRow>
);
