import { useRecordContext } from "react-admin";
import { ContractMenuModalProps } from "resources/BikeBenefitContracts/components/ContractMenu";

import { CompleteReturnProcessModal } from "components";

export const CompleteContractReturnProcessModal = (
  props: ContractMenuModalProps,
) => {
  const record = useRecordContext();
  return (
    <CompleteReturnProcessModal
      returnProcessId={record?.current_return_process_id!}
      {...props}
    />
  );
};
