import { EditVersionedInDialogButton } from "components/EditVersionedInDialogButton";
import { ConfirmationPopupContextProvider } from "components/EditVersionedInDialogButton/ConfirmationPopupContext";
import { useGetManyReference, useRecordContext } from "react-admin";

import {
  BenefitDefinitionAccessRulesRead,
  BenefitDefinitionRead,
} from "@vapaus/generated";

import { BenefitAccessRulesForm } from "./BenefitAccessRulesForm";
import { ConfirmationPopupContent } from "./ConfirmationPopupContent";
import { EditToolbar } from "components";

export const BenefitAccessRulesEditButton = () => {
  const record = useRecordContext<BenefitDefinitionAccessRulesRead>();

  const { data: benefitsUsingTheRule } =
    useGetManyReference<BenefitDefinitionRead>("benefit-definitions", {
      target: "benefit_access_rules_uuid",
      id: record.uuid,
    });

  return (
    <ConfirmationPopupContextProvider
      title="Are you sure you want to save changes?"
      content={<ConfirmationPopupContent />}
    >
      <EditVersionedInDialogButton
        fullWidth
        maxWidth="md"
        title="Edit benefit access rule"
        mutationMode="pessimistic"
      >
        <BenefitAccessRulesForm
          toolbar={
            <EditToolbar
              confirmationRequired={
                benefitsUsingTheRule && benefitsUsingTheRule.length > 0
              }
            />
          }
        />
      </EditVersionedInDialogButton>
    </ConfirmationPopupContextProvider>
  );
};
