import type { VapausDataProvider } from "providers/dataProvider";
import { HttpError, useDataProvider } from "react-admin";
import { UseQueryOptions, useQuery } from "react-query";

import { Plan } from "@vapaus/generated";

export type CalculatePeriodParams = {
  plan: Plan;
  benefit_definition_id: string;
  monthly_maintenance_budget: number | string;
  total_package_price: number | string;
  contract_correction?: {
    corrected_contract_id: string;
  };
  contract_revision?: {
    revision_apply_date: string;
    revised_contract_id: string;
  };
  fixed_down_payment_amount?: number | string;
  leasing_period_months?: number | string;
  fixed_monthly_taxable_value?: number | string;
};

export const useCalculatePeriod = (
  params: CalculatePeriodParams,
  options?: Omit<UseQueryOptions<any, HttpError>, "queryKey" | "queryFn">,
) => {
  const dataProvider = useDataProvider<VapausDataProvider>();
  return useQuery<any, HttpError>(
    ["calculatePeriod", params],
    () => {
      if (params.plan === Plan.CUSTOM || params.plan === Plan.LEGACY) {
        if (params.leasing_period_months) {
          return dataProvider.calculateBenefitFixedPeriod(params);
        }
        return dataProvider.calculateBenefitShortestPeriod(params);
      }
      if (params.plan === Plan.SWEDEN_PILOT) {
        return dataProvider.calculateBenefitFixedPeriodSwedenPilot(params);
      }
      return dataProvider.calculateBenefitFixedMonthlyPayment({
        benefit_definition_id: params.benefit_definition_id,
        monthly_payment: params.fixed_monthly_taxable_value,
        monthly_maintenance_budget: params.monthly_maintenance_budget,
        contract_correction: params?.contract_correction,
        contract_revision: params?.contract_revision,
        fixed_down_payment_amount: params.fixed_down_payment_amount,
        total_package_price: params.total_package_price,
      });
    },
    options,
  );
};
