import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  Create,
  Form,
  SaveButton,
  useGetOne,
  useNotify,
  useRefresh,
} from "react-admin";

export const CompleteReturnProcessModal = ({
  returnProcessId,
  onClose,
  open,
}: {
  returnProcessId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: returnProcess } = useGetOne("return-processes", {
    id: returnProcessId,
  });
  const { data: contract } = useGetOne(
    "bike-benefit-contracts",
    {
      id: returnProcess?.bike_benefit_contract_id,
    },
    {
      enabled: !!returnProcess?.bike_benefit_contract_id,
    },
  );
  if (!returnProcess || !contract) return null;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Complete Return Process"
    >
      <DialogTitle>Complete Return Process</DialogTitle>
      <Create
        resource={`return-processes/${returnProcessId}/complete`}
        mutationOptions={{
          onSuccess: () => {
            refresh();
            notify("Return Process completed successfully");
            onClose();
          },
        }}
        title={<></>}
        sx={{ mt: "-2em" }}
      >
        <Form>
          <DialogContent>
            <Typography>
              You're about to mark this return process as completed.
              {returnProcess.lease_end_date < DateTime.now().startOf("day")
                ? returnProcess.is_end_of_lease_return
                  ? `The contract has already ended on its planned end date (${contract.end_date}).`
                  : `The contract has already ended on its exceptional end date (${contract.exceptional_end_date}).`
                : returnProcess.is_end_of_lease_return
                ? `The contract will remain active until its planned end date (${contract.end_date}).`
                : `The contract will remain active until its exceptional end date (${contract.exceptional_end_date}).`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} startIcon={<CancelIcon />}>
              Go back
            </Button>
            <SaveButton alwaysEnable label="Complete Return Process" />
          </DialogActions>
        </Form>
      </Create>
    </Dialog>
  );
};
