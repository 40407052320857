import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { useRecordContext } from "react-admin";

import { VehicleForm } from "./VehicleForm";

export const VehicleCreateButton = () => {
  const fleet = useRecordContext();
  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="sm"
      record={{ fleet_id: fleet.id, organisation_id: fleet.organisation_id }}
      title={`Add a vehicle to ${fleet.name} fleet`}
    >
      <VehicleForm showIsActive />
    </CreateInDialogButton>
  );
};
