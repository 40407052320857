import React, { useState } from "react";

import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Form, TextInput, required, useDataProvider } from "react-admin";
import { SetRequired } from "type-fest";

import {
  BikeBrand,
  HubSpotBikeOrderTicketData,
  UserRead,
} from "@vapaus/generated";

import { ModalBox } from "../../../../components";
import { useVapausMutation } from "../../../../hooks";
import { useHubspotTicketContext } from "./HubspotTicketContext";

export function HubspotImport() {
  const dataProvider = useDataProvider();
  const { setHubspotTicketData } = useHubspotTicketContext();
  const [hubspotTicketId, setHubspotTicketId] = useState<string>("");
  const { mutate: importBikeOrderFromHubspot } = useVapausMutation(
    "importBikeOrderFromHubspot",
  );
  const [isHubspotImportModalOpen, setIsHubspotImportModalOpen] =
    useState<boolean>(false);

  const importOrderFromHubspot = (orderId: string) => {
    importBikeOrderFromHubspot(orderId, {
      onSuccess: async (order: unknown) => {
        const orderFromHubspot = order as HubSpotBikeOrderTicketData;
        const results = await Promise.all([
          Boolean(orderFromHubspot.bike_make) &&
            dataProvider.getList<SetRequired<BikeBrand, "id">>("bike-brands", {
              filter: { q: orderFromHubspot.bike_make },
              pagination: { page: 1, perPage: 1 },
              sort: { field: "name", order: "ASC" },
            }),
          Boolean(orderFromHubspot.contact.email) &&
            dataProvider.getList<SetRequired<UserRead, "id">>("users", {
              filter: { q: orderFromHubspot.contact.email },
              pagination: { page: 1, perPage: 1 },
              sort: { field: "name", order: "ASC" },
            }),
        ]);
        const bikeBrandId = results[0] ? results[0].data[0]?.id : null;
        const userId = results[1] ? results[1].data[0]?.id : "";
        setHubspotTicketData({ ...orderFromHubspot, userId, bikeBrandId });
        setIsHubspotImportModalOpen(false);
      },
    });
  };

  return (
    <>
      <Button
        startIcon={<Download />}
        onClick={() => setIsHubspotImportModalOpen(true)}
      >
        Import order from Hubspot
      </Button>
      <ModalBox
        open={isHubspotImportModalOpen}
        setOpen={() => setIsHubspotImportModalOpen(false)}
        title="Import order from Hubspot"
      >
        <Form>
          <TextInput
            name="hubspotTicketId"
            source="hubspot.ticket.id"
            value={hubspotTicketId}
            onChange={(event) => setHubspotTicketId(event.target.value)}
            validate={required()}
            fullWidth
          />
          <Button
            variant="outlined"
            startIcon={<Download />}
            onClick={() => importOrderFromHubspot(hubspotTicketId)}
          >
            Import
          </Button>
        </Form>
      </ModalBox>
    </>
  );
}
