import { round, sum } from "lodash-es";
import { useRecordContext } from "react-admin";

import {
  AccessoryCreateUpdate,
  BikeBenefitOrderAdditionalServiceWrite,
} from "@vapaus/generated";
import { toGross, toNet } from "@vapaus/utils";

type OrderSummary = {
  id: string;
  total_package_price: number;
  order_total: number;
  bike_price: number;
  accessories_total: number;
  accessories_count: number;
  additional_services_total: number;
  additional_services_count: number;
  monthly_maintenance_budget: number;
  maintenance_budget: number;
  leasing_period_months: number;
  delivery_transport_cost: number;
  flat_vat_rate: number;
  currency: string;
  toMoney: (x: number) => any;
};

export const useSummary = (): OrderSummary => {
  const {
    id,
    currency,
    bike_price,
    additional_services = [],
    accessories = [],
    monthly_maintenance_budget,
    leasing_period_months,
    delivery_transport,
    taxable_package_price_reduction,
    flat_vat_rate,
  } = useRecordContext();

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  });

  const toOrderVatRate = (price: number, vat_rate: number) =>
    toGross(toNet(price, vat_rate), flat_vat_rate);

  const accessories_total = sum(
    accessories.map((a: AccessoryCreateUpdate) =>
      toOrderVatRate(a.purchase_price, a.purchase_vat_rate),
    ),
  );

  const additional_services_total = sum(
    additional_services.map((as: BikeBenefitOrderAdditionalServiceWrite) =>
      toOrderVatRate(as.price_gross, as.vat_rate),
    ),
  );

  const maintenance_budget = monthly_maintenance_budget * leasing_period_months;

  const delivery_transport_cost = delivery_transport
    ? toOrderVatRate(
        delivery_transport.price_gross,
        delivery_transport.vat_rate,
      )
    : 0;

  const total_package_price =
    bike_price +
    accessories_total +
    additional_services_total +
    maintenance_budget +
    delivery_transport_cost;

  const order_total = total_package_price - taxable_package_price_reduction;

  return {
    id: id as string,
    delivery_transport_cost,
    total_package_price,
    order_total,
    bike_price,
    accessories_total,
    accessories_count: accessories.length,
    additional_services_total,
    additional_services_count: additional_services.length,
    leasing_period_months,
    monthly_maintenance_budget,
    maintenance_budget,
    flat_vat_rate,
    currency,
    toMoney: (x: number) => formatter.format(x || 0),
  };
};
