import {
  MutableRefObject,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { CalculatePeriodParams } from "hooks/useCalculatePeriod";
import { useRecordContext } from "react-admin";
import {
  OrderDetailsType,
  useOrderDetails,
} from "resources/BikeBenefitOrders/hooks/useOrderDetails";

import { BenefitCalculation, BikeBenefitOrderRead } from "@vapaus/generated";

type OrderFormContextValue = {
  paramsRef: MutableRefObject<CalculatePeriodParams | null>;
  calculation: BenefitCalculation | null;
  setCalculation: (calculation: BenefitCalculation | null) => void;
  initialOrderDetails: OrderDetailsType | null;
};

export const OrderFormContext = createContext<OrderFormContextValue | null>(
  null,
);

export const useOrderFormContext = () => {
  const value = useContext(OrderFormContext);
  if (!value) {
    throw new Error();
  }
  return value;
};

export const OrderFormContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const record = useRecordContext();
  const paramsRef = useRef<CalculatePeriodParams | null>(null);
  const [calculation, setCalculation] = useState<BenefitCalculation | null>(
    null,
  );
  const initialOrderDetails = record.id
    ? useOrderDetails(record as BikeBenefitOrderRead)
    : null;
  const value = useMemo(
    () => ({ paramsRef, calculation, setCalculation, initialOrderDetails }),
    [paramsRef, calculation, setCalculation],
  );
  return (
    <OrderFormContext.Provider value={value}>
      {children}
    </OrderFormContext.Provider>
  );
};
