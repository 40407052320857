import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  ReferenceManyField,
  useRecordContext,
} from "react-admin";

import { BenefitTermsActivateButton } from "./BenefitTermsActivateButton";
import { BenefitTermsCreateButton } from "./BenefitTermsCreateButton";
import { BenefitTermsEditButton } from "./BenefitTermsEditButton";

export const NestedBenefitTermsDataGrid = () => {
  const record = useRecordContext();
  if (!record?.id)
    return (
      <EmptyBoxInfo message="Please save the benefit definition first to manage benefit terms and conditions." />
    );

  return (
    <ReferenceManyField
      label="Benefit terms and conditions"
      reference="benefit-definition-terms"
      target="benefit_definition_id"
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={false}
        empty={<BenefitTermsCreateButton />}
        sx={{ maxWidth: 1024, width: "100%" }}
      >
        <DateField source="created_at" showTime />
        <FunctionField
          label="Content"
          render={(terms: any) => {
            if (terms.pdf_object?.public_url) {
              return (
                <Link
                  to={terms.pdf_object?.public_url}
                  target="_blank"
                  rel="no-refer"
                >
                  PDF
                </Link>
              );
            }

            const content = terms.content
              ?.slice(0, 40)
              .replaceAll(/[^a-zA-Z0-9\s]+/g, "");
            return `${content}...`;
          }}
        />
        <BooleanField label="Active" source="is_current" FalseIcon={null} />
        <DatagridActionsColumn>
          <BenefitTermsActivateButton />
        </DatagridActionsColumn>
        <DatagridActionsColumn label={<BenefitTermsCreateButton />}>
          <BenefitTermsEditButton />
        </DatagridActionsColumn>
      </Datagrid>
    </ReferenceManyField>
  );
};
