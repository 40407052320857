import { ReactElement } from "react";

import { BooleanInput, SelectInput, SimpleForm, TextInput } from "react-admin";

import { useEnumChoices } from "hooks";

export const LocationForm = ({ toolbar }: { toolbar?: ReactElement }) => {
  const countryChoices = useEnumChoices("SupportedOrganisationCountries");
  return (
    <SimpleForm toolbar={toolbar}>
      <TextInput source="name" required fullWidth />
      <TextInput source="address" required fullWidth />
      <TextInput source="postal_code" required fullWidth />
      <TextInput source="city" required fullWidth />
      <SelectInput
        source="country"
        choices={countryChoices}
        required
        fullWidth
      />
      <TextInput source="description" required fullWidth />
      <BooleanInput source="is_active" />
    </SimpleForm>
  );
};
