import { FC } from "react";

import {
  Bookmark,
  DateRange,
  Event,
  PostAdd,
  Tag,
  Wallet,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

// import { BikeBenefitOrderRead } from "@vapaus/generated";

type Props = {
  // order: BikeBenefitOrderRead;
  order: any;
};

export const BikeInfo: FC<Props> = ({ order }) => {
  const info = [
    {
      icon: <Tag />,
      label: "Vapaus code",
      value: order.vapaus_code,
    },
    {
      icon: <PostAdd />,
      label: "Serial No.",
      value: order.bike.serial_number,
    },
    {
      icon: <DateRange />,
      label: "Purchase Date",
      value: order.bike?.purchase_date
        ? new Date(order.bike.purchase_date).toLocaleDateString()
        : "-",
    },
    {
      icon: <Event />,
      label: "Order Created At",
      value: order.bike?.created_at
        ? new Date(order.bike.created_at).toLocaleDateString()
        : "-",
    },
    {
      icon: <Wallet />,
      label: "Currency",
      value: order.currency,
    },
    { icon: <Bookmark />, label: "Condition", value: order.bike.condition },
  ];

  return (
    <List sx={{ width: "100%" }}>
      {info.map((item, index) => (
        <div key={`bike-info-${index}`}>
          <ListItem alignItems="center">
            <ListItemIcon>
              <Avatar>{item.icon}</Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {item.label}
                </Typography>
              }
              secondary={
                <Typography
                  color="secondary"
                  style={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {item.value}
                </Typography>
              }
            />
          </ListItem>
          {index < info.length - 1 && (
            <Divider variant="inset" component="li" />
          )}
        </div>
      ))}
    </List>
  );
};
