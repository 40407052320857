import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import EmptyBoxInfo from "components/EmptyBoxInfo";
import {
  ChipField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";

import { BenefitAccessRulesCreateButton } from "./BenefitAccessRulesCreateButton";
import { BenefitAccessRulesEditButton } from "./BenefitAccessRulesEditButton";

export const NestedBenefitAccessRulesDataGrid = () => {
  const record = useRecordContext();
  if (!record?.id)
    return (
      <EmptyBoxInfo message="Please save the organisation first to manage benefit access rules." />
    );

  return (
    <ReferenceManyField
      label="Benefit access rules"
      reference="benefit-definition-access-rules"
      target="organisation_id"
      filter={{ is_active: true }}
    >
      <Datagrid
        bulkActionButtons={false}
        empty={<BenefitAccessRulesCreateButton />}
        sx={{ maxWidth: 1024 }}
      >
        <TextField source="name" />
        <TextField source="request_requirement" />
        <FunctionField
          source="allowed_email_addresses"
          label="Allowed emails"
          render={(record: any) =>
            `${record.allowed_email_addresses.length} emails`
          }
        />
        <FunctionField
          source="blacklist_emails"
          label="Blacklist emails"
          render={(record: any) => `${record.blacklist_emails?.length} emails`}
        />
        <ReferenceManyField
          label="Benefits using these rules"
          source="uuid"
          reference="benefit-definitions"
          target="benefit_access_rules_uuid"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceManyField>
        <DatagridActionsColumn label={<BenefitAccessRulesCreateButton />}>
          {<BenefitAccessRulesEditButton />}
        </DatagridActionsColumn>
      </Datagrid>
    </ReferenceManyField>
  );
};
