import * as React from "react";
import { ReactElement } from "react";

import { Grid } from "@mui/material";
import { ReferenceManyToManyInput } from "@react-admin/ra-relationships";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormTab,
  ReferenceInput,
  ReferenceManyField,
  SelectArrayInput,
  TabbedForm,
  number,
  required,
  useRecordContext,
} from "react-admin";
import { ShopDiscountsDatagrid } from "resources/Shop/components/ShopDiscountsDatagrid";

import {
  NumberInput,
  SectionTitle,
  Separator,
  NullableTextInput as TextInput,
  UrlInput,
} from "components";
import { useEnumChoices } from "hooks";

type ShopFormProps = {
  toolbar?: ReactElement;
};

const validateRequired = [required()];
const validateNumberOptional = [number()];

const ShopForm = ({ toolbar }: ShopFormProps) => {
  const record = useRecordContext();
  const shopTypeChoices = useEnumChoices("ShopType");
  const shopOperationCountryChoices = useEnumChoices(
    "SupportedOrganisationCountries",
  );

  return (
    <TabbedForm
      warnWhenUnsavedChanges
      toolbar={toolbar}
      defaultValues={{
        is_active: true,
      }}
    >
      <FormTab label="Summary" sx={{ maxWidth: 1024 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="Basic" />
            <TextInput source="name" validate={validateRequired} fullWidth />
            <TextInput source="description" multiline fullWidth />
            <ReferenceInput source="organisation_id" reference="organisations">
              <AutocompleteInput
                label="Organisation"
                optionText="name"
                optionValue="id"
                fullWidth
                isRequired
              />
            </ReferenceInput>
            <SelectArrayInput
              label="Shop types"
              source="types"
              choices={shopTypeChoices}
              fullWidth
            />
            <SelectArrayInput
              label="Shop operational countries"
              source="operation_countries"
              choices={shopOperationCountryChoices}
              fullWidth
            />
            <BooleanInput
              label="Active"
              source="is_active"
              validate={validateRequired}
            />
            <BooleanInput label="Is Featured" source="is_featured" />
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Contact" />
            <TextInput source="phone_number" fullWidth />
            <TextInput type="email" source="email" fullWidth />
            <UrlInput source="website" fullWidth />
            <Separator />
            <SectionTitle title="Address" />
            <TextInput source="street_address" fullWidth />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput source="postal_code" fullWidth />
              </Grid>
              <Grid item xs={8}>
                <TextInput source="city" fullWidth />
              </Grid>
            </Grid>
            <TextInput source="country" fullWidth />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Billing details">
        <SectionTitle title="Payment" />
        <NumberInput
          label="Payment terms (in days)"
          source="payment_terms"
          validate={validateNumberOptional}
          fullWidth
        />
        <Separator />
        <SectionTitle title="Discounts" />
        {record?.id ? (
          <ReferenceManyField
            reference="shop-discounts"
            target="shop_id"
            sort={{ field: "valid_from", order: "DESC" }}
            fullWidth
          >
            <ShopDiscountsDatagrid />
          </ReferenceManyField>
        ) : (
          "Please save the shop first to manage discounts."
        )}
      </FormTab>
      <FormTab label="Permissions" sx={{ maxWidth: 500 }}>
        <ReferenceManyToManyInput
          source="id"
          reference="users"
          through="shop-managers"
          using="shop_id,user_id"
          perPage={200}
          perPageChoices={200}
        >
          <AutocompleteArrayInput
            label="Shop managers"
            optionText="email"
            fullWidth
          />
        </ReferenceManyToManyInput>
      </FormTab>
      <FormTab label="Brands" sx={{ maxWidth: 500 }}>
        <ReferenceManyToManyInput
          source="id"
          reference="bike-brands"
          through="shop-bike-brands"
          using="shop_id,bike_brand_id"
          perPage={200}
          perPageChoices={200}
          sortChoices={{ field: "name", order: "ASC" }}
          filterChoices={{ active: true }}
        >
          <AutocompleteArrayInput
            label="Shop bike brands"
            optionText={(bikeBrand) =>
              `${bikeBrand.name}${bikeBrand.active ? "" : " (inactive)"}`
            }
            fullWidth
            filterToQuery={(searchText) => ({ active: true, q: searchText })}
          />
        </ReferenceManyToManyInput>
      </FormTab>
    </TabbedForm>
  );
};

export default ShopForm;
