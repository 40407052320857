import { ReactElement } from "react";

import { Table, TableBody, Typography } from "@mui/material";
import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  RecordContextProvider,
  ReferenceInput,
  SimpleForm,
  TextField,
  required,
} from "react-admin";
import { RowItem } from "resources/BikeBenefitContracts/components/RowItem";
import { useErpSaleInvoiceLineContext } from "resources/ErpSaleInvoice/components/ErpSaleInvoiceLineContext";

import { Separator } from "components";

export const ErpSaleInvoiceLineAssignmentForm = ({
  toolbar,
}: {
  toolbar?: ReactElement;
}) => {
  const line = useErpSaleInvoiceLineContext();
  return (
    <SimpleForm toolbar={toolbar}>
      <Typography variant="h6">Line details</Typography>
      <RecordContextProvider value={line}>
        <Table>
          <TableBody>
            <RowItem label="Description">
              <TextField source="description" />
            </RowItem>
            <RowItem label="Quantity">
              <TextField source="quantity" />
            </RowItem>
          </TableBody>
        </Table>
      </RecordContextProvider>
      <Separator />
      <Typography variant="h6">Assignment details</Typography>
      <ReferenceInput
        source="bike_benefit_contract_id"
        reference="bike-benefit-contracts"
      >
        <AutocompleteInput
          label="Contract"
          optionText={(choice) =>
            `${choice.vapaus_code} (${choice.user.full_name}) ${
              choice.start_date
            } - ${choice.exceptional_end_date ?? choice.end_date}`
          }
          optionValue="id"
          validate={required()}
          fullWidth
        />
      </ReferenceInput>
      <NumberInput source="quantity" validate={required()} fullWidth />
      <DateInput source="billing_date" validate={required()} fullWidth />
    </SimpleForm>
  );
};
