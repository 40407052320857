import { FC } from "react";

import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import { SelectArrayInput, SelectInput, required } from "react-admin";

import { BIKE_BENEFIT_CONTRACT_STATES_CHOICES } from "../../../core";
import { BikeBenefitContractState } from "../../../core/benefit";

const DownloadInvoiceReport: FC = () => {
  const currentYear = DateTime.now().year;

  const yearChoices = Array.from({ length: currentYear - 2020 + 1 }, (_, i) => {
    const year = currentYear - i;
    return { id: year, name: year.toString() };
  });

  const monthChoices = Array.from({ length: 12 }, (_, i) => ({
    id: i + 1,
    name: DateTime.fromObject({ month: i + 1 }).monthLong,
  }));

  return (
    <>
      <Grid item md={12}>
        <SelectArrayInput
          source="contract_states"
          label="Please select states of contracts which should be exported"
          choices={BIKE_BENEFIT_CONTRACT_STATES_CHOICES.filter(
            (state) => state.id !== BikeBenefitContractState.INVALIDATED,
          )}
          defaultValue={[
            BikeBenefitContractState.ACTIVE,
            BikeBenefitContractState.ACTIVE_PENDING_REDEMPTION,
            BikeBenefitContractState.ACTIVE_PENDING_RETURN,
            BikeBenefitContractState.CANCELLED,
            BikeBenefitContractState.CANCELLED_PENDING_PAYMENT,
            BikeBenefitContractState.ENDED,
            BikeBenefitContractState.ENDED_PENDING_PAYMENT,
            BikeBenefitContractState.SCHEDULED,
          ]}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          fullWidth
          defaultValue={yearChoices[0].id}
          label="Billing Period Year"
          source="billing_period_year"
          choices={yearChoices}
          validate={required()}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          fullWidth
          defaultValue={monthChoices[DateTime.now().month - 1].id}
          label="Billing Period Month"
          source="billing_period_month"
          choices={monthChoices}
          validate={required()}
        />
      </Grid>
    </>
  );
};

export default DownloadInvoiceReport;
