import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Create,
  Form,
  SaveButton,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { ContractMenuModalProps } from "resources/BikeBenefitContracts/components/ContractMenu";

export const CancelRedemptionProcessModal = ({
  onClose,
  open,
}: ContractMenuModalProps) => {
  const contract = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: redemptionProcess } = useGetOne("redemption-processes", {
    id: contract.current_redemption_process_id,
  });
  if (!redemptionProcess) return null;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Cancel Redemption Process"
    >
      <DialogTitle>Cancel Redemption Process</DialogTitle>
      <Create
        resource={`redemption-processes/${redemptionProcess.id}/cancel`}
        mutationOptions={{
          onSuccess: () => {
            refresh();
            notify("Redemption Process cancelled successfully");
            onClose();
          },
        }}
        title={<></>}
        sx={{ mt: "-2em" }}
      >
        <Form>
          <DialogContent>
            Are you sure you want to cancel this Redemption Process planned on{" "}
            {redemptionProcess.planned_redemption_date}? The previously issued
            redemption fee invoice will be reverted.
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} startIcon={<CancelIcon />}>
              Go back
            </Button>
            <SaveButton alwaysEnable label="Cancel Redemption Process" />
          </DialogActions>
        </Form>
      </Create>
    </Dialog>
  );
};
