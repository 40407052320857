import { FC } from "react";

import {
  AccountBalance,
  AccountBalanceWallet,
  Euro,
  Gavel,
  PostAdd,
  Receipt,
  ShoppingCart,
  Tag,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {
  Link,
  ReferenceFieldClasses,
  useCreatePath,
  useGetOne,
} from "react-admin";

import { SaleInvoiceTransaction } from "@vapaus/generated";

// import { SaleInvoiceRead } from "@vapaus/generated";

type Props = {
  // invoice: SaleInvoiceRead;
  invoice: any;
};

type InvoiceSummaryEntry = {
  icon: JSX.Element;
  label: string;
  value: string;
};

const InvoiceLink = ({ invoiceId }: { invoiceId: string }): JSX.Element => {
  const createPath = useCreatePath();
  const resourceLinkPath = createPath({
    resource: "invoices/sale-invoices",
    id: invoiceId,
    type: "edit",
  });
  const { data: invoice } = useGetOne("invoices/sale-invoices", {
    id: invoiceId,
  });
  return (
    <Link
      to={resourceLinkPath as string}
      className={ReferenceFieldClasses.link}
      onClick={(e) => e.stopPropagation()}
    >
      {invoice?.invoice_number ?? "..."}
    </Link>
  );
};

export const InvoiceSummary: FC<Props> = ({ invoice }) => {
  const money = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: invoice.currency,
  });

  const info = [
    {
      icon: <Tag />,
      label: "Invoice No.",
      value: invoice.invoice_number,
    },
    {
      icon: <PostAdd />,
      label: "Status",
      value: invoice.status,
    },
    {
      icon: <Gavel />,
      label: "Vat",
      value: money.format(invoice.vat),
    },
    {
      icon: <Euro />,
      label: "Total",
      value: money.format(invoice.total),
    },
    invoice.balance < 0 && {
      icon: <AccountBalanceWallet />,
      label: "Available credit",
      value: money.format(-invoice.balance),
    },
    invoice.reverts_invoice_id && {
      icon: <Receipt />,
      label: "Reverts invoice",
      value: <InvoiceLink invoiceId={invoice.reverts_invoice_id} />,
    },
    invoice.sale_invoice_transactions.length && {
      icon: <AccountBalance />,
      label: "Payment ID",
      value: (
        <>
          {invoice.sale_invoice_transactions.map(
            (transaction: SaleInvoiceTransaction) => (
              <Typography
                variant="inherit"
                component="span"
                key={transaction.id}
              >
                {transaction.payment_id}
              </Typography>
            ),
          )}
        </>
      ),
    },
    !!invoice.vapaus_code && {
      icon: <ShoppingCart />,
      label: "Vapaus code",
      value: invoice.vapaus_code,
    },
  ].filter(Boolean) as InvoiceSummaryEntry[];

  return (
    <>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {invoice?.customer_full_name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${invoice.customer_address},
                  ${invoice.customer_post_code},
                  ${invoice.customer_city},
                  ${invoice.customer_country}`}
        </Typography>
      </CardContent>
      <List sx={{ width: "100%" }}>
        {info.map((item, index) => (
          <div key={`bike-info-${index}`}>
            <ListItem alignItems="center">
              <ListItemIcon>
                <Avatar>{item.icon}</Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {item.label}
                  </Typography>
                }
                secondary={
                  <Typography
                    color="secondary"
                    style={{
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    {item.value}
                  </Typography>
                }
              />
            </ListItem>
            {index < info.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </div>
        ))}
      </List>
    </>
  );
};
