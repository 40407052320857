import React from "react";
import ReactDOM from "react-dom/client";

import CssBaseline from "@mui/material/CssBaseline";
import { init } from "@sentry/react";

import App from "./App";

if (
  import.meta.env.VITE_ENV &&
  ["prod", "staging", "feature"].includes(import.meta.env.VITE_ENV)
) {
  init({
    dsn: "https://dcbcd3a3323a4c028e591ffc775ddb43@o1371810.ingest.sentry.io/4505561031901184",
    integrations: [],
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
);
