import { ReactElement } from "react";

import { Box, Divider } from "@mui/material";
import { MarkdownInput } from "@react-admin/ra-markdown";
import { FileField, FileInput, SimpleForm } from "react-admin";
import { useFormContext } from "react-hook-form";

type BenefitTermsFormProps = {
  toolbar?: ReactElement;
};

export const BenefitTermsForm = ({ toolbar }: BenefitTermsFormProps) => {
  return (
    <SimpleForm toolbar={toolbar}>
      <TermsMarkdownInput />
      <TermsSeparator />
      <TermsPdfInput />
    </SimpleForm>
  );
};

const TermsMarkdownInput = () => {
  const { watch } = useFormContext();
  const pdf_object = watch("pdf_object");

  if (pdf_object) return null;
  return (
    <MarkdownInput
      source="content"
      toolbarItems={[
        ["heading", "bold", "italic", "strike"],
        ["hr", "quote"],
        ["ul", "ol", "indent", "outdent"],
        ["link"],
        ["code", "codeblock"],
      ]}
    />
  );
};

const TermsSeparator = () => {
  const { watch } = useFormContext();
  const [content, pdf_object] = watch(["content", "pdf_object"]);

  if (content || pdf_object) return null;

  return (
    <Box width="100%" marginTop={1} marginBottom={3}>
      <Divider>OR</Divider>
    </Box>
  );
};

const TermsPdfInput = () => {
  const { watch } = useFormContext();
  const content = watch("content");

  if (content) return null;

  return (
    <FileInput
      source="pdf_object"
      label="Terms and conditions PDF"
      accept="application/pdf"
      format={transformFile}
      parse={transformFile}
    >
      <FileField
        source="public_url"
        title="original_filename"
        target="_blank"
      />
    </FileInput>
  );
};

const transformFile = (file: File | any) => {
  if (!(file instanceof File)) {
    return file;
  }

  const preview = URL.createObjectURL(file);
  const transformedFile = {
    rawFile: file,
    public_url: preview,
    original_filename: file.name,
  };

  return transformedFile;
};
