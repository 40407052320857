import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  CreateInDialogButton,
  useFormDialogContext,
} from "@react-admin/ra-form-layout";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  required,
  useGetList,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";

export const UserBenefitActivationUserCreateButton = () => {
  const user = useRecordContext();
  const refresh = useRefresh();

  if (!user) return null;

  return (
    <CreateInDialogButton
      fullWidth
      label="Add new benefit"
      title="Grant access to a new benefit"
      resource="user-benefit-activations"
      record={{ user_id: user.id }}
      mutationOptions={{ onSettled: refresh }}
    >
      <SimpleForm toolbar={<UserBenefitActivationCreateToolbar />}>
        <OrganisationInput />
        <BenefitDefinitionInput />
        <BooleanInput
          source="confirm"
          label="I have a written authorisation from the above organisation to grant access to the selected benefit"
          validate={required()}
        />
      </SimpleForm>
    </CreateInDialogButton>
  );
};

const OrganisationInput = () => {
  const { setValue } = useFormContext();
  return (
    <ReferenceInput source="organisation_id" reference="organisations">
      <AutocompleteInput
        label="Organisation"
        optionText="name"
        optionValue="id"
        isRequired
        fullWidth
        validate={required()}
        onChange={() => setValue("benefit_definition_id", null)}
      />
    </ReferenceInput>
  );
};

const BenefitDefinitionInput = () => {
  const { watch } = useFormContext();
  const organisationId = watch("organisation_id");
  const { data: benefitDefinitions, isLoading } = useGetList(
    "benefit-definitions",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: { organisation_id: organisationId, only_active: true },
    },
    { enabled: !!organisationId },
  );
  return (
    <SelectInput
      source="benefit_definition_id"
      label={
        organisationId && !isLoading && !benefitDefinitions?.length
          ? "No active benefit definitions found"
          : "Benefit definition"
      }
      choices={
        benefitDefinitions?.map((benefit) => ({
          id: benefit.id,
          name: benefit.name,
        })) || []
      }
      defaultValue={
        benefitDefinitions?.length === 1 ? benefitDefinitions[0].id : null
      }
      disabled={!organisationId || isLoading || !benefitDefinitions?.length}
      validate={required()}
      fullWidth
    />
  );
};

const UserBenefitActivationCreateToolbar = ({
  children,
  ...props
}: ToolbarProps) => {
  const { close } = useFormDialogContext();
  const { watch } = useFormContext();
  const [benefitDefinitionId, confirm] = watch([
    "benefit_definition_id",
    "confirm",
  ]);
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Grant access"
        disabled={!benefitDefinitionId || !confirm}
      />
      <Button
        label="Cancel"
        startIcon={<CancelIcon />}
        onClick={close}
        sx={{ ml: 2 }}
      />
    </Toolbar>
  );
};
