import { FC } from "react";

import Typography from "@mui/material/Typography";
import { Datagrid, DatagridProps, useListContext } from "react-admin";

export const CustomDataGrid: FC<DatagridProps & { label?: string }> = (
  props,
) => {
  const { data } = useListContext(props);
  return (
    <>
      {data?.length > 0 && (
        <Typography variant="h6" gutterBottom>
          {props.label}
        </Typography>
      )}
      <Datagrid {...props} />
    </>
  );
};
