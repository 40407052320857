import React, { useState } from "react";

import { CheckCircle, ErrorOutline } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  BooleanInput,
  Button,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { useCustomMutationOptions, useVapausMutation } from "hooks";

export const FleetActivateButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [isOpen, setIsOpen] = useState(false);
  const { mutate, isLoading } = useVapausMutation("activateFleet");
  const { onError } = useCustomMutationOptions();
  const notify = useNotify();
  const onSubmit = (formData: any) => {
    mutate(
      { id: record.id, activateVehicles: formData.activate_vehicles },
      {
        onSuccess: () => {
          notify("Fleet has been activated");
          refresh();
        },
        onError,
      },
    );
  };
  return (
    <>
      <Button
        sx={{ marginLeft: 4 }}
        label="Activate"
        size="medium"
        onClick={() => setIsOpen(true)}
      />
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Activate fleet</DialogTitle>
        <Form
          defaultValues={{
            activate_vehicles: false,
          }}
          onSubmit={onSubmit}
        >
          <DialogContent>
            <Typography sx={{ mb: 2 }}>
              Are you sure you want to activate this fleet? Along with this
              change you may choose to activate all vehicles of the fleet.
            </Typography>
            <BooleanInput
              source="activate_vehicles"
              label="Activate all vehicles of the fleet"
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={() => setIsOpen(false)}
              startIcon={<ErrorOutline />}
              label="Cancel"
            />
            <Button
              type="submit"
              label="Activate"
              startIcon={<CheckCircle />}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
