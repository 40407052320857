import { FC, useMemo } from "react";

import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useRecordContext } from "react-admin";

import { toGross, toNet } from "@vapaus/utils";

import { CurrencyGrossField } from "components";

export const ContractTotal: FC = () => {
  const contract = useRecordContext();

  const infoRecord = useMemo(
    () => ({
      currency: contract.currency,
      total_package_price:
        contract.initial_total_package_price -
        toGross(
          toNet(
            contract.taxable_package_price_reduction,
            contract.current_flat_vat_rate,
          ),
          contract.initial_vat_rate,
        ),
      taxable_package_price: toGross(
        contract.net_taxable_package_price,
        contract.initial_vat_rate,
      ),
    }),
    [contract],
  );

  return (
    <Card variant="outlined">
      <CardContent className="order-total">
        <Grid container mb={2}>
          <Grid item xs={9}>
            <Tooltip title="total package price – maintenance budget – down payment ">
              <Typography variant="body2">
                Total without maintenance and down payment&nbsp;&#9432;
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" align="right">
              <CurrencyGrossField
                record={infoRecord}
                source="taxable_package_price"
                vatRate={contract.initial_vat_rate}
                currencySource="currency"
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={9}>
            <Tooltip title="Bike package + tax free options. Please note that bike package may include down payment and tax-free options may include maintenance budget.">
              <Typography variant="h6">
                Total package price&nbsp;&#9432;
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <CurrencyGrossField
              record={infoRecord}
              source="total_package_price"
              vatRate={contract.initial_vat_rate}
              currencySource="currency"
              sx={{ fontSize: "h6.fontSize" }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
