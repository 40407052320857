/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BikeBenefitContractReturnOrRedemptionReason {
  EMPLOYMENT_TERMINATED_BY_EMPLOYER = "employment_terminated_by_employer",
  CHANGING_JOBS_OR_LEAVING_COMPANY = "changing_jobs_or_leaving_company",
  LONG_LEAVE = "long_leave",
  WANT_DIFFERENT_BIKE = "want_different_bike",
  NOT_USING_BIKE_ENOUGH = "not_using_bike_enough",
  WANT_TO_SPEND_MONEY_DIFFERENTLY = "want_to_spend_money_differently",
  OTHER = "other",
  END_OF_LEASE = "end_of_lease",
}
