import { Edit, useRecordContext } from "react-admin";

import { EditInvoiceForm } from "./components/EditInvoiceForm";
import { useCustomMutationOptions } from "hooks";

const InvoiceTitle = () => {
  const record = useRecordContext();
  return <span>Invoice: {record?.invoice_number}</span>;
};

export const InvoiceEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<InvoiceTitle />}
    >
      <EditInvoiceForm />
    </Edit>
  );
};
