import { FC, ReactElement, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { ReferenceManyToManyInput } from "@react-admin/ra-relationships";
import { useProductVatRate } from "hooks/useProductVatRates";
import { capitalize, isNull, isUndefined } from "lodash-es";
import { DateTime } from "luxon";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Form,
  FormDataConsumer,
  FormTab,
  FormTabProps,
  FunctionField,
  Link,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TabbedFormView,
  TextField,
  Validator,
  choices,
  maxValue,
  minValue,
  number,
  required,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { BenefitDefinitionMonthlyOfficeFeeDatagrid } from "resources/BenefitDefinition/components/BenefitDefinitionMonthlyOfficeFees/BenefitDefinitionMonthlyOfficeFeeDatagrid";
import { useFormRootPath } from "utils/useFormRootPath";
import {
  relativeMaxDate,
  relativeMaxNumber,
  relativeMinDate,
  relativeMinNumber,
} from "validators";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  BoolRange,
  IntRange,
  MoneyChoices,
  MoneyRange,
  Payer,
  PercentageRange,
  Plan,
  PlanConfig,
  PreciseMoneyRange,
  ProductTypes,
  SupportedOrganisationCountries,
} from "@vapaus/generated";

import { NestedBenefitTermsDataGrid } from "../components/BenefitTerms";
import { UserBenefitActivationBenefitCreateButton } from "../components/UserBenefitActivationBenefitCreateButton";
import { BenefitAccessRulesInput } from "./BenefitAccessRulesInput";
import {
  GrossPriceInput,
  MoneyInput,
  NumberInput,
  Pagination,
  PercentInput,
  SectionTitle,
  Separator,
  NullableTextInput as TextInput,
} from "components";
import {
  BIKE_CONTRACT_PRODUCT_CODES,
  FINANCING_METHODS_CHOICES_BY_COUNTRY,
  FinancingMethods,
  PLANS_CHOICES,
  PLANS_LABELS,
  SERVICE_FEE_PRODUCT_CODES,
} from "core";

type BenefitDefinitionFormProps = {
  toolbar?: ReactElement;
  disableOrganisation?: boolean;
  initialPlan?: Plan;
};

const isDisabled = (
  range?: PreciseMoneyRange | MoneyRange | PercentageRange,
): boolean =>
  !!range && !isUndefined(range.default) && range.minimum == range.maximum;
const validateRequired = [required()];
const validateNumber = (
  range?: IntRange | MoneyRange,
  isRequired = true,
  isNet = false,
) => {
  const minErrorMessage = range
    ? isNet
      ? `Net amount must be at least ${range?.minimum}`
      : `Must be at least ${range?.minimum}`
    : undefined;
  const maxErrorMessage = range
    ? isNet
      ? `Net amount must be ${range?.minimum} or less`
      : `Must be ${range?.minimum} or less`
    : undefined;
  return [
    isRequired && required(),
    number(),
    range && minValue(range.minimum, minErrorMessage),
    range && maxValue(range.maximum, maxErrorMessage),
  ].filter(Boolean) as Validator[];
};
const validateMinLeasingPeriod = (plan?: PlanConfig) => [
  ...validateNumber(plan?.minimum_leasing_period),
  relativeMaxNumber("maximum_leasing_period"),
];
const validateMaxLeasingPeriod = (plan?: PlanConfig) => [
  ...validateNumber(plan?.maximum_leasing_period),
  relativeMinNumber("minimum_leasing_period"),
];
const validateBoolean = (range?: BoolRange) =>
  [
    range && choices(range.values, `Must be ${range.values[0] ? "on" : "off"}`),
  ].filter(Boolean) as Validator[];
const validateNumMin0Optional = [number(), minValue(0)];
const validatePercent = (range?: PercentageRange) => [
  required(),
  number(),
  minValue(range?.minimum ?? 0),
  maxValue(range?.maximum ?? 1),
];
const validateStartDate = [required(), relativeMaxDate("end_date")];
const validateEndDate = [required(), relativeMinDate("start_date")];
const validateDecisionDate = [
  relativeMaxDate("financing_decision_expiration_date"),
];
const validateDecisionExpirationDate = [
  relativeMinDate("financing_decision_date"),
];
const validateDecisionMaxLeasingPeriod = [number(), minValue(0), maxValue(60)];

const BenefitDefinitionForm: FC<BenefitDefinitionFormProps> = (props) => {
  const formRootPathname = useFormRootPath();
  return (
    <Form
      formRootPathname={formRootPathname}
      warnWhenUnsavedChanges
      defaultValues={{
        organisation: null,
        plan: null,
        order_processing_grace_period_days: 90,
        is_employee_number_mandatory: false,
        is_company_mandatory: false,
        is_cost_center_mandatory: false,
        minimum_leasing_period: 6,
        maximum_leasing_period: 60,
        minimum_monthly_taxable_value: 0,
        maximum_monthly_taxable_value: 100,
        maximum_total_package_price: 10000,
        is_downpayment_allowed: false,
        default_residual_ratio: 0.05,
        default_monthly_decrease_in_residual_value: 0,
        minimum_maintenance_service_monthly: 0,
        maximum_maintenance_service_monthly: 20,
        standard_coverage_insurance_rate: 0,
        full_coverage_insurance_rate: 0.0296,
        insurance_deductible: 240,
        net_delivery_fee: null,
        net_customer_service_monthly_fee: null,
        net_legacy_warranty_monthly_fee: null,
        net_company_monthly_cancellation_coverage: null,
        net_individual_monthly_cancellation_coverage: null,
        net_individual_onetime_cancellation_coverage: null,
        net_return_fee: null,
        net_redemption_fee: null,
      }}
    >
      <FormTabs {...props} />
    </Form>
  );
};

const FormTabs: FC<BenefitDefinitionFormProps> = ({
  disableOrganisation,
  toolbar,
}) => {
  const record = useRecordContext();
  const [initialPlan, setInitialPlan] = useState<Plan | null>(
    record?.plan ?? null,
  );
  const formRootPathname = useFormRootPath();
  const { watch, setValue } = useFormContext();
  const [
    selectedOrganisationId,
    selectedPlanName,
    selectedEndDate,
    selectedRawOrderProcessingGracePeriodDays,
  ] = watch([
    "organisation_id",
    "plan",
    "end_date",
    "order_processing_grace_period_days",
  ]);
  const selectedOrderProcessingGracePeriodDays = isNull(
    selectedRawOrderProcessingGracePeriodDays,
  )
    ? null
    : parseInt(selectedRawOrderProcessingGracePeriodDays);

  const orderProcessingEndDate = DateTime.fromISO(selectedEndDate)
    .plus({ days: selectedOrderProcessingGracePeriodDays ?? 0 })
    .toISODate();
  const handleFinancingMethodChange = (e: any) => {
    setValue(
      "service_fee_product_code",
      SERVICE_FEE_PRODUCT_CODES[
        e.target.value as keyof typeof SERVICE_FEE_PRODUCT_CODES
      ] || null,
    );
    setValue(
      "bike_contract_product_code",
      BIKE_CONTRACT_PRODUCT_CODES[
        e.target.value as keyof typeof BIKE_CONTRACT_PRODUCT_CODES
      ] || null,
    );
  };
  const { data: selectedOrganisation } = useGetOne(
    "organisations",
    {
      id: selectedOrganisationId,
    },
    {
      enabled: !!selectedOrganisationId,
      refetchOnWindowFocus: false,
    },
  );
  const { data: selectedPlan } = useGetOne<PlanConfig>(
    "plans",
    { id: selectedPlanName },
    {
      enabled: !!selectedPlanName,
      refetchOnWindowFocus: false,
    },
  );
  useEffect(() => {
    if (initialPlan && selectedPlanName !== initialPlan) {
      setInitialPlan(null);
    }
  }, [initialPlan, selectedPlanName]);
  useEffect(() => {
    if (selectedOrganisation && !initialPlan) {
      setValue("plan", selectedOrganisation.plan);
    }
  }, [selectedOrganisation]); // `initialPlan` omitted intentionally
  useEffect(() => {
    if (!selectedPlan || initialPlan) return;
    type RangeWithName = [
      string,
      IntRange | MoneyRange | PercentageRange | BoolRange | MoneyChoices,
    ];
    const ranges: RangeWithName[] = [
      ["minimum_leasing_period", selectedPlan.minimum_leasing_period],
      ["maximum_leasing_period", selectedPlan.maximum_leasing_period],
      [
        "minimum_monthly_taxable_value",
        selectedPlan.minimum_monthly_taxable_value,
      ],
      [
        "maximum_monthly_taxable_value",
        selectedPlan.maximum_monthly_taxable_value,
      ],
      [
        "maximum_taxable_package_price",
        selectedPlan.maximum_taxable_package_price,
      ],
      ["maximum_total_package_price", selectedPlan.maximum_total_package_price],
      ["is_downpayment_allowed", selectedPlan.is_downpayment_allowed],
      ["default_residual_ratio", selectedPlan.residual_ratio],
      [
        "default_monthly_decrease_in_residual_value",
        selectedPlan.monthly_decrease_in_residual_value,
      ],
      [
        "minimum_maintenance_service_monthly",
        selectedPlan.minimum_maintenance_service_monthly,
      ],
      [
        "maximum_maintenance_service_monthly",
        selectedPlan.maximum_maintenance_service_monthly,
      ],
      [
        "standard_coverage_insurance_rate",
        selectedPlan.standard_coverage_insurance_rate,
      ],
      [
        "full_coverage_insurance_rate",
        selectedPlan.full_coverage_insurance_rate,
      ],
      ["insurance_deductible", selectedPlan.insurance_deductible],
      ["net_delivery_fee", selectedPlan.net_delivery_fee],
      [
        "net_customer_service_monthly_fee",
        selectedPlan.net_customer_service_monthly_fee,
      ],
      [
        "net_legacy_warranty_monthly_fee",
        selectedPlan.net_legacy_warranty_monthly_fee,
      ],
      [
        "net_company_monthly_cancellation_coverage",
        selectedPlan.net_company_monthly_cancellation_coverage,
      ],
      [
        "net_individual_monthly_cancellation_coverage",
        selectedPlan.net_individual_monthly_cancellation_coverage,
      ],
      [
        "net_individual_onetime_cancellation_coverage",
        selectedPlan.net_individual_onetime_cancellation_coverage,
      ],
      ["net_return_fee", selectedPlan.net_return_fee],
      ["net_redemption_fee", selectedPlan.net_redemption_fee],
    ];
    ranges.forEach(([fieldName, range]) => {
      if (range.default !== null) {
        setValue(fieldName, range.default);
      }
    });
    if (selectedPlan.fixed_monthly_taxable_value.default !== null) {
      setValue("fixed_monthly_taxable_values", [
        selectedPlan.fixed_monthly_taxable_value.default,
      ]);
    }
    setValue(
      "cancellation_coverage",
      selectedPlan.cancellation_coverage.default,
    );
    setValue("return_fee_coverage", selectedPlan.return_fee_coverage.default);
  }, [selectedPlan, initialPlan]);

  const financingChoices =
    FINANCING_METHODS_CHOICES_BY_COUNTRY[
      selectedOrganisation?.country as SupportedOrganisationCountries
    ] || [];

  const defaultVatRate = useProductVatRate(
    selectedPlan?.country,
    ProductTypes.DEFAULT_PRODUCT,
  );

  return (
    <TabbedFormView toolbar={toolbar} formRootPathname={formRootPathname}>
      <FormTab label="Summary" sx={{ maxWidth: 1024 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="Basic" />
            <TextInput source="name" validate={validateRequired} fullWidth />
            <ReferenceInput source="organisation_id" reference="organisations">
              <AutocompleteInput
                label="Organisation"
                optionText="name"
                optionValue="id"
                fullWidth
                isRequired
                disabled={disableOrganisation}
                helperText={
                  !disableOrganisation &&
                  "Once the benefit definition is created, you won't be able to change the organisation it belongs to!"
                }
              />
            </ReferenceInput>
            <SelectInput
              label="Plan"
              source="plan"
              choices={PLANS_CHOICES}
              fullWidth
              helperText={
                <>
                  {selectedOrganisation &&
                    (selectedOrganisation?.plan ? (
                      <>
                        Organisation plan:{" "}
                        <strong>
                          {PLANS_LABELS[selectedOrganisation.plan as Plan] ??
                            selectedOrganisation.plan}
                        </strong>
                        <br />
                      </>
                    ) : (
                      <>
                        Organisation has no current nor scheduled plan
                        <br />
                      </>
                    ))}
                </>
              }
            />
            <TextInput source="description" fullWidth multiline />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <DateInput
                  source="start_date"
                  validate={validateStartDate}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <DateInput
                  source="end_date"
                  validate={validateEndDate}
                  fullWidth
                />
              </Grid>
            </Grid>
            <NumberInput
              source="order_processing_grace_period_days"
              fullWidth
              helperText="The number of days this benefit definition will be available for accepting and delivering existing orders after the end date"
              required
            />
            {record &&
            (record.end_date !== selectedEndDate ||
              record.order_processing_grace_period_days !==
                selectedOrderProcessingGracePeriodDays) ? (
              <Alert severity="info" sx={{ mt: 2, pb: 1 }}>
                This benefit will be deactivated on {selectedEndDate}. Orders
                will still be processed during the grace period of{" "}
                {selectedOrderProcessingGracePeriodDays} days ending on{" "}
                {orderProcessingEndDate}. Pending orders will be cancelled on{" "}
                {orderProcessingEndDate}.
                <br />
                Note! If applicable, correction and revision orders will still
                be processed after that date. Please cancel them separately.
              </Alert>
            ) : null}
            {record && record.merged_from_benefit_definition_id && (
              <Alert severity="info" sx={{ mt: 2, pb: 1 }}>
                This benefit definition has been merged from{" "}
                <ReferenceField
                  reference="benefit-definitions"
                  source="merged_from_benefit_definition_id"
                >
                  <FunctionField<BenefitDefinitionReadWithOrganisationSummary>
                    render={(benefit) =>
                      benefit
                        ? `${benefit.name} (${benefit.organisation.name})`
                        : "..."
                    }
                    label="Merged from benefit definition"
                  />
                </ReferenceField>
              </Alert>
            )}
            {record && record.duplicated_from_benefit_definition_id && (
              <Alert severity="info" sx={{ mt: 2, pb: 1 }}>
                This benefit definition has been duplicated from{" "}
                <ReferenceField
                  reference="benefit-definitions"
                  source="duplicated_from_benefit_definition_id"
                >
                  <FunctionField<BenefitDefinitionReadWithOrganisationSummary>
                    render={(benefit) =>
                      benefit
                        ? `${benefit.name} (${benefit.organisation.name})`
                        : "..."
                    }
                    label="Duplicated from benefit definition"
                  />
                </ReferenceField>
              </Alert>
            )}
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Information" />
            <TextInput source="to_whom" fullWidth multiline />
            <TextInput source="what" fullWidth multiline />
            <TextInput source="how" fullWidth multiline />
            <Separator />
            <SectionTitle title="Employee details" />
            <BooleanInput source="is_employee_number_mandatory" fullWidth />
            <BooleanInput source="is_cost_center_mandatory" fullWidth />
          </Grid>
          <Grid item md={10}>
            <SectionTitle title="Monthly office fee" />
            {record?.id && record?.plan === selectedPlanName ? (
              <ReferenceManyField
                reference="benefit-definition-monthly-office-fees"
                target="benefit_definition_id"
                sort={{ field: "valid_from", order: "DESC" }}
                fullWidth
              >
                <BenefitDefinitionMonthlyOfficeFeeDatagrid />
              </ReferenceManyField>
            ) : (
              <Typography variant="body2">
                Please save the benefit definition first to manage monthly
                office fee.
              </Typography>
            )}
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Access management">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="Access rules" />
            <BenefitAccessRulesInput />
          </Grid>
          {record?.id ? (
            <>
              <Grid item md={8}>
                <SectionTitle title="All employees" />
                <ReferenceManyField
                  label="All employees"
                  reference="user-benefit-activations"
                  target="benefit_definition_id"
                  perPage={10}
                  pagination={<Pagination />}
                  sort={{ field: "created_at", order: "DESC" }}
                >
                  <Datagrid
                    bulkActionButtons={false}
                    rowClick={false}
                    empty={
                      <Typography variant="body2">
                        No employees have access to this benefit yet
                      </Typography>
                    }
                  >
                    <DateField source="created_at" label="Activation date" />
                    <ReferenceField
                      source="user_id"
                      reference="users"
                      sortable={false}
                      label="Employee name"
                    >
                      <TextField source="full_name" />
                    </ReferenceField>
                    <ReferenceField
                      source="user_id"
                      reference="users"
                      sortable={false}
                      label="Email address"
                    >
                      <TextField source="email" />
                    </ReferenceField>
                    <FunctionField
                      label="Granted by"
                      render={(activation: any) =>
                        activation?.access_request?.resolved_by_user_id ? (
                          <ReferenceManyField
                            source="access_request.resolved_by_user_id"
                            filter={{ include_deleted: true }}
                            target="ids"
                            reference="users"
                          >
                            <SingleFieldList linkType={false}>
                              <FunctionField
                                render={(user: any) =>
                                  user.is_deleted ? (
                                    "Deleted user"
                                  ) : (
                                    <Link to={`/users/${user.id}`}>
                                      {user.full_name || user.email}
                                    </Link>
                                  )
                                }
                              />
                            </SingleFieldList>
                          </ReferenceManyField>
                        ) : (
                          "Email domain"
                        )
                      }
                    />
                    <FunctionField
                      label="Status"
                      render={(activation: any) =>
                        activation.is_active ? "Active" : "Inactive"
                      }
                    />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
              <Grid item md={4}>
                <UserBenefitActivationBenefitCreateButton />
              </Grid>
              <Grid item md={8}>
                <SectionTitle title="Access requests" />
                <ReferenceManyField
                  label="Access requests"
                  reference="benefit-definition-access-requests"
                  target="benefit_definition_id"
                  perPage={10}
                  pagination={<Pagination />}
                  sort={{ field: "created_at", order: "DESC" }}
                >
                  <Datagrid
                    bulkActionButtons={false}
                    rowClick={false}
                    empty={
                      <Typography variant="body2">
                        No access requests have been sent for this benefit yet
                      </Typography>
                    }
                  >
                    <DateField source="created_at" label="Request date" />
                    <ReferenceField
                      source="user_id"
                      reference="users"
                      sortable={false}
                      label="Employee name"
                    >
                      <TextField source="full_name" />
                    </ReferenceField>
                    <ReferenceField
                      source="user_id"
                      reference="users"
                      sortable={false}
                      label="Email address"
                    >
                      <TextField source="email" />
                    </ReferenceField>
                    <TextField source="status" textTransform="capitalize" />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
            </>
          ) : (
            <Grid item md={8}>
              Please save the benefit definition first to manage benefit
              activations and access requests.
            </Grid>
          )}
        </Grid>
      </FormTab>
      <FormTab label="Leasing Parameters" sx={{ maxWidth: 1024 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="Leasing period" />
            <NumberInput
              label="Minimum leasing period (in months)"
              source="minimum_leasing_period"
              validate={validateMinLeasingPeriod(selectedPlan)}
              disabled={isDisabled(selectedPlan?.minimum_leasing_period)}
              fullWidth
              step="1"
            />
            <NumberInput
              label="Maximum leasing period (in months)"
              source="maximum_leasing_period"
              validate={validateMaxLeasingPeriod(selectedPlan)}
              disabled={isDisabled(selectedPlan?.maximum_leasing_period)}
              fullWidth
              step="1"
            />
            <Separator />
            <SectionTitle title="Taxable value & package" />
            <MoneyInput
              source="minimum_monthly_taxable_value"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.minimum_monthly_taxable_value,
              )}
              disabled={isDisabled(selectedPlan?.minimum_monthly_taxable_value)}
              fullWidth
            />
            <MoneyInput
              source="maximum_monthly_taxable_value"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.maximum_monthly_taxable_value,
              )}
              disabled={isDisabled(selectedPlan?.maximum_monthly_taxable_value)}
              fullWidth
            />
            <SelectArrayInput
              source="fixed_monthly_taxable_values"
              choices={
                selectedPlan
                  ? selectedPlan.fixed_monthly_taxable_value.values.map(
                      (value: number) => ({ id: value, name: value }),
                    )
                  : []
              }
              fullWidth
              validate={
                selectedPlan?.fixed_monthly_taxable_value.required
                  ? required()
                  : undefined
              }
            />
            <FormDataConsumer>
              {({ formData }) => (
                <SelectInput
                  source="excess_monthly_payer"
                  choices={Object.values(Payer).map((value) => ({
                    id: value,
                    name: capitalize(value),
                  }))}
                  helperText={
                    formData.excess_monthly_payer == Payer.EMPLOYEE
                      ? "The monthly payment excess (over 100€/2000SEK) will be automatically charged monthly from the beneficiary’s payment card."
                      : formData.excess_monthly_payer == Payer.EMPLOYER
                      ? "The total monthly payment amount will be invoiced from the employer"
                      : undefined
                  }
                  fullWidth
                />
              )}
            </FormDataConsumer>
            <MoneyInput
              label="Maximum taxable package price (excl. down payment)"
              source="maximum_taxable_package_price"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.maximum_taxable_package_price,
                false,
              )}
              disabled={isDisabled(selectedPlan?.maximum_taxable_package_price)}
              fullWidth
            />
            <MoneyInput
              source="maximum_total_package_price"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.maximum_total_package_price,
              )}
              disabled={isDisabled(selectedPlan?.maximum_total_package_price)}
              fullWidth
            />
            <BooleanInput
              source="is_downpayment_allowed"
              validate={validateBoolean(selectedPlan?.is_downpayment_allowed)}
              fullWidth
            />
            <Separator />
            <SectionTitle title="Redemption value" />
            <PercentInput
              source="default_residual_ratio"
              validate={validatePercent(selectedPlan?.residual_ratio)}
              disabled={isDisabled(selectedPlan?.residual_ratio)}
              fullWidth
            />
            <PercentInput
              source="default_monthly_decrease_in_residual_value"
              validate={validatePercent(
                selectedPlan?.monthly_decrease_in_residual_value,
              )}
              disabled={isDisabled(
                selectedPlan?.monthly_decrease_in_residual_value,
              )}
              fullWidth
            />
            <PercentInput
              source="fixed_end_of_lease_redemption_percentage"
              helperText="Optional fixed redemption percentage applied to redemptions on the planned end date"
              fullWidth
              disabled
            />
            <BooleanInput
              source="is_end_of_lease_redemption_price_discounted"
              helperText="Apply 75€/100€/150€/200€/250€ redemption price discount based on the taxable package price. Applies only to redemptions on the planned end date."
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Options" />
            <MoneyInput
              source="minimum_maintenance_service_monthly"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.minimum_maintenance_service_monthly,
              )}
              disabled={isDisabled(
                selectedPlan?.minimum_maintenance_service_monthly,
              )}
              fullWidth
            />
            <MoneyInput
              source="maximum_maintenance_service_monthly"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.maximum_maintenance_service_monthly,
              )}
              disabled={isDisabled(
                selectedPlan?.maximum_maintenance_service_monthly,
              )}
              fullWidth
            />
            <Separator />
            <SectionTitle title="Contract financials" />
            <PercentInput
              source="standard_coverage_insurance_rate"
              validate={validatePercent(
                selectedPlan?.standard_coverage_insurance_rate,
              )}
              disabled={isDisabled(
                selectedPlan?.standard_coverage_insurance_rate,
              )}
              fullWidth
            />
            <PercentInput
              source="full_coverage_insurance_rate"
              validate={validatePercent(
                selectedPlan?.full_coverage_insurance_rate,
              )}
              disabled={isDisabled(selectedPlan?.full_coverage_insurance_rate)}
              fullWidth
            />
            <MoneyInput
              source="insurance_deductible"
              currency={selectedPlan?.currency}
              validate={validateNumber(selectedPlan?.insurance_deductible)}
              disabled={isDisabled(selectedPlan?.insurance_deductible)}
              fullWidth
            />
            <GrossPriceInput
              source="net_delivery_fee"
              label="Delivery fee"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_delivery_fee,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(selectedPlan?.net_delivery_fee)}
              fullWidth
            />
            <GrossPriceInput
              source="net_customer_service_monthly_fee"
              label="Customer service monthly fee"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_customer_service_monthly_fee,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(
                selectedPlan?.net_customer_service_monthly_fee,
              )}
              fullWidth
            />
            <GrossPriceInput
              source="net_legacy_warranty_monthly_fee"
              label="Legacy warranty monthly fee"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_legacy_warranty_monthly_fee,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(
                selectedPlan?.net_legacy_warranty_monthly_fee,
              )}
              fullWidth
            />
            <SelectInput
              label="Cancellation coverage"
              placeholder="Choose a cancellation coverage"
              choices={
                selectedPlan
                  ? selectedPlan.cancellation_coverage.values.map(
                      (value: string) => ({ id: value, name: value }),
                    )
                  : []
              }
              source="cancellation_coverage"
              fullWidth
              required
            />
            <GrossPriceInput
              source="net_company_monthly_cancellation_coverage"
              label="Company monthly cancellation coverage"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_company_monthly_cancellation_coverage,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(
                selectedPlan?.net_company_monthly_cancellation_coverage,
              )}
              fullWidth
            />
            <GrossPriceInput
              source="net_individual_monthly_cancellation_coverage"
              label="Individual monthly cancellation coverage"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_individual_monthly_cancellation_coverage,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(
                selectedPlan?.net_individual_monthly_cancellation_coverage,
              )}
              fullWidth
            />
            <GrossPriceInput
              source="net_individual_onetime_cancellation_coverage"
              label="Individual onetime cancellation coverage"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_individual_onetime_cancellation_coverage,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(
                selectedPlan?.net_individual_onetime_cancellation_coverage,
              )}
              fullWidth
            />
            <SelectInput
              label="Return fee coverage"
              placeholder="Choose a return fee coverage"
              choices={
                selectedPlan
                  ? selectedPlan.return_fee_coverage.values.map(
                      (value: string) => ({ id: value, name: value }),
                    )
                  : []
              }
              source="return_fee_coverage"
              fullWidth
              required
            />
            <GrossPriceInput
              source="net_return_fee"
              label="Return fee"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_return_fee,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(selectedPlan?.net_return_fee)}
              fullWidth
            />
            <GrossPriceInput
              source="net_redemption_fee"
              label="Redemption fee"
              currency={selectedPlan?.currency}
              validate={validateNumber(
                selectedPlan?.net_redemption_fee,
                true,
                true,
              )}
              vatRate={defaultVatRate}
              netDecimalPoints={4}
              grossDecimalPoints={2}
              disabled={isDisabled(selectedPlan?.net_redemption_fee)}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Billing details" sx={{ maxWidth: 1024 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <SectionTitle title="General" />
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  source="organisation_billing_details_id"
                  reference="organisation-billing-details"
                  filter={{ organisation_id: formData.organisation_id }}
                >
                  <AutocompleteInput
                    optionText={(choice) =>
                      `${choice.netvisor_customer_code} - ${choice.invoicing_method}`
                    }
                    disabled={!formData.organisation_id}
                    validate={validateRequired}
                  />
                </ReferenceInput>
              )}
            </FormDataConsumer>
            <NumberInput
              source="billing_frequency"
              validate={validateNumMin0Optional}
              fullWidth
            />
            <NumberInput
              source="payment_terms"
              validate={validateNumMin0Optional}
              fullWidth
            />
            <TextInput source="service_fee_product_code" fullWidth />
            <TextInput source="bike_contract_product_code" fullWidth />
          </Grid>
          <Grid item md={6}>
            <SectionTitle title="Financing" />
            <SelectInput
              source="financing_method"
              choices={financingChoices}
              onChange={handleFinancingMethodChange}
              validate={validateRequired}
              fullWidth
            />
            <FormDataConsumer>
              {({ formData }) => {
                switch (formData.financing_method) {
                  case FinancingMethods.Tukirahoitus:
                  case FinancingMethods.TukirahoitusPuitesopimus:
                    return (
                      <>
                        <TextInput
                          source="financing_application_number"
                          fullWidth
                        />
                        <TextInput
                          source="financing_decision_number"
                          fullWidth
                        />
                        <DateInput
                          source="financing_decision_date"
                          validate={validateDecisionDate}
                          fullWidth
                        />
                        <DateInput
                          source="financing_decision_expiration_date"
                          validate={validateDecisionExpirationDate}
                          fullWidth
                        />
                        <NumberInput
                          source="financing_decision_max_leasing_period"
                          validate={validateDecisionMaxLeasingPeriod}
                          fullWidth
                        />
                        <MoneyInput
                          source="financing_decision_total_amount"
                          validate={validateNumMin0Optional}
                          fullWidth
                        />
                        <MoneyInput
                          source="financing_decision_used_amount"
                          validate={validateNumMin0Optional}
                          fullWidth
                        />
                        <MoneyInput
                          source="financing_decision_remaining_amount"
                          validate={validateNumMin0Optional}
                          fullWidth
                        />
                      </>
                    );
                }
                return null;
              }}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Terms & conditions">
        <NestedBenefitTermsDataGrid />
      </FormTab>
      <PermissionsTab />
    </TabbedFormView>
  );
};

const PermissionsTab = (props: Omit<FormTabProps, "label">) => {
  return props.hidden ? null : (
    <FormTab sx={{ maxWidth: 500 }} {...props} label="Permissions">
      <ReferenceManyToManyInput
        source="id"
        reference="users"
        through="benefit-managers"
        using="benefit_definition_id,user_id"
        perPage={200}
        perPageChoices={200}
      >
        <AutocompleteArrayInput
          label="Benefit managers"
          optionText="email"
          fullWidth
        />
      </ReferenceManyToManyInput>
    </FormTab>
  );
};

export default BenefitDefinitionForm;
