import { FC } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { round, sumBy } from "lodash-es";
import {
  Datagrid,
  NumberField,
  ReferenceField,
  TextField,
  useGetOne,
  useRecordContext,
} from "react-admin";

import { toGross, toNet } from "@vapaus/utils";

import { Separator, UpdateOrderNumberButton } from "components";

type OrderContentsEntry = {
  type: string;
  description: string;
  price: number;
  discounted_price: number | null;
};

export const ContractContents: FC = () => {
  const {
    bike_benefit_order_id,
    additional_services,
    accessories,
    bike,
    bike_price,
    currency,
    delivery_transport,
    total_maintenance_budget,
    monthly_maintenance_budget,
    leasing_period_months,
  } = useRecordContext();
  const { data: order } = useGetOne("bike-benefit-orders", {
    id: bike_benefit_order_id,
  });
  if (!order) return null;
  const order_contents: OrderContentsEntry[] = [
    {
      type: "Bike",
      description: bike.bike_brand.name + " " + bike.model,
      price: bike_price,
      discounted_price: round(bike_price * (1 - order.discount_bike), 2),
    },
  ];

  const toOrderVatRate = (price: number, vatRate: number) =>
    toGross(toNet(price, vatRate), order.flat_vat_rate);

  for (const accessory of accessories) {
    const accessoryGrossPrice = toOrderVatRate(
      accessory.purchase_price,
      accessory.purchase_vat_rate,
    );
    order_contents.push({
      type: accessory.accessory_type,
      description: accessory.manufacturer + " " + accessory.model,
      price: accessoryGrossPrice,
      discounted_price: round(
        accessoryGrossPrice * (1 - order.discount_accessory),
        2,
      ),
    });
  }

  for (const service of additional_services) {
    const serviceGrossPrice = toOrderVatRate(
      service.price_gross,
      service.vat_rate,
    );
    order_contents.push({
      type: service.service_type,
      description: service.description,
      price: serviceGrossPrice,
      discounted_price: serviceGrossPrice,
    });
  }

  if (delivery_transport) {
    const transportGrossPrice = toOrderVatRate(
      delivery_transport.price_gross,
      delivery_transport.vat_rate,
    );
    order_contents.push({
      type: "Delivery",
      description: "-",
      price: transportGrossPrice,
      discounted_price: transportGrossPrice,
    });
  }

  if (total_maintenance_budget > 0) {
    order_contents.push({
      type: "Maintenance",
      description:
        monthly_maintenance_budget + " * " + leasing_period_months + " months",
      price: total_maintenance_budget,
      discounted_price: null,
    });
  }

  order_contents.push({
    type: "Total",
    description: "",
    price: sumBy(order_contents, (item) => item.price),
    discounted_price: sumBy(
      order_contents.filter(Boolean),
      (item) => item.discounted_price!,
    ),
  });
  const flatVatRate = order.flat_vat_rate.toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: 2,
  });
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel-contract-contents-content"
        id="panel-contract-contents-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <ShoppingCartIcon sx={{ mr: 2 }} />{" "}
        <Typography>Order contents</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="order-summary-item">
          <Typography className="label">Order</Typography>
          <ReferenceField
            source="bike_benefit_order_id"
            reference="bike-benefit-orders"
            link={`/bike-benefit-orders/${bike_benefit_order_id}/show`}
          >
            <TextField source="vapaus_code" />
          </ReferenceField>
        </div>
        <div className="order-summary-item">
          <Typography className="label">Order number</Typography>
          <div>
            <ReferenceField
              source="bike_benefit_order_id"
              reference="bike-benefit-orders"
              link={`/bike-benefit-orders/${bike_benefit_order_id}/show`}
            >
              <TextField source="order_number" emptyText="-" />
            </ReferenceField>
            <UpdateOrderNumberButton orderId={bike_benefit_order_id} />
          </div>
        </div>
        <div className="order-summary-item">
          <Typography className="label">Shop</Typography>
          <ReferenceField
            source="bike_benefit_order_id"
            reference="bike-benefit-orders"
          >
            <ReferenceField source="shop_id" reference="shops">
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
        </div>
        <div className="order-summary-item">
          <Typography className="label">Discount bike</Typography>
          <NumberField
            record={order}
            source="discount_bike"
            options={{ style: "percent", maximumFractionDigits: 2 }}
          />
        </div>
        <div className="order-summary-item">
          <Typography className="label">Discount accessory</Typography>
          <NumberField
            record={order}
            source="discount_accessory"
            options={{ style: "percent", maximumFractionDigits: 2 }}
          />
        </div>
        <Separator />
        <Datagrid data={order_contents} sort={{ field: "type", order: "ASC" }}>
          <TextField source="type" />
          <TextField source="description" />
          <NumberField
            label={`Price (VAT ${flatVatRate})`}
            source="price"
            options={{ style: "currency", currency }}
          />
          <NumberField
            label={`Discounted price (VAT ${flatVatRate})`}
            source="discounted_price"
            options={{ style: "currency", currency }}
          />
        </Datagrid>
      </AccordionDetails>
    </Accordion>
  );
};
