import { EditVersionedInDialogButton } from "components/EditVersionedInDialogButton";

import { FleetAccessRulesForm } from "./FleetAccessRulesForm";
import { EditToolbar } from "components";

export const FleetAccessRulesEditButton = () => {
  return (
    <EditVersionedInDialogButton
      fullWidth
      maxWidth="md"
      title="Edit fleet access rule"
      mutationMode="pessimistic"
    >
      <FleetAccessRulesForm toolbar={<EditToolbar />} />
    </EditVersionedInDialogButton>
  );
};
