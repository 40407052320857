import {
  Datagrid,
  DateField,
  List,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { InvoiceStatusField } from "components";
import { Pagination } from "components";
import { INVOICE_STATUS_CHOICES } from "core";

const filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    label="Status"
    source="status"
    choices={INVOICE_STATUS_CHOICES}
    alwaysOn
  />,
];

export const InvoiceList = () => (
  <List filters={filters} perPage={20} pagination={<Pagination />}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="invoice_number" />
      <TextField source="customer_full_name" label="Customer" />
      <TextField source="vapaus_code" sortable={false} />
      <DateField source="date" />
      <DateField source="due_date" />
      <TextField source="currency" />
      <NumberField source="vat" />
      <NumberField source="total" />
      <InvoiceStatusField source="status" textTransform="uppercase" />
    </Datagrid>
  </List>
);
