import React, { useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import MergeIcon from "@mui/icons-material/Merge";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  Datagrid,
  DatagridClasses,
  DateField,
  DateInput,
  Form,
  FormDataConsumer,
  List,
  Pagination,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextField,
  TextInput,
  required,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
  useUnselectAll,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { BikeBenefitOrderState } from "@vapaus/generated";

import { useCustomMutationOptions, useVapausMutation } from "hooks";

export const ProcessMergerModal = ({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) => {
  const { mutate, isLoading } = useVapausMutation(
    "processBenefitDefinitionMerger",
  );
  const benefitDefinition = useRecordContext();
  const { onError } = useCustomMutationOptions();
  const notify = useNotify();
  const redirect = useRedirect();
  const unselectAllContracts = useUnselectAll("bike-benefit-contracts");
  const unselectAllOrders = useUnselectAll("bike-benefit-orders");

  useEffect(() => {
    unselectAllContracts();
    unselectAllOrders();
  }, [open]);

  if (!benefitDefinition) return null;

  const handleSubmit = async (formData: any) => {
    try {
      mutate(
        {
          id: benefitDefinition.id,
          params: formData,
        },
        {
          onSuccess: (newBenefitDefinition: any) => {
            onClose();
            redirect(`/benefit-definitions/${newBenefitDefinition.id}`);
            notify(
              "Merger processed successfully. You've been redirected to the new benefit definition.",
            );
          },
          onError,
        },
      );
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label="Process organisation merger"
      sx={{ form: { display: "contents" } }}
    >
      <Form
        record={{
          target_organisation_id: null,
          target_organisation_billing_details_id: null,
          contract_ids: [],
          order_ids: [],
          merger_date: DateTime.now().toISODate(),
          copy_managers: true,
        }}
        onSubmit={handleSubmit}
        key={open.toString()}
      >
        <DialogTitle>Process organisation merger</DialogTitle>
        <DialogContent>
          <TargetOrganisationField />
          <TargetOrganisationBillingDetailsField />
          <DateInput source="merger_date" isRequired fullWidth />
          <BooleanInput
            source="copy_managers"
            label="Copy benefit manager permissions"
          />
          <ContractListField
            benefitDefinitionId={benefitDefinition.id.toString()}
          />
          <OrderListField
            benefitDefinitionId={benefitDefinition.id.toString()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isLoading}
            startIcon={<CancelIcon />}
            label="Cancel"
          />
          <FormDataConsumer>
            {({ formData }) => (
              <SaveButton
                label="Process merger"
                disabled={
                  isLoading ||
                  !(formData.contract_ids.length || formData.order_ids.length)
                }
                icon={<MergeIcon />}
              />
            )}
          </FormDataConsumer>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

const TargetOrganisationField = () => {
  const { setValue } = useFormContext();
  return (
    <ReferenceInput source="target_organisation_id" reference="organisations">
      <AutocompleteInput
        label="Target organisation"
        optionText="name"
        optionValue="id"
        fullWidth
        isRequired
        onChange={() => {
          setValue("target_organisation_billing_details_id", null);
        }}
      />
    </ReferenceInput>
  );
};

const TargetOrganisationBillingDetailsField = () => {
  const { watch } = useFormContext();
  const target_organisation_id = watch("target_organisation_id");
  const { data: organisationBillingDetails, isLoading } = useGetList(
    "organisation-billing-details",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "DESC" },
      filter: { organisation_id: target_organisation_id || undefined },
    },
  );
  return (
    <SelectInput
      source="target_organisation_billing_details_id"
      choices={
        organisationBillingDetails?.map((detail) => ({
          id: detail.id,
          name: `${detail.netvisor_customer_code} - ${detail.invoicing_method}`,
        })) || []
      }
      defaultValue={
        organisationBillingDetails?.length == 1
          ? organisationBillingDetails[0].id
          : null
      }
      disabled={!target_organisation_id || isLoading}
      validate={required()}
      fullWidth
    />
  );
};

const ContractListField = ({
  benefitDefinitionId,
}: {
  benefitDefinitionId: string;
}) => {
  const { watch } = useFormContext();
  const currentContractIds = watch("contract_ids");
  return (
    <>
      <Typography variant="subtitle1">
        Please select contracts to be moved to the new organisation
      </Typography>
      <List
        resource="bike-benefit-contracts"
        perPage={50}
        filter={{ benefit_definition_id: benefitDefinitionId }}
        filters={[
          <TextInput
            label="Search"
            source="q"
            alwaysOn
            sx={{ mb: currentContractIds.length ? 8 : 0 }}
          />,
        ]}
        pagination={<Pagination rowsPerPageOptions={[]} />}
        exporter={false}
        disableSyncWithLocation
        empty={
          <Typography variant="body2">
            This benefit definition has no contracts. No contract will be moved
            to the new organisation.
          </Typography>
        }
        sx={{ flexFlow: "column" }}
      >
        <Datagrid
          bulkActionButtons={<ContractListBulkActions />}
          sx={{
            maxWidth: 1024,
            width: "100%",
            ["." + DatagridClasses.tableWrapper]: {
              overflowX: "auto",
              overflowY: "clip",
            },
          }}
          sort={{ field: "created_at", order: "DESC" }}
          rowClick={(id, resource) => {
            window.open(`#/${resource}/${id}/show`, "_blank");
            return false;
          }}
        >
          <TextField source="vapaus_code" sortable={false} />
          <DateField source="start_date" sortable={false} />
          <DateField source="end_date" sortable={false} />
          <TextField
            source="state"
            textTransform="uppercase"
            sortable={false}
          />
        </Datagrid>
      </List>
    </>
  );
};

const ContractListBulkActions = ({
  selectedIds,
}: {
  selectedIds?: string[];
}) => {
  const { setValue, watch } = useFormContext();
  const currentContractIds = watch("contract_ids");
  useEffect(() => {
    if (JSON.stringify(currentContractIds) !== JSON.stringify(selectedIds))
      setValue("contract_ids", selectedIds);
  }, [currentContractIds, selectedIds]);
  return null;
};

const OrderListField = ({
  benefitDefinitionId,
}: {
  benefitDefinitionId: string;
}) => {
  const { watch } = useFormContext();
  const currentOrderIds = watch("order_ids");
  return (
    <>
      <Typography variant="subtitle1">
        Please select orders to be moved to the new organisation
      </Typography>
      <List
        resource="bike-benefit-orders"
        perPage={50}
        filter={{
          benefit_definition_id: benefitDefinitionId,
          states: [
            BikeBenefitOrderState.DRAFT,
            BikeBenefitOrderState.WAITING_FOR_PAYMENT,
            BikeBenefitOrderState.READY_FOR_DELIVERY,
          ],
        }}
        filters={[
          <TextInput
            label="Search"
            source="q"
            alwaysOn
            sx={{ mb: currentOrderIds.length ? 8 : 0 }}
          />,
        ]}
        pagination={<Pagination rowsPerPageOptions={[]} />}
        exporter={false}
        disableSyncWithLocation
        empty={
          <Typography variant="body2">
            This benefit definition has no active/draft orders. No order will be
            moved to the new organisation.
          </Typography>
        }
        sx={{ flexFlow: "column" }}
      >
        <Datagrid
          bulkActionButtons={<OrderListBulkActions />}
          sx={{
            maxWidth: 1024,
            width: "100%",
            ["." + DatagridClasses.tableWrapper]: {
              overflowX: "auto",
              overflowY: "clip",
            },
          }}
          sort={{ field: "created_at", order: "DESC" }}
          rowClick={(id, resource) => {
            window.open(`#/${resource}/${id}/show`, "_blank");
            return false;
          }}
        >
          <TextField source="vapaus_code" sortable={false} />
          <DateField source="created_at" sortable={false} />
          <TextField
            source="state"
            textTransform="uppercase"
            sortable={false}
          />
        </Datagrid>
      </List>
    </>
  );
};

const OrderListBulkActions = ({ selectedIds }: { selectedIds?: string[] }) => {
  const { setValue, watch } = useFormContext();
  const currentOrderIds = watch("order_ids");
  useEffect(() => {
    if (JSON.stringify(currentOrderIds) !== JSON.stringify(selectedIds))
      setValue("order_ids", selectedIds);
  }, [currentOrderIds, selectedIds]);
  return null;
};
