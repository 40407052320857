import ReceiptLong from "@mui/icons-material/ReceiptLong";
import { ResourceProps } from "react-admin";

import MonthlyInvoicesGenerate from "./MonthlyInvoicesGenerate";

const billingResource: ResourceProps = {
  name: "billing",
  icon: ReceiptLong,
  list: MonthlyInvoicesGenerate,
  options: {
    label: "Billing",
  },
};

export default billingResource;
