import { Enum } from "@vapaus/generated";

import { useVapausQuery } from "./useCustomMutation";

export const useEnum = (identifier: string) => {
  const { data, isSuccess: isEnumsLoaded } = useVapausQuery<Array<Enum>>(
    "getEnums",
    {},
  );
  if (!isEnumsLoaded) return [];
  return (
    data.find((e: { identifier: string }) => e.identifier === identifier)
      ?.entries ?? []
  );
};

export const useEnumChoices = (identifier: string) => {
  const entries = useEnum(identifier);
  return entries.map((choice: { value: string; label: string }) => ({
    id: choice.value,
    name: choice.label,
  }));
};

export const useEnumLabel = (identifier: string) => {
  const entries = useEnum(identifier);
  return (value?: string) =>
    entries.find((e) => e.value === value)?.label ?? value; // If the label is not found, return the value as fallback
};
