import React from "react";

import { Edit, useCreatePath } from "react-admin";
import { useLocation } from "react-router-dom";

import { useCustomMutationOptions } from "../../hooks";
import { MaintenanceEventForm } from "./MaintenanceEventForm";

const MaintenanceEventTitle = () => {
  return <span>Edit maintenance event</span>;
};

export const MaintenanceEventEdit = () => {
  const location = useLocation();
  const createPath = useCreatePath();
  const mutationOptions = useCustomMutationOptions();
  const contractId = location.state?.record?.bike_benefit_contract_id;
  const bikeId = location.state?.record?.bike_id;

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect={
        contractId
          ? createPath({
              resource: "bike-benefit-contracts",
              id: contractId,
              type: "show",
            })
          : bikeId
          ? createPath({
              resource: "bikes",
              id: bikeId,
              type: "edit",
            })
          : "edit"
      }
      title={<MaintenanceEventTitle />}
    >
      <MaintenanceEventForm editMode />
    </Edit>
  );
};
