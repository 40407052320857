import { ReactElement } from "react";

import { Grid } from "@mui/material";
import { TextListInput } from "components/TextListInput";
import { SelectInput, SimpleForm, TextInput } from "react-admin";

import { ACCESS_RULES_REQUEST_REQUIREMENTS_CHOICES } from "core";

type BenefitAccessRulesFormProps = {
  toolbar?: ReactElement;
};

export const BenefitAccessRulesForm = ({
  toolbar,
}: BenefitAccessRulesFormProps) => {
  return (
    <SimpleForm toolbar={toolbar}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextInput source="name" label="Name" fullWidth required />
          <SelectInput
            source="request_requirement"
            choices={ACCESS_RULES_REQUEST_REQUIREMENTS_CHOICES}
            fullWidth
            required
          />
        </Grid>
        <Grid item md={6}>
          <TextListInput
            source="allowed_email_addresses"
            label="Allowed email addresses"
            helperText="Separate with comma"
          />
          <TextListInput
            source="blacklist_emails"
            label="Blacklisted email addresses"
            disabled
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
