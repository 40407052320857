import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Form,
  SaveButton,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";

import { BookingTermsAndConditionsForm } from "./BookingTermsAndConditionsForm";
import { useCustomMutationOptions } from "hooks";

export const CreateBookingTermsAndConditionsButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const { onError } = useCustomMutationOptions();

  const [create, { isLoading }] = useCreate();
  const refresh = useRefresh();

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      organisation_id: record.id,
    };

    create(
      "booking-terms-and-conditions",
      { data },
      {
        onSuccess: () => {
          notify("Created successfully");
          setShowDialog(false);
          refresh();
        },
        onError,
      },
    );
  };

  const handleOpenClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button onClick={handleOpenClick}>
        Add booking terms and conditions
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create booking terms and conditions"
      >
        <DialogTitle>Create booking terms and conditions</DialogTitle>
        <Form onSubmit={handleSubmit} record={{}}>
          <DialogContent>
            <BookingTermsAndConditionsForm />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseClick}
              disabled={isLoading}
              startIcon={<CancelIcon />}
            >
              {translate("ra.action.cancel")}
            </Button>
            <SaveButton
              label="Create booking terms and conditions"
              disabled={isLoading}
              alwaysEnable={!isLoading}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
