import React from "react";

import { Edit, useRecordContext } from "react-admin";

import FleetForm from "./FleetForm";
import { FleetActivateButton } from "./components/FleetActivateButton";
import { FleetDeactivateButton } from "./components/FleetDeactivateButton";
import { EditToolbar } from "components";
import { useCustomMutationOptions } from "hooks";

const FleetTitle = () => {
  const record = useRecordContext();
  return <span>{record?.name}</span>;
};

const FleetEditToolbar = () => {
  const record = useRecordContext();
  return (
    <EditToolbar>
      {record.is_active ? <FleetDeactivateButton /> : <FleetActivateButton />}
    </EditToolbar>
  );
};

export const FleetEdit = () => {
  const mutationOptions = useCustomMutationOptions();
  const transform = (formData: any) => {
    const data = { ...formData };
    delete data.logo_url;
    if (!data.logo_object_id) delete data.logo_object_id;
    return data;
  };

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<FleetTitle />}
      transform={transform}
    >
      <FleetForm toolbar={<FleetEditToolbar />} disableOrganisation />
    </Edit>
  );
};
