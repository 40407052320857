/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum VehicleType {
  BIKE = "bike",
  CAR = "car",
  SCOOTER = "scooter",
}
