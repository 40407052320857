import { FC } from "react";

import { Grid } from "@mui/material";

const EmptyBoxInfo: FC<{ message: string }> = ({ message }) => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        md={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <p>{message}</p>
      </Grid>
    </Grid>
  );
};

export default EmptyBoxInfo;
