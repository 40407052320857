import Alert from "@mui/material/Alert";
import { RowForm } from "@react-admin/ra-editable-datagrid";
import { DateInput, number, required, useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { maxPercent, minPercent } from "validators";

import { PercentInput } from "components";

const validatePercent = [required(), number(), minPercent(0), maxPercent(100)];

export const ShopDiscountsForm = () => {
  const { getValues } = useFormContext();
  const record = useRecordContext();
  return (
    <>
      <RowForm defaultValues={{ shop_id: getValues("id") }}>
        <DateInput source="valid_from" validate={required()} />
        <PercentInput source="discount_bike" validate={validatePercent} />
        <PercentInput source="discount_accessory" validate={validatePercent} />
      </RowForm>
      {record?.id ? (
        <Alert severity="warning" sx={{ mt: 1 }}>
          This change will affect bike purchase prices of existing orders.
          Please be sure that you wish to proceed with this update.
        </Alert>
      ) : null}
    </>
  );
};
