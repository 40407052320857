import { FC, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  DateInput,
  Form,
  NumberInput,
  SaveButton,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  useUpdate,
} from "react-admin";

import { useCustomMutationOptions } from "hooks";

export const UpdatePurchaseInvoiceButton: FC = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();

  const [update, { isLoading }] = useUpdate();

  const { onError } = useCustomMutationOptions();

  const handleOpenClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = (purchaseInvoice: any) => {
    update(
      "invoices/purchase-invoices",
      {
        id: record.id,
        data: purchaseInvoice,
      },
      {
        onSuccess: () => {
          refresh();
          notify("Purchase invoice updated");
          setShowDialog(false);
        },
        onError,
      },
    );
  };

  return (
    <>
      <Button
        color="primary"
        startIcon={<EditIcon />}
        onClick={handleOpenClick}
      />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit Purchase Invoice"
      >
        <DialogTitle>Edit Purchase Invoice</DialogTitle>
        <Form onSubmit={handleSubmit} record={record}>
          <DialogContent>
            <DateInput source="date" fullWidth required />
            <TextInput source="invoice_number" fullWidth required />
            <NumberInput source="total" fullWidth required />
            <TextInput source="description" fullWidth multiline />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseClick}
              disabled={isLoading}
              startIcon={<CancelIcon />}
            >
              {translate("ra.action.cancel")}
            </Button>

            <SaveButton
              label="Update Purchase Invoice"
              disabled={isLoading}
              alwaysEnable={!isLoading}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
