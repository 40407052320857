import { useState } from "react";

import { useNotify } from "react-admin";

export const downloadContent = (
  content: string | Blob,
  filename: string,
  type: string,
) => {
  const blob =
    typeof content === "string" ? new Blob([content], { type }) : content;
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export type DownloadStatus = "idle" | "error" | "loading" | "success";

export declare type DownloadFunction<TVariables = unknown> = (
  variables: TVariables,
) => Promise<any>; // we could have added a return type like: Promise<TData> but I don't think it's relevant since it will be directly passed to the downloadContent function

export interface DownloadResult<TVariables = unknown, TError = unknown> {
  status: DownloadStatus;
  download: (
    variables: TVariables,
    type: string,
    defaultFilename?: string,
  ) => Promise<void>; // type's type can probably be improved
  error: TError | null;
}

export const useDownload = <TVariables = unknown, TError = unknown>(
  downloadFn: DownloadFunction<TVariables>,
): DownloadResult<TVariables, TError> => {
  const [status, setStatus] = useState<DownloadStatus>("idle");
  const [error, setError] = useState<TError | null>(null);
  const notify = useNotify();
  const download = async (
    variables: TVariables,
    type: string,
    defaultFilename?: string,
  ) => {
    try {
      setStatus("loading");
      setError(null);
      const { body, filename } = await downloadFn(variables);
      downloadContent(body, filename || defaultFilename, type);
      setStatus("success");
    } catch (error: any) {
      setError(error);
      setStatus("error");
      notify(error.toString());
    }
  };

  return { status, error, download };
};
