import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type ConfirmationPopupContextType = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  content: ReactNode;
};

const defaultState: ConfirmationPopupContextType = {
  isOpen: false,
  setIsOpen: () => {},
  title: "",
  content: "",
};

export const ConfirmationPopupContext =
  createContext<ConfirmationPopupContextType>(defaultState);

type ConfirmationPopupContextProviderProps = {
  title: string;
  content: ReactNode;
  children: ReactNode;
};

export const ConfirmationPopupContextProvider = ({
  title: initialTitle,
  content: initialContent,
  children,
}: ConfirmationPopupContextProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<ReactNode>("");

  useEffect(() => {
    setTitle(initialTitle);
    setContent(initialContent);
  }, []);

  const contextValue: ConfirmationPopupContextType = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      title,
      content,
    }),
    [isOpen, setIsOpen, title, content],
  );

  return (
    <ConfirmationPopupContext.Provider value={contextValue}>
      {children}
    </ConfirmationPopupContext.Provider>
  );
};

export const useConfirmationPopup = () =>
  useContext<ConfirmationPopupContextType>(ConfirmationPopupContext);
