/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BikeBenefitOrderState {
  DRAFT = "draft",
  READY_FOR_DELIVERY = "ready_for_delivery",
  WAITING_FOR_PAYMENT = "waiting_for_payment",
  FINALISED = "finalised",
  CANCELLED = "cancelled",
}
