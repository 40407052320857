import * as React from "react";

import { DatagridActionsColumn } from "components/DatagridActionsColumn";
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EmailField,
  ReferenceManyField,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { EmailCreateButton } from "./EmailCreateButton";
import { EmailMakePrimaryButton } from "./EmailMakePrimaryButton";
import { SectionTitle } from "components";
import { useCustomMutationOptions } from "hooks";

const EmailsManager = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const { onError } = useCustomMutationOptions();
  if (!record?.id) return null;
  return (
    <>
      <SectionTitle title="Email Addresses" />
      <ReferenceManyField
        label="Emails"
        reference="user-emails"
        target="user_id"
        sort={{ field: "primary", order: "DESC" }}
      >
        <Datagrid bulkActionButtons={false}>
          <EmailField source="email" sortable={false} />
          <BooleanField source="email_verified" sortable={false} />
          <BooleanField source="primary" sortable={false} />
          <DatagridActionsColumn>
            <EmailMakePrimaryButton />
          </DatagridActionsColumn>
          <DatagridActionsColumn label={<EmailCreateButton />}>
            <DeleteButton
              confirmTitle="Delete email"
              size="medium"
              redirect={false}
              mutationOptions={{
                onSuccess: refresh,
                onError: onError,
              }}
            />
          </DatagridActionsColumn>
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

export default EmailsManager;
