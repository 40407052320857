import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  Form,
  SaveButton,
  TextInput,
  useGetOne,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";

import { useCustomMutationOptions, useVapausMutation } from "../hooks";

export const UpdateOrderNumberButton = ({ orderId }: { orderId: string }) => {
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const { data: order } = useGetOne("bike-benefit-orders", { id: orderId });
  const { mutate, isLoading } = useVapausMutation(
    "partialUpdateBikeBenefitOrder",
  );
  const { onError } = useCustomMutationOptions();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleSubmit = (formData: any) => {
    mutate(
      { id: orderId, params: formData },
      {
        onSuccess: () => {
          notify("Order number has been updated");
          setShowDialog(false);
          refresh();
        },
        onError,
      },
    );
  };
  return (
    <>
      <IconButton color="primary" onClick={() => setShowDialog(true)}>
        <EditIcon fontSize="small" />
      </IconButton>
      {order && (
        <Dialog
          fullWidth
          open={showDialog}
          onClose={() => setShowDialog(false)}
          aria-label="Edit order number"
        >
          <DialogTitle>Edit order number</DialogTitle>
          <Form
            record={{ order_number: order.order_number }}
            onSubmit={handleSubmit}
          >
            <DialogContent>
              <TextInput source="order_number" fullWidth />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowDialog(false)}
                disabled={isLoading}
                startIcon={<CancelIcon />}
              >
                {translate("ra.action.cancel")}
              </Button>

              <SaveButton
                label="Save"
                disabled={isLoading}
                alwaysEnable={!isLoading}
              />
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </>
  );
};
