import { ReactElement } from "react";

import { MarkdownInput } from "@react-admin/ra-markdown";
import { SelectInput, SimpleForm, required } from "react-admin";

import { TERMS_TYPES_CHOICES } from "core";

type FormProps = {
  toolbar?: ReactElement;
  isEdit?: boolean;
};

const validateRequired = [required()];

const TermsForm = ({ toolbar, isEdit }: FormProps) => {
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      toolbar={toolbar}
      defaultValues={{ content: "Start writing new terms and conditions." }}
    >
      <SelectInput
        source="terms_type"
        choices={TERMS_TYPES_CHOICES}
        fullWidth
        validate={validateRequired}
        disabled={isEdit}
      />
      <MarkdownInput source="content" validate={validateRequired} />
    </SimpleForm>
  );
};

export default TermsForm;
