import React, { useEffect } from "react";

import {
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  required,
  useGetOne,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import {
  BikeBenefitOrderingMethod,
  Currency,
  ProductTypes,
  SupportedCountriesEnum,
} from "@vapaus/generated";

import { useHubspotTicketContext } from "./HubspotTicketContext";
import { CustomFormIterator, MoneyInput, UrlInput } from "components";
import { useEnumChoices, useProductVatRate } from "hooks";

export const OrderFormAccessories = () => {
  const { watch, setValue } = useFormContext();
  const { hubspotTicketData } = useHubspotTicketContext();
  const [benefitDefinitionId, orderingMethod] = watch([
    "benefit_definition_id",
    "ordering_method",
  ]);
  const { data: benefitDefinition } = useGetOne(
    "benefit-definitions",
    {
      id: benefitDefinitionId,
    },
    { enabled: !!benefitDefinitionId },
  );

  const accessoryTypes = useEnumChoices("AccessoryType");
  const accessoryVatRate = useProductVatRate(
    benefitDefinition?.organisation
      .country as unknown as SupportedCountriesEnum,
    ProductTypes.ACCESSORY,
  );

  useEffect(() => {
    if (hubspotTicketData.accessory_types)
      setValue(
        "accessories",
        hubspotTicketData.accessory_types.map((accessoryType) => ({
          accessory_type: accessoryType,
          manufacturer: "",
          model: "",
          purchase_price: null,
          purchase_vat_rate: accessoryVatRate,
        })),
      );
  }, [hubspotTicketData.accessory_types, setValue]);

  return (
    <ArrayInput source="accessories" fullWidth>
      <CustomFormIterator
        fullWidth
        disableReordering
        getItemLabel={(index: number) => `Accessory #${index + 1}`}
        actionsAlwaysVisible
      >
        <SelectInput
          source="accessory_type"
          choices={accessoryTypes}
          validate={required()}
          fullWidth
        />
        <TextInput source="manufacturer" validate={required()} fullWidth />
        <TextInput source="model" validate={required()} fullWidth />
        <NumberInput
          source="purchase_vat_rate"
          defaultValue={accessoryVatRate}
          sx={{ display: "none" }}
        />
        <AccessoryPurchasePriceInput currency={benefitDefinition?.currency} />
        {orderingMethod === BikeBenefitOrderingMethod.ONLINE_ORDER && (
          <UrlInput source="url" label="URL" validate={required()} fullWidth />
        )}
      </CustomFormIterator>
    </ArrayInput>
  );
};

const AccessoryPurchasePriceInput = ({
  source,
  index,
  currency,
}: {
  source?: string;
  index?: number;
  currency?: Currency;
}) => {
  return (
    <FormDataConsumer index={index} source={source}>
      {({ scopedFormData }) => (
        <MoneyInput
          label="Price"
          source={`${source}.purchase_price`}
          currency={currency}
          validate={required()}
          helperText={`VAT ${scopedFormData?.purchase_vat_rate?.toLocaleString(
            undefined,
            { style: "percent", maximumFractionDigits: 2 },
          )}`}
          fullWidth
        />
      )}
    </FormDataConsumer>
  );
};
