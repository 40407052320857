import React from "react";

import { Edit, useDataProvider, useRecordContext } from "react-admin";

import {
  AccessoryCreateUpdate,
  AccessoryStatus,
  AdminBikeBenefitOrderCreateUpdate,
} from "@vapaus/generated";

import { OrderForm } from "./components/OrderForm/OrderForm";
import { useCustomMutationOptions } from "hooks";

const OrderEditTitle = () => {
  const record = useRecordContext();
  return <span>Edit Benefit Order {record?.vapaus_code}</span>;
};

export const OrderEdit = () => {
  const mutationOptions = useCustomMutationOptions();
  const dataProvider = useDataProvider();
  const orderEditTransform = (data: AdminBikeBenefitOrderCreateUpdate) => {
    return dataProvider
      .getOne("benefit-definitions", { id: data.benefit_definition_id })
      .then((benefitDefinition) => {
        return {
          ...data,
          bike: data.bike
            ? {
                ...data.bike,
                shop_id: data.shop_id,
              }
            : undefined,
          accessories: data.accessories.map(
            (accessory: AccessoryCreateUpdate) => ({
              ...accessory,
              shop_id: data.shop_id,
              purchase_currency:
                accessory.purchase_currency ?? benefitDefinition.data.currency,
              status: accessory.status ?? AccessoryStatus.BENEFIT_BIKE,
            }),
          ),
        };
      });
  };
  return (
    <Edit
      redirect="show"
      mutationOptions={mutationOptions}
      mutationMode="pessimistic"
      transform={orderEditTransform}
      title={<OrderEditTitle />}
    >
      <OrderForm />
    </Edit>
  );
};
