import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import { DateInput, required } from "react-admin";
import { relativeMaxDate, relativeMinDate } from "validators";

type ParamsBetweenDatesProps = {
  required?: boolean;
};

const ParamsBetweenDates = ({
  required: requiredProps,
}: ParamsBetweenDatesProps) => {
  const validateStartDate = [relativeMaxDate("end_date")];
  const validateEndDate = [relativeMinDate("start_date")];

  if (requiredProps) {
    validateStartDate.unshift(required());
    validateEndDate.unshift(required());
  }

  return (
    <>
      <Grid item xs={6}>
        <DateInput
          defaultValue={DateTime.now()
            .minus({ months: 1 })
            .startOf("month")
            .toISODate()}
          source="start_date"
          label="Start Date"
          fullWidth
          validate={validateStartDate}
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          defaultValue={DateTime.now()
            .minus({ months: 1 })
            .endOf("month")
            .toISODate()}
          source="end_date"
          label="End Date"
          fullWidth
          validate={validateEndDate}
        />
      </Grid>
    </>
  );
};

export default ParamsBetweenDates;
