import { useEffect } from "react";

import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { Pagination } from "components";
import {
  BIKE_BENEFIT_ORDER_STATES_CHOICES,
  ORDERING_METHOD_CHOICES,
} from "core";

const BenefitDefinitionFilter = ({
  label,
  source,
}: {
  label: string;
  source: string;
}) => {
  const { watch, setValue } = useFormContext();
  const organisationId = watch("organisation_id");
  useEffect(() => {
    setValue(source, null);
  }, [organisationId]);
  return (
    <ReferenceInput
      source={source}
      reference="benefit-definitions"
      filter={{ organisation_id: organisationId }}
      enableGetChoices={() => !!organisationId}
    >
      {organisationId ? (
        <AutocompleteInput
          label={label}
          optionText="name"
          optionValue="id"
          fullWidth
        />
      ) : (
        <SelectInput
          label={label}
          source=""
          defaultValue={"empty"}
          choices={[{ id: "empty", name: "select organisation" }]}
          disabled
        />
      )}
    </ReferenceInput>
  );
};

const filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="organisation_id" reference="organisations" alwaysOn>
    <AutocompleteInput
      label="Organisation"
      optionText="name"
      optionValue="id"
    />
  </ReferenceInput>,
  <BenefitDefinitionFilter
    label="Benefit definition"
    source="benefit_definition_id"
  />,
  <SelectInput
    label="Ordering method"
    source="ordering_methods"
    choices={ORDERING_METHOD_CHOICES}
  />,
  <SelectInput
    label="Order status"
    source="states"
    choices={BIKE_BENEFIT_ORDER_STATES_CHOICES}
  />,
  <ReferenceInput source="shop_id" reference="shops">
    <AutocompleteInput
      label="Shop"
      optionText="name"
      optionValue="id"
      fullWidth
      isRequired
    />
  </ReferenceInput>,
  <BooleanInput
    label="For manual expiration"
    source="for_manual_expiration"
    defaultValue={true}
  />,
];

export const OrderList = () => (
  <List filters={filters} perPage={20} pagination={<Pagination />}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="vapaus_code" />
      <TextField source="user.full_name" />
      <ReferenceField
        source="benefit_definition_id"
        reference="benefit-definitions"
        sortBy="benefit_definition.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="state" textTransform="uppercase" label="Status" />
      <TextField source="bike.serial_number" label="Bike frame number" />
      <ReferenceField source="shop_id" reference="shops" sortBy="shop.name">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
