import { useVapausQuery } from "hooks/useCustomMutation";

import { ProductTypes, SupportedCountriesEnum } from "@vapaus/generated";

export const useProductVatRates = (country?: SupportedCountriesEnum) => {
  const { data, isSuccess } = useVapausQuery<Record<ProductTypes, number>>(
    "getProductVatRates",
    { country },
    { enabled: !!country },
  );
  return data;
};

export const useProductVatRate = (
  country: SupportedCountriesEnum | undefined,
  productType: ProductTypes,
) => {
  const rates = useProductVatRates(country);
  if (!rates) return undefined;
  return rates[productType];
};
