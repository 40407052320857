import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { ResourceProps } from "react-admin";

import { RedemptionProcessList } from "./RedemptionProcessList";

const redemptionProcessResource: ResourceProps = {
  name: "redemption-processes",
  icon: ShoppingBasketIcon,
  list: RedemptionProcessList,
  options: {
    label: "Redemption Processes",
  },
};

export default redemptionProcessResource;
