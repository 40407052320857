import { useRefreshableRow } from "components/RefreshableDatagridRow";
import { DeleteWithConfirmButton } from "react-admin";

export const DeleteErpSaleInvoiceLineAssignmentButton = () => {
  const { refresh } = useRefreshableRow();
  return (
    <DeleteWithConfirmButton
      confirmTitle="Are you sure?"
      confirmContent="Are you sure you want to delete this assignment?"
      redirect={false}
      mutationOptions={{
        onSuccess: refresh,
      }}
    />
  );
};
