import { ReactElement } from "react";

import { BooleanInput, SimpleForm, TextInput, required } from "react-admin";

type BikeBrandFormProps = {
  toolbar?: ReactElement;
};

const validateRequired = [required()];

const BikeBrandForm = ({ toolbar }: BikeBrandFormProps) => {
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      defaultValues={{
        active: true,
      }}
      toolbar={toolbar}
    >
      <TextInput source="name" validate={validateRequired} label="Name" />
      <BooleanInput source="active" label="Active" />
    </SimpleForm>
  );
};

export default BikeBrandForm;
