import React, { useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import { Card, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { round, sortBy } from "lodash-es";
import {
  Button,
  DateField,
  FunctionField,
  ImageField,
  Labeled,
  Link,
  RecordContextProvider,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useGetOne,
  useRecordContext,
} from "react-admin";

import {
  Accessory,
  ReturnProcessCancellation,
  ReturnProcessChanges,
  ReturnProcessCompletion,
  ReturnProcessHubspotTicket,
  ReturnProcessInvoicing,
  ReturnProcessPickupDetails,
  ReturnProcessPictureRead,
  ReturnProcessRead,
  ReturnProcessRedeemedAccessory,
  ReturnProcessStatus,
} from "@vapaus/generated";

import { CompleteReturnProcessModal } from "components";
import { useEnumLabel, useVapausQuery } from "hooks";

enum HistoryEntryType {
  PickupDetails,
  RedeemedAccessory,
  Picture,
  Invoicing,
  HubspotTicket,
  Completion,
  Cancellation,
}

export const ReturnProcessShow = () => {
  const getReturnProcessStatusLabel = useEnumLabel("ReturnProcessStatus");
  const getReturnReasonLabel = useEnumLabel(
    "BikeBenefitContractReturnOrRedemptionReason",
  );
  return (
    <Show actions={<ReturnProcessShowToolbar />}>
      <SimpleShowLayout>
        <Typography variant="h6">Return Process</Typography>
        <ReferenceField
          reference="bike-benefit-contracts"
          source="bike_benefit_contract_id"
          link="show"
        >
          <TextField source="vapaus_code" label="Contract" />
        </ReferenceField>
        <FunctionField<ReturnProcessRead>
          label="Return type"
          render={(process) =>
            process?.is_end_of_lease_return ? "End of lease" : "Mid-lease"
          }
        />
        <DateField source="lease_end_date" />
        <FunctionField<ReturnProcessRead>
          label="Reason"
          render={(process) => getReturnReasonLabel(process?.return_reason)}
        />
        <FunctionField<ReturnProcessRead>
          label="Status"
          render={(process) => getReturnProcessStatusLabel(process?.status)}
        />
        <Labeled label="Event history">
          <ReturnProcessEventHistory />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};

const ReturnProcessShowToolbar = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      {record?.status === ReturnProcessStatus.PROCESSING && (
        <CompleteReturnProcessButton />
      )}
    </TopToolbar>
  );
};

const CompleteReturnProcessButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const returnProcess = useRecordContext();
  return (
    <>
      <Button
        label="Complete"
        size="medium"
        onClick={() => {
          setShowDialog(true);
        }}
        startIcon={<CheckIcon />}
      />
      <CompleteReturnProcessModal
        open={showDialog}
        onClose={() => setShowDialog(false)}
        returnProcessId={returnProcess.id as string}
      />
    </>
  );
};

const ReturnProcessEventHistory = () => {
  const returnProcess = useRecordContext();
  const { data: changes } = useVapausQuery<ReturnProcessChanges>(
    "getReturnProcessChanges",
    returnProcess.id,
  );
  if (!changes) return null;
  const orderedChanges = sortBy(
    [
      changes.pickup_details
        ? {
            entryType: HistoryEntryType.PickupDetails,
            ...changes.pickup_details,
          }
        : null,
      ...changes.redeemed_accessories.map(
        (link: ReturnProcessRedeemedAccessory) => ({
          entryType: HistoryEntryType.RedeemedAccessory,
          ...link,
        }),
      ),
      ...changes.pictures.map((picture: ReturnProcessPictureRead) => ({
        entryType: HistoryEntryType.Picture,
        ...picture,
      })),
      changes.invoicing
        ? { entryType: HistoryEntryType.Invoicing, ...changes.invoicing }
        : null,
      changes.hubspot_ticket
        ? {
            entryType: HistoryEntryType.HubspotTicket,
            ...changes.hubspot_ticket,
          }
        : null,
      changes.completion
        ? {
            entryType: HistoryEntryType.Completion,
            ...changes.completion,
          }
        : null,
      changes.cancellation
        ? { entryType: HistoryEntryType.Cancellation, ...changes.cancellation }
        : null,
    ].filter(Boolean),
    (element: any) => element.created_at,
  );
  return (
    <>
      <Card>
        <CardContent>
          <DateField source="created_at" showTime sx={{ mr: 1 }} />
          <ReferenceField reference="users" source="initiated_by_user_id">
            <TextField source="full_name" />
          </ReferenceField>
          <Typography>Initiated</Typography>
        </CardContent>
      </Card>
      {orderedChanges.map((change: any) => (
        <Card key={change.id} sx={{ mt: 2 }}>
          <CardContent>
            <DateField
              source="created_at"
              showTime
              sx={{ mr: 1 }}
              record={change}
            />
            {change.entryType === HistoryEntryType.RedeemedAccessory && (
              <RedeemedAccessoryCardContent change={change} />
            )}
            {change.entryType === HistoryEntryType.Cancellation && (
              <CancellationCardContent change={change} />
            )}
            {change.entryType === HistoryEntryType.PickupDetails && (
              <PickupDetailsCardContent change={change} />
            )}
            {change.entryType === HistoryEntryType.Picture && (
              <PictureCardContent change={change} />
            )}
            {change.entryType === HistoryEntryType.Invoicing && (
              <InvoicingCardContent change={change} />
            )}
            {change.entryType === HistoryEntryType.Completion && (
              <CompletionCardContent change={change} />
            )}
            {change.entryType === HistoryEntryType.HubspotTicket && (
              <HubspotTicketCardContent change={change} />
            )}
          </CardContent>
        </Card>
      ))}
    </>
  );
};

const RedeemedAccessoryCardContent = ({
  change,
}: {
  change: ReturnProcessRedeemedAccessory;
}) => {
  const getAccessoryTypeLabel = useEnumLabel("AccessoryType");
  const returnProcess = useRecordContext();
  const { data: contract } = useGetOne("bike-benefit-contracts", {
    id: returnProcess.bike_benefit_contract_id,
  });
  if (!contract) return null;
  return (
    <>
      <ReferenceField reference="users" source="set_by_user_id" record={change}>
        <TextField source="full_name" />
      </ReferenceField>
      <Typography>Added redeemed accessory</Typography>
      <RecordContextProvider
        value={contract.accessories.find(
          (accessory: Accessory) => accessory.id === change.accessory_id,
        )}
      >
        <SimpleShowLayout sx={{ p: 0, mt: 1 }}>
          <FunctionField<Accessory>
            render={(accessory) =>
              getAccessoryTypeLabel(accessory?.accessory_type)
            }
            label="Accessory type"
          />
          <TextField source="manufacturer" emptyText="-" />
          <TextField source="model" emptyText="-" />
        </SimpleShowLayout>
      </RecordContextProvider>
    </>
  );
};

const CompletionCardContent = ({
  change,
}: {
  change: ReturnProcessCompletion;
}) => (
  <>
    <ReferenceField
      reference="users"
      source="confirmed_by_user_id"
      record={change}
    >
      <TextField source="full_name" />
    </ReferenceField>
    <Typography>Completed</Typography>
    <RecordContextProvider value={change}>
      <SimpleShowLayout sx={{ p: 0, mt: 1 }}>
        <DateField source="lease_end_date" />
      </SimpleShowLayout>
    </RecordContextProvider>
  </>
);

const CancellationCardContent = ({
  change,
}: {
  change: ReturnProcessCancellation;
}) => (
  <>
    <ReferenceField
      reference="users"
      source="cancelled_by_user_id"
      record={change}
    >
      <TextField source="full_name" />
    </ReferenceField>
    <Typography>Cancelled</Typography>
  </>
);

const PickupDetailsCardContent = ({
  change,
}: {
  change: ReturnProcessPickupDetails;
}) => (
  <RecordContextProvider value={change}>
    <ReferenceField reference="users" source="set_by_user_id">
      <TextField source="full_name" />
    </ReferenceField>
    <Typography>Set pickup details</Typography>
    <SimpleShowLayout sx={{ p: 0, mt: 1 }}>
      <DateField source="requested_pickup_date" />
      <TextField source="address" />
      <TextField source="post_code" />
      <TextField source="city" />
      <TextField source="country" />
      <TextField source="phone_number" />
    </SimpleShowLayout>
  </RecordContextProvider>
);

const PictureCardContent = ({
  change,
}: {
  change: ReturnProcessPictureRead;
}) => (
  <RecordContextProvider value={change}>
    <ReferenceField reference="users" source="added_by_user_id">
      <TextField source="full_name" />
    </ReferenceField>
    <Typography>Attached picture</Typography>
    {change.picture_url ? (
      <Link to={change.picture_url} target="_blank">
        <ImageField source="picture_url" />
      </Link>
    ) : null}
  </RecordContextProvider>
);

const InvoicingCardContent = ({
  change,
}: {
  change: ReturnProcessInvoicing;
}) => {
  const getSaleInvoiceLineTypeLabel = useEnumLabel("SaleInvoiceLineType");
  return (
    <RecordContextProvider value={change}>
      <ReferenceField reference="users" source="confirmed_by_user_id">
        <TextField source="full_name" />
      </ReferenceField>
      <Typography>Confirmed invoicing</Typography>
      <SimpleShowLayout sx={{ p: 0, mt: 1 }}>
        <ReferenceField
          reference="invoices/sale-invoices"
          source="sale_invoice_id"
          emptyText="-"
        >
          <TextField source="invoice_number" />
        </ReferenceField>
        <Labeled label="Invoicing plan">
          <Stack divider={<Divider />} spacing={1}>
            {change.invoicing_actions.length == 0 && (
              <Typography variant="body2">
                no invoicing actions planned
              </Typography>
            )}
            {change.invoicing_actions.map((action) => (
              <div>
                <Typography variant="body2">
                  <b>Type</b>:{" "}
                  {getSaleInvoiceLineTypeLabel(action.line_in.type)}
                </Typography>
                {action.line_in.description ? (
                  <Typography variant="body2">
                    <b>Description</b>: {action.line_in.description}
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  <b>Amount</b>:{" "}
                  {round(
                    action.line_in.price *
                      action.line_in.quantity *
                      (1 + action.line_in.vat_rate),
                    2,
                  )}
                </Typography>
                <Typography variant="body2">
                  <b>Notes</b>: {action.notes}
                </Typography>
                <Typography variant="body2">
                  <b>Paid by</b>: {action.payer}
                </Typography>
              </div>
            ))}
          </Stack>
        </Labeled>
      </SimpleShowLayout>
    </RecordContextProvider>
  );
};

const HubspotTicketCardContent = ({
  change,
}: {
  change: ReturnProcessHubspotTicket;
}) => (
  <RecordContextProvider value={change}>
    <Typography>Registered in HubSpot</Typography>
    <SimpleShowLayout sx={{ p: 0, mt: 1 }}>
      <TextField source="hubspot_ticket_id" />
    </SimpleShowLayout>
  </RecordContextProvider>
);
