import ShopIcon from "@mui/icons-material/Store";
import { ResourceProps } from "react-admin";

import { ShopCreate } from "./ShopCreate";
import { ShopEdit } from "./ShopEdit";
import { ShopList } from "./ShopList";

const shopResource: ResourceProps = {
  name: "shops",
  icon: ShopIcon,
  create: ShopCreate,
  edit: ShopEdit,
  list: ShopList,
};

export default shopResource;
