import { EditInDialogButton } from "@react-admin/ra-form-layout";

import { LocationForm } from "./LocationForm";
import { EditToolbar } from "components";

export const LocationEditButton = () => {
  return (
    <EditInDialogButton inline fullWidth maxWidth="sm" title="Edit location">
      <LocationForm toolbar={<EditToolbar />} />
    </EditInDialogButton>
  );
};
