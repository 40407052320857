import React from "react";

import { Confirm, Create, useNotify, useRedirect } from "react-admin";

import TermsForm from "./TermsForm";
import { useCustomMutation, useCustomMutationOptions } from "hooks";

export const TermsCreate = () => {
  const mutationOptions = useCustomMutationOptions();
  const notify = useNotify();
  const redirect = useRedirect();
  const [termsAndConditionsId, setTermsAndConditionsId] = React.useState<
    string | null
  >(null);

  const { mutate } = useCustomMutation(
    "activateTermsAndConditions",
    termsAndConditionsId,
    {
      onSuccess: () => {
        redirect("/terms");
      },
    },
  );

  const onSuccess = (data: any) => {
    notify("ra.notification.created");
    setTermsAndConditionsId(data.id);
  };

  return (
    <Create mutationOptions={{ ...mutationOptions, onSuccess }} redirect="list">
      <>
        <TermsForm />
        <Confirm
          isOpen={Boolean(termsAndConditionsId)}
          title="Set these terms and conditions as active"
          content="Do you want to set the new terms and conditions you just created as active?"
          onConfirm={() => {
            setTermsAndConditionsId(null);
            mutate();
          }}
          onClose={() => {
            setTermsAndConditionsId(null);
            redirect("/terms");
          }}
        />
      </>
    </Create>
  );
};
