import React from "react";

import { Edit, useRecordContext } from "react-admin";

import OrganisationForm from "./OrganisationForm";
import { EditToolbar } from "components";
import { useCustomMutationOptions } from "hooks";

const OrganisationTitle = () => {
  const record = useRecordContext();
  return <span>{record?.name}</span>;
};

export const OrganisationEdit = () => {
  const mutationOptions = useCustomMutationOptions();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
      redirect="list"
      title={<OrganisationTitle />}
    >
      <OrganisationForm toolbar={<EditToolbar />} />
    </Edit>
  );
};
