import { FC } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import StepConnector from "@mui/material/StepConnector";
import Typography from "@mui/material/Typography";
import { useEnumLabel } from "hooks/useEnum";
import {
  DateField,
  FunctionField,
  Link,
  ReferenceField,
  TextField,
} from "react-admin";

import { BikeBenefitOrderRead } from "@vapaus/generated";

import { useSummary } from "../hooks/useSummary";
import { CurrencyGrossField, UpdateOrderNumberButton } from "components";

export const OrderSummary: FC = () => {
  const {
    id,
    accessories_count,
    additional_services_count,
    maintenance_budget,
    leasing_period_months,
    monthly_maintenance_budget,
    toMoney,
    ...summary
  } = useSummary();
  const getOrderingMethodLabel = useEnumLabel("BikeBenefitOrderingMethod");
  return (
    <Card variant="outlined">
      <CardContent sx={{ mb: 0 }}>
        <Typography variant="h6" component="div" sx={{ mb: 1.5 }}>
          Order Summary
        </Typography>
        <div className="order-summary-item">
          <Typography className="label" component="div">
            Order number
          </Typography>
          <Typography className="value" color="text.secondary">
            <TextField source="order_number" emptyText="-" />
            <UpdateOrderNumberButton orderId={id} />
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography className="label" component="div">
            Ordering method
          </Typography>
          <Typography className="value" color="text.secondary">
            <FunctionField<BikeBenefitOrderRead>
              render={(record) => (
                <Link
                  to={{
                    pathname: "/bike-benefit-orders",
                    search: `filter=${JSON.stringify({
                      ordering_methods: record!.ordering_method,
                    })}`,
                  }}
                >
                  {getOrderingMethodLabel(record!.ordering_method)}
                </Link>
              )}
            />
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography className="label" component="div">
            Order date
          </Typography>
          <Typography className="value" color="text.secondary">
            <DateField source="created_at" />
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography className="label" component="div">
            Shop name
          </Typography>
          <Typography className="value" color="text.secondary">
            <ReferenceField source="shop_id" reference="shops">
              <TextField source="name" />
            </ReferenceField>
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography className="label" component="div">
            Organisation
          </Typography>
          <Typography className="value" color="text.secondary">
            <ReferenceField
              source="benefit_definition_id"
              reference="benefit-definitions"
            >
              <ReferenceField
                source="organisation_id"
                reference="organisations"
              >
                <TextField source="name" />
              </ReferenceField>
            </ReferenceField>
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography className="label" component="div">
            Benefit
          </Typography>
          <Typography className="value" color="text.secondary">
            <ReferenceField
              source="benefit_definition_id"
              reference="benefit-definitions"
            >
              <TextField source="name" />
            </ReferenceField>
          </Typography>
        </div>
        <StepConnector sx={{ mb: 1.5, mt: 1.5 }} />

        <div className="order-summary-item">
          <Typography className="label" component="div">
            Bike Price
          </Typography>
          <Typography className="value" color="text.secondary">
            <CurrencyGrossField
              record={summary}
              source="bike_price"
              vatRate={summary.flat_vat_rate}
              currencySource="currency"
            />
          </Typography>
        </div>

        {accessories_count > 0 && (
          <div className="order-summary-item">
            <Typography className="label" component="div">
              {accessories_count} Accessories
            </Typography>
            <Typography className="value" color="text.secondary">
              <CurrencyGrossField
                record={summary}
                source="accessories_total"
                vatRate={summary.flat_vat_rate}
                currencySource="currency"
              />
            </Typography>
          </div>
        )}
        {summary.delivery_transport_cost > 0 && (
          <div className="order-summary-item">
            <Typography className="label" component="div">
              Delivery Price
            </Typography>
            <Typography className="value" color="text.secondary">
              <CurrencyGrossField
                record={summary}
                source="delivery_transport_cost"
                vatRate={summary.flat_vat_rate}
                currencySource="currency"
              />
            </Typography>
          </div>
        )}
        {additional_services_count > 0 && (
          <div className="order-summary-item">
            <Typography className="label" component="div">
              {additional_services_count} Additional Services
            </Typography>
            <Typography className="value" color="text.secondary">
              <CurrencyGrossField
                record={summary}
                source="additional_services_total"
                vatRate={summary.flat_vat_rate}
                currencySource="currency"
              />
            </Typography>
          </div>
        )}
        {maintenance_budget > 0 && (
          <div className="order-summary-item">
            <Typography className="label" component="div">
              Maintenance Budget ({toMoney(monthly_maintenance_budget)} x{" "}
              {leasing_period_months})
            </Typography>
            <Typography className="value" color="text.secondary">
              <FunctionField render={() => toMoney(maintenance_budget)} />
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
